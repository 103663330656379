/*
时间库
*/
import moment from 'moment/moment';

export class DateTime {
  static currentTime() {
    return moment(new Date()).format('YYYY-MM-DD HH:MM:ss');
  }

  static timeMonthDay(date = new Date()) {
    return moment(date).format('M.DD');
  }

  static diffDay(timeFrom, timeTo) {
    return moment(timeTo).diff(moment(timeFrom), 'days');
  }

  static diffHours(timeFrom, timeTo) {
    return moment(timeTo).diff(moment(timeFrom), 'hours');
  }
}

/**
 *
 * 根据 crossDay 返回两个时间点 [timeFrom, timeTo]，不会保留传入 date 的小时和分钟精度
 * * timeFrom 格式，一天的起始：YYYY-MM-DDT00:00:00.000ZZ
 * * timeTo 格式，一天的结束：YYYY-MM-DDT23:59:59.999ZZ
 * * // https://momentjs.com/docs/#/displaying/
 * * timeFromFormateTemplate: timeFrom 格式化模版
 * * timeToFormateTemplate: timeTo 格式化模版
 * @param {*} date 日期
 * @param {*} crossDay 跨越的时间范围，传入负数即返回 timeFrom: date-crossDay, timeTo: date
 * @returns
 */
export const genTimeRange = (
  date,
  crossDay,
  timeFromFormateTemplate = 'YYYY-MM-DDT00:00:00.000ZZ',
  timeToFormateTemplate = 'YYYY-MM-DDT23:59:59.999ZZ',
) => {
  const datetime = date || new Date();
  const range = [moment(datetime), moment(datetime).add(crossDay, 'days')];
  if (crossDay < 0) {
    range.reverse();
  }
  const [timeFrom, timeTo] = range;
  return [timeFrom.format(timeFromFormateTemplate), timeTo.format(timeToFormateTemplate)];
};

// 时间戳转时间 2022-4-1 11:10:00
export const formatTime = (timestamp, type) => {
  const time = new Date(timestamp * 1000);
  const y = time.getFullYear();
  const m = time.getMonth() + 1;
  const d = time.getDate();
  const h = time.getHours();
  const mm = time.getMinutes();
  const s = time.getSeconds();
  const result = [`${y}-${addZero(m)}-${addZero(d)} ${addZero(h)}:${addZero(mm)}:${addZero(s)}`,
    `${addZero(h)}:${addZero(mm)}:${addZero(s)}`];
  return result[type];
};

function addZero(m) {
  return m < 10 ? `0${m}` : m;
}

export const dataToTime = time => formatTime(Date.parse(new Date(time)) / 1000, 0);

export const timeToTimestmp = time => Date.parse(new Date(time)) / 1000;
export const weekTimestamp = 7 * 24 * 60 * 60;

export const taskTimeFormat = (taskTime) => {
  if (taskTime?.Valid) {
    const time = new Date(taskTime.Time);
    const y = time.getFullYear();
    const m = time.getMonth() + 1;
    const d = time.getDate();
    const h = time.getHours();
    const mm = time.getMinutes();
    const s = time.getSeconds();
    return `${y}-${addZero(m)}-${addZero(d)} ${addZero(h)}:${addZero(mm)}:${addZero(s)}`;
  }
  return '--';
};
