import ActionTypes from './DropActionTypes';

const INITIAL_STATE = {
  SendStartRes: [],
  GetInfoRes: [],
  GetAgentInfoIPRes: [],
  GetDropTaskResultRes: [],
  GetDropResultImage: [],
  GetDropCost: [],
  DeleteDropTask: [],
  GetDropAgentList: [],
  GetAgentMonitorData: [],
  GetUserUid: [],
  StartDiskTracing: [],
  CreateGroup: [],
  DisbandGroup: [],
  AddGroupMember: [],
  RemoveGroupMember: [],
  GetGroup: [],
  GetGroupAgent: [],
  AddGroupAgent: [],
  RemoveGroupAgent: [],
  GetGroups: [],
  GetUserAgents: [],
  GetDropResultCallgraphImage: [],
  GetCallgraphData: [],
  GetTaskResult: [],
  GetSharedTaskURL: [],
  GetFlamegraphData: [],
  RemoveAgent: [],
};
const DropReducer = (state = INITIAL_STATE, action) => {
  // eslint-disable-next-line
  state = INITIAL_STATE;
  switch (action.type) {
    case ActionTypes.DID_SEND_DROP_START: {
      return Object.assign({}, state, { SendStartRes: action.payload });
    }
    case ActionTypes.DID_GET_DROP_INFO: {
      return Object.assign({}, state, { GetInfoRes: action.payload });
    }
    case ActionTypes.DID_GET_DROP_AGENT_INFO_IP: {
      return Object.assign({}, state, { GetAgentInfoIPRes: action.payload });
    }
    // 添加drop_result
    case ActionTypes.DID_GET_DROP_TASK_RESULT: {
      return Object.assign({}, state, { GetDropTaskResultRes: action.payload });
    }
    case ActionTypes.DID_GET_DROP_RESULT_IMAGE: {
      return Object.assign({}, state, { GetDropResultImage: action.payload });
    }
    case ActionTypes.DID_GET_DROP_COST: {
      return Object.assign({}, state, { GetDropCost: action.payload });
    }
    case ActionTypes.DID_DELETE_DROP_TASK: {
      return Object.assign({}, state, { DeleteDropTask: action.payload });
    }
    case ActionTypes.DID_GET_DROP_AGENT_LIST: {
      return Object.assign({}, state, { GetDropAgentList: action.payload });
    }
    case ActionTypes.DID_GET_AGENT_MONITOR_DATA: {
      return Object.assign({}, state, { GetAgentMonitorData: action.payload });
    }
    case ActionTypes.DID_GET_USER_UID: {
      return Object.assign({}, state, { GetUserUid: action.payload });
    }
    case ActionTypes.DID_START_DISK_TRACING: {
      return Object.assign({}, state, { StartDiskTracing: action.payload });
    }
    case ActionTypes.DID_CREATE_GROUP: {
      return Object.assign({}, state, { CreateGroup: action.payload });
    }
    case ActionTypes.DID_DISBAND_GROUP: {
      return Object.assign({}, state, { DisbandGroup: action.payload });
    }
    case ActionTypes.DID_ADD_GROUP_MEMBER: {
      return Object.assign({}, state, { AddGroupMember: action.payload });
    }
    case ActionTypes.DID_REMOVE_GROUP_MEMBER: {
      return Object.assign({}, state, { RemoveGroupMember: action.payload });
    }
    case ActionTypes.DID_GET_GROUP: {
      return Object.assign({}, state, { GetGroup: action.payload });
    }
    case ActionTypes.DID_GET_GROUP_AGENT: {
      return Object.assign({}, state, { GetGroupAgent: action.payload });
    }
    case ActionTypes.DID_ADD_GROUP_AGENT: {
      return Object.assign({}, state, { AddGroupAgent: action.payload });
    }
    case ActionTypes.DID_REMOVE_GROUP_AGENT: {
      return Object.assign({}, state, { RemoveGroupAgent: action.payload });
    }
    case ActionTypes.DID_GET_GROUPS: {
      return Object.assign({}, state, { GetGroups: action.payload });
    }
    case ActionTypes.DID_GET_USER_AGENTS: {
      return Object.assign({}, state, { GetUserAgents: action.payload });
    }
    case ActionTypes.DID_GET_DROP_RESULT_CALLGRAPH_IMAGE: {
      return Object.assign({}, state, { GetDropResultCallgraphImage: action.payload });
    }
    case ActionTypes.DID_GET_CALLGRAPH_DATA: {
      return Object.assign({}, state, { GetCallgraphData: action.payload });
    }
    case ActionTypes.DID_GET_TASK_RESULT: {
      return Object.assign({}, state, { GetTaskResult: action.payload });
    }
    case ActionTypes.DID_GET_SHARED_TASK_URL: {
      return Object.assign({}, state, { GetSharedTaskURL: action.payload });
    }
    case ActionTypes.DID_GET_FLAMEGRAPH_DATA: {
      return Object.assign({}, state, { GetFlamegraphData: action.payload });
    }
    case ActionTypes.DID_REMOVE_AGENT: {
      return Object.assign({}, state, { RemoveAgent: action.payload });
    }
    default:
      return state;
  }
};

export default DropReducer;
