import React, { useState, useEffect } from 'react';
import { Table, Card, SearchBox } from '@tencent/tea-component';
import PropTypes from 'prop-types';
const { pageable, autotip, sortable, filterable } = Table.addons;
// 受控组件
const TaskList = (props) => {
  // hook
  const [inputKeyword, setInputKeyword] = useState('');
  const [keyword, setKeyword] = useState('');
  const [loading, setLoading] = useState(false);
  const [records, setRecords] = useState([]);  // 实际展示的数据

  useEffect(() => {
    setInputKeyword(keyword);
    if (props.records.length === 0) {
      return;
    }
    if (!keyword) {
      setRecords(props.records);
      return;
    }
    setLoading(true);
    setTimeout(() => { // BUG: 组件销毁后，需要从全局定时器中释放
      let tempRecord = props.records;
      if (keyword) {
        tempRecord = props.searchFilter(tempRecord, keyword);
      }
      setRecords(tempRecord);
      setLoading(false);
    }, 800);
  }, [keyword]);

  useEffect(() => {
    let tempRecord = props.records;
    if (keyword) {
      tempRecord = props.searchFilter(tempRecord, keyword);
    }
    setRecords(tempRecord);
  }, [props.records]);

  const addons = [
    pageable(props.pageable),
    autotip({
      isLoading: loading,
      isFound: Boolean(keyword),
      onClear: () => setKeyword(''),
      foundKeyword: keyword,
      emptyText: props.tableEmptyText,
    }),
  ];

  if ('sortable' in props) {
    addons.push(sortable(props.sortable));
  }

  // TODO: 支持多个过滤项
  if ('filterable' in props) {
    addons.push(filterable(props.filterable));
  }

  let searchBox = <></>;
  if ('searchFilter' in props) {
    searchBox = (
      <>
        <SearchBox
          value={inputKeyword}
          onChange={inputKeyword => setInputKeyword(inputKeyword)}
          onSearch={keyword => setKeyword(keyword)}
          onClear={() => setKeyword('')}
          placeholder={props.searchPlaceholder}
        />
      </>
    );
  };

  return (
    <div style={props.style}>
      <Card.Body
        title={props.cardTitle}
        subtitle={props.cardSubTitle}
        operation={searchBox}
      >
        <Table
          style={props.tableStyle}
          columns={props.columns}
          recordKey={props.recordKey}
          records={records}
          addons={addons}
        ></Table>
      </Card.Body>
    </div>
  );
};

TaskList.propTypes = {
  cardTitle: PropTypes.string,  // card标题
  cardSubTitle: PropTypes.any, // card副标题
  records: PropTypes.array, // 表格内容
  style: PropTypes.object,  // 整体style
  pageable: PropTypes.object,  // 表格行数
  columns: PropTypes.array,  // 表格列
  tableStyle: PropTypes.object,  // 表格style
  searchPlaceholder: PropTypes.string,  // 搜索提示符
  searchFilter: PropTypes.func,  // 过滤条件
  tableEmptyText: PropTypes.string, // 空数据表格展示
  recordKey: PropTypes.string, // recordKey
  sortable: PropTypes.object,
  filterable: PropTypes.object,
};


// NOTE: 设置默认排序函数需要用到
export { sortable }; // 导出Table.addons sortable，供外部使用
export default TaskList;
