import React, { memo, useState, useEffect } from 'react';
import { Justify,
  message,
  Card,
  Modal,
} from '@tencent/tea-component';
import IndexAgentList from './agentList';
import IndexTaskList from './taskList';
import IndexUserInfo from './userInfo';
import { getOAName } from '@/utils/Storage';
import { goodStatus, doesntHaveStatus, badStatus } from '@/utils/ErrorConfirm';
import { HOST_URL } from '@/config';
import axios from 'axios';
import { withRouter } from 'react-router-dom';
import CheckModal from '@/components/checkModal';
import StatAgentModal from './statAgentModal';
import { bindActionCreators } from 'redux';
import DropActionCreator from '../../api/DropActions';
import { connect } from 'react-redux';
import StartTaskComponent from '../../components/startTaskComponent';
import qs from 'qs';
import { jumpTargetConfig } from '../../utils/URL';

const index = withRouter((props) => {
  // hook
  const [username] = useState(getOAName());
  const [agents, setAgents] = useState([]);
  const [tasks, setTasks] = useState([]);
  const [deleteTaskObject, setDeteleTaskObject] = useState({});
  const [statAgentResult, setStatAgentResult] = useState({
    selfPstats: [],
    childrenPstats: [],
  });

  // hook-status
  const [showDeleteTask, setShowDeleteTask] = useState(false);
  const [showStatAgent, setShowStatAgent] = useState(false);
  const [showStartTask, setShowStartTask] = useState(false);

  // effect
  // init
  useEffect(() => {
    queryAgents();
    queryTasks();
  }, []);

  // polling
  useEffect(() => {
    const pollingAgents = setInterval(() => {
      queryAgents();
    }, 2000);
    return () => clearInterval(pollingAgents);
  }, []);

  useEffect(() => {
    const pollingTasks = setInterval(() => {
      queryTasks();
    }, 2000);
    return () => clearInterval(pollingTasks);
  }, []);

  useEffect(() => {
    const { RemoveAgent } = props.DropReducer;

    if (Object.keys(RemoveAgent).length) {
      if (doesntHaveStatus(RemoveAgent)) {
        message.error({
          content: `error:${RemoveAgent?.message}`,
        });
      } else if (badStatus(RemoveAgent)) {
        message.error({
          content: `error:${RemoveAgent?.message}`,
        });
      }
      if (goodStatus(RemoveAgent)) {
        message.success({
          content: '移除成功',
        });
      }
    }
  }, [props.DropReducer]);

  // func
  const queryTasks = () => {
    axios({
      method: 'get',
      url: `${HOST_URL}/api/v1/tasks`,
      params: {
        user: username,
      } })
      .then((response) => {
        if (goodStatus(response)) {
          setTasks(response.data.tasks.sort((a, b) => Date.parse(b.create_time.Time) - Date.parse(a.create_time.Time)));
        } else {
          message.error({
            content: '用户信息获取失败,请重试',
          });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const queryAgents = () => {
    axios({
      method: 'get',
      url: `${HOST_URL}/api/v1/agents`,
      params: {
        user: username,
      } })
      .then((response) => {
        if (goodStatus(response)) {
          setAgents(response?.data?.agents);
        } else {
          message.error({
            content: '用户信息获取失败,请重试',
          });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const removeAgent = (cvm) => {
    Modal.confirm({
      size: 's',
      message: <Modal.Body>移除机器不会影响已有任务，确认移除？</Modal.Body>,
      onOk: () => {
        props.DropAction.removeAgent(cvm.id);
      },
    });
  };

  const deleteTask = (deleteTaskObject) => {
    axios({
      method: 'delete',
      url: `${HOST_URL}/api/v1/tasks/${deleteTaskObject.tid}`,
    })
      .then((response) => {
        if (goodStatus(response)) {
          setShowDeleteTask(false);
          message.success({
            content: '删除成功',
          });
        } else {
          message.error({
            content: '删除失败',
          });
        }
      })
      .catch((error) => {
        console.log(error);
        message.success({
          content: '系统错误',
        });
      });
  };

  const statAgent = (agent) => {
    axios({
      method: 'get',
      url: `${HOST_URL}/api/v1/agent/stat`,
      params: {
        target_ip: agent.ipaddr,
      } })
      .then((response) => {
        if (goodStatus(response)) {
          setStatAgentResult(response?.data?.result);
        } else {
          message.error({
            content: '信息获取失败,请重试',
          });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  //  route
  const routeTo = (to) => {
    props.history.push(to);
  };

  // render
  return <>
    <Justify
    left={
      <IndexUserInfo
        style={{ height: '290px', width: '330px', display: 'inline-block' }}
        username={ username }
        agentsNumber={ agents.length }
        tasksNumber={ tasks.length }
        setShowTaskTable = { () => {
          setShowStartTask(true);
        } }
      />
    }
    right={
      <IndexAgentList
        style={{
          height: '290px', width: '1000px', marginLeft: '27.5px' }}
        agents={ agents }
        onSearchAgent = { () => {} }
        onAccess = { () => {
          routeTo('user');
        }}
        onAgent = { () => {
          routeTo('agents');
        }}
        viewTask = { (cvm) => {
          // props.history.push({ pathname: 'tasksByAgent', search: qs.stringify({ agent: cvm.ipaddr }) });
          window.open(`/tasksByAgent?${qs.stringify({ agent: cvm.ipaddr  })}`, jumpTargetConfig());
        }}
        statAgent = { (cvm) => {
          setShowStatAgent(true);
          statAgent(cvm);
        }}
        getAgents = { () => agents}
        removeAgent = { cvm => removeAgent(cvm) }
      />}
    >
    </Justify>
    <Card>
      <IndexTaskList
        style={{ marginTop: '20px' }}
        tasks={ tasks }
        viewTask = { (task) => {
          window.open(
            `/task/result?${qs.stringify({ taskID: task.tid, isSharedURL: false, jumped: true })}`,
            jumpTargetConfig(),
          );
        } }
        viewTasks = { () => {
          routeTo('tasks');
        }}
        deleteTask = { (task) => {
          setShowDeleteTask(true);
          setDeteleTaskObject(task);
        }}
      />
    </Card>
    <CheckModal
      caption='确定删除任务?'
      visable = {showDeleteTask}
      bodyText = '删除后无法恢复,是否删除?'
      okText='确认'
      cancelText='取消'
      onClick={ () => {
        deleteTask(deleteTaskObject);
      }}
      onClose={ () => {
        setShowDeleteTask(false);
      }}
    />
    <StatAgentModal
      visable = { showStatAgent }
      onClose = { () => setShowStatAgent(false) }
      result = { statAgentResult }
    />
    <StartTaskComponent
      visable={showStartTask}
      agents={agents}
      onClose={ () => setShowStartTask(false) }
    ></StartTaskComponent>
  </> ;
});


// 只存一次接口数据 下次请求会替换掉当前的数据 所以需要把数据保存到state里
const mapStateToProps = (state) => {
  const { DropReducer } = state;
  return {
    DropReducer,
  };
};// Reducer

const mapDispatchToProps = (dispatch) => {
  const DropAction = bindActionCreators(DropActionCreator, dispatch);

  return {
    DropAction,
  };
};// Action


export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(memo(index));
