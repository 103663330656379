import React, { useState, useEffect } from 'react';
import { Table, Card, Button, _SearchBox, _StatusTip, Tooltip } from '@tencent/tea-component';
import PropTypes from 'prop-types';
import EmptyTask from '../../dropIndex/emptyTask';
// import ButtonShareTask from '@/components/buttonShareTask';
import { TASK_STATUS_INFO, _TASK_TYPE_MAP, _CODE_TO_MESSAGE } from '../../../config';
import { Log } from '@/utils/Log';
import { taskTimeFormat } from '../../../utils/DateTime';
import FibonaButton from '@/components/fibona';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import DropActionCreator from '@/api/DropActions';
import { FIBONA_TRIGGER } from '@/config';
import axios from 'axios';
import { HOST_URL } from '@/config/';
const { pageable, _autotip, filterable } = Table.addons;
const ALL_VALUE = '__ALL__';
// 受控组件
const CompareTaskList = (props) => {
  Log.debug('CompareTaskList', props);
  Log.debug('CompareTaskList', props.domref);
  // hook
  const [_inputKeyword, setInputKeyword] = useState('');
  const [keyword, setKeyword] = useState('');
  const [_loading, setLoading] = useState(false);
  const [tasks, setTasks] = useState([]);
  const [taskType, setTaskType] = useState(ALL_VALUE);

  useEffect(() => {
    setKeyword("Golang");
    setInputKeyword(keyword);
    if (props.tasks.length === 0) {
      return ;
    }
    setLoading(true);
    setTimeout(() => {
      let tempTask = props.tasks;
      if (keyword) {
        tempTask = tempTask.filter(x => x.name.includes(keyword)
            || x.target_ip.includes(keyword));
      }
      if (taskType !== ALL_VALUE) {
        tempTask = tempTask.filter(x => x.type === taskType);
      }
      setTasks(tempTask);
      setLoading(false);
    }, 800);
  }, [keyword, taskType]);

  useEffect(() => {
    let tempTask = props.tasks;
    if (keyword) {
      tempTask = tempTask.filter(x => x.name.includes(keyword)
          || x.target_ip.includes(keyword));
    }
    if (taskType !== ALL_VALUE) {
      tempTask = tempTask.filter(x => x.type === taskType);
    }
    setTasks(tempTask);
  }, [props.tasks]);

    // effect
  async function handleClick() {
      // 调用外部传入的回调函数
      props.FibonaClick();
  };

  const columns = [
    {
      header: '任务名',
      key: 'name',
      align: 'center',

    },

    {
      header: '目标机器IP',
      key: 'target_ip',
      align: 'center',
      render: (record) => {
        const target = record.target_ip;
        if (target === '-') { // 复合任务可能存在多个目标主机的情况
          return <Tooltip title="该任务存在多个目标主机，请查看任务详情">{target}</Tooltip>;
        }
        Log.debug("record", record);
        return target;
      },
    },
    {
      header: '任务ID',
      key: 'uid',
      align: 'center',
      render: record => record.tid,
      width: 150,
    },
    {
      header: '新建时间',
      key: 'create_time',
      align: 'center',
      render: record => taskTimeFormat(record.create_time),
      width: 150,
    },
    {
      header: '状态',
      key: 'status',
      align: 'center',
      render: (record) => {
        Log.debug("record", record);
        const key = record.status * 10 + record.analysis_status;
        return TASK_STATUS_INFO[key];
      },

    },
    {
      header: '操作',
      key: 'action',
      align: 'center',
      render: (record) => {
      return <div style={{ display: 'flex' }}>
      <FibonaButton plain title="进行对比分析"
      trigger={FIBONA_TRIGGER.golangmem}
      data={async ()=>  await getPrompt(record.tid, props.selfTaskID)}
      FibonaClick={async ()=>  await handleClick()} // 为了确认Fibona按钮被按下了
      domref={props.domref.current}
      callback={props.callback}
      />
      </div>;
      },
      width: 150,
    },
  ];

  async function getPrompt(targetTaskID, selfTaskID) {
    Log.debug("ttaskid", targetTaskID, selfTaskID);
    // setselfTaskID(selfTaskID);
    // settargetTaskID(targetTaskID);
    let prompt = "第一个任务:\n";
    const firstprompt = await getInformationOfID(targetTaskID);
    prompt = prompt + firstprompt;
    prompt = `${prompt} 第二个任务:\n `;
    const secondprompt = await getInformationOfID(selfTaskID);
    prompt = prompt + secondprompt;
    Log.debug("final", firstprompt, prompt);
    return prompt;
  };

  async function  getInformationOfID(taskID) {
    // 获取热点函数
    let taskresult;
    await axios({
      method: 'get',
      url: `${HOST_URL}/api/v1/task`,
      params: {
        tid: taskID,
        is_shared_task: '0',
      } })
      .then((response) => {
        Log.debug("rresponse", response);
        // setTaskResult(response.data.taskResult[""]);
        taskresult = response.data.taskResult[""];
      })
      .catch((error) => {
        console.log(error);
      });
    let prompt = "\n";
    // Log.debug("ttemp", temp);
    if (taskresult !== undefined) {
      // Log.debug("ttaskresult", taskresult);
      const toplist = JSON.parse(taskresult.total_toplist);
      prompt = `${prompt}堆内存热点函数：\n`;
      for (let i = 0;i < toplist[0].length; i++) {
        prompt = prompt + toplist[0][i];
        prompt = `${prompt} \n`;
      }
      prompt = `${prompt}Goroutine热点函数：\n`;
      for (let i = 0;i < toplist[1].length; i++) {
        prompt = prompt + toplist[1][i];
        prompt = `${prompt} \n`;
      }
      // prompt = `${prompt}堆内存分配相关代码：\n`;
    }
    let requestParams = {};
    let allFucntionDicList;
    let allGoFucntionDicList;
    let callList;
    let gocallList;
    const annotateResults = '/pprof_heap_source_code_results.json';
    const goannotateResults = '/pprof_goroutine_source_code_results.json';
    const callResults = '/call_stack.json';
    const gocallResults = '/goroutine_call_stack.json';
    const cosFiles = taskID + annotateResults;
    const gocosFiles = taskID + goannotateResults;
    const callcosFiles = taskID + callResults;
    const gocallcosFiles = taskID + gocallResults;
    requestParams = {
          cos_files: [cosFiles, gocosFiles, callcosFiles, gocallcosFiles],
          tid: taskID,
          is_shared_task: '0',
    };
    await axios({
        method: 'get',
        url: `${HOST_URL}/api/v1/cosfiles`,
        params: requestParams,
      }).then(async (response) => {
        const files = response.data.cos_files;
        await axios({
          method: 'get',
          url: files[0],
        }).then((response) => {
          console.log(response);
          // setAllFucntionDicList(response);
          allFucntionDicList = response;
        });
        await axios({
          method: 'get',
          url: files[1],
        }).then((response) => {
          console.log(response);
          // setAllGoFucntionDicList(response);
          allGoFucntionDicList = response;
        });
        await axios({
          method: 'get',
          url: files[2],
        }).then((response) => {
          console.log(response);
          // setCallList(response);
          callList = response;
        });
        await axios({
          method: 'get',
          url: files[3],
        }).then((response) => {
          console.log(response);
          // setgoCallList(response);
          gocallList = response;
        });
    });
    Log.debug("targetfile", taskID, allFucntionDicList, allGoFucntionDicList, callList, gocallList);
    const array = ['runtime.', 'net/', 'internal/'];
    prompt = `${prompt}堆内存相关调用栈：\n`;
    const filepath = {};
    const gofilepath = {};
    if (callList !== undefined) {
      for (let i = 0;i < callList.length & i < 10; i++) {
        prompt = `${prompt} 第${i + 1}条调用栈,在这条调用栈上分配了${callList[i][1]}堆内存:\n `;
        for (let j = 0;j < callList[i][0].length; j++) {
          prompt = `${prompt} 函数名${callList[i][0][j][0]}\n `;
          if (callList[i][0][j][0] in filepath) {
            continue;
          } else {
            filepath[callList[i][0][j][0]] = callList[i][0][j][1];
          }
        }
        prompt = `${prompt} \n`;
      }
    }
    Log.debug("callList", callList);
    prompt = `${prompt}Goroutine相关调用栈：\n`;
    if (gocallList !== undefined) {
      for (let i = 0;i < gocallList.length & i < 10; i++) {
        prompt = `${prompt} 第${i + 1}条调用栈,在这条调用栈上分配了${gocallList[i][1]}个Goroutine:\n `;
        for (let j = 0;j < gocallList[i][0].length; j++) {
          // if (gocallList[i][0][j][0] === "runtime.gopark") {
          //   continue;
          // }
          prompt = `${prompt} 函数名${gocallList[i][0][j][0]}\n `;
          if (gocallList[i][0][j][0] in gofilepath) {
            continue;
          } else {
            gofilepath[gocallList[i][0][j][0]] = gocallList[i][0][j][1];
          }
        }
        prompt = `${prompt} \n`;
      }
    }
    Log.debug("gofilepath", gofilepath);
    prompt = `${prompt}堆内存相关源代码：\n`;
    if (allFucntionDicList !== undefined) {
      for (const key in allFucntionDicList) {
        const containsAny = array.some(item => key.includes(item));
        if (containsAny) {
          continue;
        }
        if (key in filepath) {
          prompt = `${prompt} 函数${key}的源码，并且函数位置是${filepath[key]}:\n `;
        } else {
          prompt = `${prompt} 函数${key}:\n `;
        }
            // prompt = `${prompt} 函数${key}:\n `;
            for (let i = 0;i < allFucntionDicList[key]['asmCodeArray'].length; i++) {
              prompt = `${prompt} ${allFucntionDicList[key]['asmCodeArray'][i]['code']}\n `;
              // Log.debug("source code", allFucntionDicList[key]['asmCodeArray'][i]['code']);
            }
            prompt = `${prompt} \n`;
      }
    }
    prompt = `${prompt}Goroutine相关源代码：\n`;
    if (allGoFucntionDicList !== undefined) {
      for (const key in allGoFucntionDicList) {
        const containsAny = array.some(item => key.includes(item));
        if (containsAny) {
          continue;
        }
        Log.debug("func", key);
            if (key in gofilepath) {
              prompt = `${prompt} 函数${key}的源码，并且函数位置是${gofilepath[key]}:\n `;
            } else {
              prompt = `${prompt} 函数${key}:\n `;
            }
            for (let i = 0;i < allGoFucntionDicList[key]['asmCodeArray'].length; i++) {
              prompt = `${prompt} ${allGoFucntionDicList[key]['asmCodeArray'][i]['code']}\n `;
              // Log.debug("source code", allFucntionDicList[key]['asmCodeArray'][i]['code']);
            }
            prompt = `${prompt} \n`;
      }
    }
    Log.debug("tempprompt", prompt);
    return prompt;
  }


  return (
    <div>
        <Card.Body
          style={{ padding: '5px', width: "760px"}}
          title="历史任务"
          subtitle={(props.viewTasks)
            ? <>
              <Button style={{ marginLeft: '20px' }} type = 'link' onClick={ () => props.viewTasks() }>历史任务详情
              </Button>
            </>
            : ''
          }
        >
        {
          (props.tasks.length !== 0) ? <div>
            <Table
          columns = { columns }
          recordKey = 'id'
          records = { tasks }
          bordered = {props.bordered}
          addons={[
            pageable({
              pageSize: props.tableRow,
              pageSizeVisible: false,
              pageCountChangingResetType: 'first',
            }),
            filterable({
              type: 'single',
              column: 'type',
              value: "Golang内存分析",
              onChange: value => setTaskType(value),
              // 增加 "全部" 选项
              all: {
                value: ALL_VALUE,
                text: '全部',
              },
              // 选项列表
              options: [
                { value: 0, text: '通用采样分析' },
                { value: 1, text: 'Java采样分析' },
                { value: 2, text: '磁盘追踪' },
                { value: 3, text: '时序分析' },
                { value: 4, text: '内存泄漏检测' },
                { value: 6, text: 'Java堆分析' },
                { value: -1, text: '动态检测' },
              ],
            }),
          ]}
        ></Table></div>
            : <EmptyTask/>}
        </Card.Body>
    </div>
  );
};

CompareTaskList.propTypes = {
  DropReducer: PropTypes.any,
  Dropaction: PropTypes.any,
  selfTaskID: PropTypes.any,
  tasks: PropTypes.array, // agent信息
  onSearchTask: PropTypes.func,  // 搜索任务
  viewTask: PropTypes.func,  // 查看任务
  viewTasks: PropTypes.func, // 历史任务详情
  deleteTask: PropTypes.func, // 删除任务
  FibonaClick: PropTypes.func, // Fibona触发
  style: PropTypes.object,
  tableRow: PropTypes.number,
  bordered: PropTypes.any,
  domref: PropTypes.object,
  callback: PropTypes.func,
};


CompareTaskList.defaultProps = {
  tableRow: 4,
  bordered: false,
};

const mapStateToProps = (state) => {
  const { DropReducer } = state;
  return {
    DropReducer,
  };
};// Reducer

const mapDispatchToProps = (dispatch) => {
  const Dropaction = bindActionCreators(DropActionCreator, dispatch);

  return {
    Dropaction,
  };
};// Action

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps,
)(CompareTaskList));

// export default CompareTaskList;
