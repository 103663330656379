import React from 'react';
import PropTypes from 'prop-types';
import {
  Space,
  Popup,
  List,
  Link,
} from 'tdesign-react';

const { ListItem } = List;

const oprations = [
  {
    content: '聚焦',
    value: 'focus frames',
    desc: name => <>聚焦<code><code>{name}</code></code>的所有调用栈</>,
  },
  {
    content: '展开子调用',
    value: 'show callees',
    desc: name => <>显示被<code>{name}</code>调用的所有函数</>,
  },
  {
    content: '折叠子调用',
    value: 'hide callees',
    desc: name => <>隐藏被<code>{name}</code>调用的所有函数</>,
  },
  {
    content: '显示调用者',
    value: 'show callers',
    desc: name => <>显示调用<code>{name}</code>的所有函数</>,
  },
  {
    content: '显示调用栈',
    value: 'show stacks',
    desc: name => <>显示<code>{name}</code>的调用栈</>,
  },
  {
    content: '隐藏调用者',
    value: 'hide callers',
    desc: name => <>隐藏调用<code>{name}</code>的所有函数</>,
  },
  {
    content: '隐藏调用栈',
    value: 'hide stacks',
    desc: name => <>隐藏<code>{name}</code>的调用栈</>,
  },
  {
    content: '高亮',
    value: 'highlight frames',
    desc: name => <>高亮<code>{name}</code></>,
  },
];

function TooltipHeader({ optGroupSize, onClick, node }) {
  if (node.isRoot || node.isFocusRoot) {
    return <></>;
  }

  return <div className='header'>
    <Space
      size='large'
      align="center"
    >
      {
        oprations.slice(0, optGroupSize).map((op, id) => (
          <Link
            className='bold'
            key={id}
            onClick={() => onClick(node, op.value)}
          >
            <b>{op.content}</b>
          </Link>
        ))
      }
      <Popup
        trigger="hover"
        overlayInnerClassName="flame-graph-tip--opt-popup"
        placement='bottom-left'
        content={
          <Space size={0}>
            <List className='list'>
              {oprations.slice(optGroupSize).map((item, idx) => (
                <ListItem
                  className='listitem'
                  key={idx}
                >
                  <div onClick={() => onClick(node, item.value)}>
                    <div className='listitem-content'>
                      <div style={{ fontSize: 'larger' }}>
                        {item.content}
                      </div>
                      <div className='flame-graph-tip--operation-desc'>
                        {item.desc(node?.data.func)}
                      </div>
                    </div>
                  </div>
                </ListItem>
              ))}
            </List>
          </Space>
        } >
        <Link>更多操作</Link>
      </Popup>
    </Space>
  </div>;
}

TooltipHeader.propTypes = {
  node: PropTypes.object,
  optGroupSize: PropTypes.number,
  onClick: PropTypes.func,
};

export function Tooltip({ options, onClick, isGroupByTid }) {
  const style = {};
  let visible = false;

  if (options && options.isShow) {
    const { left, top } = options;
    style.left = left;
    style.top = top;
    style.width = 0;
    style.height = 0;
    style.display = 'inline-block';
    visible = true;
  } else {
    style.display = 'none';
    visible = false;
  }

  function formatTimePercentDesc(type, timePercent) {
    const tp = timePercent;

    let desc;
    if (type === 'total') {
      desc = '总计: ';
    } else {
      desc = '自身: ';
    }

    if (tp.selected) desc += `所选区域的 ${tp.selected}%`;
    if (tp.focused) desc += `聚焦区域的 ${tp.focused}%`;
    if (tp.profile) desc += `，整体区域的 ${tp.profile}%`;

    return <p>{desc}</p>;
  }

  function totalTimeDesc() {
    const cum = options.fg.totalTimeOf(options.node);
    return formatTimePercentDesc('total', cum);
  }

  function selfTimeDesc() {
    const flat = options.fg.selfTimeOf(options.node);
    return formatTimePercentDesc('self', flat);
  }

  function processText() {
    const n = options.node;
    if (n.procs) {
      return [...n.procs].join();
    }
    return n.data.process;
  }

  function tidText() {
    const n = options.node;
    if (n.tids) {
      return [...n.tids].join();
    }
    return n.data.tid;
  }

  function moduleText() {
    const n = options.node;
    if (n.modules) {
      return [...n.modules].join();
    }
    return n.data.module;
  }

  function diffText() {
    const n = options.node.data.diff;
    if (n > 0) {
      return `+${(n * 100).toFixed(2)}%`;
    }
    return `${(n * 100).toFixed(2)}%`;
  }

  function attrDesc() {
    if (options.node.isRoot) {
      return (
        <div className='attr-group'>
          <p>样本数:&ensp;{options.fg.realValue(options.node)}</p>
        </div>
      );
    }
    const modules = moduleText().split('\n');
    const firstModule = modules[0];
    const remainingModules = modules.slice(1);

    return (
      <div className='attr-group'>
        <p>进程名:&ensp;{processText()}</p>
        <p>模块名:&ensp;{firstModule}</p>
        {remainingModules.map((module, index) => (
          <p key={index} style={{ marginLeft: '3.3rem' }}>{module}</p>
        ))}
        {isGroupByTid && <p>线程ID:&ensp;{tidText()}</p>}
        <p>样本数:&ensp;{options.fg.realValue(options.node)}</p>
        {options?.node?.data?.diff !== undefined ? <p>差值:&ensp;{diffText()}</p> : null}
      </div>
    );
  }

  const optGroupSize = 3;
  return (
    <Popup
      overlayInnerClassName='flame-graph-tip--popup'
      trigger='manual'
      placement='bottom-left'
      visible={visible}
      content={<div id='flame-graph-tip' className='flame-graph-tip'>
        {
          options.node
          && <>
            <TooltipHeader
              node={options.node}
              optGroupSize={optGroupSize}
              onClick={onClick}
            />
            <div className='content'>
              <div className='func'>{options.node.data.func}</div>
              {totalTimeDesc()}
              {selfTimeDesc()}
              {attrDesc()}
            </div>
          </>
        }
      </div >
      }
    >
      <div className='flame-graph-tip-trigger' style={style}></div>
    </Popup>
  );
}

Tooltip.propTypes = {
  options: PropTypes.object,
  onClick: PropTypes.func,
  isGroupByTid: PropTypes.bool,
};
