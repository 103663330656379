/* eslint-disable quote-props */
import React, { useState, useEffect, useRef } from 'react';
import { Button } from 'tdesign-react';
import PropTypes from 'prop-types';
import { CommonCpuTask, CommonMemoryTask, GolangCpuTask, GolangMemoryTask, JavaCpuTask, JavaHeapDumpTask } from './tasks';

const StartTaskFormV2 = (props) => {
  const [currentStep, setCurrentStep] = useState(0);
  const [selections, setSelections] = useState({});
  const [selectedTask, setSelectedTask] = useState({});
  const [steps, setSteps] = useState([]);
  const stepsRef = useRef([]);
  const startRef = useRef(null);
  const [result, setResult] = useState({});

  const StartTask = () => {
    result.name = selectedTask.fullName;
    result.task_type = selectedTask.task_type;
    result.profiler_type = selectedTask.profiler_type;
    result.event = selectedTask.event;
    console.log(result);
    props.onClick(result);
  };

  const getSteps = (currentSelections) => {
    const options = [];
    props.agents.map((agent) => {
      if (agent.online) {
        const unavailableFeatures = new Set();
        agent.unavailable_features?.forEach((feature) => {
          // 将 feature 的数字后两位置置为 00(这两位为具体原因，不需要用于判断)
          unavailableFeatures.add(feature - (feature % 100));
        });

        options.push(`${agent.ipaddr} [${agent.hostname}]`);
      }
      return 0;
    });

    const baseSteps = [
      {
        title: '请选择您遇到的问题',
        options: ['程序变慢', 'CPU占用较高', '疑似内存泄漏', '内存占用过大'],
        type: 'buttons',
        tips: {
          '程序变慢': '程序变慢可能是由于算法效率低、I/O操作频繁、锁竞争严重或系统资源不足导致执行时间过长和等待时间增加，通过CPU采样可获取到程序的执行热点。',
          'CPU占用较高': 'CPU占用较高可能是由于大量计算密集型任务、死循环、过多线程增加调度开销或任务在多核CPU上分配不合理，通过CPU采样可获取到程序的执行热点。',
          '疑似内存泄漏': '内存泄漏可能是由于未及时释放分配的内存资源、缓存未定期清理或第三方库存在内存泄漏问题等，长时间泄漏容易导致OOM，通过内存采样可获取到程序的内存占用情况。',
          '内存占用过大': '内存占用过大可能是由于处理的数据量过大、大量对象未及时释放、使用不合理的数据结构或一次性加载过多资源导致内存需求过高和内存浪费，通过内存采样可获取到程序的内存占用情况。',
        },
      },
      {
        title: '请选择程序使用的开发语言',
        options: ['C/C++', 'Golang', 'Java'],
        type: 'buttons',
      },
      {
        title: '请选择目标主机',
        options,
        type: 'buttons',
        changeAction: (v) => {
          setResult((prev) => {
            prev.target_host = [v.split(' ')[0]];
            return prev;
          });
          console.log(result);
        },
      },
    ];

    if (currentSelections[0] && currentSelections[1]) {
      if (['程序变慢', 'CPU占用较高'].includes(currentSelections[0])) {
        if (currentSelections[1] === 'C/C++') {
          setSelectedTask(CommonCpuTask);
          baseSteps.push(
            {
              title: '请输入目标Pid',
              type: 'buttons&input',
              options: ['全局采样'],
              placeholder: '请选择左侧按钮或直接输入pid，例如: 20000',
              changeAction: (v) => {
                setResult((prev) => {
                  prev.profiling_freqence = 99;
                  prev.callgraph = 'fp';
                  prev.annotate_enable = false;
                  if (v === '全局采样') {
                    prev.perf_wide = 'system';
                    prev.pid = -1;
                    return prev;
                  }
                  prev.perf_wide = 'pid';
                  prev.pid = v;
                  return prev;
                });
              },
            },
            {
              title: '请选择采样时间（秒）',
              options: ['10', '30', '60', '120', '180'],
              type: 'buttons',
              changeAction: (v) => {
                setResult((prev) => {
                  prev.duration = v;
                  return prev;
                });
              },
            },
          );
        } else if (currentSelections[1] === 'Golang') {
          setSelectedTask(GolangCpuTask);
          baseSteps.push(
            {
              title: '请输入程序开放的pprof端口',
              type: 'input',
              placeholder: '例如: 20000',
              changeAction: (v) => {
                setResult((prev) => {
                  prev.port = v;
                  return prev;
                });
              },
            },
            {
              title: '请选择采样时间（秒）',
              options: ['10', '30', '60', '120', '180'],
              type: 'buttons',
              changeAction: (v) => {
                setResult((prev) => {
                  prev.duration = v;
                  return prev;
                });
              },
            },
          );
        } else if (currentSelections[1] === 'Java') {
          setSelectedTask(JavaCpuTask);
          baseSteps.push(
            {
              title: '请输入目标Pid',
              type: 'buttons&input',
              options: ['最近启动的Java进程'],
              placeholder: '请选择左侧按钮或直接输入pid，例如: 20000',
              changeAction: (v) => {
                setResult((prev) => {
                  if (v === '最近启动的Java进程') {
                    prev.perf_wide = 'system';
                    return prev;
                  }
                  prev.perf_wide = 'pid';
                  prev.pid = v;
                  return prev;
                });
              },
            },
            {
              title: '请选择采样时间（秒）',
              options: ['10', '30', '60', '120', '180'],
              type: 'buttons',
              changeAction: (v) => {
                setResult((prev) => {
                  prev.duration = v;
                  return prev;
                });
              },
            },
          );
        }
      } else if (['疑似内存泄漏', '内存占用过大'].includes(currentSelections[0])) {
        setSelectedTask(CommonMemoryTask);
        if (currentSelections[1] === 'C/C++') {
          baseSteps.push(
            {
              title: '请输入目标Pid',
              type: 'input',
              placeholder: '例如: 20',
              changeAction: (v) => {
                setResult((prev) => {
                  prev.perf_wide = 'pid';
                  prev.pid = v;
                  return prev;
                });
              },
            },
            {
              title: '请选择采样时间（秒）',
              options: ['10', '30', '60', '120', '180'],
              type: 'buttons',
              changeAction: (v) => {
                setResult((prev) => {
                  prev.duration = v;
                  return prev;
                });
              },
            },
          );
        } else if (currentSelections[1] === 'Golang') {
          setSelectedTask(GolangMemoryTask);
          baseSteps.push(
            {
              title: '请输入程序开放的pprof端口',
              type: 'input',
              placeholder: '例如: 60',
              changeAction: (v) => {
                setResult((prev) => {
                  prev.perf_wide = 'pid';
                  prev.port = v;
                  return prev;
                });
              },
            },
            {
              title: '请选择采样时间（秒）',
              options: ['0', '10', '30', '60', '120', '180'],
              type: 'buttons',
              tips: '0即采样当前时刻内存情况，设置其他数即为未来时间段内的内存变化情况',
              changeAction: (v) => {
                setResult((prev) => {
                  prev.duration = Number(v);
                  return prev;
                });
              },
            },
          );
        } else if (currentSelections[1] === 'Java') {
          setSelectedTask(JavaHeapDumpTask);
          baseSteps.push({
            title: '请输入目标Pid',
            options: ['最近启动的Java进程'],
            type: 'buttons&input',
            placeholder: '请选择左侧按钮或直接输入pid，例如: 20000',
            changeAction: (v) => {
              setResult((prev) => {
                prev.perf_wide = 'pid';
                prev.pid = v;
                return prev;
              });
            },
          });
        }
      }
    }

    return baseSteps;
  };

  useEffect(() => {
    setSteps(getSteps(selections));
  }, [selections]);

  useEffect(() => {
    if (currentStep > 0 && stepsRef.current[currentStep]) {
      stepsRef.current[currentStep].scrollIntoView({ behavior: 'smooth' });
    }
    if (currentStep === steps.length && startRef.current) {
      startRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [currentStep, steps.length]);


  const handleSelection = (stepIndex, option) => {
    setSelections((prev) => {
      const newSelections = { ...prev, [stepIndex]: option };
      if (stepIndex === 2) {
        setSelectedTask(prevTask => ({
          ...prevTask,
          host: option,
        }));
      }
      if (stepIndex + 1 === steps.length) {
        setSelectedTask(prevTask => ({
          ...prevTask,
          params: {
            param1: newSelections[3],
            param2: newSelections[4],
          },
        }));
      }
      return newSelections;
    });
    setCurrentStep(stepIndex + 1);
    const action = steps[stepIndex].changeAction;
    if (action) {
      action(option);
    }
  };

  const handleInputChange = (stepIndex, value) => {
    setSelections(prev => ({
      ...prev,
      [stepIndex]: value,
    }));
    const action = steps[stepIndex].changeAction;
    if (action) {
      action(value);
    }
  };

  const handleInputSubmit = (stepIndex) => {
    if (selections[stepIndex]) {
      setCurrentStep(stepIndex + 1);
    }
  };

  return (
    <div style={{ maxWidth: '48rem', margin: '0 auto', padding: '1rem' }}>
      {steps.map((step, index) => (
        <React.Fragment key={index}>
          <div
            ref={el => (stepsRef.current[index] = el)}
            style={{
              marginBottom: '2rem',
              transition: 'all 0.5s',
              opacity: index <= currentStep ? 1 : 0,
              transform: index <= currentStep ? 'translateX(0)' : 'translateX(1rem)',
            }}
          >
            <h2 style={{ fontSize: '1.25rem', fontWeight: 'bold', marginBottom: '0.8rem' }}>
              {step.title}
            </h2>
            {step.type === 'buttons' || step.type === 'buttons&input' ? (
              <div style={{ display: 'flex', flexWrap: 'wrap', gap: '1rem', marginBottom: '0.3rem' }}>
                {step.options.length ? step.options.map((option, optionIndex) => (
                  <button
                    key={optionIndex}
                    onClick={() => handleSelection(index, option)}
                    style={{
                      padding: '0.5rem 1rem',
                      borderRadius: '0.25rem',
                      backgroundColor: selections[index] === option ? '#3b82f6' : '#e5e7eb',
                      color: selections[index] === option ? '#ffffff' : '#000000',
                      cursor: 'pointer',
                      border: 'none',
                    }}
                    onMouseOver={(e) => {
                      if (selections[index] !== option) {
                        e.target.style.backgroundColor = '#d1d5db';
                      }
                    }}
                    onMouseOut={(e) => {
                      if (selections[index] !== option) {
                        e.target.style.backgroundColor = '#e5e7eb';
                      }
                    }}
                  >
                    {option}
                  </button>
                )) : '无可用主机，请先通过快速接入页面接入主机'}
                {typeof step.tips === 'string' ? (
                  <div style={{ fontSize: '0.875rem', color: '#6b7280' }}>
                    {step.tips}
                  </div>)
                  : (step.tips && step.tips[selections[index]] && (
                  <div style={{ fontSize: '0.875rem', color: '#6b7280' }}>
                    {step.tips[selections[index]]}
                  </div>)
                  )}
                {step.type === 'buttons&input' ? <>
                <input
                  type="text"
                  placeholder={step.placeholder}
                  value={selections[index] === '最近启动的Java进程' || selections[index] === '全局采样' || selections[index] === '' ? '' : selections[index]}
                  onChange={e => handleInputChange(index, e.target.value)}
                  onKeyPress={e => e.key === 'Enter' && handleInputSubmit(index)}
                  style={{ flexGrow: 1 }}
                />
                  <Button onClick={() => handleInputSubmit(index)} disabled={!selections[index]}>
                确认
              </Button></> : null}
          </div>
            ) : (
          <div style={{ display: 'flex', gap: '1rem' }}>
            <input
              type="text"
              placeholder={step.placeholder}
              value={selections[index] || ''}
              onChange={e => handleInputChange(index, e.target.value)}
              onKeyPress={e => e.key === 'Enter' && handleInputSubmit(index)}
              style={{ flexGrow: 1 }}
            />
            <Button onClick={() => handleInputSubmit(index)} disabled={!selections[index]}>
                  确认
                </Button>
              </div>
            )}
          </div>
          {index === 1 && currentStep > 1 && (
            <div style={{ marginTop: '2rem', marginBottom: '2rem', padding: '1rem', backgroundColor: '#d1fae5', borderRadius: '0.25rem' }}>
              <h3 style={{ fontSize: '1.125rem', fontWeight: 'bold' }}>基于您的选择，我们推荐任务{selectedTask.fullName}</h3>
            </div>
          )}
        </React.Fragment>
      ))}
      {currentStep === steps.length && (
        <div style={{ marginTop: '2rem', marginBottom: '2rem', padding: '1rem', backgroundColor: '#f3f4f6', borderRadius: '0.25rem' }}>
          <h3 style={{ fontSize: '1.125rem', fontWeight: 'bold' }}>任务配置如下:</h3>
          <p>任务名：{selectedTask.fullName}</p>
          <p>选择的主机: {selections[2]}</p>
        </div>
      )}
      {currentStep === steps.length && (
        <Button ref={startRef} onClick={StartTask}>
          {'快速发起任务'}
        </Button>
      )}
    </div>
  );
};

StartTaskFormV2.propTypes = {
  agents: PropTypes.array,
  onClick: PropTypes.func,
};

export default StartTaskFormV2;
