const mockProxy = {
  _proxy: {
    proxy: {
      '/api/user': 'http://127.0.0.1:8080',
      '/web/singleCase/getCaseRecords/': 'http://127.0.0.1:8080',
      '/web/productconfigure/getProductListForHomepage': 'http://127.0.0.1:8080',
      '/api/v1/product/leftMenu?pId=1': 'http://127.0.0.1:8080',
    },
    changeHost: true,
  },
  'GET /api/user': {
    id: 1,
    username: 'kenny',
    sex: 6,
  },
  'POST /web/singleCase/getCaseRecords/': {
    id: 1,
    username: 'kenny',
    sex: 6,
  },
  'GET /web/productconfigure/getProductListForHomepage': (req, res) => res.json({ code: 23333 }),
  'GET /api/v1/product/leftMenu?pId=1': (req, res) => {
    res.json({
      requestId: '876acffe-c7f1-43bf-b3b3-6320faff16b7',
      code: 'Success',
      msg: 'Request succeeded.',
      data: [
        {
          group_id: 1,
          group_name: '报表服务',
          group_key: 'lm-overview',
          icon_type_name: 'timeline',
          sub_list: [
            {
              page_name: '性能总览',
              page_key: 'lm-dashboard',
              url: '/web/1/common/showTabPage?p_id=1',
              tab_list: [
                {
                  tab_name: '性能总览',
                  url: '/new_grafana/d/ZdQ5UwzGz/xing-neng-zong-lan?orgId=1&var-p_id=1',
                  tab_key: 'lm-dashboard-cvm',
                },
                {
                  tab_name: '性能总览（母机）',
                  url: '/new_grafana/d/dLFlilzMz/xing-neng-zong-lan-mu-ji?orgId=1&var-p_id=1',
                  tab_key: 'lm-dashboard-host',
                },
              ],
            },
            {
              page_name: '性能对比',
              page_key: 'lm-dashboard-compare',
              url: '/web/1/common/showTabPage?p_id=1',
              tab_list: [
                {
                  tab_name: '同版本多次测试',
                  url: '/new_grafana/d/VHH7XwkGz/tong-ban-ben-duo-ci-ce-shi?orgId=1&var-p_id=1',
                  tab_key: 'lm-compare-same-version',
                },
                {
                  tab_name: '不同被测组件版本对比',
                  url: '/new_grafana/d/NNK7W_kMz/bu-tong-bei-ce-zu-jian-ban-ben-dui-bi?orgId=1&var-p_id=1',
                  tab_key: 'lm-compare-diff-version',
                },
                {
                  tab_name: '不同结果版本对比',
                  url: '/new_grafana/d/tQ5f5ciGk/bu-tong-jie-guo-ban-ben-dui-bi?orgId=1&var-p_id=1',
                  tab_key: 'lm-compare-test-version',
                },
              ],
            },
            {
              page_name: '性能报表',
              page_key: 'lm-report',
              url: '/web/1/common/showTabPage?p_id=1',
              tab_list: [
                {
                  tab_name: '单次结果报表',
                  zone: 'testReport',
                  tab_key: 'lm-report-single',
                },
                {
                  tab_name: '各机型性能报表',
                  tab_key: 'lm-baseline-export',
                  zone: 'exportReport',
                },
              ],
            },
          ],
        },
        {
          group_id: 3,
          group_name: '任务管理',
          group_key: 'lm-task',
          icon_type_name: 'av_timer',
          sub_list: [
            {
              page_name: '任务看板',
              page_key: 'lm-task-overview',
              url: '/web/1/common/showTabPage?p_id=1',
              tab_list: [
                {
                  tab_name: '概括',
                  url: '/new_grafana/d/NdjG-j9Wz/overview?orgId=1&from=now-24h&to=now&var-p_id=1&kiosk',
                  tab_key: 'lm-task-overview-all',
                },
                {
                  tab_name: '任务运行情况',
                  url: '/new_grafana/d/VVj8lEeZz/overview-task?orgId=1&from=now-24h&to=now&var-p_id=1&kiosk',
                  tab_key: 'lm-task-overview-list',
                },
              ],
            },
          ],
        },
        {
          group_id: 8,
          group_name: '云驭1.0功能',
          group_key: 'lm-overview-old',
          icon_type_name: 'timeline',
          sub_list: [
            {
              page_name: '云驭1.0主页',
              page_key: 'lm-main-old',
              url: '/web/1/common/showTabPage?p_id=1',
              tab_list: [
                {
                  tab_name: '主页',
                  url: '/grafana/d/BhtP1LAmz/yun-yu?orgId=1',
                  tab_key: 'lm-main-content-old',
                },
              ],
            },
            {
              page_name: '总览(云驭1.0)',
              page_key: 'lm-dashboard-old',
              url: '/web/1/common/showTabPage?p_id=1',
              tab_list: [
                {
                  tab_name: '子机',
                  url: '/grafana/d/CvPyf88mz/zi-ji-xing-neng-zong-lan?orgId=1',
                  tab_key: 'lm-dashboard-cvm-old',
                },
                {
                  tab_name: '母机',
                  url: '/grafana/d/7OdOQfAmk/mu-ji-xing-neng-zong-lan?orgId=1',
                  tab_key: 'lm-dashboard-host-old',
                },
                {
                  tab_name: 'GPU',
                  url: '/grafana/d/tBLrO6Ymk/gpuxing-neng-zong-lan?orgId=1',
                  tab_key: 'lm-dashboard-GPU-old',
                },
                {
                  tab_name: '磁盘',
                  url: '/grafana/d/ihKFydgWk/ci-pan-xing-neng-zong-lan?orgId=1',
                  tab_key: 'lm-dashboard-disk-old',
                },
                {
                  tab_name: 'CFS',
                  url: '/grafana/d/uJ0tXwJWz/cfsxing-neng-zong-lan?orgId=1',
                  tab_key: 'lm-dashboard-cfs-old',
                },
              ],
            },
            {
              page_name: '竞品(云驭1.0)',
              page_key: 'lm-cp-old',
              url: '/web/1/common/showTabPage?p_id=1',
              tab_list: [
                {
                  tab_name: '总览',
                  url: '/grafana/d/jU-eh1ZWz/jing-pin-qing-kuang-hui-zong?orgId=1',
                  tab_key: 'lm-cp-old-qcloud-all',
                },
              ],
            },
            {
              page_name: '报表(云驭1.0)',
              page_key: 'lm-report-old',
              url: '/web/1/common/showTabPage?p_id=1',
              tab_list: [
                {
                  tab_name: '单次结果报表',
                  url: '/grafana/d/CqJPeeiZk/xing-neng-bao-gao-zhan-shi?orgId=1',
                  tab_key: 'lm-report-single-old',
                },
                {
                  tab_name: '母机基准值报表',
                  url: '/grafana/d/GhTKkuiWz/mu-ji-ji-zhun-zhi-cha-kan?orgId=1',
                  tab_key: 'lm-report-host-single-old',
                },
              ],
            },
            {
              page_name: '稳定性(云驭1.0)',
              page_key: 'lm-stability-old',
              url: '/web/1/common/showTabPage?p_id=1',
              tab_list: [
                {
                  tab_name: '单次网络监控',
                  url: '/grafana/d/Ex1gw83Wz/wen-ding-xing-jian-kong-pingkong-xian-shi-yan-wen-ding-xing-jian-kong-dan-ci-jie-guo-cha-kan?orgId=1',
                  tab_key: 'lm-stability-single-old',
                },
                {
                  tab_name: '子机网络稳定性（背景流量）',
                  url: '/grafana/d/qbrFmyOWk/zi-ji-wang-luo-wen-ding-xing-ce-shi',
                  tab_key: 'lm-stability-old',
                },
                {
                  tab_name: 'Ping时延稳定性',
                  url: '/grafana/d/4Ze5q11Wz/wen-ding-xing-jian-kong-pingkong-xian-shi-yan-wen-ding-xing-jian-kong?orgId=1',
                  tab_key: 'lm-stability-ping-old',
                },
                {
                  tab_name: 'Codis/Redis时延稳定性',
                  url: '/grafana/d/tycNkNaZz/wen-ding-xing-jian-kong-codisji-qun-redisshi-yan-jian-kong?orgId=1',
                  tab_key: 'lm-stability-redis-old',
                },
              ],
            },
          ],
        },
        {
          group_id: 10,
          group_name: '其他功能',
          group_key: 'lm-other',
          icon_type_name: 'av_timer',
          sub_list: [
            {
              page_name: '长短连接转换',
              page_key: 'lm-short-link',
              url: '/web/1/common/showTabPage?p_id=1',
              tab_list: [
                {
                  tab_name: '长短链接转换',
                  tab_key: 'lm-short-link-content',
                  zone: 'short_link',
                },
              ],
            },
          ],
        },
        {
          group_id: 11,
          group_name: '文档',
          group_key: 'lm-document',
          icon_type_name: 'description',
          sub_list: [
            {
              page_name: '云驭使用文档',
              page_key: 'lm-document-sdk',
              url: 'https://yunyu.cloud.tencent.com/file/docs/',
              tab_list: [

              ],
            },
            {
              page_name: 'iwiki主页',
              page_key: 'lm-document-wiki',
              url: 'http://iwiki.oa.com/display/CloudPerformance/CloudPerfPlatform',
              tab_list: [

              ],
            },
            {
              page_name: '云驭源代码',
              page_key: 'lm-document-git',
              url: 'https://git.code.oa.com/CloudPerfPlatform',
              tab_list: [

              ],
            },
          ],
        },
      ],
    });
  },

};

module.exports = process.env.MOCK === 'true' ? mockProxy : { _proxy: { proxy: {} } };
