/* eslint-disable */
import * as d3 from 'd3';
import * as each from './foreach';
import { Node } from 'd3-hierarchy';
import { scaleLinear } from 'd3-scale';
import {transition} from "d3-transition";

function hidePath(path) {
  if (!path) {
    return;
  }

  const startNode = path[0];
  const { parent } = startNode;
  const parentOldChildrenLeftLen = parent.children.indexOf(startNode);
  const parentOldChildrenRightLen = parent.children.length - parentOldChildrenLeftLen - 1;

  const parentChildren = [];

  const pathLen = path.length;

  path.forEach((n) => {
    n.value = 0;
  });

  for (let i = 1; i < path.length; i++) {
    const node = path[i];
    const depth = node.depth - 1;
    each.siblings(node, (s) => {
      s.depth -= depth;
    });
  }

  for (let i = 0; i < pathLen; i++) {
    const node = path[i];
    const idx = node.parent.children.indexOf(node);
    parentChildren.push(...node.parent.children.slice(0, idx));
  }

  const endNode = path[pathLen - 1];
  if (endNode.children) {
    endNode.each((n) => {
      n.depth -= pathLen;
    });
    parentChildren.push(...endNode.children);
  }

  for (let i = path.length - 1; i >= 0; --i) {
    const node = path[i];
    const idx = node.parent.children.indexOf(node);
    parentChildren.push(...node.parent.children.slice(idx + 1));
  }

  parentChildren.forEach((n) => {
    n.parent = parent;
  });
  parent.children = parentChildren;

  const start = parentOldChildrenLeftLen;
  const end = parentChildren.length - parentOldChildrenRightLen;
  const additionalChildren = parentChildren.slice(start, end);
  if (additionalChildren.length === 0) return null;
  return additionalChildren;
}

function getHiddenChildren(node) {
  if (!node.children) {
    return null;
  }

  const { children } = node;
  for (let i = 0; i < children.length; i++) {
    const node = children[i];
    if (node.hidden) {
      return node;
    }
  }
  return null;
}

function getHiddenPath(node) {
  if (!node.hidden) {
    return null;
  }

  const path = [];
  path.push(node);
  while (node) {
    node = getHiddenChildren(node);
    if (node) path.push(node);
  }
  return path;
}

export function hidden(root) {
  let node = root; const nodes = [node];
  while (node = nodes.pop()) {
    let children = hidePath(getHiddenPath(node));
    if (!children) {
      children = node.children;
    }

    if (children) {
      for (let i = children.length - 1; i >= 0; --i) {
        nodes.push(children[i]);
      }
    }
  }

  if (!root.isFocus) {
    // 更新根节点的值
    let sumvalue = 0;
    let childrenVal = 0;
    root.children.forEach((n) => {
      if (!n.hidden) {
        sumvalue += n.value;
        childrenVal += n.selfv;
      }
    });
    root.value = sumvalue;
    root.selfv = childrenVal;
  }
}

function mergeChildren(node) {
  if (!node.children) return null;

  const stack = [node];
  let n = null;
  while (n = stack.pop()) {
    if (!n.children) continue;

    const mergeMap = {};
    const { children } = n;

    let isMerged = false;
    for (let i = 0; i < children.length; ++i) {
      const child = children[i];
      const d = child.data;
      const key = [d.process, d.func].join('\x1E')
      if (mergeMap[key]) {
        isMerged = true;

        const base = mergeMap[key];

        if (base.id === child.id) console.error('merge equal children: ', base.id, child.id, base, child);

        base.value += child.value;
        base.selfv += child.selfv;
        base.realValue += child.realValue;
        base.data.value = base.value;
        const baseLines = base.module.split('\n');
        const childModules = child.module.split('\n');

        childModules.forEach(childModule => {
          if (!baseLines.includes(childModule.trim())) {
            base.module = `${base.module.trim()}\n${childModule.trim()}`;
          }
        });

        if (!child.children) continue;

        child.children.forEach((c) => {
          c.parent = base;
        });

        if (base.children) {
          base.children.push(...child.children);
        } else {
          base.children = child.children;
        }

        stack.push(base);
      } else {
        mergeMap[key] = child;
      }
    }

    if (isMerged) {
      n.children = Object.values(mergeMap);
    }
  }
}

function rootSelfValue(root) {
  if (!root.children) return 0;

  let selfv = 0;
  root.children.forEach(child => {
    if (!child.hidden) selfv += child.selfv;
  });
  return selfv;
}

function rootValue(root) {
  if (!root.children) return 0;

  let value = 0;
  root.children.forEach(child => {
    if (!child.hidden) value += child.value;
  });
  return value;
}

export function focus(root) {
  let nodes = [];
  each.descendants(root, (node) => {
    if (node.focus) nodes.push(node);
  });

  if (!nodes.length) {
    root.focusNode = null;
    return;
  }

  let { maxid } = root;

  function makeCallers() {
    const callers = [];

    nodes.forEach((node) => {
      const ancestors = [];
      each.ancestors(node, (parent) => {
        const p = new Node({ ...parent.data });
        /* eslint-disable no-plusplus */
        p.id = ++maxid;
        p.value = parent.value; // 用来控制帧宽度
        p.realValue = parent.value; // 用来计算占比
        p.selfv = parent.selfv; // 用来计算占比
        ancestors.push(p);
      });

      const sectionalRoot = new Node({ ...root.data });
      sectionalRoot.value = 0;
      sectionalRoot.realValue = rootValue(root);
      sectionalRoot.selfv = rootSelfValue(root);
      sectionalRoot.isRoot = true;
      /* eslint-disable no-plusplus */
      sectionalRoot.id = ++maxid;
      sectionalRoot.depth = ancestors.length + 1;
      ancestors.push(sectionalRoot);
      let i;
      for (i = 0; i < ancestors.length - 1; ++i) {
        ancestors[i].children = [ancestors[i + 1]];
        ancestors[i].depth = i + 1;
      }
      ancestors[i].children = null;

      // reset value
      for (i = 1; i < ancestors.length; ++i) {
        ancestors[i].value = ancestors[0].value; //调用聚焦函数的调用链等宽，来将聚焦函数的宽度放大到100%
        ancestors[i].data.value = ancestors[0].value;
      }

      for (i = ancestors.length - 1; i > 0; --i) {
        ancestors[i].parent = ancestors[i - 1];
      }
      ancestors[i].parent = null;

      if (ancestors.length !== 0) {
        callers.push(ancestors[0]);
      }
    });

    const callersRoot = new Node({ ...nodes[0].data });
    /* eslint-disable no-plusplus */
    callersRoot.id = ++maxid;
    callersRoot.depth = 0;
    callersRoot.value = 0;
    callersRoot.children = callers;

    callers.forEach((child) => {
      child.parent = callersRoot;
      callersRoot.value += child.value;
    });
    callersRoot.data.value = callersRoot.value;

    mergeChildren(callersRoot);

    return callersRoot;
  }

  function makeCallees() {
    // TODO: 优化调用栈拷贝
    const newNodes = [];
    nodes.forEach((node) => {
      const newNode = node.copy();
      newNode.each((x) => {
        /* eslint-disable no-plusplus */
        x.id = ++maxid;
        x.data = { ...x.data };
        x.selfv = x.data.selfv;
      });
      newNodes.push(newNode);
      const stack = [node];
      let n;
      while (n = stack.pop()) {
        if (!n.focus) {
          if (n.children) {
            stack.push(...n.children);
          }
        }
      }
    });
    nodes = newNodes;

    const root = new Node({ ...nodes[0].data });
    /* eslint-disable no-plusplus */
    root.id = ++maxid;
    root.isFocusRoot = true;

    let sumValue = 0;
    let sumSelfValue = 0;
    const children = [];
    root.tids = new Set();
    root.procs = new Set();
    root.modules = new Set();
    nodes.forEach((node) => {
      sumValue += node.value;
      sumSelfValue += node.selfv;
      if (node.children) {
        children.push(...node.children);
      }
      root.tids.add(node.data.tid);
      root.procs.add(node.data.process);
      root.modules.add(node.data.module);
    });
    root.value = sumValue;
    root.selfv = sumSelfValue;
    root.data.value = root.value;

    root.children = children;
    children.forEach((child) => {
      child.parent = root;
    });

    root.depth = 0;
    root.each((node) => {
      if (node === root) return;
      node.depth = node.parent.depth + 1;
    });

    mergeChildren(root);
    return root;
  }

  const callersRoot = makeCallers();
  const calleesRoot = makeCallees();

  const topSelfv1 = calleesRoot.descendants().sort((a, b) => d3.descending(a.selfv, b.selfv));
  const topSelfv2 = callersRoot.descendants().sort((a, b) => d3.descending(a.selfv, b.selfv));
  const topSelfvNodes = [...topSelfv1, ...topSelfv2].sort((a, b) => d3.descending(a.selfv, b.selfv));

  root.focusNode = {
    callersRoot: callersRoot,
    calleesRoot: calleesRoot,
    topSelfvNodes: topSelfvNodes,
  };
}

function filterNodes(root, width, minFrameSize) {
  function isMinFrame(kx, n) {
    return ((n.x1 - n.x0) * kx) < minFrameSize;
  }

  function iterativeTraversal(node, kx, isCaller) {
    const stack = [node];
    const result = [];
    let maxDepth = 0;

    while (stack.length > 0) {
      const current = stack.pop();

      if (isCaller) {
        current.isCaller = true;
      } else if (isCaller === false) {
        current.isCallee = true;
      }

      if (!isMinFrame(kx, current)) {
        result.push(current);
        if (current.depth > maxDepth) {
          maxDepth = current.depth;
        }
      }

      if (current.children) {
        for (let i = current.children.length - 1; i >= 0; i--) {
          stack.push(current.children[i]);
        }
      }
    }

    return [result, maxDepth];
  }

  if (root.focusNode) {
    const { callersRoot, calleesRoot } = root.focusNode;
    const kx = width / (root.x1 - root.x0);

    const [callersNodes, maxCallersDepth] = iterativeTraversal(callersRoot, kx, true);
    const [calleesNodes, maxCalleesDepth] = iterativeTraversal(calleesRoot, kx, false);

    return [callersNodes.concat(calleesNodes), maxCallersDepth, maxCalleesDepth];
  } else {
    const kx = width / (root.x1 - root.x0);
    return iterativeTraversal(root, kx);
  }
}

export function render({
                         root, svg, colorScheme, partition, graphWidth,
                         cellHeight, transitionEase, transitionDuration,
                         zoom, tooltip, minFrameSize }) {
  if (root.focusNode) {
    const { callersRoot, calleesRoot } = root.focusNode;

    partition(calleesRoot);
    partition(callersRoot);
  } else {
    partition(root);
  }

  let descendants; let maxCallersDepth; let maxCalleesDepth; let maxDepth;
  if (root.focusNode) {
    [descendants, maxCallersDepth, maxCalleesDepth] = filterNodes(root, graphWidth, minFrameSize);
  } else {
    [descendants, maxDepth] = filterNodes(root, graphWidth, minFrameSize);
  }

  const x = scaleLinear().range([0, graphWidth]);
  const y = scaleLinear().range([0, cellHeight]);

  const kx = graphWidth;
  function width(d) {
    return (d.x1 - d.x0) * kx;
  }

  svg.attr('width', graphWidth);

  let g = svg.selectAll('g').data(descendants, d => d.id);

  let svgHeight; let callersHeight; let calleesHeight;
  if (root.focusNode) {
    callersHeight = (maxCallersDepth + 1) * cellHeight;
    calleesHeight = (maxCalleesDepth + 3) * cellHeight;
    svgHeight = callersHeight + calleesHeight;
  } else {
    svgHeight = (maxDepth + 3) * cellHeight;
  }

  svg.attr('height', svgHeight);

  function newTranslate(d) {
    let inverted = false;
    let height = 0;
    let { depth } = d;
    if (root.focusNode) {
      if (d.isCallee) {
        inverted = true;
        depth += maxCallersDepth;
      } else {
        height = callersHeight;
      }
    } else {
      inverted = true;
      height = svgHeight;
    }
    return `translate(${x(d.x0)},${inverted ? y(depth) : (height - y(depth) - cellHeight)})`;
  }

  g.transition()
    .duration(transitionDuration)
    .ease(transitionEase)
    .attr('transform', newTranslate);

  g.select('rect')
    .transition()
    .duration(transitionDuration)
    .ease(transitionEase)
    .attr('width', width);

  const node = g.enter()
    .append('svg:g')
    .attr('transform', newTranslate);

  node.append('svg:rect')
    .transition()
    .delay(transitionDuration / 2)
    .attr('width', width);

  node.append('svg:title');

  node.append('foreignObject')
    .append('xhtml:div');

  g = svg.selectAll('g').data(descendants, d => d.id);

  g.attr('width', width)
    .attr('height', () => cellHeight)
    .attr('name', d => d.data.func)
    .attr('class', d => (d.data.fade ? 'frame fade' : 'frame'));

  g.select('rect')
    .attr('height', () => cellHeight)
    .attr('fill', (d) => {
      let c = colorScheme.pick(d);
      if (d.hl) {
        c = d3.color(c).brighter()
          .formatHex();
      }
      return c;
    });

  g.select('foreignObject')
    .attr('width', width)
    .attr('height', () => cellHeight)
    .select('div')
    .attr('class', 'd3-flame-graph-label')
    .style('display', d => ((width(d) < 35) ? 'none' : 'block'))
    .transition()
    .delay(transitionDuration)
    .text((d) => {
      let name;
      if (d.isRoot || d.isFocusRoot) {
        name = d.data.func;
      } else {
        name = `${d.data.func}`;
      }
      if (d.rank && d.rank !== 0) {
        name += ` [rank ${d.rank}]`;
      }
      return name;
    });

  g.on('click', (_, d) => {
    zoom(root, d);
  });

  g.on('mouseenter', function (e, d) {
    if (tooltip) tooltip.show(d, e, this);
  }).on('mouseleave', function (e, d) {
    if (tooltip) tooltip.hide(d, e, this);
  }).on('mousemove', function (e, d) {
    if (tooltip) tooltip.show(d, e, this);
  });

  g.exit()
    .remove();
}


export function updateTree(root, doSort) {
  if (root.focusNode) {
    const { calleesRoot, callersRoot } = root.focusNode;
    // TODO: reappraiseNode 为原项目程序，主要处理点击放大功能，需重构
    reappraiseNode(calleesRoot);
    reappraiseNode(callersRoot);
    if (root.isSort) {
      calleesRoot.sort(doSort);
      callersRoot.sort(doSort);
    }
  } else {
    reappraiseNode(root);
    focus(root);
    hidden(root);
    if (root.isSort) {
      root.sort(doSort);
    }
  }
}

function reappraiseNode(root) {
  let node; let children; let grandChildren; let childrenValue; let i; let j; let child; let childValue;
  const stack = [];
  const included = [];
  const excluded = [];
  // const compoundValue = !selfValue
  const compoundValue = true;
  let item = root.data;
  if (item.hide) {
    root.value = 0;
    children = root.children;
    if (children) {
      excluded.push(children);
    }
  } else {
    if (root.isFocusRoot) {
      root.value = item.fade ? 0 : root.value;
    } else {
      root.value = item.fade ? 0 : item.value;
    }
    stack.push(root);
  }
  // First DFS pass:
  // 1. Update node.value with node's self value
  // 2. Populate excluded list with children under hidden nodes
  // 3. Populate included list with children under visible nodes
  while ((node = stack.pop())) {
    children = node.children;
    if (children && (i = children.length)) {
      childrenValue = 0;
      while (i--) {
        child = children[i];
        item = child.data;
        if (item.hide) {
          child.value = 0;
          grandChildren = child.children;
          if (grandChildren) {
            excluded.push(grandChildren);
          }
          continue;
        }
        if (item.fade) {
          child.value = 0;
        } else {
          childValue = item.value;
          child.value = childValue;
          childrenValue += childValue;
        }
        stack.push(child);
      }
      // Here second part of `&&` is actually checking for `node.data.fade`. However,
      // checking for node.value is faster and presents more oportunities for JS optimizer.
      if (compoundValue && node.value) {
        node.value -= childrenValue;
      }
      included.push(children);
    }
  }
  // Postorder traversal to compute compound value of each visible node.
  i = included.length;
  while (i--) {
    children = included[i];
    childrenValue = 0;
    j = children.length;
    while (j--) {
      childrenValue += children[j].value;
    }
    children[0].parent.value += childrenValue;
  }
  // Continue DFS to set value of all hidden nodes to 0.
  while (excluded.length) {
    children = excluded.pop();
    j = children.length;
    while (j--) {
      child = children[j];
      child.value = 0;
      grandChildren = child.children;
      if (grandChildren) {
        excluded.push(grandChildren);
      }
    }
  }
}
