import ActionTypes from './DropActionTypes';
const sendDropStart = params => ({
  type: ActionTypes.SEND_DROP_START,
  payload: params,
});

const didSendDropStart = params => ({
  type: ActionTypes.DID_SEND_DROP_START,
  payload: params,
});

// 获取agentip 的信息
const getDropAgentInfoIP = params => ({
  type: ActionTypes.GET_DROP_AGENT_INFO_IP,
  payload: params,
});

const didGetDropAgentInfoIP = params => ({
  type: ActionTypes.DID_GET_DROP_AGENT_INFO_IP,
  payload: params,
});


// 获取drop result信息
const getDropTaskResult = params => ({
  type: ActionTypes.GET_DROP_TASK_RESULT,
  payload: params,
});

const didGetDropTaskResult = params => ({
  type: ActionTypes.DID_GET_DROP_TASK_RESULT,
  payload: params,
});


const getDropResultImage = params => ({
  type: ActionTypes.GET_DROP_RESULT_IMAGE,
  payload: params,
});

const didGetDropResultImage = params => ({
  type: ActionTypes.DID_GET_DROP_RESULT_IMAGE,
  payload: params,
});

const getDropCost = params => ({
  type: ActionTypes.GET_DROP_COST,
  payload: params,
});

const didGetDropCost = params => ({
  type: ActionTypes.DID_GET_DROP_COST,
  payload: params,
});

const deleteDropTask = params => ({
  type: ActionTypes.DELETE_DROP_TASK,
  payload: params,
});

const didDeleteDropTask = params => ({
  type: ActionTypes.DID_DELETE_DROP_TASK,
  payload: params,
});


const getDropAgentList = params => ({
  type: ActionTypes.GET_DROP_AGENT_LIST,
  payload: params,
});

const didGetDropAgentList = params => ({
  type: ActionTypes.DID_GET_DROP_AGENT_LIST,
  payload: params,
});

const getAgentMonitorData = params => ({
  type: ActionTypes.GET_AGENT_MONITOR_DATA,
  payload: params,
});

const didGetAgentMonitorData = params => ({
  type: ActionTypes.DID_GET_AGENT_MONITOR_DATA,
  payload: params,
});

const getUserUid = params => ({
  type: ActionTypes.GET_USER_UID,
  payload: params,
});

const didGetUserUid = params => ({
  type: ActionTypes.DID_GET_USER_UID,
  payload: params,
});

const startDiskTracing = params => ({
  type: ActionTypes.START_DISK_TRACING,
  payload: params,
});

const didStartDiskTracing = params => ({
  type: ActionTypes.DID_START_DISK_TRACING,
  payload: params,
});

const createGroup = params => ({
  type: ActionTypes.CREATE_GROUP,
  payload: params,
});

const didCreateGroup = params => ({
  type: ActionTypes.DID_CREATE_GROUP,
  payload: params,
});

const disbandGroup = params => ({
  type: ActionTypes.DISBAND_GROUP,
  payload: params,
});

const didDisbandGroup = params => ({
  type: ActionTypes.DID_DISBAND_GROUP,
  payload: params,
});

const addGroupMember = params => ({
  type: ActionTypes.ADD_GROUP_MEMBER,
  payload: params,
});

const didAddGroupMember = params => ({
  type: ActionTypes.DID_ADD_GROUP_MEMBER,
  payload: params,
});

const removeGroupMember = params => ({
  type: ActionTypes.REMOVE_GROUP_MEMBER,
  payload: params,
});

const didRemoveGroupMember = params => ({
  type: ActionTypes.DID_REMOVE_GROUP_MEMBER,
  payload: params,
});

const getGroup = params => ({
  type: ActionTypes.GET_GROUP,
  payload: params,
});

const didGetGroup = params => ({
  type: ActionTypes.DID_GET_GROUP,
  payload: params,
});

const getGroupAgent = params => ({
  type: ActionTypes.GET_GROUP_AGENT,
  payload: params,
});

const didGetGroupAgent = params => ({
  type: ActionTypes.DID_GET_GROUP_AGENT,
  payload: params,
});

const addGroupAgent = params => ({
  type: ActionTypes.ADD_GROUP_AGENT,
  payload: params,
});

const didAddGroupAgent = params => ({
  type: ActionTypes.DID_ADD_GROUP_AGENT,
  payload: params,
});

const removeGroupAgent = params => ({
  type: ActionTypes.REMOVE_GROUP_AGENT,
  payload: params,
});

const didRemoveGroupAgent = params => ({
  type: ActionTypes.DID_REMOVE_GROUP_AGENT,
  payload: params,
});

const getGroups = params => ({
  type: ActionTypes.GET_GROUPS,
  payload: params,
});

const didGetGroups = params => ({
  type: ActionTypes.DID_GET_GROUPS,
  payload: params,
});


const getUserAgents = params => ({
  type: ActionTypes.GET_USER_AGENTS,
  payload: params,
});

const didGetUserAgents = params => ({
  type: ActionTypes.DID_GET_USER_AGENTS,
  payload: params,
});

const getDropResultCallgraphImage = params => ({
  type: ActionTypes.GET_DROP_RESULT_CALLGRAPH_IMAGE,
  payload: params,
});

const didGetDropResultCallgraphImage = params => ({
  type: ActionTypes.DID_GET_DROP_RESULT_CALLGRAPH_IMAGE,
  payload: params,
});

const getCallgraphData = params => ({
  type: ActionTypes.GET_CALLGRAPH_DATA,
  payload: params,
});

const didGetCallgraphData = params => ({
  type: ActionTypes.DID_GET_CALLGRAPH_DATA,
  payload: params,
});

const getTaskResult = params => ({
  type: ActionTypes.GET_TASK_RESULT,
  payload: params,
});

const didGetTaskResult = params => ({
  type: ActionTypes.DID_GET_TASK_RESULT,
  payload: params,
});

const getSharedTaskURL = params => ({
  type: ActionTypes.GET_SHARED_TASK_URL,
  payload: params,
});

const didGetSharedTaskURL = params => ({
  type: ActionTypes.DID_GET_SHARED_TASK_URL,
  payload: params,
});

const getFlamegraphData = params => ({
  type: ActionTypes.GET_FLAMEGRAPH_DATA,
  payload: params,
});

const didGetFlamegraphData = params => ({
  type: ActionTypes.DID_GET_FLAMEGRAPH_DATA,
  payload: params,
});

const removeAgent = params => ({
  type: ActionTypes.REMOVE_AGENT,
  payload: params,
});

const didRemoveAgent = params => ({
  type: ActionTypes.DID_REMOVE_AGENT,
  payload: params,
});

// eslint-disable-next-line
export default {
  sendDropStart,
  didSendDropStart,

  getDropAgentInfoIP,
  didGetDropAgentInfoIP,

  getDropTaskResult,
  didGetDropTaskResult,

  getDropResultImage,
  didGetDropResultImage,

  getDropCost,
  didGetDropCost,

  deleteDropTask,
  didDeleteDropTask,

  getDropAgentList,
  didGetDropAgentList,

  getAgentMonitorData,
  didGetAgentMonitorData,

  getUserUid,
  didGetUserUid,

  startDiskTracing,
  didStartDiskTracing,

  createGroup,
  didCreateGroup,

  disbandGroup,
  didDisbandGroup,

  addGroupMember,
  didAddGroupMember,

  removeGroupMember,
  didRemoveGroupMember,

  getGroup,
  didGetGroup,

  getGroupAgent,
  didGetGroupAgent,

  addGroupAgent,
  didAddGroupAgent,

  removeGroupAgent,
  didRemoveGroupAgent,

  getGroups,
  didGetGroups,

  getUserAgents,
  didGetUserAgents,

  getDropResultCallgraphImage,
  didGetDropResultCallgraphImage,

  getCallgraphData,
  didGetCallgraphData,
  getTaskResult,
  didGetTaskResult,

  getSharedTaskURL,
  didGetSharedTaskURL,

  getFlamegraphData,
  didGetFlamegraphData,

  removeAgent,
  didRemoveAgent,
};
