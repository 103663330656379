/* eslint-disable */
import * as match from './matcher';
import * as op from './operation';

export function exec(root, cmd) {
  if (!cmd) {
    return;
  }

  const opName = cmd[0];
  if (opName === 'sort') {
    if (typeof cmd[1] !== 'boolean') return;
    root.isSort = cmd[1];
    return;
  }

  const op = name2op(opName);
  if (!op) {
    console.error('invalid op name', opName);
    return;
  }

  const matcherName = cmd[1];
  const matcher = name2matcher(matcherName);
  if (!matcher) {
    console.error('invalid matcher name', matcherName);
    return;
  }

  if (opName === 'focus') {
    cmd.push((value, term) => value === term);
  }

  if (root.focusNode) {
    matcher(root.focusNode.callersRoot, op, ...cmd.slice(2));
    matcher(root.focusNode.calleesRoot, op, ...cmd.slice(2));
  } else {
    matcher(root, op, ...cmd.slice(2));
  }
}

function name2op(name) {
  switch (name) {
    case 'show': return op.show;
    case 'hide': return op.hide;
    case 'highlight': return op.highlight;
    case 'focus': return op.focus;
    default:
      console.error('invalid op name', name);
  }
}

function name2matcher(name) {
  switch (name) {
    case 'stacks': return match.stacks;
    case 'callees': return match.callees;
    case 'callers': return match.callers;
    case 'frames': return match.frames;
    case 'top': return match.matchTop;
    default:
      console.error('invalid match name', name);
      return null;
  }
}
