/**
 * 基于 https://github.com/DomParfitt/graphviz-react 改造
 *
 * */
import * as React from 'react';
import PropTypes from 'prop-types';
import { useEffect, useMemo, useRef } from 'react';
import { graphviz } from 'd3-graphviz';
import * as d3 from 'd3';
import DraggableScalableContainer from '../../components/draggableContainer';

let counter = 0;
// eslint-disable-next-line no-plusplus
const getId = () => `graphviz${counter++}`;

const Graphviz = ({ dot }) => {
  const id = useMemo(getId, []);

  const resetRef = useRef(null);

  const handleReset = () => {
    if (resetRef.current) {
      resetRef.current();
    }
  };

  useEffect(() => {
    handleReset();
    graphviz(`#${id}`)
      .options({
        fit: true,
        height: '100%',
        width: '100%',
        zoom: false,
      })
      .transition(() => {
        d3.transition()
          .delay(200)
          .duration(1000);
      })
      .renderDot(dot);
  }, [dot]);

  return <>
    <div className="h-screen w-full overflow-scroll">
      <DraggableScalableContainer className="w-full h-full" onResetRef={resetRef}>
        <div id={id}>
        </div>
      </DraggableScalableContainer>
    </div>
  </>;
};

Graphviz.propTypes = {
  dot: PropTypes.string,
};

export default Graphviz;
