/*
存储相关公共函数
*/
import Cookies from 'js-cookie';
import { PRODUCT_PREFIX } from '@/config';


// 获取cookie
export function getCookie(cname) {
  return Cookies.get(cname);
}

// 设置cookie
export function setCookie(name, value, days) {
  Cookies.set(name, value, { expires: days });
}

// 清理cookie
export function clearAllCookie() {
  const cookies = Object.keys(getAllCookie());
  cookies.forEach((cookie) => {
    Cookies.remove(cookie);
  });
  window.localStorage.clear('drop_user_uid');
  window.localStorage.clear('drop_user_name');
}

// 获取全部cookie
function getAllCookie() {
  return Cookies.get();
}

const getLocalStorage = (key) => {
  const value = window.localStorage.getItem(key);
  if (value === null) {
    return '';
  }
  return value;
};

// 获取${PRODUCT_PREFIX}user_name
// 也即获取drop_user_name
export const  getOAName = () => {
  const { search } = window.location;
  const searchParams = new URLSearchParams(search);
  // 只获取当前环境，不论是否为父环境
  const platform = searchParams.get('platform'); // string
  if (platform === 'zhiyan') {
    const serviceName = searchParams.getAll('service');
    const projectID = searchParams.getAll('project');
    const name = `${serviceName}_${projectID}`;
    window.localStorage.setItem('drop_user_name', name);
    return name;
  }
  const userName = getCookie(`${PRODUCT_PREFIX}user_name`);
  if (!userName || userName.indexOf('[') !== -1) {
    return getLocalStorage('drop_user_name');
  }
  return decodeURIComponent(userName.replace(/"/g, ''));
};

export const  getUserUid = () => {
  const { search } = window.location;
  const searchParams = new URLSearchParams(search);
  // 只获取当前环境，不论是否为父环境
  const platform = searchParams.get('platform'); // string
  if (platform === 'zhiyan') {
    const projectID = searchParams.getAll('project');
    const appID = searchParams.getAll('zhiyan_app_id');
    const tuid = `${projectID}_${appID}`;
    window.localStorage.setItem('drop_user_uid', tuid);
    return `${projectID}_${appID}`;
  }
  const Uid = getCookie(`${PRODUCT_PREFIX}user_uid`);
  if (!Uid || Uid.indexOf('[') !== -1) {
    return getLocalStorage('drop_user_uid');
  }
  return decodeURIComponent(Uid.replace(/"/g, ''));
};
