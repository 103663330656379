import React, {
  useRef,
  useState,
} from 'react';
import PropTypes from 'prop-types';

import {
  Input,
  Space,
  Popup,
  List,
} from 'tdesign-react';

import { FilterIcon } from 'tdesign-icons-react';
const { ListItem } = List;

export function OpInput({ autoComplement, onFinish, onFocus, onBlur }) {
  const [inputVal, setInputVal] = useState('');
  const [completeList, setCompleteList] = useState(autoComplement.next.list);
  const inputElem = useRef(null);
  const [complementDesc, setComplementDesc] = useState(autoComplement.next.desc);
  const [autofocus, setAutofocus] = useState(false); // TODO: 不生效

  function onEnter(text) {
    const op = [];
    const fields = text.trim().split(/\s+/);
    if (fields.length !== 3) {
      setInputStatus('error');
      setComplementDesc(<p style={{ color: 'red' }}>invalid operation</p>);
      return;
    }
    op.push(...fields.slice(0, -1));
    const lastField = fields[fields.length - 1];
    const attrFields = lastField.split(':');
    if (attrFields.length === 2) {
      if (attrFields[0] === 'tid') {
        const tid = parseInt(attrFields[1], 10);
        op.push('tid', tid);
      } else {
        op.push(...attrFields);
      }
    } else if (attrFields.length === 1) {
      if (op[op.length - 1] === 'top') {
        op.push(parseInt(attrFields[0], 10));
      }
    } else {
      setInputStatus('error');
      setComplementDesc(<p style={{ color: 'red' }}>invalid operation</p>);
      return;
    }

    setInputStatus('default');
    onFinish(op);
    setInputVal('');
    setAutofocus(false);
  }

  function onInputChange(text) {
    const selectionStart = getSelectionStart();
    const preText = text.slice(0, selectionStart);
    const fields = preText.split(/\s+/);
    const [list, desc, status] = getAutoCompletion(fields);
    setCompleteList(list);
    setComplementValidDesc(desc);
    setInputStatus(status);
    if (status === 'default') {
      setInputVal(text);
    } /* else 不更新输入值 */
  }

  function onClick(event) {
    const { selectionStart } = event.e.target;
    const preText = inputVal.slice(0, selectionStart + 1);
    const fields = preText.split(/\s+/);
    const [list, desc, status] = getAutoCompletion(fields);
    setCompleteList(list);
    setComplementValidDesc(desc);
    setInputStatus(status);
  }

  function replaceWord(pos, word) {
    let start = 0;
    let end = inputVal.length;
    for (let i = pos; i >= 0; --i) {
      if (inputVal[i] === ' ') {
        start = i + 1;
        break;
      }
    }

    for (let i = pos; i < inputVal.length; ++i) {
      if (inputVal[i] === ' ') {
        end = i - 1;
        break;
      }
    }

    const newInputVal = inputVal.substring(0, start) + word + inputVal.substring(end);
    setInputVal(newInputVal);
  }

  function getSelectionStart() {
    if (inputElem.current === null) {
      return 0;
    }
    const realInputElem = inputElem.current.inputElement;
    return realInputElem.selectionStart ?? 0;
  }

  function onClickListItem(event) {
    const cursorPos = getSelectionStart();
    const word = event.target.innerText;
    replaceWord(cursorPos, word);
    setAutofocus(true);
  }

  const [inputStatus, setInputStatus] = useState('default');

  function getAutoCompletion(fields) {
    let curOp = autoComplement;
    let { list } = curOp.next;
    let filteredList = list;
    for (let i = 0; i < fields.length; ++i) {
      const field = fields[i];
      filteredList = list.filter(op => op.name.startsWith(field));

      if (!curOp.next.check(filteredList, field)) {
        return [list, <p key={1} style={{ color: 'red' }}>invalid input</p>, 'error'];
      }

      if (filteredList.length !== 1) {
        break;
      }

      curOp = filteredList[0];
      if (!curOp.next) {
        break;
      }
      if (i < fields.length - 1 && field !== curOp.name) {
        return [list, <p key={1} style={{ color: 'red' }}>invalid input</p>, 'error'];
      }

      list = curOp.next.list;
    }

    let desc;
    if (filteredList.length === 1) {
      desc = filteredList[0].desc;
    } else {
      desc = curOp.next.desc;
    }

    return [filteredList, desc, 'default'];
  }

  function setComplementValidDesc(desc) {
    if (desc && desc !== '') setComplementDesc(desc);
  }
  function onHoverComplementListItem(item) {
    setComplementValidDesc(item.desc);
  }

  return (
    <>
      <Popup
        trigger="focus"
        overlayInnerClassName='flame-graph-operation-input-popup'
        placement='bottom-left'
        content={
          <Space size={0}>
            <List
              className='flame-graph-operation-input-popup-menu'>
              {completeList.map((item, idx) => (
                <div key={idx}
                  onClick={e => onClickListItem(e)}
                  onMouseEnter={() => onHoverComplementListItem(item)}>
                  <ListItem
                    key={idx}
                    className='flame-graph-operation-input-popup-listitem'
                    content={item.name}
                  />
                </div>
              ))}
            </List>
            {
              complementDesc
              && <div
                className='flame-graph-operation-input-popup-desc'>
                {complementDesc}
              </div>
            }
          </ Space>
        } >
        <Input
          ref={inputElem}
          autofocus={autofocus}
          prefixIcon={<FilterIcon />}
          placeholder='输入操作以过滤火焰图'
          status={inputStatus}
          value={inputVal}
          onChange={(text, e) => onInputChange(text, e)}
          onClick={e => onClick(e)}
          onEnter={val => onEnter(val)}
          onFocus={() => onFocus()}
          onBlur={() => onBlur()}
        />
      </Popup>
    </>
  );
}

OpInput.propTypes = {
  autoComplement: PropTypes.object,
  onFinish: PropTypes.func,
  onFocus: PropTypes.func,
  onBlur: PropTypes.func,
};
