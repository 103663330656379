// 容器视角采样结果展示页面
import React, { useEffect, useState } from 'react';
import '../../Drop.css';
// import qs from 'qs';
// import Callgraph from './callgraph';
// import FlameGraph from '@/components/flamegraph/flamegraph';
import {
  Card,
  Layout,
  // Text,
} from '@tencent/tea-component';
import {
  Tooltip as _TDTooltip,
  _List, _Popup,
  // Space,
  // Link, Tag, Tooltip, _Statistic,
} from 'tdesign-react';
import PropTypes, { _string } from 'prop-types';
import { Log } from '@/utils/Log';
import axios from 'axios';
import { HOST_URL } from '@/config/';
import { TaskInfoCard } from '@/components/taskInfo';
import { _getgoroutineFlameGraphURL, _getFlameGraphURL, _getheapFlameGraphURL, _downloadFile, _jumpTargetConfig } from '@/utils/URL';
// import FibonaButton from '@/components/fibona';
import GolangHeapResultCard from './golangHeapResultCard';
// import { FIBONA_TRIGGER } from '@/config';
import { _NotificationErrorIcon, _CloseCircleFilledIcon, _ChevronDownIcon } from 'tdesign-icons-react';
import _AnchorItem from 'tdesign-react/es/anchor/AnchorItem';


const { Body, Content } = Layout;
// const { ListItem } = List;
const fetchTask = (id) => {
  const response =  axios.get(`${HOST_URL}/api/v1/task`, {
    params: { id, is_shared_task: 0 },
  });
  return response;
};

const PprofheapResult = (props) => {
  const [taskData, settaskData] = useState(props.task_data);
  const [taskResult, settaskResult] = useState(props.task_result);
  const [analysisSuggestions, setanalysisSuggestions] = useState(props.analysis_suggestions);
  const [tid, settid] = useState("");
  const [initial, setInitial] = useState(false);
  const [cacheTaskResult, setCacheTaskResult] = useState({});
  const [showResultCard, setShowResult] = useState(false);
  if (props.taskid !== undefined) {
    settid(props.taskid);
  }

  useEffect(() => {
    if (tid !== "" & taskData === undefined) {
      try {
        const Data = fetchTask(tid);
        if (Data) {
          settaskData(Data.data);
          settaskResult(Data.data.taskResult);
          setanalysisSuggestions(Data.data.taskResult[""].suggestion);
        }
        Log.debug('PprofheapResulttaskData', Data);
      } catch (error) {
        console.error('Error fetching flame graph:', error);
      }
    }
  }, [tid]);

  useEffect(() => {
    setInitial(true);
  }, []);

  useEffect(() => {
    if (cacheTaskResult[''] | taskResult) {
      return ;
    }
    setShowResult(false);
    const result = taskResult[''];
    const params = [
      result?.callgraph_file,
      // result?.callgraph_file,
      // result?.extended_flamegraph_file,
      result?.goroutine_callgraph_file];
    let requestParams = {};
    if (props.is_shared_url) {
      requestParams = {
        cos_files: params,
        tid: taskData.tid,
        expired_time: props.expired_time,
        token: props.shared_token,
        is_shared_task: '1',
      };
    } else {
      requestParams = {
        cos_files: params,
        tid: taskData.tid,
        is_shared_task: '0',
      };
    }
    axios({
      method: 'get',
      url: `${HOST_URL}/api/v1/cosfiles`,
      params: requestParams,
    }).then((response) => {
      const files = response.data.cos_files;
      const data = cacheTaskResult;
      data[''] = {
        callgraph_file: files[0],
        // callgraph_file: files[1],
        // extended_flamegraph_file: files[2],
        goroutine_callgraph_file: files[1],
      };
      setCacheTaskResult(data);
      setShowResult(true);
    });
  }, [taskResult]);

  return <div
  className="show-module-style"
>
  <Layout className="demo-layout">
    <Body>
      <Content>
        {props.showGobackButton
          ? <Content.Header
            showBackButton
            onBackButtonClick={() => props.goback()}
            title="返回"
          >
          </Content.Header> : ''}
        <Content.Body>
          <TaskInfoCard
            task_info={taskData}
            analysis_version={props.analysis_version}
            jupyter_url={props.jupyter_url}>
          </TaskInfoCard>
            <Card>
              <Card.Body
              title="任务结果"
              subtitle= '全局'
              operation =  ''
              >
              { initial && showResultCard ? <GolangHeapResultCard
                key={''}
                active_threads_json = {taskResult['']?.active_threads_json}
                callgraph_file = {cacheTaskResult['']?.callgraph_file}
                flamegraph_file = {cacheTaskResult['']?.flamegraph_file}
                goroutine_callgraph_file = {cacheTaskResult['']?.goroutine_callgraph_file}
                extended_flamegraph_file = {cacheTaskResult['']?.extended_flamegraph_file}
                top_funcs = {taskResult['']?.top_funcs}
                total_toplist = {taskResult['']?.total_toplist}
                goroutine_top_funcs = {taskResult['']?.goroutine_top_funcs}
                top_funcs_annotate_enable = {taskData?.request_params?.annotate_enable}
                total_records = {taskResult['']?.total_records}
                analyse_suggestions = {analysisSuggestions['']}
                display_flamegraph_search={new Date(props.task_data.create_time.Time).getTime()
                      > new Date('2023-11-09T10:30:00.000000+08:00').getTime()}
                task_name = {props.task_data.name}
              >
              </GolangHeapResultCard> : ''}
              </Card.Body>
            </Card>
        </Content.Body>
          </Content>
        </Body>
      </Layout>
    </div>;
};
export default PprofheapResult;


PprofheapResult.propTypes = {
  taskid: PropTypes.string,
  task_data: PropTypes.object,
  containers_info: PropTypes.object,
  analysis_version: PropTypes.string,
  task_result: PropTypes.object,
  analysis_suggestions: PropTypes.object,
  goback: PropTypes.func,
  is_shared_url: PropTypes.bool,
  jupyter_url: PropTypes.string,
  expired_time: PropTypes.string,
  shared_token: PropTypes.string,
  showGobackButton: PropTypes.bool,
};

