/*
time:2023-2-20
author:lokinli
description:时序分析结果页面
props:
    task_data  ：Drop任务信息
    user_name  ：用户名
    cost_data  :开销数据
*/
import React from 'react';
import { withRouter } from 'react-router-dom';
import DropActionCreator from '@/api/DropActions';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { goodStatus } from '@/utils/ErrorConfirm';
import '../Drop.css';
import * as echarts from 'echarts';
import {
  Card,
  Layout,
  List,
  StatusTip,
  Table,
  TabPanel,
  Tabs,
  Tag,
  Text,
  Tooltip,
} from '@tencent/tea-component';
import PropTypes from 'prop-types';
import { Log } from '@/utils/Log';
import axios from 'axios';
import { HOST_URL } from '@/config/';
import { TaskInfoCard } from '@/components/taskInfo';
import { jumpTargetConfig } from '../../utils/URL';

const { Body, Content } = Layout;
const { pageable, scrollable } = Table.addons;
// 首先由DropResult拿到全部数据 然后把数据往两个子组件上传输
// 图片在组件中获取
class TimingSamplingResult extends React.Component {
  constructor(props) {
    super(props);
    Log.debug('TimingSamplingResult', props);

    const randomTopNData = props.timing_analysis_result.detection_results.top_event_total_duration;
    // 先按照值对object排序
    let sortedKeysArray = [];
    if (randomTopNData && Object.keys(randomTopNData).length) {
      sortedKeysArray = Object.values(randomTopNData).sort((a, b) => b - a);
    }
    const topEventInfo = {};
    for (let i = 0; i < sortedKeysArray.length; i++) {
      // 将原对象中的键值对按照属性值的排序顺序写入新对象
      Object.keys(randomTopNData).map((item) => {
        if (randomTopNData[item] === sortedKeysArray[i]) {
          topEventInfo[item] = sortedKeysArray[i];
        }
        return 0;
      });
    }
    this.state = {
      task_data: this.props.task_data, // 任务信息
      timing_analysis_result: this.props.timing_analysis_result, // 时序检测的结果
      result_flag: this.props.timing_analysis_result.result_flag, // 是否有分析结果
      detection_results_set: {
        detection_results_flag: 0,
        detection_result_detail: [],
      }, // 用于渲染分析结果的表格
      top_event_info: topEventInfo, // topN 函数持次数
      shared_token: this.props.shared_token,
      is_shared_url: this.props.is_shared_url,
      user_name: this.props.user_name, // 用户名
      expired_time: this.props.expired_time,
      cost_dataset: { // 开销数据
        cpuDataset: [], // cpu开销 [["11:21:39",0,0]] 需要参看echarts图表渲染内容
        ioDataset: [], // io开销   [["11:21:39",0,0,0,0]]
        memDataset: [], // mem开销 [["11:21:39",0,0]]
        flag: 0, // 是否加载完成
      },
    };
  }
  componentDidMount() {
    const resultFlag = this.props.timing_analysis_result.result_flag;
    if (resultFlag === '1') {
      const detectionResults = this.props.timing_analysis_result.detection_results.detection_result_detail;
      for (const key in detectionResults) {
        const params = [detectionResults[key].call_chains_file, detectionResults[key].timing_graph_file];
        const tid = detectionResults[key].call_chains_file.split('/')[0];
        let requestParams = {};
        if (this.props.is_shared_url) {
          requestParams = {
            cos_files: params,
            tid,
            expired_time: this.props.expired_time,
            token: this.props.shared_token,
            is_shared_task: '1',
          };
        } else {
          requestParams = {
            cos_files: params,
            tid,
            is_shared_task: '0',
          };
        }
        axios({
          method: 'get',
          url: `${HOST_URL}/api/v1/cosfiles`,
          params: requestParams,
        }).then((response) => {
          detectionResults[key].call_chains_file = response.data.cos_files[0];
          detectionResults[key].timing_graph_file = response.data.cos_files[1];
        });
      }
      console.log('获取的cosfile如下：');
      console.log(detectionResults);
      this.setState({
        detection_results_set: {
          detection_result_detail: detectionResults,
          detection_results_flag: 1,
        },
      });
    }
  }
  componentWillReceiveProps(nextProps) {//eslint-disable-line
    // 获取开销数据 - 时序分析结果也使用该页面
    // todo:添加错误信息
    const DropCost = nextProps.DropReducer.GetDropCost;
    if (Object.keys(DropCost).length) {
      if (goodStatus(DropCost)) {
        const ioDataset = [];
        const cpuDataset = [];
        const memDataset = [];
        const { selfPstats } = DropCost.data.result;
        const { childrenPstats } = DropCost.data.result;
        for (let i = 0; i < selfPstats.length; ++i) {
          const datatime = new Date(selfPstats[i].timestamp.seconds * 1000);
          const time = `${datatime.getHours()}:${datatime.getMinutes()}:${datatime.getSeconds()}`; // 时间
          cpuDataset.push([
            time,
            selfPstats[i].cpuPercent ?? 0,
            childrenPstats[i].cpuPercent ?? 0,
          ]);
          ioDataset.push([
            time,
            selfPstats[i].readKbytesRate ?? 0,
            selfPstats[i].writeKbytesRate ?? 0,
            childrenPstats[i].readKbytesRate ?? 0,
            childrenPstats[i].writeKbytesRate ?? 0,
          ]);
          memDataset.push([
            time,
            // self_pstats[i].vszKbytes,
            selfPstats[i].rssKbytes ?? 0,
            // children_pstats[i].vszKbytes,
            childrenPstats[i].rssKbytes ?? 0,
          ]);
        }
        this.setState({
          cost_dataset: {
            cpuDataset,
            ioDataset,
            memDataset,
            flag: 1,
          },
        });
      }
    }
  }

  // 切换tab时触发的事件
  // activekey = 2：获取开销数据
  onChange = (activeKey) => {
    // 添加获取cost的调用
    if (activeKey === 'cost_dataset_id' && this.state.cost_dataset.flag === 0) {
      const param = {
        user: this.state.user_name,
        task_id: this.state.task_data.tid,
        target_ip: this.state.task_data.target_ip,
      };
      this.props.DropAction.getDropCost(param);
    }
  };

  render() {
    // Tab组件传入数据
    const tabs = [
      { id: 'top_event_info_id', label: '函数持续执行次数TopN' },
      { id: 'detection_results_id', label: '检测结果' },
      // { id: 'cost_dataset_id', label: '开销数据' },
    ];
    // 渲染内容
    return <div
      className="show-module-style"
    >
      <Layout className="demo-layout">
        <Body>
          <Content>
            {this.props.showGobackButton
              ? <Content.Header
                showBackButton
                onBackButtonClick={() => this.props.goBack()}
                title="返回"
              >
              </Content.Header> : ''}
            <Content.Body>
              <TaskInfoCard
              task_info={this.props.task_data}
              jupyter_url = {this.props.jupyterURL}>
            </TaskInfoCard>
              <br></br>
              <Card>
                <Card.Body title="任务结果">
                  <Tabs onActive={(data) => {
                    this.onChange(data.id);
                  }}
                    tabs={tabs}>
                    <TabPanel id="top_event_info_id">
                      <HeapStackInfo
                        HeapStackInfo={this.state.top_event_info}></HeapStackInfo>
                    </TabPanel>
                    <TabPanel id="detection_results_id">
                    {(() => {
                      switch (this.state.result_flag) {
                        case '0':
                          return <p> 没有检测到系统发生 busy-wait synchronization 竞争。</p>;
                        case '1':
                          return this.state.detection_results_flag === 0
                            ? <StatusTip style={{ width: '10%', margin: '10% 45%' }}
                            status="loading" loadingText="获取分析结果中..." />
                            : <DetectionResultTable
                            detection_result_detail =  {this.state.detection_results_set.detection_result_detail} >
                            </DetectionResultTable>;
                      }
                    })()}
                    </TabPanel>
                    <TabPanel id="cost_dataset_id">
                      {this.state.cost_dataset.flag === 0
                        ? <StatusTip style={{ width: '10%', margin: '10% 45%' }}
                          status="loading" loadingText="获取开销数据中..." />
                        : <div><CostData style={{ height: '540px', width: '100%' }}
                          cpuDataset={this.state.cost_dataset.cpuDataset}
                          memDataset={this.state.cost_dataset.memDataset}
                          ioDataset={this.state.cost_dataset.ioDataset}></CostData>
                          <br></br>
                          <p style={{
                            textAlign: 'center',
                            fontSize: '20',
                            color: 'red',
                            'font-weight': 'bold',
                          }}>注：可以通过鼠标滚轮和左键拖拽调整查看范围</p>
                        </div>}
                    </TabPanel>
                  </Tabs>
                </Card.Body>
              </Card>
            </Content.Body>
          </Content>
        </Body>
      </Layout>
    </div>;
  }
}

TimingSamplingResult.propTypes = {
  DropAction: PropTypes.any.isRequired,
  DropReducer: PropTypes.any.isRequired,
  task_data: PropTypes.object,
  timing_analysis_result: PropTypes.object,
  // detection_results: PropTypes.object,
  user_name: PropTypes.string,
  goBack: PropTypes.func,
  jupyterURL: PropTypes.string,
  expired_time: PropTypes.string,
  shared_token: PropTypes.string,
  results_flag: PropTypes.bool,
  is_shared_url: PropTypes.bool,
  jupyter_url: PropTypes.string,
  showGobackButton: PropTypes.bool,
};

// 封装了时序分析的结果数据
// 只需要输入data就可以
// props：detection_results
class DetectionResultTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      searchText: '',
      searchedColumn: '',
      pageSize: 10,
      detection_result_detail: this.props.detection_result_detail,
    };
  }
  render() {
    const resultColumns = [
      {
        header: '冲突持续时间(ms)',
        key: 'total_time',
        width: 120,
        render: text => (
          <Tooltip placement="topLeft" title={String((Number(text.total_time).toFixed(3)) * 1000)}>
            {(Number(text.total_time).toFixed(3)) * 1000}
          </Tooltip>
        ),
      },
      {
        header: '连续调用次数',
        key: 'total_duration',
        width: 95,
        render: text => (
          <Tooltip placement="topLeft" title={text.total_duration}>
            {text.total_duration}
          </Tooltip>
        ),
      },
      {
        header: '开始时间',
        key: 'time_begin',
        width: 120,
        render: text => (
          <Tooltip placement="topLeft" title={text.time_begin}>
            {text.time_begin}
          </Tooltip>
        ),
      },
      {
        header: '结束时间',
        key: 'time_end',
        width: 120,
        render: text => (
          <Tooltip placement="topLeft" title={text.time_end}>
            {text.time_end}
          </Tooltip>
        ),
      },
      {
        header: '运行态',
        key: 'operation_mode',
        width: 90,
        render: (text) => {
          let color = 'red';
          text.operation_mode === 'kernel' ? color = 'red' : color = 'green';
          return (
            <Tag key={text.operation_mode} color={color}>
              {text.operation_mode}
            </Tag>
          );
        },
      },
      // {
      //   header: '疑似线程',
      //   key: 'threads_id',
      //   ellipsis: {
      //     showTitle: false,
      //   },
      //   render: text => (
      //     <Tooltip placement="topLeft" title={text.threads_id} style={{ width: '400px' }}>
      //       <div style={{ maxWidth: 100, maxHeight: 95, textOverflow: 'ellipsis',
      //         overflow: 'hidden', whiteSpace: 'normal', cursor: 'pointer' }}>
      //         {text.threads_id}
      //       </div>
      //     </Tooltip>
      //   ),
      // },
      // {
      //   header: '疑似进程',
      //   key: 'processes_id',
      //   ellipsis: {
      //     showTitle: false,
      //   },
      //   render: text => (
      //     <Tooltip placement="topLeft" title={text.processes_id} style={{ width: '400px' }}>
      //     <div style={{ maxWidth: 100, maxHeight: 95, textOverflow: 'ellipsis',
      //       overflow: 'hidden', whiteSpace: 'normal', cursor: 'pointer' }}>
      //       {text.processes_id}
      //     </div>
      //   </Tooltip>
      //   ),
      // },
      {
        header: '疑似竞争调用栈(从栈顶开始的5层调用）',
        key: 'show_function_call_stacks',
        ellipsis: {
          showTitle: false,
        },
        width: 300,
        render: text => (
        //   // <Tooltip placement="topLeft" title={text.show_function_call_stacks} style={{ width: '400px' }}>
            <div style={{ maxWidth: 400, maxHeight: 95, textOverflow: 'ellipsis',
              overflow: 'hidden', whiteSpace: 'normal', cursor: 'pointer' }}>
              {text.show_function_call_stacks.replace(/;/g, ' ← ')}
              {/* {text.show_function_call_stacks.split(';').map((value, index) => {
              return index === text.show_function_call_stacks.length - 1 ? `${value}`
              : `${value} ← `;
              })} */}
            </div>
        //   // </Tooltip>
        ),
      },
      {
        header: '线程时序图',
        key: 'timing_graph_file',
        width: 100,
        render: text =>  <a href={text.timing_graph_file} target={jumpTargetConfig()} rel="noreferrer">
          线程时序图
          {/* <img style={{ height: '100px', width: '100%' }} src={text.timing_graph_file} /> */}
          </a>,
      },
      {
        header: '函数调用图',
        key: 'call_chains_file',
        width: 100,
        render: text => <a href={text.call_chains_file} target={jumpTargetConfig()} rel="noreferrer" >
            函数调用图
            {/* <img style={{ height: '100px', width: '100%' }} src={text.call_chains_file} /> */}
            </a>,
      },
    ];
    return <Table columns={resultColumns}
      records={this.props.detection_result_detail}
      addons={[
        pageable({
          pageSize: 10,
        }),
        scrollable({ maxHeight: 480, scrollToTopOnChange: true }),
      ]} />
    ;
  }
}
DetectionResultTable.propTypes = {
  detection_result_detail: PropTypes.array,
};


// 封装tree生成函数和最大长度限制
const maxLength = 200;
// 把传入的json转换成数组
const gengData = (heapStackData) => {
  const gData = [];
  for (const i in heapStackData) {//eslint-disable-line
    if (i.length > maxLength) {
      gData.push({
        title: `${i.substr(0, maxLength - 1)}...`,
        key: i,
        value: `    (${heapStackData[i]})`,
      });
    } else {
      gData.push({
        title: i,
        key: i,
        value: `    (${heapStackData[i]})`,
      });
    }
  }
  return gData;
};

// topN方法展示类
// props：HeapStackInfo
class HeapStackInfo extends React.Component {
  constructor(props) {
    super(props);
    // 根据传入的json生成数组
    const gData = gengData(this.props.HeapStackInfo);
    this.state = {
      treeData: gData,
    };
  }

  // 渲染
  render() {
    // eslint-disable-next-line no-console
    return (
      <div style={{ margin: '10 auto' }}>
        <List type="number">
          {this.state.treeData.map((data, index) => <List.Item key={index} tooltip={data.key}>{data.title}
            <Text
              theme="primary">{data.value}
            </Text>
          </List.Item>)}
        </List>
        <br></br>
        <Tag style={{ left: '10px' }} theme="warning">蓝色数字为<b>连续调用次数</b></Tag>
      </div>
    );
  }
}

HeapStackInfo.propTypes = {
  HeapStackInfo: PropTypes.object,
};

// 开销绘制模块
// todo:目前是echarts 后续需要换成tea-chart
// 输入数据:
// cpuDataset
// memDataset
// ioDataset
class CostData extends React.Component {
  constructor(props) {
    super(props);
    const { cpuDataset } = this.props;
    const { memDataset } = this.props;
    const { ioDataset } = this.props;
    this.state = {
      chartData1: {
        dom_name: 'myChart1',
        title_text: 'CPU使用率(%)',
        dataset_dimensions: ['product', '自身', '采集'],
        dataset_source: cpuDataset,
        legend_data: ['自身', '采集'],
        series_number: 2,
      },
      chartData2: {
        dom_name: 'myChart2',
        title_text: '常驻内存使用量(单位:KB)',
        dataset_dimensions: ['product', '自身', '采集'],
        dataset_source: memDataset,
        legend_data: ['自身', '采集'],
        series_number: 2,
      },
      chartData3: {
        dom_name: 'myChart3',
        title_text: 'IO速率(单位:KB/s)',
        dataset_dimensions: ['product', '自身(读取速率)', '自身(写入速率)', '采集(读取速率)', '采集(写入速率)'],
        dataset_source: ioDataset,
        legend_data: ['自身(读取速率)', '自身(写入速率)', '采集(读取速率)', '采集(写入速率)'],
        series_number: 4,
      },
    };
  }

  // 初始化
  componentDidMount() {
    this.initCharts(this.state.chartData1);
    this.initCharts(this.state.chartData2);
    this.initCharts(this.state.chartData3);
  }

  // 封装初始化函数
  // 参考echarts文档
  initCharts = (chartData) => {
    const myChart = echarts.init(document.getElementById(chartData.dom_name));
    const option = {
      title: {
        text: chartData.title_text,
      },
      tooltip: {
        trigger: 'axis',
      },
      dataset: {
        dimensions: chartData.dataset_dimensions,
        source: chartData.dataset_source,
      },
      legend: {
        data: [],
      },
      grid: {
        left: '3%',
        right: '4%',
        bottom: '3%',
        containLabel: true,
      },
      toolbox: {
        feature: {
          saveAsImage: {},
        },
      },
      xAxis: {
        type: 'category',
        boundaryGap: false,
      },
      yAxis: {
        type: 'value',
        splitLine: {
          lineStyle: {
            width: 0.3,
          },
        },
      },
      series: [],
      dataZoom: [{
        type: 'inside',
        start: 90,
        end: 100,
        show: true,
      }],
    };
    // 压入对应的数据
    // 设置线的颜色
    const color = ['rgba(255, 0, 0,0.5)', 'rgba(0, 255, 0,0.5)', 'rgba(0, 0, 255,0.5)', 'rgba(100, 120, 120,0.5)'];
    for (let i = 0; i < chartData.series_number; ++i) {
      option.series.push({
        type: 'line',
        showSymbol: false,
        lineStyle: {
          color: color[i],
        },
        itemStyle: {
          color: color[i],
        },
        label: {
          formatter(params) {
            if (params.value > 0) {
              return params.value;
            }
            return ' ';
          },
        },
      });
      option.legend.data.push({
        name: chartData.legend_data[i],
        textStyle: {
          color: color[i],
        },
      });
    }
    myChart.setOption(option);
    window.addEventListener('resize', () => {
      myChart.resize();
    });
  };

  render() {
    return (
      <div>
        <div style={{ height: '170px', width: '100%' }} id="myChart1">
        </div>
        <br></br><br></br>
        <div style={{ height: '170px', width: '100%' }} id="myChart2">
        </div>
        <br></br><br></br>
        <div style={{ height: '170px', width: '100%' }} id="myChart3">
        </div>
      </div>
    );
  }
}

CostData.propTypes = {
  cpuDataset: PropTypes.array,
  memDataset: PropTypes.array,
  ioDataset: PropTypes.array,
};

// 只存一次接口数据 下次请求会替换掉当前的数据 所以需要把数据保存到state里
const mapStateToProps = (state) => {
  const { DropReducer } = state;
  return {
    DropReducer,
  };
};// Reducer

const mapDispatchToProps = (dispatch) => {
  const DropAction = bindActionCreators(DropActionCreator, dispatch);

  return {
    DropAction,
  };
};// Action


export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps,
)(TimingSamplingResult));
