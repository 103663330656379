/*
time:2021-8-19
author:ashinz
description:Drop Agent List页面
props:无需显示传入props属性
*/

import React from 'react';
import {
  Table,
  Justify,
  Button,
  Card,
  Icon,
  List,
  Dropdown,
  Drawer,
  StatusTip,
  message,
  Bubble,
  Tag,
  PopConfirm,
} from '@tencent/tea-component';
import { getOAName } from '@/utils/Storage';
import { bindActionCreators } from 'redux';
import DropActionCreator from '@/api/DropActions';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import '@tencent/tea-component/dist/tea.css';
import { badStatus, doesntHaveStatus, goodStatus } from '@/utils/ErrorConfirm';
import '../Drop.css';
import { CompareLine } from '@tencent/tea-chart/lib/compareline';
import StartTaskComponent from '../../components/startTaskComponent';
import PropTypes from 'prop-types';
import qs from 'qs';
import { jumpTargetConfig } from '../../utils/URL';
const { pageable, autotip, filterable } = Table.addons;

const filterAll = '全部';
/*
drop agent组件
尝试使用函数组件作为展示部分 类组件包含请求模块
*/
class DropAgentList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      agent_list: [], // agent列表
      user: getOAName(), // 获取用户名
      // 监控数据
      drawer_visable: false, // 监控页面显示开关
      drawer_target_ip: '', // 监控页面监控ip
      ioDataset: [], // 监控io数据   [{time: "10:47:17", data: 0, type: "self_cpuPercent", name: ""},...]
      cpuDataset: [], // 监控cpu数据  [{time: "10:47:17", data: 0, type: "self_cpuPercent", name: ""},...]
      memDataset: [], // 监控mem数据  [{time: "10:47:17", data: 0, type: "self_cpuPercent", name: ""},...]
      drawer_loading: true, // 监控页面是否在loading状态

      // 发起
      showDropStart: false, // 任务发起界面显示开关
      filterStatus: '在线', // 默认只显示在线机器
    };
    this.agent_info = []; // 传入到DropTaskStart的agent列表 同this.state.agent_list
    // 配置table的列信息
    this.column = [
      {
        key: 'ipaddr',
        header: '主机IP',
      },
      {
        key: 'hostname',
        header: '主机名',
        render: agent => `${agent.hostname}${(agent.group_id) ? '(共享)' : ''}`,
      },
      {
        key: 'version',
        header: () => (
          <>
            agent版本
            <Bubble
              content="agent版本过低会影响功能运行，请保持版本更新"
            >
              <Icon type="info" />
            </Bubble>
          </>
        ),
        render: agent => (agent.version ? `v${agent.version}`
          : <Tag  theme="warning">agent版本过低请及时升级</Tag>),
      },
      {
        key: 'status',
        header: '状态',
        render: (agent) => {
          if (agent.online === true) {
            return <p>
                            在线    <Icon type="success" />
                        </p>;
          } if (agent.online === false) {
            return <p>
                            离线    <Icon type="error" />
                        </p>;
          }
        },
      },
      {
        key: 'action',
        header: '操作',
        render: agent => <span>
                            <Button
                                disabled={!agent.online}
                                tooltip={(!agent.online) ? '机器已离线' : ''}
                                type="link"
                                onClick={() => {
                                  this.agent_info = [agent];
                                  this.setState({
                                    showDropStart: true,
                                  });
                                }}>发起任务
                            </Button >
                            <PopConfirm
                                title="移除机器"
                                message="确定要移除该离线机器？(不影响已有任务)"
                                footer={close => (
                                    <>
                                        <Button type="link" onClick={() => {
                                          // 移除离线机器
                                          this.props.DropAction.removeAgent(agent.id);
                                          close();
                                        }}>移除</Button>
                                        <Button type="text" onClick={() => {
                                          close();
                                        }}>取消</Button>
                                    </>
                                )}
                                placement="top-start"
                            >
                            <Button disabled = {agent.group_id || agent.online }
                                    tooltip={(agent.group_id || agent.online)
                                      ? '无法移除共享机器和在线机器' : ''}
                                    type = 'link' style={{ marginLeft: '50px' }} >移除</Button>
                            </PopConfirm>
                            <Dropdown
                                clickClose={true}
                                style={{ marginRight: 10, left: 20 }}
                                button="更多"
                                appearance="link"
                                placement="bottom"
                            >
                                <List type="option">
                                    {/* <List.Item
                                      disabled={!agent.online}
                                      tooltip={(!agent.online) ? '机器已离线' : ''}
                                      onClick={() => {
                                        this.setState({
                                          drawer_visable: true,
                                          drawer_target_ip: agent.ipaddr,
                                        });
                                        const requestParam = {
                                          user: this.state.user,
                                          target_ip: agent.ipaddr,
                                        };
                                        this.props.DropAction.getAgentMonitorData(requestParam);
                                      }}>监控</List.Item> */}
                                    <List.Item
                                      disabled={!agent.online}
                                      tooltip={(!agent.online) ? '机器已离线' : ''}
                                      onClick={
                                        () => {
                                          window.open(`/tasksByAgent?${qs.stringify({ agent: agent.ipaddr, number: '1' })}`, jumpTargetConfig());
                                        }
                                    }>查看任务</List.Item>
                                </List>
                            </Dropdown>
                        </span>,
      },
    ];
    // todo：监控页面图标的变量与含义对应
    this.nameToGraphMap = {
      self_cpuPercent: '自身',
      children_cpuPercent: '采集',
      self_readKbytesRate: '自身(读取速率)',
      self_writeKbytesRate: '自身(写入速率)',
      children_readKbytesRate: '采集(读取速率)',
      children_writeKbytesRate: '采集(写入速率)',
      self_rssKbytes: '自身',
      children_rssKbytes: '采集',
    };

    // 回绑函数
    this.setShowDropStart = this.setShowDropStart.bind(this);
  }


  /*
    */
  componentDidMount() {
    // 轮询请求获取agent数据
    const param = {
      user: this.state.user,
    };
    this.props.DropAction.getDropAgentList(param);
    this.dataPolling = setInterval(
      () => {
        if (this.state.drawer_visable) {
          const requestParam = {
            user: this.state.user,
            target_ip: this.state.drawer_target_ip,
          };
          this.props.DropAction.getAgentMonitorData(requestParam);
        } else {
          this.props.DropAction.getDropAgentList(param);
        }
      },
      this.state.drawer_visable ? 10000 : 5000,
    );
  }

  // 清理定时器
  componentWillUnmount() {
    clearInterval(this.dataPolling);
  }

  componentWillReceiveProps(nextProps) { //eslint-disable-line
    const { RemoveAgent } = nextProps.DropReducer;
    // 有值才进入
    if (Object.keys(RemoveAgent).length) {
      if (doesntHaveStatus(RemoveAgent)) {
        message.error({
          content: `error:${RemoveAgent?.message}`,
        });
      } else if (badStatus(RemoveAgent)) {
        message.error({
          content: `error:${RemoveAgent?.message}`,
        });
      }
      if (goodStatus(RemoveAgent)) {
        message.success({
          content: '移除成功',
        });
      }
    }
    // 获取agent的回调
    const AgentList = nextProps.DropReducer.GetDropAgentList;
    // 有结果
    if (Object.keys(AgentList).length) {
      if (doesntHaveStatus(AgentList)) {
        message.error({
          content: 'Agent信息获取失败,请重试',
        });
      } else if (badStatus(AgentList)) {
        message.error({
          content: 'Agent信息获取失败,请重试',
        });
      }
      if (goodStatus(AgentList)) {
        this.setState({
          agent_list: AgentList.data.agents,
        });
      }
    }

    // 获取监控数据的回调
    const AgentMonitorData = nextProps.DropReducer.GetAgentMonitorData;
    if (Object.keys(AgentMonitorData).length) {
      if (doesntHaveStatus(AgentMonitorData)) {
        message.error({
          content: '获取Agent监控信息失败',
        });
      } else if (badStatus(AgentMonitorData)) {
        message.error({
          content: '获取Agent监控信息失败',
        });
      }
      if (goodStatus(AgentMonitorData)) {
        // 数据处理
        // todo:只加入新数据
        const ioDataset =  [];
        const cpuDataset = [];
        const memDataset = [];
        const { selfPstats } = AgentMonitorData.data.result;
        const { childrenPstats } = AgentMonitorData.data.result;
        for (let i = 0; i < selfPstats.length; ++i) {
          const datatime = new Date(selfPstats[i].timestamp.seconds * 1000);
          const time = `${datatime.getHours()}:${datatime.getMinutes()}:${datatime.getSeconds()}`; // 时间
          cpuDataset.push({
            time,
            data: selfPstats[i].cpuPercent ?? 0,
            type: 'self_cpuPercent',
            name: '自身',
          });
          cpuDataset.push({
            time,
            data: childrenPstats[i].cpuPercent ?? 0,
            type: 'children_cpuPercent',
            name: '采集',
          });
          ioDataset.push({
            time,
            data: selfPstats[i].readKbytesRate ?? 0,
            type: 'self_readKbytesRate',
            name: '自身(读取速率)',
          });
          ioDataset.push({
            time,
            data: selfPstats[i].writeKbytesRate ?? 0,
            type: 'self_writeKbytesRate',
            name: '自身(写入速率)',
          });
          ioDataset.push({
            time,
            data: childrenPstats[i].readKbytesRate ?? 0,
            type: 'self_readKbytesRate',
            name: '采集(读取速率)',
          });
          ioDataset.push({
            time,
            data: childrenPstats[i].writeKbytesRate ?? 0,
            type: 'self_writeKbytesRate',
            name: '采集(写入速率)',
          });
          memDataset.push({
            time,
            data: selfPstats[i].rssKbytes ?? 0,
            type: 'children_rssKbytes',
            name: '自身',
          });
          memDataset.push({
            time,
            data: childrenPstats[i].rssKbytes ?? 0,
            type: 'children_rssKbytes',
            name: '采集',
          });
        }
        this.setState({
          drawer_loading: false,
          ioDataset,
          cpuDataset,
          memDataset,
        });
      }
    }
  }


  render() {
    // 监控界面显示内容
    let drawerGraph;
    if (this.state.drawer_loading) {
      drawerGraph = <StatusTip
                // @ts-ignore
                status="loading"
                style={{ margin: '0 345px' }}
            />;
    } else {
      drawerGraph = <div>
                <Card>
                    <Card.Body title="CPU使用率(%)">
                        <CompareLine
                            connectNulls
                            canvasMode
                            size={1}
                            height={250}
                            dataSource={this.state.cpuDataset}
                            position="time*data"
                            color="name"
                            interaction={['zoom']}
                        />
                    </Card.Body>
                </Card>
                <Card>
                    <Card.Body title="IO速率(单位:KB/s)">
                        <CompareLine
                            connectNulls
                            canvasMode
                            size={1}
                            height={250}
                            dataSource={this.state.ioDataset}
                            position="time*data"
                            color="name"
                            interaction={['zoom']}
                        />
                    </Card.Body>
                </Card>
                <Card>
                    <Card.Body title="常驻内存使用量(单位:KB)">
                        <CompareLine
                            connectNulls
                            canvasMode
                            size={1}
                            height={250}
                            dataSource={this.state.memDataset}
                            position="time*data"
                            color="name"
                            interaction={['zoom']}
                        />
                    </Card.Body>
                </Card>
            </div>;
    }
    // 渲染页面内容

    let filteredRecords = this.state.agent_list.slice();
    const status = this.state.filterStatus;
    if (status !== filterAll) {
      filteredRecords = filteredRecords.filter((record) => {
        if (status === '在线') {
          return record.online === true;
        }
        return record.online === false;
      });
    }

    // 将 online 为 true 的 agent 放在前面
    filteredRecords.sort((a, b) => {
      if (a.online === b.online) {
        return 0;
      } if (a.online === true) {
        return -1;
      }
      return 1;
    });

    return <div className = "show-module-style">
            <StartTaskComponent
              visable={this.state.showDropStart}
              agents={this.agent_info}
              onClose={ () => this.setShowDropStart(false) }
              successAction={ () => {
                this.props.history.push({ pathname: 'tasks' });
              }}
            ></StartTaskComponent>
            <Table.ActionPanel>
                <Justify
                    left={
                        <div>
                            <Button type="primary" onClick={() => {
                              this.agent_info = this.state.agent_list;
                              this.setState({
                                showDropStart: true,
                              });
                            }
                            }>新建任务</Button>
                        </div>
                    }
                    // right={
                    //     <div>
                    //         <SearchBox />
                    //         <Button icon="setting" />
                    //         <Button icon="refresh" />
                    //         <Button icon="download" />
                    //     </div>
                    // }
                />
            </Table.ActionPanel>
            <Card>
                <Table
                    verticalTop
                    records={filteredRecords}
                    recordKey="agent-key"
                    rowClassName={record => record.status}
                    columns={this.column}
                    bordered={true}
                    addons={[
                      pageable({
                        pageSize: 10,
                      }),
                      autotip({
                        emptyText: '暂未接入机器,请按照指引接入',
                      }),
                      filterable({
                        type: 'single',
                        column: 'status',
                        value: status,
                        onChange: (value) => {
                          this.setState({
                            filterStatus: value,
                          });
                        },
                        all: { value: filterAll },
                        options: [
                          { value: '在线' },
                          { value: '离线' },
                        ],
                      }),
                    ]}
                />
            </Card>
            <Drawer
                // @ts-ignore
                size="l"
                // @ts-ignore
                placement="right"
                visible={this.state.drawer_visable}
                title="监控数据"
                onClose={() => {
                  this.setState({
                    drawer_visable: false,
                  });
                }}
            >
                {drawerGraph}
            </Drawer>
        </div>;
  }

  // 修改showDropStart
  setShowDropStart(x) {
    this.setState({
      showDropStart: x,
    });
  }
}

DropAgentList.propTypes = {
  DropAction: PropTypes.any.isRequired,
  history: PropTypes.any.isRequired,
  DropReducer: PropTypes.any.isRequired,
};


// 只存一次接口数据 下次请求会替换掉当前的数据 所以需要把数据保存到state里
const mapStateToProps = (state) => {
  const { DropReducer } = state;
  return {
    DropReducer,
  };
};// Reducer

const mapDispatchToProps = (dispatch) => {
  const DropAction = bindActionCreators(DropActionCreator, dispatch);

  return {
    DropAction,
  };
};// Action


export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps,
)(DropAgentList));
