import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Tabs, Table } from 'tdesign-react';
import { formatTime } from '../../utils/DateTime';
import { formatByte } from './util';

export function calcIoTop(diskData) {
  const data = {};
  const timestamps = new Set();
  diskData.forEach((item) => {
    const { timestamp } = item;
    timestamps.add(timestamp);
    for (const disk in item.disks) {
      const value = item.disks[disk];

      let totalBytes = 0;
      value.events.forEach(event => totalBytes += event.bytes);

      const groupByData = {};
      value.events.forEach((event) => {
        const groupByKey = [event.comm, event.pid, event.rwflag].join('-');
        if (!groupByData[groupByKey]) groupByData[groupByKey] = {
          comm: event.comm,
          pid: event.pid,
          rwflag: event.rwflag,
          bytes: 0,
          delta: 0,
          count: 0,
        };
        groupByData[groupByKey].bytes += event.bytes;
        groupByData[groupByKey].delta += event.delta;
        groupByData[groupByKey].count += 1;
      });

      for (const key in groupByData) {
        const value = groupByData[key];
        if (!data[disk]) data[disk] = {};
        if (!data[disk][timestamp]) data[disk][timestamp] = [];
        data[disk][timestamp].push({
          comm: value.comm,
          pid: value.pid,
          rwflag: value.rwflag,
          bytes: value.bytes,
          bytesPercent: value.bytes / totalBytes * 100,
          deltaAvg: value.delta / value.count,
        });
      }
    } // disks
  }); // times

  return data;
}

const columns = [
  {
    colKey: 'comm',
    title: '进程',
  },
  {
    colKey: 'pid',
    title: 'pid',
  },
  {
    colKey: 'rwflag',
    title: '读写标记',
    cell: ({ row }) => (row.rwflag === 'R' ? '读' : '写'),
  },
  {
    colKey: 'bytes',
    title: 'IO大小',
    sorter: (a, b) => a.bytes - b.bytes,
    cell: ({ row }) => {
      const byteVal = formatByte(row.bytes);
      return <p>{byteVal[0]}<span style={{ fontWeight: 'lighter' }}>{byteVal[1]}</span></p>;
    },
  },
  {
    colKey: 'bytesPercent',
    title: 'IO占比',
    cell: ({ row }) => `${row.bytesPercent.toFixed(2)}%`,
  },
  {
    colKey: 'deltaAvg',
    title: '平均延迟',
    sorter: (a, b) => a.deltaAvg - b.deltaAvg,
    cell: ({ row }) => <p>{row.deltaAvg.toFixed(3)}<span style={{ fontWeight: 'lighter' }}>ms</span></p>,
  },
];

const IoTopTable = (props) => {
  const [data, setData] = useState([]);
  const [sort, setSort] = useState({
    sortBy: 'bytes',
    descending: true,
  });

  useEffect(() => {
    const sortedData = props.data.sort((a, b) => {
      if (sort.descending) {
        return b[sort.sortBy] - a[sort.sortBy];
      }
      return a[sort.sortBy] - b[sort.sortBy];
    });
    setData(sortedData);
  }, [props.data]);

  function onSortChange(sort, data) {
    setSort(sort);
    setData(data);
  }

  return <Table
    rowKey='id'
    columns={columns}
    hover={true}
    data={data}
    sort={sort}
    showSortColumnBgColor={true}
    hideSortTips={true}
    onSortChange={(sort, opts) => onSortChange(sort, opts.currentDataSource)}
  />;
};

IoTopTable.propTypes = {
  data: PropTypes.array,
};

const DiskTracingIoTopChart = (props) => {
  if (!props.data) return <></>;

  const times = Object.keys(props.data).sort();
  const [value, setValue] = useState(times[0]);
  const [datas, setDatas] = useState(props.data);
  const [panelOption, setPanelOption] = useState([]);

  useEffect(() => {
    const times = Object.keys(props.data).sort();
    if (times.indexOf(value) < 0) {
      setValue(times[0]);
    } else {
      setValue(preValue => preValue);
    }

    setPanelOption(times.map(ts => ({
      ts,
      label: formatTime(ts, 1),
    })));
    setDatas(props.data);
  }, [props.data]);

  return <>
    <Tabs
      theme='normal'
      placement={'top'}
      value={value}
      onChange={v => setValue(v)}
    >
      {
        panelOption.map((opt, idx) => <Tabs.TabPanel
            key={idx}
            value={opt.ts}
            label={opt.label}
          >
            <IoTopTable data={datas[opt.ts]} />
          </Tabs.TabPanel>)
      }
    </Tabs>
  </>;
};

DiskTracingIoTopChart.propTypes = {
  data: PropTypes.object,
};

export default DiskTracingIoTopChart;
