import React from 'react';
import {
  Card,
  Drawer,
  StatusTip,
} from '@tencent/tea-component';
import PropTypes from 'prop-types';
import { CompareLine } from '@tencent/tea-chart/lib/compareline';

const StatAgentModal = (props) => {
  const ioDataset =  [];
  const cpuDataset = [];
  const memDataset = [];
  const { selfPstats } = props.result;
  const { childrenPstats } = props.result;
  for (let i = 0; i < selfPstats.length; ++i) {
    const datatime = new Date(selfPstats[i].timestamp.seconds * 1000);
    const time = `${datatime.getHours()}:${datatime.getMinutes()}:${datatime.getSeconds()}`; // 时间
    cpuDataset.push({
      time,
      data: selfPstats[i].cpuPercent ?? 0,
      type: 'self_cpuPercent',
      name: '自身',
    });
    cpuDataset.push({
      time,
      data: childrenPstats[i].cpuPercent ?? 0,
      type: 'children_cpuPercent',
      name: '采集',
    });
    ioDataset.push({
      time,
      data: selfPstats[i].readKbytesRate ?? 0,
      type: 'self_readKbytesRate',
      name: '自身(读取速率)',
    });
    ioDataset.push({
      time,
      data: selfPstats[i].writeKbytesRate ?? 0,
      type: 'self_writeKbytesRate',
      name: '自身(写入速率)',
    });
    ioDataset.push({
      time,
      data: childrenPstats[i].readKbytesRate ?? 0,
      type: 'self_readKbytesRate',
      name: '采集(读取速率)',
    });
    ioDataset.push({
      time,
      data: childrenPstats[i].writeKbytesRate ?? 0,
      type: 'self_writeKbytesRate',
      name: '采集(写入速率)',
    });
    memDataset.push({
      time,
      data: selfPstats[i].rssKbytes ?? 0,
      type: 'children_rssKbytes',
      name: '自身',
    });
    memDataset.push({
      time,
      data: childrenPstats[i].rssKbytes ?? 0,
      type: 'children_rssKbytes',
      name: '采集',
    });
  }
  let drawerGraph;
  if (cpuDataset.length === 0) {
    drawerGraph = <StatusTip
                // @ts-ignore
                status="loading"
                style={{ margin: '0 345px' }}
            />;
  } else {
    drawerGraph = <div>
                <Card>
                    <Card.Body title="CPU使用率(%)">
                        <CompareLine
                            connectNulls
                            canvasMode
                            size={1}
                            height={250}
                            dataSource={cpuDataset}
                            position="time*data"
                            color="name"
                            interaction={['zoom']}
                        />
                    </Card.Body>
                </Card>
                <Card>
                    <Card.Body title="IO速率(单位:KB/s)">
                        <CompareLine
                            connectNulls
                            canvasMode
                            size={1}
                            height={250}
                            dataSource={ioDataset}
                            position="time*data"
                            color="name"
                            interaction={['zoom']}
                        />
                    </Card.Body>
                </Card>
                <Card>
                    <Card.Body title="常驻内存使用量(单位:KB)">
                        <CompareLine
                            connectNulls
                            canvasMode
                            size={1}
                            height={250}
                            dataSource={memDataset}
                            position="time*data"
                            color="name"
                            interaction={['zoom']}
                        />
                    </Card.Body>
                </Card>
            </div>;
  }
  return <Drawer
    // @ts-ignore
    size="l"
    // @ts-ignore
    placement="right"
    visible={ props.visable }
    title="监控数据"
    onClose={() => {
      props.onClose();
    }}
  >
    {drawerGraph}
  </Drawer>;
};

StatAgentModal.propTypes = {
  visable: PropTypes.bool,
  onClose: PropTypes.func,
  result: PropTypes.object,
};

export default StatAgentModal;
