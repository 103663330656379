/*
description:暂时存储一些静态数据，以后变成动态获取
*/
// 模拟登陆 设置权限
// todo:接入登陆功能
export const fakeAuth = {
  isAuthenticated: 0,
  userName: '',
  authenticate(cb, name) {
    this.isAuthenticated = cb;
    this.userName = name;
  },
};

// 配置规则
// {
//   title:"一级菜单名"
//   iconType:"一级菜单对应icon"
//   menuItem:[ //二级菜单
//     {
//       title:"二级菜单名",
//       routeTo:"路由指向"
//     }
//   ]
// }
export const leftMenuRouteData = [
  {
    title: '机器配置',
    iconType: 'setting',
    menuItem: [
      {
        title: '配置信息',
        routeTo: '/user',
        id: '1-1',
      },
      {
        title: '机器列表',
        routeTo: '/agents',
        id: '1-2',
      },
    ],
  },
  {
    title: '历史任务',
    iconType: 'table',
    menuItem: [
      {
        title: '历史任务',
        routeTo: '/tasks',
        id: '2-1',
      },
    ],
  },
  // {
  //   title:"通用分析",
  //   iconType:"datasheet",
  //   menuItem:[
  //     {
  //       title:"新建任务",
  //       routeTo:"/tasks",
  //       id:"3-1",
  //     },
  //     {
  //       title:"历史任务",
  //       routeTo:"/tasks",
  //       id:"3-2",
  //     }
  //   ]
  // },
  // {
  //   title:"Java分析",
  //   iconType:"database",
  //   menuItem:[
  //     {
  //       title:"新建任务",
  //       routeTo:"/tasks",
  //       id:"4-1",
  //     },
  //     {
  //       title:"历史任务",
  //       routeTo:"/tasks",
  //       id:"4-2",
  //     }
  //   ]
  // }
];

export const menuRouteData = [
  {
    key: '0',
    type: 'default',
    title: '首页',
    routeTo: '/index',
    children: [],
  },
  {
    key: '1',
    type: 'default',
    title: '快速接入',
    routeTo: '/user',
    children: [],
  },
  {
    key: '2',
    type: 'default',
    title: '机器列表',
    routeTo: '/agents',
    children: [],
  },
  // {
  //   key: '3',
  //   type: 'dropdown',
  //   title: '性能分析',
  //   children: [
  //     {
  //       key: '3-1',
  //       title: '通用分析',
  //       routeTo: '/generalSampling',
  //     },
  //     {
  //       key: '3-2',
  //       title: 'Java分析',
  //       routeTo: '/javaSampling',
  //     },
  //     {
  //       key: '3-3',
  //       title: '时序分析',
  //       routeTo: '/timingSampling',
  //     },
  //   ],
  // },
  // {
  //   key: '5',
  //   type: 'default',
  //   title: '动态追踪',
  //   routeTo: '/tracing',
  //   children: [],
  // },
  {
    key: '4',
    type: 'default',
    title: '历史任务',
    routeTo: '/tasks',
    children: [],
  },
  {
    key: '5',
    type: 'default',
    title: '文件分析',
    routeTo: '/fileAnalysis',
    children: [],
  },
  {
    key: '6',
    type: 'default',
    title: '计划任务',
    routeTo: '/scheduledTask',
    children: [],
  },
  {
    key: '7',
    type: 'default',
    title: '任务对比',
    routeTo: '/taskCompare',
    children: [],
  },
];
