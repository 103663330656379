import storeCreator from './Store';

import {
  WatchSendDropStartSaga,
  WatchGetDropAgentInfoIPSaga,
  WatchGetDropTaskResult,
  WatchGetDropResultImage,
  WatchGetDropCost,
  WatchDeleteDropTask,
  WatchGetDropAgentList,
  WatchGetAgentMonitorData,
  WatchGetUserUid,
  WatchStartDiskTracing,
  WatchCreateGroup,
  WatchDisbandGroup,
  WatchAddGroupMember,
  WatchRemoveGroupMember,
  WatchGetGroup,
  WatchGetGroupAgent,
  WatchAddGroupAgent,
  WatchRemoveGroupAgent,
  WatchGetGroups,
  WatchGetUserGroups,
  WatchGetDropResultCallgraphImage,
  WatchGetCallgraphData,
  WatchGetSharedTaskURL,
  WatchGetTaskResult,
  WatchGetFlamegraphData,
  WatchRemoveAgent,
} from '@/api/DropSaga';
import DropReducer from '@/api/DropReducer';

const AppReducers = {
  DropReducer,
};

const RootSagas = [
  WatchSendDropStartSaga,
  WatchGetDropAgentInfoIPSaga,
  WatchGetDropTaskResult,
  WatchGetDropResultImage,
  WatchGetDropCost,
  WatchDeleteDropTask,
  WatchGetDropAgentList,
  WatchGetAgentMonitorData,
  WatchGetUserUid,
  WatchStartDiskTracing,
  WatchCreateGroup,
  WatchDisbandGroup,
  WatchAddGroupMember,
  WatchRemoveGroupMember,
  WatchGetGroup,
  WatchGetGroupAgent,
  WatchAddGroupAgent,
  WatchRemoveGroupAgent,
  WatchGetGroups,
  WatchGetUserGroups,
  WatchGetDropResultCallgraphImage,
  WatchGetCallgraphData,
  WatchGetSharedTaskURL,
  WatchGetTaskResult,
  WatchGetFlamegraphData,
  WatchRemoveAgent,
];
// eslint-disable-next-line
export default (initialState = {}) => () => storeCreator(initialState, AppReducers, RootSagas);
