/*
description:404页面 错误导入这边
*/

// todo:优化页面
import React, { memo } from 'react';
import {
  Text,
  Blank,
  Layout,
  ExternalLink,
  Button,
} from '@tencent/tea-component';
const { Body, Content } = Layout;

const Drop404 = (props) => {
  console.log(props);
  return <Layout>
  <Body>
    <Content>
      <Content.Body>
        <Blank
          title="页面走丢啦"
          image={{
            background:
              'https://imgcache.qq.com/qcloud/tcloud_dtc/static/tc_console/114b4d79-fdd8-4c8c-8771-418a8fa81bbb.svg',
            logo:
              'https://imgcache.qq.com/qcloud/tcloud_dtc/static/tc_console_product/3ad6629b-8cf5-42ee-8a7f-4e3802768638.svg',
          }}
          operation={<Button type="primary" onClick={() => {
            window.location.href = '/';
          }}>重新载入</Button>}
          extra={
            <>
              <ExternalLink href="https://iwiki.woa.com/pages/viewpage.action?pageId=1583794981">查看文档</ExternalLink>
            </>
          }
          bottom={[
            <>
              <Text type="primary">Drop</Text>
            </>,
          ]}
        >
        </Blank>
      </Content.Body>
    </Content>
  </Body>
</Layout>;
};
export default memo(Drop404);
