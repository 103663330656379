/*
time:2021-7-30
author:ashinz
description:drop结果展示页面组件
props:
    thread_data : 线程运行信息
    setShowDropResult ：返回任务列表界面的回调
    HeapStackInfo ：topN信息
    flamegraph_url ：火焰图地址
    task_data  ：Drop任务信息
    user_name  ：用户名
    cost_data  :开销数据
todo: 逐步替换掉原有任务结果查看组件
*/
import React from 'react';
import { withRouter } from 'react-router-dom';
import DropActionCreator from '@/api/DropActions';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { goodStatus } from '@/utils/ErrorConfirm';
import '../Drop.css';
import * as echarts from 'echarts';
import Callgraph from './callgraph';
import FlameGraph from '@/components/flamegraph/flamegraphV0';
import {
  Card,
  Col,
  Form,
  Icon,
  Input,
  Layout,
  Row,
  StatusTip,
  Table,
  TabPanel,
  Tabs,
  Tag,
  Text, Tooltip,
} from '@tencent/tea-component';
import PropTypes from 'prop-types';
import { Log } from '@/utils/Log';
import qs from 'qs';
import { taskTimeFormat } from '@/utils/DateTime';
import { getFlameGraphURL } from '@/utils/URL';
import { jumpTargetConfig } from '../../utils/URL';
import { Link, List, Space, Switch } from 'tdesign-react';
import FibonaButton from '../../components/fibona';
import { FIBONA_TRIGGER } from '@/config';
const { ListItem } = List;

const { Body, Content } = Layout;
const { pageable, scrollable } = Table.addons;
// 包含了ThreadTable 和HeapStackInfo两个组件 分别展示线程信息和topN
// 首先由DropResult拿到全部数据 然后把数据往两个子组件上传输
// tood：优化数据关系
// 图片在组件中获取
class SamplingResult extends React.Component {
  constructor(props) {
    super(props);
    Log.debug('SamplingResult', props);
    // 初始化的时候把值初始为自己的值
    // 给topN进行排序
    // console.log(props);
    const randomTopNData = props.heap_stack_info;
    // 先按照值对object排序
    let sortedKeysArray = [];

    if (randomTopNData && Object.keys(randomTopNData).length) {
      sortedKeysArray = Object.values(randomTopNData).sort((a, b) => b - a);
    }
    const topNData = {};
    for (let i = 0; i < sortedKeysArray.length; i++) {
      // 将原对象中的键值对按照属性值的排序顺序写入新对象
      Object.keys(randomTopNData).map((item) => {
        if (randomTopNData[item] === sortedKeysArray[i]) {
          topNData[item] = sortedKeysArray[i];
        }
        return 0;
      });
    }
    this.state = {
      task_data: this.props.task_data, // 任务信息
      thread_data: this.props.thread_data, // 线程数据
      HeapStackInfo: topNData, // topN数据
      total_records: this.props.total_records, // 采样记录总条数
      java_result: this.props.java_result,
      image_url: this.props.flamegraph_url, // 火焰图在cos中地址
      user_name: this.props.user_name, // 用户名
      show_url: '', // 火焰图在cos上生成的给用户获取的临时链接,
      show_java_url: '',
      callgraph_url: this.props.callgraph_url, // 调用图cos地址
      callgraph_show_url: '',
      urlRecorder: {},
      cost_dataset: { // 开销数据
        cpuDataset: [], // cpu开销 [["11:21:39",0,0]] 需要参看echarts图表渲染内容
        ioDataset: [], // io开销   [["11:21:39",0,0,0,0]]
        memDataset: [], // mem开销 [["11:21:39",0,0]]
        flag: 0, // 是否加载完成
      },
      javaText: '',
      container_list: this.props.container_list,
    };
  }

  componentDidMount() {
    // 获取java数据
    if (this.state.java_result !== '') {
      const param = {
        drop_result_image_url: this.state.java_result,
        cos_type: 'java',
        task_id: this.state.task_data.id,
      };
      this.props.DropAction.getDropResultImage(param);
    }
  }

  // 接受新的props之前被调用 所以用它来判断是否成功
  // 该方法已经被认为是unsafe的
  // 可以根据我们获取的数据的情况来进行一些提示
  // 同时处理数据
  componentWillReceiveProps(nextProps) {//eslint-disable-line
    // 获取火焰图信息
    // todo：添加获取失败后的显示内容
    const GetDropResultImageRes = nextProps.DropReducer.GetDropResultImage;
    if (Object.keys(GetDropResultImageRes).length) {
      /* if (doesntHaveStatus(GetInfoRes)) {
                notification.error("任务列表获取失败,请刷新重试");

            } else if (badStatus(GetInfoRes)) {
                notification.error("任务列表获取失败,请刷新重试");
            }*/
      if (goodStatus(GetDropResultImageRes)) {
        // debugger;
        // 通过这里的操作来展示获取的图片
        const { urlRecorder } = this.state;

        urlRecorder[GetDropResultImageRes.data.image_url_name] = GetDropResultImageRes.data.image_url;
        this.setState({
          // "show_url": GetInfoRes.data.image_url,
          show_url: this.state.image_url in urlRecorder ? urlRecorder[this.state.image_url] : '',
          show_java_url: this.state.java_result in urlRecorder ? urlRecorder[this.state.java_result] : '',
          urlRecorder,
          javaText: GetDropResultImageRes.data.image_url,
        });
      }
    }

    // 获取调用图
    const callgraphImageRes = nextProps.DropReducer.GetDropResultCallgraphImage;
    if (Object.keys(callgraphImageRes).length) {
      if (goodStatus(callgraphImageRes)) {
        const { urlRecorder } = this.state;

        urlRecorder[callgraphImageRes.data.image_url_name] = callgraphImageRes.data.image_url;
        this.setState({
          callgraph_show_url: this.state.callgraph_url in urlRecorder ? urlRecorder[this.state.callgraph_url] : '',
          urlRecorder,
        });
      }
    }

    // 获取开销数据
    // todo:添加错误信息
    const DropCost = nextProps.DropReducer.GetDropCost;
    if (Object.keys(DropCost).length) {
      if (goodStatus(DropCost)) {
        const ioDataset = [];
        const cpuDataset = [];
        const memDataset = [];
        const { selfPstats = [] } = DropCost.data.result;
        const { childrenPstats = [] } = DropCost.data.result ;
        for (let i = 0; i < selfPstats.length; ++i) {
          const datatime = new Date(selfPstats[i].timestamp.seconds * 1000);
          const time = `${datatime.getHours()}:${datatime.getMinutes()}:${datatime.getSeconds()}`; // 时间
          cpuDataset.push([
            time,
            selfPstats[i].cpuPercent ?? 0,
            childrenPstats[i].cpuPercent ?? 0,
          ]);
          ioDataset.push([
            time,
            selfPstats[i].readKbytesRate ?? 0,
            selfPstats[i].writeKbytesRate ?? 0,
            childrenPstats[i].readKbytesRate ?? 0,
            childrenPstats[i].writeKbytesRate ?? 0,
          ]);
          memDataset.push([
            time,
            // self_pstats[i].vszKbytes,
            selfPstats[i].rssKbytes ?? 0,
            // children_pstats[i].vszKbytes,
            childrenPstats[i].rssKbytes ?? 0,
          ]);
        }
        this.setState({
          cost_dataset: {
            cpuDataset,
            ioDataset,
            memDataset,
            flag: 1,
          },
        });
      }
    }
  }

  // 切换tab时触发的事件
  // 3：查看火焰图 通过image_url去获取火焰图的临时链接
  // 4：获取开销数据
  onChange = (activeKey) => {
    if (activeKey === '3' && this.state.show_url === '') {
      const param = {
        drop_result_image_url: this.state.image_url,
        cos_type: 'image',
        task_id: this.state.task_data.id,
      };
      this.props.DropAction.getDropResultImage(param);
    }
    if (activeKey === '5' && this.state.show_java_url === '') {
      // const param = {
      //     "drop_result_image_url": this.state.java_result,
      //     "cos_type":"text"
      //
      // };
      // this.props.DropAction.getDropResultImage(param);
      // this.setState({
      //     "show_java_url": this.state.java_result
      // });

    }

    // 添加获取cost的调用
    if (activeKey === '4' && this.state.cost_dataset.flag === 0) {
      const param = {
        user: this.state.user_name,
        task_id: this.state.task_data.tid,
        target_ip: this.state.task_data.target_ip,
      };
      this.props.DropAction.getDropCost(param);
    }

    if (activeKey === 'callgraph' && this.state.callgraph_show_url === '') {
      const param = {
        drop_result_image_url: this.state.callgraph_url,
        cos_type: 'callgraph',
        task_id: this.state.task_data.id,
      };
      this.props.DropAction.getDropResultCallgraphImage(param);
    }
  };

  render() {
    // Tab组件传入数据
    let tabs;
    if (this.state.java_result) {
      tabs = [
        { id: '5', label: 'Java采样分析结果文件' },
        { id: '2', label: '热点函数' },
        { id: '3', label: '火焰图' },
        // { id: '4', label: '开销数据' },
      ];
    } else {
      tabs = [
        { id: '1', label: '活跃线程' },
        { id: '2', label: '热点函数' },
        { id: '3', label: '火焰图' },
        // { id: '4', label: '开销数据' },
      ];
    }
    if (this.state.callgraph_url !== '') {
      tabs.push({ id: 'callgraph', label: '调用图' });
    }
    // 渲染内容
    return <div
      className="show-module-style"
    >
      <Layout className="demo-layout">
        <Body>
          <Content>
            {this.props.showGobackButton
              ? <Content.Header
                showBackButton
                onBackButtonClick={() => this.props.goBack()}
                title="返回"
              >
              </Content.Header> : ''}
            <Content.Body>
              <Card>
                <Card.Body title="任务信息">
                  <Row showSplitLine>
                    <Col span={8}>
                      <Form readonly>
                        <Form.Item label="任务名">
                          <Form.Text>{this.state.task_data.name}</Form.Text>
                        </Form.Item>
                        <Form.Item label="任务ID">
                          <Form.Text>{this.state.task_data.tid}</Form.Text>
                        </Form.Item>
                        <Form.Item label="目标IP">
                          <Form.Text>{this.state.task_data.target_ip}</Form.Text>
                        </Form.Item>
                        <Form.Item label="任务状态">
                          <Form.Text>执行成功，分析成功 <Icon type="success" /></Form.Text>
                        </Form.Item>
                      </Form>
                    </Col>
                    <Col span={8}>
                      <Form readonly>
                        <Form.Item label="任务类型">
                          <Form.Text>{this.state.task_data.request_params.perf_record_type === 0 ? '通用' : 'Java'}</Form.Text>
                        </Form.Item>
                        <Form.Item label="采样器">
                          <Form.Text>
                            {
                              this.state.task_data.request_params.perf_record_profiler_type === 0
                                ? 'perf' : 'async'}
                          </Form.Text>
                        </Form.Item>
                        <Form.Item label="查询范围">
                          <Form.Text>
                            {
                              this.state.task_data.request_params.perf_record_pid === -1
                                ? this.state.task_data.request_params.perf_record_options
                                : `PID${this.state.task_data.request_params.perf_record_pid}`
                            }
                          </Form.Text>
                        </Form.Item>
                        <Form.Item label="查看调用栈">
                          <Form.Text>
                            {this.state.task_data.request_params.if_perf_record_callgraph
                              ? '是' : '否'}
                          </Form.Text>
                        </Form.Item>
                        <Form.Item label="采样频率(Hz)">
                          <Form.Text>
                            {this.state.task_data.request_params.perf_record_freq}
                          </Form.Text>
                        </Form.Item>
                        <Form.Item label="采样时长(秒)">
                          <Form.Text>
                            {this.state.task_data.request_params.perf_record_duration}
                          </Form.Text>
                        </Form.Item>

                      </Form>
                    </Col>
                    <Col span={8}>
                      <Form readonly>
                        <Form.Item label="创建时间">
                          <Form.Text>{taskTimeFormat(this.state.task_data.create_time)}</Form.Text>
                        </Form.Item>
                        <Form.Item label="开始时间">
                          <Form.Text>{taskTimeFormat(this.state.task_data.begin_time)}</Form.Text>
                        </Form.Item>
                        <Form.Item label="结束时间">
                          <Form.Text>{taskTimeFormat(this.state.task_data.end_time)}</Form.Text>
                        </Form.Item>
                        {/* {this.props.jupyterURL ? <Form.Item label="高级分析功能">
                          <Form.Text><a href={this.props.jupyterURL} target="_blank"
                            rel="noopener noreferrer">进入</a></Form.Text>
                        </Form.Item> : ''} */}
                      </Form>
                    </Col>
                  </Row>
                </Card.Body>
              </Card>
              <br></br>
              <Card>
                <Card.Body title="任务结果">
                  <Tabs onActive={(data) => {
                    this.onChange(data.id);
                  }}
                    tabs={tabs}>
                    <TabPanel id="1">
                      <ThreadTable thread_data={this.state.thread_data}></ThreadTable>
                    </TabPanel>
                    <TabPanel id="2">
                      <HeapStackInfo
                        HeapStackInfo={this.state.HeapStackInfo}></HeapStackInfo>
                    </TabPanel>
                    <TabPanel id="3">
                      <div>
                        {
                          this.state.show_url === ''
                            ? <StatusTip style={{ width: '10%', margin: '10% 45%' }}
                              status="loading" loadingText="火焰图获取中..." />
                            : (
                              (this.state.image_url.endsWith('.json') === true || this.state.image_url.endsWith('.json.gz') === true)
                                ? <FlameGraph
                                  flamegraphURL={this.state.show_url}
                                  containerList={this.state.container_list}
                                />
                                : <object width="100%" style={{ margin: '0  auto', display: 'block' }}
                                  id="object" data={this.state.show_url}
                                  type="image/svg+xml">FlameGraph</object>
                            )
                        }
                      </div>
                    </TabPanel>
                    <TabPanel id="callgraph">
                      <div>
                        {
                          this.state.callgraph_show_url === ''
                            ? <StatusTip style={{ width: '10%', margin: '10% 45%' }}
                              status="loading" loadingText="调用图获取中..." />
                            : (
                              this.state.callgraph_url.endsWith('.json') === true
                                ? <Callgraph
                                  callgraphURL={this.state.callgraph_show_url}
                                  defaultNodeCnt={80}
                                />
                                : <object width="100%" style={{ margin: '0  auto', display: 'block' }}
                                  id="object" data={this.state.callgraph_show_url}
                                  type="image/svg+xml">callgraph</object>
                            )
                        }
                      </div>
                    </TabPanel>
                    <TabPanel id="5">
                      {this.state.javaText
                        ? <Input.TextArea
                          defaultValue={this.state.javaText}
                          readonly
                          size={'full'}
                          style={{
                            backgroundColor: 'white',
                            color: 'black',
                            height: 480,
                          }}
                          key={this.state.javaText.length}
                        ></Input.TextArea>
                        : <div>
                          <Input.TextArea value={'数据暂无'} readonly size={'full'} style={{
                            backgroundColor: 'white',
                            color: 'black',
                            height: 480,
                          }}
                            key={this.state.javaText.length}
                          ></Input.TextArea>
                        </div>
                      }
                    </TabPanel>
                    {/* <TabPanel id="4">
                      {this.state.cost_dataset.flag === 0
                        ? <StatusTip style={{ width: '10%', margin: '10% 45%' }}
                          status="loading" loadingText="获取开销数据中..." />
                        : <div><CostData style={{ height: '540px', width: '100%' }}
                          cpuDataset={this.state.cost_dataset.cpuDataset}
                          memDataset={this.state.cost_dataset.memDataset}
                          ioDataset={this.state.cost_dataset.ioDataset}></CostData>
                          <br></br>
                          <p style={{
                            textAlign: 'center',
                            fontSize: '20',
                            color: 'red',
                            'font-weight': 'bold',
                          }}>注：可以通过鼠标滚轮和左键拖拽调整查看范围</p>
                        </div>}
                    </TabPanel> */}
                  </Tabs>
                </Card.Body>
              </Card>
            </Content.Body>
          </Content>
        </Body>
      </Layout>
    </div>;
  }
}

SamplingResult.propTypes = {
  DropAction: PropTypes.any.isRequired,
  DropReducer: PropTypes.any.isRequired,
  task_data: PropTypes.object,
  thread_data: PropTypes.arrayOf(PropTypes.object),
  heap_stack_info: PropTypes.object,
  total_records: PropTypes.string,
  java_result: PropTypes.string,
  flamegraph_url: PropTypes.string,
  callgraph_url: PropTypes.string,
  container_list: PropTypes.object,
  user_name: PropTypes.string,
  goBack: PropTypes.func,
  jupyterURL: PropTypes.string,
  showGobackButton: PropTypes.bool,
};
// 封装了和ThreadTable相关的信息
// 只需要输入data就可以
// props：thread_data
export class ThreadTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      searchText: '',
      searchedColumn: '',
      pageSize: 10,
      thread_data: props.thread_data,
    };
  }

  render() {
    const threadColumns = [
      {
        header: '线程ID',
        key: 'tid',
      },
      {
        header: '线程名',
        key: 'comm',
      },
      {
        header: '采中次数',
        key: 'count',
      },
    ];
    return <Table columns={threadColumns}
      records={ this.props.thread_data}
      addons={[
        pageable({
          recordCount: this.props.thread_data.length,
          pageSize: this.state.pageSize,
          onPagingChange: (size) => {
            Log.debug(size);
            this.setState({
              pageSize: size.pageSize,
            });
          },
          pageCountChangingResetType: 'first',
        }),
        scrollable({ maxHeight: 480, scrollToTopOnChange: true }),
      ]} />
    ;
  }
}

ThreadTable.propTypes = {
  thread_data: PropTypes.array,
};
// 封装tree生成函数和最大长度限制
const maxLength = 200;

// 把传入的json转换成数组
const gengData = (heapStackData) => {
  const gData = [];
  heapStackData.map((data) => {
    const i = data[0];
    if (i.length > maxLength) {
      gData.push({
        title: `${i.substr(0, maxLength - 1)}...`,
        key: i,
        value: `    (${data[1]})`,
      });
    } else {
      const lastIndex = i.lastIndexOf('(');
      let funcName = i;
      if (i[i.length - 1] === ')') {
        funcName = i.substring(0, lastIndex);
        const module = i.substring(lastIndex);
        funcName = `${funcName} ${module}`;
      }
      gData.push({
        title: funcName,
        key: i,
        value: `    (${data[1]})`,
      });
    }
    return true;
  });
  return gData;
};

// topN方法展示类
// props：HeapStackInfo


export class HeapStackInfo extends React.Component {
  constructor(props) {
    super(props);
    // 根据传入的json生成数组
    const gData = gengData(this.props.HeapStackInfo);
    this.state = {
      treeData: gData,
      total_records: this.props.total_records,
    };
  }

  // 渲染
  render() {
    // eslint-disable-next-line no-console
    const args = qs.parse(window.location.search.slice(1));
    return (
      <div style={{ margin: '10 auto' }}>
        <Space style={{ display: 'flex' }}>
          <FibonaButton title="热点函数智能分析" trigger={FIBONA_TRIGGER.topN} format="csv" data={() => {
            let data = '函数名,位置,采样次数占比\r\n';
            // 遍历
            this.props.HeapStackInfo.map((info) => {
              const f = info[0];
              if (f.toLowerCase().includes('unknown')) {
                return true;
              }

              const funcName = f.split('(')[0].split('+')[0].trim();
              const location = f.split('(')[1]?.split(')')[0]?.trim();
              const percent = ((info[1] / this.props.total_records) * 100.0).toFixed(3);

              data += `${funcName},${location},${percent}%\r\n`;
              return true;
            });
            return data;
          }} />
          {this.props.HeapStackInfoSwapper && this.props.total_records !== this.props.total_records_with_swapper
            && <Switch
              style={{ marginTop: '3px' }}
              size="large"
              label={['展示swapper数据', '隐藏swapper数据']}
              onChange={(value) => {
                this.setState(() => ({
                  treeData: value ? gengData(this.props.HeapStackInfoSwapper) : gengData(this.props.HeapStackInfo),
                  total_records: value ? this.props.total_records_with_swapper : this.props.total_records,
                }));
              }}
            />}
        </Space>
        <List type="number" split size="small">
          {this.state.treeData.map((data, index) => {
            const showJump = this.props.annotate_enable && !(data.title.toLowerCase().includes('unknown'));
            const dataValue = `采样命中次数: ${(data.value.split(')')[0].split('(')[1])}`;
            const functionName = data.title.split('(')[0].split('+')[0].trim();
            const flameGraphURL = getFlameGraphURL(window.location, functionName);

            return <ListItem key={index} action={
                <Space>
                  {showJump ? <Link theme="primary" hover="color" target={jumpTargetConfig()} href = {
                    `/functionAssemblyPerfReport?${qs.stringify(Object.assign(args, { functionName }))}`}
                  >热点汇编</Link> : ''}

                  {this.props.display_flamegraph_search ? <Link theme="primary" hover="color" target={jumpTargetConfig()}
                                                                href={flameGraphURL.toString()}>火焰图</Link> : ''}
                </Space>
            }>
              <Tooltip title={dataValue}>
                <Space size={6}>
                  <b>{index + 1}{'.'}</b>
                  {data.title}
                  <Text theme="primary">
                    {this.props.total_records
                      ? `[${(data.value.split(')')[0].split('(')[1] / this.state.total_records * 100.0).toFixed(3)}%]`
                      : data.value
                    }
                  </Text>
                </Space>
              </Tooltip>
          </ListItem>;
          })}
        </List>
        {typeof this.props.total_records === 'undefined'
            && <Tag style={{ left: '10px' }} theme="primary">黄色数字为采样命中次数</Tag>}
        {typeof this.props.total_records !== 'undefined'
            && <Tag style={{ left: '10px' }} theme="primary">蓝色数字为采样命中占比</Tag>}
      </div>
    );
  }
}

HeapStackInfo.propTypes = {
  HeapStackInfo: PropTypes.array,
  HeapStackInfoSwapper: PropTypes.array,
  annotate_enable: PropTypes.bool,
  total_records: PropTypes.string,
  total_records_with_swapper: PropTypes.string,
  display_flamegraph_search: PropTypes.bool,
};

// 开销绘制模块
// todo:目前是echarts 后续需要换成tea-chart
// 输入数据:
// cpuDataset
// memDataset
// ioDataset
class CostData extends React.Component {
  constructor(props) {
    super(props);
    const { cpuDataset } = this.props;
    const { memDataset } = this.props;
    const { ioDataset } = this.props;
    this.state = {
      chartData1: {
        dom_name: 'myChart1',
        title_text: (props.cpuDataset.length === 0) ? 'CPU使用率(%) 暂无数据' : 'CPU使用率(%)',
        dataset_dimensions: ['product', '自身', '采集'],
        dataset_source: cpuDataset,
        legend_data: ['自身', '采集'],
        series_number: 2,
      },
      chartData2: {
        dom_name: 'myChart2',
        title_text: (props.memDataset.length === 0) ? '常驻内存使用量(单位:KB) 暂无数据' : '常驻内存使用量(单位:KB)',
        dataset_dimensions: ['product', '自身', '采集'],
        dataset_source: memDataset,
        legend_data: ['自身', '采集'],
        series_number: 2,
      },
      chartData3: {
        dom_name: 'myChart3',
        title_text: (props.ioDataset) ? 'IO速率(单位:KB/s) 暂无数据' : 'IO速率(单位:KB/s)',
        dataset_dimensions: ['product', '自身(读取速率)', '自身(写入速率)', '采集(读取速率)', '采集(写入速率)'],
        dataset_source: ioDataset,
        legend_data: ['自身(读取速率)', '自身(写入速率)', '采集(读取速率)', '采集(写入速率)'],
        series_number: 4,
      },
    };
  }

  // 初始化
  componentDidMount() {
    this.initCharts(this.state.chartData1);
    this.initCharts(this.state.chartData2);
    this.initCharts(this.state.chartData3);
  }

  // 封装初始化函数
  // 参考echarts文档
  initCharts = (chartData) => {
    const myChart = echarts.init(document.getElementById(chartData.dom_name));
    const option = {
      title: {
        text: chartData.title_text,
      },
      tooltip: {
        trigger: 'axis',
      },
      dataset: {
        dimensions: chartData.dataset_dimensions,
        source: chartData.dataset_source,
      },
      legend: {
        data: [],
      },
      grid: {
        left: '3%',
        right: '4%',
        bottom: '3%',
        containLabel: true,
      },
      toolbox: {
        feature: {
          saveAsImage: {},
        },
      },
      xAxis: {
        type: 'category',
        boundaryGap: false,
      },
      yAxis: {
        type: 'value',
        splitLine: {
          lineStyle: {
            width: 0.3,
          },
        },
      },
      series: [],
      dataZoom: [{
        type: 'inside',
        start: 90,
        end: 100,
        show: true,
      }],
    };
    // 压入对应的数据
    // 设置线的颜色
    const color = ['rgba(255, 0, 0,0.5)', 'rgba(0, 255, 0,0.5)', 'rgba(0, 0, 255,0.5)', 'rgba(100, 120, 120,0.5)'];
    for (let i = 0; i < chartData.series_number; ++i) {
      option.series.push({
        type: 'line',
        showSymbol: false,
        lineStyle: {
          color: color[i],
        },
        itemStyle: {
          color: color[i],
        },
        label: {
          formatter(params) {
            if (params.value > 0) {
              return params.value;
            }
            return ' ';
          },
        },
      });
      option.legend.data.push({
        name: chartData.legend_data[i],
        textStyle: {
          color: color[i],
        },
      });
    }
    myChart.setOption(option);
    window.addEventListener('resize', () => {
      myChart.resize();
    });
  };

  render() {
    return (
      <div>
        <div style={{ height: '170px', width: '100%' }} id="myChart1">
        </div>
        <br></br><br></br>
        <div style={{ height: '170px', width: '100%' }} id="myChart2">
        </div>
        <br></br><br></br>
        <div style={{ height: '170px', width: '100%' }} id="myChart3">
        </div>
      </div>
    );
  }
}

CostData.propTypes = {
  cpuDataset: PropTypes.array,
  memDataset: PropTypes.array,
  ioDataset: PropTypes.array,
};

// 只存一次接口数据 下次请求会替换掉当前的数据 所以需要把数据保存到state里
const mapStateToProps = (state) => {
  const { DropReducer } = state;
  return {
    DropReducer,
  };
};// Reducer

const mapDispatchToProps = (dispatch) => {
  const DropAction = bindActionCreators(DropActionCreator, dispatch);

  return {
    DropAction,
  };
};// Action


export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps,
)(SamplingResult));
