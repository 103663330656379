import React, { memo, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import DropActionCreator from '@/api/DropActions';
import {
  Row,
  Col,
  Bubble,
  Button,
  Card,
  List,
  Select,
  Slider,
  StatusTip,
  Text,
} from '@tencent/tea-component';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import Graphviz from './graphviz';
import { has } from '@/utils/ErrorConfirm';


/**
 * 调用图组件
*/
const Callgraph = (props) => {
  const [dot, setDot] = useState(null);
  const [form, setForm] = useState(null);
  const [error, setError] = useState(null);

  let process = '';
  const processNodeCnt = {};

  // 获取调用图数据
  useEffect(() => {
    props.DropAction.getCallgraphData({ dataURL: props.callgraphURL });
  }, []);

  let graph = null;
  let { defaultNodeCnt } = props;
  useEffect(() => {
    const callgraphData = props.DropReducer.GetCallgraphData;
    if (Object.keys(callgraphData).length === 0) {
      return;
    }
    if (has.call(callgraphData, 'code') && callgraphData.code === 'error') {
      setError(callgraphData.msg);
      return;
    }

    graph = new Graph(callgraphData);
    defaultNodeCnt = Math.min(defaultNodeCnt, graph.getNodes(process).length);
    processNodeCnt[process] = defaultNodeCnt;

    setForm({
      processes: graph.getProcessNames(),
      ratios: graph.getRatios(process),
      initNodeCnt: defaultNodeCnt,
      curNodeCnt: defaultNodeCnt,
      nodeCntOnChange,
      processOnChange,
    });

    setDot(graph.toDot(defaultNodeCnt));
  }, [props.DropReducer.GetCallgraphData]);

  const nodeCntOnChange = (nodeCnt) => {
    processNodeCnt[process] = nodeCnt;
    setDot(graph.toDot(nodeCnt, process));
  };

  const processOnChange = (value) => {
    process = value;
    const totalNodeCnt = graph.getNodes(process).length;
    if (processNodeCnt[process] === undefined) {
      const nodeCnt = Math.min(defaultNodeCnt, totalNodeCnt);
      processNodeCnt[process] = nodeCnt;
    }
    const nodeCnt = processNodeCnt[process];

    setForm({
      processes: graph.getProcessNames(),
      ratios: graph.getRatios(process),
      initNodeCnt: Math.min(defaultNodeCnt, totalNodeCnt),
      curNodeCnt: nodeCnt,
      nodeCntOnChange,
      processOnChange,
    });

    setDot(graph.toDot(nodeCnt, process));
  };

  if (error !== null) {
    return (
      <div id='callgraph'>
        <Card>
          <Card.Body>
            <Text align={'left'}>
              获取调用图数据错误:
              {error}
            </Text>
          </Card.Body>
        </Card>
      </div>
    );
  }

  if (form === null || dot === null) {
    return (
    <div id='callgraph'>
          <StatusTip style={{ width: '10%', margin: '10% 45%' }}
                       status="loading" loadingText="调用图渲染中..." />
           </div>
    );
  }

  return (
      <div id='callgraph'>
          <Card
              style={{
                boxShadow: '0 0 0 0',
              }}>
              <Card.Header
                style={{
                  borderBottom: '0',
                }}>
                <div>
                  <CallgraphForm
                    ratios={form.ratios}
                    initNodeCnt={form.initNodeCnt}
                    curNodeCnt={form.curNodeCnt}
                    processes={form.processes}
                    nodeCntOnChange={form.nodeCntOnChange}
                    processOnChange={form.processOnChange}
                  />
                  </div>
                </Card.Header>
              <Card.Body>
                <div style={{
                  margin: 'auto',
                  height: 700, // TODO: 根据窗口最大高度设定
                  overflow: 'auto',
                }}>
                  <Graphviz
                    dot={dot}
                    options={{
                      height: '100%',
                      width: '100%',
                    }}
                  />
                </div>
              </Card.Body>
            </Card>
      </div>
  );
};

Callgraph.propTypes = {
  DropReducer: PropTypes.any,
  DropAction: PropTypes.any,

  defaultNodeCnt: PropTypes.number,
  callgraphURL: PropTypes.string,
};

const mapStateToProps = (state) => {
  const { DropReducer } = state;
  return {
    DropReducer,
  };
};// Reducer

const mapDispatchToProps = (dispatch) => {
  const DropAction = bindActionCreators(DropActionCreator, dispatch);

  return {
    DropAction,
  };
};// Action

/**
 * dot图对象，主要用来生成dot图
 * @param data 分析程序生成的调用图
*/
function Graph(data) {
  // 定义dot图相关属性
  this.startDot = `digraph {\n\tgraph [fontname=${data.fontname}, nodesep=${data.nodesep}, ranksep=${data.ranksep}];`;
  this.startDot += `\n\tnode [fontname=${data.fontname}];`;
  this.startDot += `\n\tedge [fontname=${data.fontname}];`;
  this.endDot = '\n}';

  this.nodes = []; // 所有节点，节点按照函数总执行时间从大到小排序
  this.edges = [];

  // 各进程的节点信息
  this.processes = {};

  const processSet = new Set();
  Object.values(data.objects).forEach((n) => {
    const node = {};

    let dotSrc = '';
    dotSrc += `\n\tn${n._gvid} [color="${n.color}", fillcolor="${n.fillcolor}", label="${n.label}"`;
    dotSrc += `fontsize="${n.fontsize}", shape="${n.shape}", style=${n.style}];`;
    node.src = dotSrc;

    node.id = n._gvid;

    const fields = n.label.split('\\n');

    const process = fields[0];
    processSet.add(process);
    node.process = process;

    const ratio = Number(fields.slice(-1)[0].slice(3, -1));
    node.ratio = ratio;

    this.nodes.push(node);
  });
  this.nodes.sort((a, b) => b.ratio - a.ratio);

  processSet.forEach((p) => {
    this.processes[p] = {
      nodes: [],
      edges: [],
    };
  });

  Object.values(this.nodes).forEach((n) => {
    this.processes[n.process].nodes.push(n);
  });

  // 节点原始ID与排序后索引关系，用来调整边指向的头尾节点
  const nodeId2idx = {};
  Object.entries(this.nodes).forEach(([i, n]) => {
    nodeId2idx[n.id] = i;
  });

  Object.values(data.edges).forEach((e) => {
    const edge = {};

    let dotSrc = '';
    dotSrc += `\n\tn${e.tail} -> n${e.head} [arrowsize="${e.arrowsize}", color="${e.color}",`;
    dotSrc += `fontsize="${e.fontsize}", label="${e.label}",`;
    dotSrc += `labeldistance="${e.labeldistance}", penwidth="${e.penwidth}"];`;

    edge.src = dotSrc;
    edge.tail = nodeId2idx[e.tail];
    edge.id = e._gvid;
    edge.head = nodeId2idx[e.head];
    this.edges.push(edge);
  });

  Object.values(this.edges).forEach((e) => {
    console.assert(this.nodes[e.tail].process === this.nodes[e.head].process, 'graph data error');
    this.processes[this.nodes[e.tail].process].edges.push(e);
  });
};

Graph.prototype.toDot = function (nodeCnt, process = '') {
  let nodes = null;
  let edges = null;
  if (process === '') {
    nodes = this.nodes.slice(0, nodeCnt);
    edges = this.edges;
  } else {
    nodes = this.processes[process].nodes.slice(0, nodeCnt);
    edges = this.processes[process].edges;
  }

  let dotsrc = '';
  dotsrc += this.startDot;

  const showNodes = {}; // 过滤边使用
  Object.values(nodes).forEach((n) => {
    dotsrc +=  n.src;
    showNodes[n.id] = true;
  });

  Object.values(edges).forEach((e) => {
    const tailNode = this.nodes[e.tail].id;
    const headNode = this.nodes[e.head].id;
    if (tailNode in showNodes && headNode in showNodes) {
      dotsrc += e.src;
    }
  });

  dotsrc += this.endDot;

  return dotsrc;
};

Graph.prototype.getProcessNames = function () {
  return Object.keys(this.processes);
};

Graph.prototype.getNodes = function (process = '') {
  if (process === '') {
    return this.nodes;
  }

  return this.processes[process].nodes;
};

/**
 * 生成某进程的 ratios
*/
Graph.prototype.getRatios = function (process = '') {
  const ratios = [];
  let nodes = null;
  if (process === '') {
    if (ratios in this) {
      return this.ratios;
    }
    this.ratios = ratios;
    nodes = this.nodes;
  } else {
    if (ratios in this.processes[process]) {
      return this.processes[process].ratios;
    }
    this.processes[process].ratios = ratios;
    nodes = this.processes[process].nodes;
  }

  Object.values(nodes).forEach((n) => {
    ratios.push(n.ratio);
  });

  return ratios;
};

/**
 * 调用图图例内容
*/
const legendItems = [
  '节点内容: 程序名 | 函数名 | flat占比 | cum占比',
  '节点颜色: cum 值越大颜色越红，反之颜色越灰',
  '节点字体大小: flat 值越大字体越大，反之字体越小',
  '边内容: 被调用函数的cum占比',
  '边的粗细和颜色: 调用耗时越大边越粗越红，反之越细越灰',
  'cum(cumulative): 函数累计执行耗时',
  'flat: 函数自身执行耗时',
].map((item, index) => <List.Item key={index}>{item}</List.Item>);

/**
 * 调用图表单组件
*/
const CallgraphForm = (props) => {
  const { ratios, initNodeCnt, curNodeCnt, nodeCntOnChange, processes, processOnChange } = props;
  const min = 1;
  const max = ratios.length;

  const [nodeCnt, setNodeCnt] = useState(curNodeCnt);

  useEffect(() => {
    if (nodeCnt !== curNodeCnt) {
      setNodeCnt(curNodeCnt);
    };
  }, [curNodeCnt]);

  const marks = [{
    value: initNodeCnt,
    label: `默认${initNodeCnt}(${ratios[initNodeCnt - 1]}%)`,
  }];

  if (initNodeCnt - 1 >= 5) {
    marks.push({
      value: min,
      label: `${min}(${ratios[min]}%)`,
    });
  }

  if (ratios.length - initNodeCnt >= 5) {
    marks.push({
      value: max,
      label: `${max}(${ratios[max - 1]}%)`,
    });
  };

  const nodeCntOnChangeWrap = (value) => {
    if (nodeCnt !== value) {
      setNodeCnt(value);
      nodeCntOnChange(value);
    }
  };
  const tipFormater = nodeCnt => `显示${nodeCnt}个节点，节点最小执行时间占比${ratios[nodeCnt - 1]}%`;

  const processOptions = processes.map(item => ({ value: item }));
  processOptions.unshift({
    value: '', text: '所有进程',
  });

  return (
    <Row gap={6}>
      <Col span={20}>
        <Slider
          style={{
            width: '95%',
            margin: 'auto',
          }}
          enableTrackTip
          min={min}
          max={max}
          marks={marks}
          value={nodeCnt}
          tipFormatter={value => tipFormater(value)}
          onChange={value => nodeCntOnChangeWrap(value)}
        />
      </Col>
      <Col span={2}>
         <Select
           style={{
             width: '100%',
             margin: 'auto',
           }}
           appearance="button"
           options={processOptions}
           defaultValue={''}
           onChange={value => processOnChange(value)}
           placeholder="请选择进程名"
        />
      </Col>
      <Col span={2}>
        <Bubble
          style={{
            width: '100%',
            margin: 'auto',
          }}
          placement={'bottom'}
          content={
            <>
              <List split='divide'>
                {legendItems}
              </List>
            </>
          }>
          <Button type='text'>图例</Button>
        </Bubble>
      </Col>
    </Row>
  );
};

CallgraphForm.propTypes = {
  ratios: PropTypes.array,
  initNodeCnt: PropTypes.number,
  curNodeCnt: PropTypes.number,
  processes: PropTypes.array,
  nodeCntOnChange: PropTypes.func,
  processOnChange: PropTypes.func,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(memo(Callgraph));
