/*
@date:2021-12-28
@description:捕获错误，错误上报
*/
import React, { memo } from 'react';
import { bindActionCreators } from 'redux';
import DropActionCreator from '@/api/DropActions';
import { connect } from 'react-redux';
import {
  Text,
  Blank,
  Layout,
  ExternalLink,
  Alert,
  Button,
  message,
} from '@tencent/tea-component';
import { Axios } from '@/api';
import { HOST_URL, TEST_ENV } from '@/config';
import { getOAName } from '@/utils/Storage';
import { goodStatus } from '@/utils/ErrorConfirm';
import PropTypes from 'prop-types';

const { Body, Content } = Layout;

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      hasError: false,
      errInfo: {},
    };
    console.log('errorboundry');
    console.log(props);
  }

  static getDerivedStateFromError(error) {
    // 更新 state 使下一次渲染能够显示降级后的 UI
    console.log('捕获错误');
    console.log(error);
    return { hasError: true, errInfo: error };
  }

  componentDidCatch(error) {
    if (!TEST_ENV) {
      Axios.post(`${HOST_URL}/api/v1/alert/webhook`, {
        user: getOAName(),
        location: window.location.hash,
        errorInfo: error.toString(),
      })
        .then((response) => {
          if (goodStatus(response)) {
            message.success({
              content: '上报错误成功',
            });
          } else {
            message.error({
              content: `上报错误失败${response.msg}` ?? '',
            });
          }
        })
        .catch((error) => {
          message.error({
            content: `上报错误失败:${error}`,
          });
        });
    }
  }

  render() {
    // 有错则渲染错误提示页面
    if (this.state.hasError) {
      // 你可以自定义降级后的 UI 并渲染
      // todo:设置error页面
      return  <Layout>
      <Body>
        <Content>
          <Content.Body>
            <Blank
              title="出错啦"
              image={{
                background:
                  'https://imgcache.qq.com/qcloud/tcloud_dtc/static/tc_console/114b4d79-fdd8-4c8c-8771-418a8fa81bbb.svg',
                logo:
                  'https://imgcache.qq.com/qcloud/tcloud_dtc/static/tc_console_product/3ad6629b-8cf5-42ee-8a7f-4e3802768638.svg',
              }}
              operation={<Button type="primary" onClick={() => {
                window.location.reload();
              }}>重新载入</Button>}
              extra={
                <>
                  <ExternalLink href="https://iwiki.woa.com/pages/viewpage.action?pageId=1583794981">查看文档</ExternalLink>
                </>
              }
              bottom={[
                <>
                  <Text type="primary">Drop</Text>
                </>,
              ]}
            >
              <Alert type="info">
                <h4 style={{ marginBottom: 8 }}>错误信息</h4>
                <Text theme="text">{this.state.errInfo.message}</Text>
              </Alert>
            </Blank>
          </Content.Body>
        </Content>
      </Body>
    </Layout>;
    }
    return this.props.children;
  }
}

ErrorBoundary.propTypes = {
  children: PropTypes.element,
};

const mapStateToProps = (state) => {
  const { DropReducer } = state;
  return {
    DropReducer,
  };
};// Reducer

const mapDispatchToProps = (dispatch) => {
  const DropAction = bindActionCreators(DropActionCreator, dispatch);

  return {
    DropAction,
  };
};// Action


export default memo(connect(
  mapStateToProps,
  mapDispatchToProps,
)(ErrorBoundary));
