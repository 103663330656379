import React, { memo, Suspense, useEffect } from 'react';
import './App.css';
import { BrowserRouter } from 'react-router-dom';
import '@tencent/tea-component/dist/tea.css';
import { renderRoutes } from 'react-router-config';
import routes from '@/router';
import { Provider } from 'react-redux';
import storeCreator from '@/store';
import ErrorBoundary from '@/components/errorBoundary';
import { FIBONA_APPID } from './config';
import { Log } from './utils/Log';

const INITIAL_STATE = window.INITIAL_STATE || {};
const { store } = storeCreator(INITIAL_STATE)();

function App() {
  useEffect(() => {
    try {
      window.fibonaAI.start({ app_id: FIBONA_APPID });
    } catch (err) {
      Log.error('FibonaAI 启动失败:', err);
    }
  }, []);
  return (
    <Provider store={store}>
      <ErrorBoundary>
        <BrowserRouter>
          <Suspense fallback={'loading'}>{renderRoutes(routes)}</Suspense>
        </BrowserRouter>
      </ErrorBoundary>
    </Provider>
  );
}

export default memo(App);
