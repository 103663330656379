import React from 'react';
import { generateIntHash } from './colorUtils';
import './flamegraph.css';

export function calculateColor(hue, vector) {
  let r;
  let g;
  let b;

  if (hue === 'red') {
    r = 200 + Math.round(55 * vector);
    g = 50 + Math.round(80 * vector);
    b = g;
  } else if (hue === 'orange') {
    r = 190 + Math.round(65 * vector);
    g = 90 + Math.round(65 * vector);
    b = 0;
  } else if (hue === 'yellow') {
    r = 175 + Math.round(55 * vector);
    g = r;
    b = 50 + Math.round(20 * vector);
  } else if (hue === 'green') {
    r = 50 + Math.round(60 * vector);
    g = 200 + Math.round(55 * vector);
    b = r;
  } else if (hue === 'pastelgreen') {
    // rgb(163,195,72) - rgb(238,244,221)
    r = 163 + Math.round(75 * vector);
    g = 195 + Math.round(49 * vector);
    b = 72 + Math.round(149 * vector);
  } else if (hue === 'blue') {
    // rgb(91,156,221) - rgb(217,232,247)
    r = 91 + Math.round(126 * vector);
    g = 156 + Math.round(76 * vector);
    b = 221 + Math.round(26 * vector);
  } else if (hue === 'aqua') {
    r = 50 + Math.round(60 * vector);
    g = 165 + Math.round(55 * vector);
    b = g;
  } else if (hue === 'cold') {
    r = 0 + Math.round(55 * (1 - vector));
    g = 0 + Math.round(230 * (1 - vector));
    b = 200 + Math.round(55 * vector);
  } else {
    // original warm palette
    r = 200 + Math.round(55 * vector);
    g = 0 + Math.round(230 * (1 - vector));
    b = 0 + Math.round(55 * (1 - vector));
  }

  return `rgb(${r},${g},${b})`;
}

const name2ColorSchemeMap = {};
name2ColorSchemeMap[vintageRainbow.name] = vintageRainbow;
name2ColorSchemeMap[satisfyingSenses.name] = satisfyingSenses;

export function name2ColorScheme(name) {
  return name2ColorSchemeMap[name];
}

// https://www.schemecolor.com/another-vintage-rainbow.php
vintageRainbow.colors = [
  '#646FA9',
  '#49A9B4',
  '#6BCD69',
  '#DBDCB8',
  '#CBA18C',
  '#C4737C',
  '#F6C8DD',
  '#F6E5E4',
];
vintageRainbow.diffColors = [
  { percent: -100, color: '#33cc33' },
  { percent: -80, color: '#4ec64f' },
  { percent: -60, color: '#66b266' },
  { percent: -40, color: '#80a080' },
  { percent: -20, color: '#99b399' },
  { percent: 0, color: '#adadad' },
  { percent: 20, color: '#b88282' },
  { percent: 40, color: '#c26666' },
  { percent: 60, color: '#cc4f4f' },
  { percent: 80, color: '#d63333' },
  { percent: 100, color: '#e00000' },
];
export function vintageRainbow(attr) {
  const { colors, diffColors } = vintageRainbow;

  function getSmoothColor(diff) {
    // Clamp the diff value to be between -1 and 1
    diff = Math.max(-1, Math.min(1, diff));

    // Calculate position
    const position = (diff + 1) * 5; // Normalize diff to [0, 12]
    const index = Math.floor(position);
    const fraction = position - index;

    // If the position is at the last color, return it
    if (index >= diffColors.length - 1) {
      return diffColors[diffColors.length - 1].color;
    }

    // Function to interpolate two hex colors
    function interpolateColor(color1, color2, factor) {
      let result = '#';
      for (let i = 1; i < 7; i += 2) {
        const hex1 = parseInt(color1.substr(i, 2), 16);
        const hex2 = parseInt(color2.substr(i, 2), 16);
        const hex = Math.round(hex1 + (hex2 - hex1) * factor).toString(16);
        result += (`0${hex}`).slice(-2); // Pad with zero if needed
      }
      return result;
    }

    // Return interpolated color
    return interpolateColor(diffColors[index].color, diffColors[index + 1].color, fraction);
  }

  vintageRainbow.pick = function (frame) {
    if (frame.data.diff !== undefined) {
      return getSmoothColor(frame.data.diff);
    }
    if (frame.data.tid === -1) {
      return '#D5DBDB';
    }
    if (frame.isFocusRoot) {
      return '#D5DBDB';
    }
    const value = frame.data[attr];
    return colors[generateIntHash(value) % colors.length];
  };

  return vintageRainbow;
}

// https://www.schemecolor.com/satisfying-senses.php
satisfyingSenses.colors = [
  '#3F8FC4',
  '#46CFCA',
  '#FCD779',
  '#FC8D6A',
  '#766DB5',
];
export function satisfyingSenses(attr) {
  const { colors } = satisfyingSenses;
  satisfyingSenses.pick = function (frame) {
    if (frame.data.tid === -1) {
      return '#D5DBDB';
    }
    if (frame.isFocusRoot) {
      return '#D5DBDB';
    }
    const value = frame.data[attr];
    return colors[generateIntHash(value) % colors.length];
  };

  return satisfyingSenses;
}

export function ColorDifferenceBar() {
  return (
    <div className="color-bar-container">
      <div className="color-bar-layout">
        <span className="color-bar-label color-bar-removed">(-) Removed</span>
        <div className="color-bar-wrapper">
          <div className="color-bar">
          {vintageRainbow.diffColors.map(({ percent, color }) => (
            <div
              key={percent}
              className="color-block"
              style={{ backgroundColor: color }}
            >
              {`${percent > 0 ? '+' : ''}${percent}%`}
            </div>
          ))}
          </div>
        </div>
        <span className="color-bar-label color-bar-added">Added (+)</span>
      </div>
    </div>
  );
};
