import React from 'react';
import PropTypes from 'prop-types';
import {
  Form,
  Card,
  Button,
} from '@tencent/tea-component';

const GroupInfo = (props) => {
  console.log(props);

  return <>
    <Card.Body
      title="用户信息"
    >
      <Form readonly layout='inline'>
          <Form.Item label="用户组ID">
              <Form.Text >{props.groupInfo.gid}</Form.Text>
          </Form.Item>
          <Form.Item label="用户组名">
              <Form.Text>{props.groupInfo.group_name}</Form.Text>
          </Form.Item>
          <Form.Item label="管理员">
              <Form.Text>{props.groupInfo.owner_name}</Form.Text>
          </Form.Item>
          {(props.isGroupOwner) ? <Form.Item label="用户组操作">
              <Form.Text>
                <Button
                  type='link'
                  onClick={() => props.disbandGroup() }
                >解散</Button>
                <Button
                  style={{ marginLeft: '10px' }}
                  type='link'
                  onClick={() => props.addGroupMember() }
                >添加成员</Button>
              </Form.Text>
          </Form.Item> : ''}
      </Form>
    </Card.Body>
  </>;
};

GroupInfo.propTypes = {
  groupInfo: PropTypes.object,
  isGroupOwner: PropTypes.bool,
  disbandGroup: PropTypes.func,
  addGroupMember: PropTypes.func,
};

export default GroupInfo;
