import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Button, Form,
  message, PopConfirm, Select,
} from '@tencent/tea-component';
import { bindActionCreators } from 'redux';
import DropActionCreator from '../../api/DropActions';
import { connect } from 'react-redux';
import { goodStatus, doesntHaveStatus, badStatus } from '@/utils/ErrorConfirm';
import { withRouter } from 'react-router-dom';
import copy from 'copy-to-clipboard';
import qs from 'qs';


const ButtonShareTask = (props) => {
  const day = 24 * 3600;
  const [isButtonClicked, setIsButtonClicked] = useState(false);
  const [duration, setDuration] = useState((7 * day).toString());

  // effect
  useEffect(() => {
    const { GetSharedTaskURL } = props.DropReducer;
    if (Object.keys(GetSharedTaskURL).length) {
      if (doesntHaveStatus(GetSharedTaskURL)) {
        message.error({
          content: `error:${GetSharedTaskURL?.message}`,
        });
      } else if (badStatus(GetSharedTaskURL)) {
        message.error({
          content: `error:${GetSharedTaskURL?.message}`,
        });
      }
      if (goodStatus(GetSharedTaskURL)) {
        // 弹出成功了的提示
        if (GetSharedTaskURL.data.taskID !== props.task.tid) {
          return ;
        }
        if (!isButtonClicked) {
          return;
        }
        setIsButtonClicked(false);
        const data = qs.stringify({
          taskID: props.task.tid,
          taskExpiredTime: GetSharedTaskURL.data.expiredTime,
          sharedTaskToken: GetSharedTaskURL.data.token,
          isSharedURL: true,
        });
        const text = `${window.location.protocol}//${window.location.host}/task/result?${data}`;
        if (copy(text)) {
          message.success({
            content: '复制分享链接成功',
          });
        }
      }
    }
  }, [props.DropReducer]);

  return <>
    <PopConfirm
      title="创建任务分享链接"
      message = {
        <Form>
          <Form.Item label="过期时间">
            <Select
              size="full"
              appearance="button"
              options={[
                { value: day.toString(), text: '1天' },
                { value: (3 * day).toString(), text: '3天' },
                { value: (7 * day).toString(), text: '一周' },
                { value: (30 * day).toString(), text: '一个月' },
                { value: (10 * 365 * day).toString(), text: '永久' },
              ]}
              value={duration}
              onChange={value => setDuration(value)}
              placeholder="请选择分享时长"
            />
          </Form.Item>
        </Form>
    }
      footer={close => (
        <>
          <Button type="link" onClick={() => {
            props.DropAction.getSharedTaskURL({
              tid: props.task.tid,
              duration,
              task_type: props.task.type,
            });
            setIsButtonClicked(true);
            close();
          }}
          >分享</Button>
          <Button type="text" onClick={() => {
            close();
          }}>取消</Button>
        </>
      )}
      placement="top-start"
      >
        <Button type='link'
                disabled={ !((props.task.status === 2 && props.task.analysis_status === 2)
                  || (props.task.status === 4 && props.task.analysis_status !== 0)) }
        >分享</Button>
      </PopConfirm>
    </>;
};


ButtonShareTask.propTypes = {
  DropReducer: PropTypes.any,
  DropAction: PropTypes.any,
  location: PropTypes.any,
  task: PropTypes.object,
};

const mapStateToProps = (state) => {
  const { DropReducer } = state;
  return {
    DropReducer,
  };
};// Reducer

const mapDispatchToProps = (dispatch) => {
  const DropAction = bindActionCreators(DropActionCreator, dispatch);

  return {
    DropAction,
  };
};// Action

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps,
)(ButtonShareTask));
