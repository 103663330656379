import { MessagePlugin, Button, Select, Popconfirm } from 'tdesign-react';
import { SearchIcon } from 'tdesign-icons-react';
import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import { Log } from '../../utils/Log';
import axios from 'axios';
import { HOST_URL, GongFengHOST } from '@/config/';

// 工蜂转发地址
const GongFengButton = (props) => {
  Log.debug("props", props);
  Log.debug("HOST_URL", HOST_URL, GongFengHOST);
  const [tokenValid, settokenValid] = useState(false);
  const [web, setweb] = useState([]);// 最后要打开的新标签页
  const [url, seturl] = useState({});

  // 存放具体的项目、分支、commit的信息（来自后端的数据）
  const [Projects, setProjects] = useState([]);
  const [Branches, setBranches] = useState([]);
  const [Commits, setCommits] = useState([]);

  const [sendSug, setsendSug] = useState(0);
  const [gongFengVisible, setgongFengVisible] = useState(false);
  const [selectProjectValue, setSelectProjectValue] = useState('');
  const [selectBranchValue, setSelectBranchValue] = useState('');
  const [selectCommitValue, setSelectCommitValue] = useState('');

  useEffect(() => {
    if (!tokenValid) {
    const checkClosed = setInterval(function () {
      const requestParams = {
        uid: props.uid,
        origin: window.location.origin,
      };
      axios({
        method: 'get',
        url: `${GongFengHOST}/api/v1/gongfeng/token`,
        params: requestParams,
      }).then((response) => {
        // 此处不处理超时情况，超时刷新的判断在apisever中进行
          if (response.data.is_exist) {
            settokenValid(true);
            clearInterval(checkClosed);
          }
      });
    }, 2000);
    }
  }, [tokenValid]);

  useEffect(() => {
    if (sendSug > 0) {
      for (let i = 0; i < web.length; i++) {
        web[i]['uid'] = props.uid;
        const requestParams = web[i];
        axios({
          method: 'post',
          url: `${GongFengHOST}/api/v1/gongfeng/suggestion`,
          params: requestParams,
        }).then((response) => {
          if (response !== undefined) {
            Log.debug("sendSuggestion", response);
          }
        });
      }
    }
  }, [sendSug]);

  useEffect(() => {
    if (Object.keys(url).length !== 0) {
      const requestParams = {
        task_id: props.taskid,
        url: JSON.stringify(url),
      };
      axios({
        method: 'post',
        url: `${HOST_URL}/api/v1/ai/url`,
        params: requestParams,
      }).then((response) => {
        if (response !== undefined) {
          Log.debug("sendSuggestion", response);
        }
      });
    }
  }, [url]);

  // 单个选择条的通用处理
  function SelectInputSingle(OPTIONS, selectValue, setSelectValue) {
      const onChange = (selectValue) => {
      setSelectValue(selectValue);
      };
      return (<>
        <Select
          value={selectValue}
          onChange={onChange}
          style={{
          width: '270%',
          }}
          clearable
          options={OPTIONS}
        />
    </>);
  }

  // 所有选择框的处理
  function SelectInputAll() {
    // 得到token后，获取项目列表
    useEffect(() => {
      if (gongFengVisible) {
        // 这里放置你想要执行的操作
        const requestParams = {
          uid: props.uid,
        };
        axios({
          method: 'get',
          url: `${GongFengHOST}/api/v1/gongfeng/projects`,
          params: requestParams,
        }).then((response) => {
          if (response !== undefined & response.data.projects.length !== 0) {
            setProjects(response.data.projects);
          }
        });
      }
    }, [gongFengVisible]);

    // 项目选中时更新分支列表
    useEffect(() => {
      const requestParams = {
        projectID: selectProjectValue,
        uid: props.uid,
      };
      axios({
        method: 'get',
        url: `${GongFengHOST}/api/v1/gongfeng/branches`,
        params: requestParams,
      }).then((response) => {
        setBranches(response.data.branches);
      });
    }, [selectProjectValue]);

    // 分支选中时更新commit列表
    useEffect(() => {
      const requestParams = {
        projectID: selectProjectValue,
        branchName: selectBranchValue,
        uid: props.uid,
      };
      axios({
        method: 'get',
        url: `${GongFengHOST}/api/v1/gongfeng/commits`,
        params: requestParams,
      }).then((response) => {
        if (response !== undefined) {
          setCommits(response.data.commits);
        }
      });
    }, [selectBranchValue]);

    // commithash选中时更新跳转到页面
    useEffect(() => {
      // 更新相关业务文件
      const filelist = [];// 存放相关的业务文件
      for (let i = 0; i < props.suggestion.length; i++) {
        const index = props.suggestion[i]['path'].indexOf(Projects[selectProjectValue].name);
        if (index !== -1) { // 如果找到对应的文件
          const temp = props.suggestion[i]['path'].substring(index + 1 + Projects[selectProjectValue].name.length);
          const temp2 = temp.split(":");
          const requestParams = {
            projectID: selectProjectValue,
            commitSha: selectCommitValue,
            path: temp2[0],
            line: Number(temp2[1]),
            content: props.suggestion[i]['commits'],
          };
          filelist.push(requestParams);
        }
      }
      setweb(filelist);
    }, [selectCommitValue]);

    const projectsArray = Object.entries(Projects).map(([id, info]) => ({
      label: info.name_with_namespace,
      value: Number(id),
      updated_at: info.updated_at,
      name: info.name,
    }));
    projectsArray.sort((a, b) => new Date(b.updated_at) - new Date(a.updated_at));
    const projectOPTIONS = projectsArray.map(({ label, value }) => ({ label, value }));

    // 对项目、分支和commit进行排序，方便用户查找
    const OPTIONS = [];
    for (const [key, value] of Object.entries(Projects)) {
      OPTIONS.push({
        label: value,
        value: key,
      });
    }
    const branchOPTIONS = [];
    for (const [key, value] of Object.entries(Branches)) {
      branchOPTIONS.push({
        label: value,
        value: key,
      });
    }
    const commitsArray = Object.entries(Commits).map(([id, info]) => ({
      label: info.id,
      value: id,
      create_at: info.create_at
    }));
    commitsArray.sort((a, b) => new Date(b.create_at) - new Date(a.create_at));
    const commitOPTIONS = commitsArray.map(({ label, value }) => ({ label, value }));

    return <>
          <p>请选择相应的项目：</p>
          {[SelectInputSingle(projectOPTIONS, selectProjectValue, setSelectProjectValue)]}
          <p>请选择相应的分支：</p>
          {SelectInputSingle(branchOPTIONS, selectBranchValue, setSelectBranchValue)}
          <p>请选择相应的commit：</p>
          {SelectInputSingle(commitOPTIONS, selectCommitValue, setSelectCommitValue)}
          </>;
  }
  const onClickConfirm = () => {
    // 判断是否存留用户token。。
    if (!tokenValid) { // 为新用户，进行验证获取accesstoken
      // 认证界面，仅和域名相关，因此可以固定
      const loginUrl = `https://git.woa.com/oauth/authorize?client_id=be944b5259f346eda8ffb85ae8d69d13&redirect_uri=${location.origin}/gitlogin&response_type=code&state=${props.uid}`;
      new Promise(_resolve => window.open(loginUrl, '_blank')); // 在新窗口或新标签页中打开
    } else { // 不是新用户，进入工蜂跳转流程
      setgongFengVisible(true); // 打开工蜂仓库、分支、commit选择界面
    }
  };
  const sendSuggestion = () => {
    const temp = {};
    for (let i = 0;i < web.length & i < 5; i = i + 1) {
      const loginUrl = `https://git.woa.com/${props.username}/${Projects[selectProjectValue].name}/blob/${selectCommitValue}/${web[i]['path']}`;
      if (web[i]['path'] !== undefined & !(web[i]['path'] in temp)) {
        temp[web[i]['path']] = loginUrl;
        window.open(loginUrl, `${i}`);
      }
    }
    if (web.length > 0) {
      setsendSug(sendSug + 1);// 触发后端发送建议
      seturl(temp);
    } else {
      MessagePlugin.error('当前无相应的工蜂跳转链接');
      return;
    }
    setgongFengVisible(false);
  };


  return <Popconfirm
  style={{
    width: '600px',
  }}
  visible={gongFengVisible}
  content={ [SelectInputAll()]}
  destroyOnClose
  placement="top"
  onCancel={() => {
    setgongFengVisible(false);
  }}
  confirmBtn={
    <Button size={'small'} onClick={sendSuggestion}>
      确定
    </Button>
  }
  theme="default">
    <Button  style={{ marginBottom: '10px', marginLeft: '10px' }}
      className="gongfeng-button"
      size="small"
      theme= {props.existSuggestion === false ? "default" : "success"}
      icon={<SearchIcon />}
      onClick={onClickConfirm }
      disabled={!props.existSuggestion}
      >
      {
        props.existSuggestion === false ? '暂未生成AI建议，无法生成工蜂copilot修复代码' : '跳转到工蜂，查看AI修复代码'
      }
      </Button>
      </Popconfirm>;
};

GongFengButton.propTypes = {
  username: PropTypes.string,  // 用户名
  suggestion: PropTypes.array,  // AI建议
  existSuggestion: PropTypes.bool, // 是否已经生成AI建议（和按钮形态相关）
  taskid: PropTypes.string, // 任务id
  uid: PropTypes.string, // 用户id
};

export default GongFengButton;
