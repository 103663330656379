// diskTracingResult
import React, { useEffect, useState } from 'react';
import DiskTracingResultChart from './diskTracingResultChart';
import DiskTracingIOStatChart, { calcIoStat } from './diskTracingIoStatChart';
import DiskTracingIOTopChart, { calcIoTop } from './diskTracingIoTopChart';
import PropTypes from 'prop-types';
import {
  Card,
  Bubble,
  Button,
  Text,
  Icon,
  Segment,
  Status,
} from '@tencent/tea-component';
import { Tabs } from 'tdesign-react';
import { formatTime } from '../../utils/DateTime';
import { has } from '../../utils/ErrorConfirm';
import TaskList, { sortable } from '../../components/taskList';


const DiskTracingResult = (props) => {
  // hook
  const [diskEvent, setDiskEvent] = useState([]);
  const [eveneTime, setEveneTime] = useState();
  const [diskType, setDiskType] = useState(0);
  const [graphData, setGraphData] = useState([]);
  const [y, setY] = useState([]);
  const [diskTypeArray, setDiskTypeArray] = useState([]);
  const [maxEventNumber, setMaxEventNumber] = useState(0);

  // 将yData和resultData存储 以便调用
  const [xState, setXState] = useState([]);
  const [yState, setYState] = useState([]);
  const [resultDataState, setResultDataState] = useState([]);
  const [maxEventDataState, setMaxEventDataState] = useState([]);

  const [sorts, setSorts] = useState([]);

  const allComms = '__ALL__';
  const [selectedComms, setSelectedComms] = useState([]);
  const [comms, setComms] = useState([]);
  const [ioStatData, setIoStatData] = useState({});
  const [ioTopData, setIoTopData] = useState({});

  const max = (a, b) => (a > b ? a : b);

  // 处理数据
  const resultData = [];
  const yMax = [];
  const xData = []; // 横轴一致
  const yData = [];
  const indexData = {}; // 作为索引
  const diskTypeIndex = []; // 类型索引
  const maxEventData = [];

  for (let i = props.startTimeStamp; i <= props.endTimeStamp; ++i) {
    xData.push(formatTime(i, 1));
  }

  let filteredDiskEvents = diskEvent.slice();
  if (selectedComms.length > 0) {
    filteredDiskEvents = filteredDiskEvents.filter(x => selectedComms.indexOf(x.comm) > -1);
  }

  let haveSeqIOProp = false;
  if (filteredDiskEvents.length > 0 && 'sequential' in filteredDiskEvents[0]) {
    haveSeqIOProp = true;
  }

  // 遍历原数据
  useEffect(() => {
    props.diskData.map((item) => {
      Object.keys(item.disks).map((i) => {
        if (!has.call(indexData, i)) {
          // 不存在该属性 则先添加
          indexData[i] = resultData.length;
          diskTypeIndex.push(i);
          resultData.push([]);
          yData.push([]);
          yMax.push(0);
          maxEventData.push(0);
        }
        // 加载数据
        item.disks[i].histogram.map((histo) => {
          resultData[indexData[i]].push([
            item.timestamp - props.startTimeStamp,
            histo[0],
            histo[1],
          ]);
          yMax[indexData[i]] = max(yMax[indexData[i]], histo[0]);
          maxEventData[indexData[i]] = max(maxEventData[indexData[i]], histo[1]);
          return 0;
        });
        return 0;
      });
      return 0;
    });

    // 基于yMax填充y轴
    const time = ['μs', 'ms', 's'];
    for (let i = 0; i < yMax.length; ++i) {
      yData[i].push('0~1μs');
      for (let j = 1; j <= yMax[i] + 2; ++j) {
        if (j >= 31) { // BUG: 会无限循环
          j -= 20;
          const first = 2 ** (j - 1);
          const second = 2 ** j;
          yData[i].push(`${first}s~${second}s`);
        } else {
          const first = 2 ** ((j - 1) % 10);
          const second = 2 ** (j % 10);
          const firstTime = time[Math.floor((j - 1) / 10)];
          const secondTime = time[Math.floor(j / 10)];
          yData[i].push(`${first}${firstTime}~${second}${secondTime}`);
        }
      }
    }
    setY(yData[diskType]);
    setGraphData(resultData[diskType]);
    setDiskTypeArray(diskTypeIndex);
    setResultDataState(resultData);
    setMaxEventNumber(maxEventData[0]);
    setXState(xData);
    setYState(yData);
    setMaxEventDataState(maxEventData);

    setIoStatData(calcIoStat(props.diskData));
    setIoTopData(calcIoTop(props.diskData));
  }, []);

  // 基于点击情况显示数据
  const setEvent = (event) => {
    for (let i = 0; i < props.diskData.length; i++) {
      if (event[0] + props.startTimeStamp === props.diskData[i].timestamp) {
        // 根据展示类型确定数据
        setEveneTime(formatTime(props.diskData[i].timestamp, 1));
        const { events } = props.diskData[i].disks[diskTypeArray[diskType]];
        setDiskEvent(events);

        const commSet = new Set();
        Object.values(events).forEach(({ comm }) => {
          commSet.add(comm);
        });
        setComms([...commSet]);
        setSelectedComms([]);
        break;
      }
    };
  };

  const columns = [
    {
      key: 'toffset',
      align: 'center',
      render: ({ toffset }) => (
        <>
          <Text theme='weak'>第</Text>
          <Text theme='strong'>{toffset}</Text>
          <Text theme='weak'>μs</Text>
        </>
      ),
      header: () => (
        <>
          时间偏移量
          <Bubble content='距离当前秒开始时刻的时间偏移'>
            <Icon type="help" />
          </Bubble>
        </>
      ),
    },
    {
      header: '进程名',
      key: 'comm',
      align: 'center',

    },
    {
      header: 'PID',
      key: 'pid',
      align: 'center',
    },
    {
      header: '读写标记',
      key: 'rwflag',
      align: 'center',
      render: ({ rwflag }) => {
        let event = '';
        switch (rwflag) {
          case 'R':
            event = '读';
            break;
          case 'W':
            event = '写';
            break;
          default:
            event = rwflag;
        }
        return event;
      },
    },
    {
      header: '扇区',
      key: 'sector',
      align: 'center',
    },
    {
      header: 'IO大小',
      key: 'bytes',
      align: 'center',
      render: ({ bytes }) => {
        const base = 1024;
        const decimals = 2; // 保留2位小数

        const units = ['B', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

        let digit;
        if (bytes === 0) {
          digit = 0;
        } else {
          digit = Math.floor(Math.log(bytes) / Math.log(base));
        }

        const value = parseFloat((bytes / base ** digit).toFixed(decimals));
        const unit = units[digit];

        return (
          <>
            <Text theme='strong'>{value}</Text>
            <Text theme='weak'>{unit}</Text>
          </>
        );
      },
    },
    {
      header: '排队时延',
      key: 'qdelta',
      align: 'center',
      render: ({ qdelta }) => (
        <>
          <Text theme='strong'>{parseFloat(qdelta).toFixed(3)}</Text>
          <Text theme='weak'>ms</Text>
        </>
      ),
    },
    {
      header: 'IO操作延迟',
      key: 'delta',
      align: 'center',
      render: ({ delta }) => (
        <>
          <Text theme='strong'>{parseFloat(delta).toFixed(3)}</Text>
          <Text theme='weak'>ms</Text>
        </>
      ),
    },
  ];

  const [isSortedByOffset, setIsSortedByOffset] = useState(true);
  if (isSortedByOffset && haveSeqIOProp) {
    columns.push({
      header: '顺序IO',
      key: 'sequential',
      align: 'center',
      render: ({ sequential }) => (
        <Text theme='strong'> {sequential === 1 ? '是' : '否'}</Text>
      ),
    });
  }

  const titleDetails = [];
  let randomIOCnt = 0;
  let sumDelta = 0;
  let maxDelta = 0;
  let minDelta = Infinity;
  let readCnt = 0;
  let writeCnt = 0;
  filteredDiskEvents.forEach((e) => {
    // TODO: 分析程序是number，但这里是string。需确定原因
    const delta = parseFloat(e.delta);
    sumDelta += delta;

    delta > maxDelta && (maxDelta = delta);
    delta < minDelta && (minDelta = delta);

    haveSeqIOProp && e.sequential === 0 && (randomIOCnt += 1);

    e.rwflag === 'R' && (readCnt += 1);
    e.rwflag === 'W' && (writeCnt += 1);
  });

  titleDetails.push(`事件数：读${readCnt}，写${writeCnt}`);

  const avgDelta = (sumDelta / filteredDiskEvents.length).toFixed(3);
  titleDetails.push(`操作延迟：平均${avgDelta}ms，最大${maxDelta}ms，最小${minDelta}ms`);
  if (haveSeqIOProp) {
    const randomIOPct = ((randomIOCnt / filteredDiskEvents.length) * 100).toFixed(2);
    titleDetails.push(`随机IO占比${randomIOPct}%`);
  }

  const cardTitle = `${eveneTime} 磁盘I/O事件详情 (${titleDetails.join('；')})`;

  function onSorted(sorts) {
    if (sorts.length === 0) {
      setIsSortedByOffset(true);
    } else {
      if (sorts[0].by === 'toffset' && sorts[0].order === 'asc') {
        setIsSortedByOffset(true);
      } else {
        setIsSortedByOffset(false);
      }
    }
    setSorts(sorts);
  }

  return <>
    <Card.Body
      operation={props.showGobackButton ? <Button type='link' onClick={() => props.onClick()}>返回</Button> : ''}
    >
      <Segment
        value={diskType}
        onChange={(value) => {
          setDiskType(value);
          setY(yState[value]);
          setMaxEventNumber(maxEventDataState[value]);
          setGraphData(resultDataState[value]);
        }}
        defaultValue={0}
        options={
          diskTypeArray.map((i, index) => ({
            text: i,
            value: index,
          }))
        }
      />
      <div style={{ paddingBottom: 20 }} />

      <Tabs
        theme='normal'
        placement={'top'}
        defaultValue='heatmap'
      >
        <Tabs.TabPanel
          value='heatmap'
          label='磁盘I/O延迟热图'
          destroyOnHide={false}
        >
          <DiskTracingResultChart
            setEvent={setEvent}
            startTimeStamp={props.startTimeStamp}
            endTimeStamp={props.endTimeStamp}
            xData={xState}
            yData={y}
            maxEventNumber={maxEventNumber}
            graphData={graphData}
          />
          {(filteredDiskEvents.length !== 0) ? <TaskList
            columns={columns}
            cardTitle={cardTitle}
            records={[...filteredDiskEvents].sort(sortable.comparer(sorts))}
            pageable={{
              pageSizeVisible: true,
              pageCountChangingResetType: 'first',
            }}
            sortable={{
              columns: [
                {
                  key: 'toffset',
                  prefer: 'asc',
                },
                'bytes',
                'qdelta',
                'delta',
              ],
              value: sorts,
              onChange: value => onSorted(value),
            }}
            filterable={{
              type: 'multiple',
              column: 'comm',
              value: selectedComms,
              onChange: value => setSelectedComms(value),
              all: {
                value: allComms,
                text: '全部',
              },
              searchable: true,
              options: comms.map(comm => ({ value: comm })),
            }}
          ></TaskList>
            : <Status
              icon='chart'
              size='m'
              title='暂无数据'
              description="点击图块查看详细事件信息"
            />
          }
        </Tabs.TabPanel>

        <Tabs.TabPanel
          value='iostat'
          label='磁盘 I/O 统计图表'
          destroyOnHide={false}
        >
          <DiskTracingIOStatChart
            data={ioStatData[diskTypeArray[diskType]]}
          />
        </Tabs.TabPanel>

        <Tabs.TabPanel
          value='iotop'
          label='进程 I/O Top 列表'
          destroyOnHide={false}
        >
          <DiskTracingIOTopChart
            data={ioTopData[diskTypeArray[diskType]]}
          />
        </Tabs.TabPanel>
      </Tabs>
    </Card.Body>
  </>;
};

DiskTracingResult.propTypes = {
  diskData: PropTypes.array,
  startTimeStamp: PropTypes.number,
  endTimeStamp: PropTypes.number,
  onClick: PropTypes.func,
  showGobackButton: PropTypes.bool,
};

export default DiskTracingResult;
