import 'tdesign-react/es/style/index.css';
import './d3-flamegraph/flamegraph.css';
import './flamegraph.css';
import React, { memo, useEffect, useState } from 'react';
import * as pako from 'pako';
// import * as gzip from 'gzip-js';
// import * as zlib from 'zlib';
import PropTypes from 'prop-types';
import DropActionCreator from '@/api/DropActions';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { has } from '@/utils/ErrorConfirm';
import * as d3 from 'd3';
import { Icon } from 'tdesign-icons-react';
import {
  Space,
  Tag,
  Switch,
  Select,
  Row,
  Col,
  Tooltip as TDTooltip,
  MessagePlugin,
  Loading,
} from 'tdesign-react';
import * as d3fg from './d3-flamegraph';
import { vintageRainbow, name2ColorScheme } from './d3-flamegraph/colorScheme';
import { Tooltip } from './tooltip';
import { OpInput } from './opInput';
import { Log } from '@/utils/Log';

const flameGraph = d3fg
  .flamegraph()
  .width(800)
  .cellHeight(25)
  .transitionDuration(200)
  .inverted(true);

const toolTip = {
  disabled: false,
  onShow: null,
  onHide: null,
};

toolTip.hide = function (n, event, elem) {
  if (this.disabled) {
    return;
  }

  if (!event) {
    return;
  }

  const domRect = elem.getBoundingClientRect();
  if (event.clientX > domRect.left
    && event.clientX < domRect.right
    && event.clientY > domRect.top
    && event.clientY < domRect.bottom) {
    return;
  }

  this.onHide(n);
};

toolTip.show = function (n, e, elem) {
  if (this.disabled) {
    return;
  }

  const domRect = elem.getBoundingClientRect();
  const y = domRect.top + (domRect.bottom - domRect.top) / 2;
  // const top = `${domRect.bottom - 2}px`;
  const top = `${y}px`;
  const left = `${e.clientX + 3}px`;

  this.onShow(n, left, top);
};

function formatOp(op) {
  const fields = [];
  if (op.length >= 1) fields.push(<span key='0'>{op[0]}</span>);
  if (op.length >= 2) fields.push(<span key='1'>{op[1]}</span>);

  if (op.length >= 3) {
    if (op[1] === 'top') {
      fields.push(<span key='2' style={{ fontWeight: 'bold' }}>{op[2]}</span>);
    } else {
      if (op.length >= 4) {
        if (op[2] === 'tid') {
          fields.push(<span key='3'>
            <span style={{ fontWeight: 300 }}>{`${op[2]}:`}</span>
            <span style={{ fontWeight: 'bold' }}>{`${op[3]}`}</span>
          </span>);
        } else {
          fields.push(<span key='3'>
            <span style={{ fontWeight: 300 }}>{`${op[2]}:`}</span>
            <span style={{ fontWeight: 'bold' }}>{`"${op[3]}"`}</span>
          </span>);
        }
      }
    }
  }

  return (
    <Space style={{ gap: '5px' }}>
      {fields.map(field => field)}
    </Space >
  );
}

const searcher = {
  check: (list, field) => {
    if (list.length === 0) {
      return (
        field.startsWith('func:')
        || field.startsWith('process:')
        || field.startsWith('tid:')
        || field.startsWith('module:'));
    }
    return true;
  },
  desc: (
    <>
      <p><b><code>{'<attr:value>'}</code></b></p>
      <p>attr包括process、module、tid、func。</p>
      <p>process、module、func的value可指定其子串。</p>
      <p>tid的value指定具体线程ID。</p>
    </>
  ),
  list: null,
};

const stackMatcher = {
  name: 'stacks',
  desc: (
    <>
      <p><b><code>{'stacks <attr:value>'}</code></b></p>
      <p>匹配{'<attr:value>'}指定帧的调用栈</p>
    </>
  ),
  next: searcher,
};

const calleesMatcher = {
  name: 'callees',
  desc: (
    <>
      <p><b><code>{'callees <attr:value>'}</code></b></p>
      <p>匹配{'<attr:value>'}指定帧的所有子帧（被调用者）</p>
    </>
  ),
  next: searcher,
};

const callersMatcher = {
  name: 'callers',
  desc: (
    <>
      <p><b><code>{'callers <attr:value>'}</code></b></p>
      <p>匹配{'<attr:value>'}指定帧的所有父帧（调用者）</p>
    </>
  ),
  next: searcher,
};

const framesMatcher = {
  name: 'frames',
  desc: (
    <>
      <p><b><code>{'frames <attr:value>'}</code></b></p>
      <p>匹配{'<attr:value>'}指定所有帧</p>
    </>
  ),
  next: searcher,
};

const topMatcher = {
  name: 'top',
  desc: (
    <>
      <p><b><code>{'top <rank>'}</code></b></p>
      <p>匹配 total-time 排名前 rank 的帧</p>
    </>
  ),
  next: {
    check: (list, field) => {
      if (list.length === 0) {
        return /^[1-9]\d*$/.test(field);
      }
      return true;
    },
    desc: (
      <>
        <p><b><code>{'<rank>'}</code></b></p>
        <p>指定 total-time 排名</p>
      </>
    ),
    list: [
      { name: '5' },
      { name: '10' },
      { name: '15' },
    ],
  },
};

const allMarcher = {
  check: list => list.length !== 0,
  desc: (
    <>
      <p><b><code>{'<matcher> <[attr:]value>'}</code></b></p>
      <p>匹配器用来某个帧的相关帧，包括：调用栈<code>stacks</code>，被调用栈<code>callees</code>，调用栈<code>callers</code>、执行时间TopN的帧。</p>
    </>
  ),
  list: [
    stackMatcher,
    calleesMatcher,
    framesMatcher,
    callersMatcher,
    topMatcher,
  ],
};

const showOp = {
  name: 'show',
  desc: (
    <>
      <p><b><code>{'show <matcher> <[attr:]value>'}</code></b></p>
      <p>显示匹配到的所有帧</p>
    </>
  ),
  next: allMarcher,
};

const hideOp = {
  name: 'hide',
  desc: (
    <>
      <p><b><code>{'hide <matcher> <[attr:]value>'}</code></b></p>
      <p>隐藏匹配到的所有帧</p>
    </>
  ),
  next: allMarcher,
};

const highlightOp = {
  name: 'highlight',
  desc: (
    <>
      <p><b><code>{'highlight <matcher> <[attr:]value>'}</code></b></p>
      <p>高量匹配到的所有帧</p>
    </>
  ),
  next: allMarcher,
};

const focusOp = {
  name: 'focus',
  desc: (
    <>
      <p><b><code>{'focus frames <[func]:value>'}</code></b></p>
      <p>聚焦某个函数</p>
    </>
  ),
  next: {
    check: list => list.length !== 0,
    desc: (
      <>
        <p><b><code>{'<matcher> <[attr:]value>'}</code></b></p>
        <p>匹配器用来某个帧的相关帧，包括：调用栈<code>stacks</code>，被调用栈<code>callees</code>，调用栈<code>callers</code>、执行时间TopN的帧。</p>
      </>
    ),
    list: [
      framesMatcher,
    ],
  },
};

const opAutoComplement = {
  name: '',
  desc: '',
  next: {
    check: list => list.length !== 0,
    desc: (
      <>
        <b><code>{'<op> <matcher> <[attr:]value>'}</code></b>
        <p>
          操作用来过滤、聚焦、高亮火焰图。</p>
        <p>
          操作由操作名、匹配器、以及帧属性组成。
        </p>
        <p>
          匹配器用来某个帧的相关帧，包括：调用栈<code>stacks</code>，被调用栈<code>callees</code>，调用栈<code>callers</code>、执行时间TopN的帧。</p>
        <p>
          帧属性用来查询帧，可用的属性包括：函数名<code>func</code>、进程名<code>process</code>、模块<code>module</code>、线程ID<code>tid</code>
        </p>
        <p>
          操作的工作过程为：通过<code>{'<[attr]:value>'}</code>查询出一些帧，然后通过<code>{'<matcher>'}</code>匹配相关帧，最后在匹配到的所有帧上执行
          <code>{'<op>'}</code>操作
        </p>
      </>
    ),
    list: [
      focusOp,
      showOp,
      hideOp,
      highlightOp,
    ],
  },
};

// const hideIdleProcessOp = ['hide', 'frames', 'tid', 0];

function mergeData(oriData, oriContainerList, showContainerName) {
  if (!oriData) return null;

  const data = { ...oriData };
  if (!data.children) return data;

  let containerList = [];
  if (oriContainerList.length === 0) {
    containerList.push('');
  } else {
    containerList = oriContainerList;
  }
  const resultData = {};
  containerList.map((value) => {
    resultData[value] = {
      value: 0,
      tid: data.tid,
      process: data.process,
      module: data.module,
      func: data.func,
      container_name: value,
      children: [],
    };
    return 0;
  });
  const stack = [data];
  let n = null;
  while (n = stack.pop()) {
    if (!n.children) {
      continue;
    }

    const mergeMap = {};
    const { children } = n;

    for (let i = 0; i < children.length; ++i) {
      const child = children[i];
      if (!child.container_name) {
        child.container_name = '';
      }
      const key = [child.process, child.func, child.container_name].join('\x1E');
      if (!mergeMap[key]) {
        const base = {};
        base.func = child.func;
        base.process = child.process;
        base.module = child.module;
        base.container_name = child.container_name;
        base.tid = 1; // 将tid设置为定值
        base.value = 0;
        mergeMap[key] = base;
      }

      const base = mergeMap[key];
      base.value += child.value;
      const baseLines = base.module.split('\n');
      const childModules = child.module.split('\n');

      childModules.forEach((childModule) => {
        if (!baseLines.includes(childModule.trim())) {
          base.module = `${base.module.trim()}\n${childModule.trim()}`;
        }
      });

      if (!child.children) continue;

      if (base.children) {
        base.children.push(...child.children);
      } else {
        base.children = [...child.children];
      }

      stack.push(base);
    }

    const newChildren = Object.values(mergeMap);
    if (newChildren.length > 0) {
      n.children = newChildren;
    }
  }
  const { children } = data;
  for (let i = 0; i < children.length; ++i) {
    if (children[i].container_name !== '') {
      resultData[children[i].container_name].children.push(children[i]);
      resultData[children[i].container_name].value += children[i].value;
    }
    resultData[showContainerName].children.push(children[i]);
    resultData[showContainerName].value += children[i].value;
  }
  return resultData;
}

// function stringToUint8Array(str) {
//   // return new TextEncoder().encode(str);
//   const charData = str.split('').map(x => x.charCodeAt(0));
//   // Turn number array into byte-array
//   return new Uint8Array(charData);
// }

function Flamegraph(props) {
  Log.debug('FlameGraph', props);
  const [ops, setOps] = useState([]);
  const [isSort, setIsSort] = useState(false);
  const [isInited, setIsInit] = useState(false);
  const [isGroupByTid, setIsGroupByTid] = useState(false);
  const [mergedData, setMergedData] = useState(null);
  const [oriData, setOriData] = useState(null);

  const [colorAttr, setColorAttr] = useState('process');
  const [colorScheme] = useState(vintageRainbow.name);
  const [containerList] = useState(Object.keys(props.containerList));
  const [containerNameList] = useState(initContainerNamelist(props.containerList));
  const [containerName, setContainerName] = useState((containerList.length === 1 && containerList[0] !== '') ? containerList[0] : '');
  const [containerOptions] = useState(initContainerOptions(props.containerList));
  const [loadingStatus, setLoadingStatus] = useState(false);

  function changeContainerInfoToObject(item) {
    const { containerList } = props;
    const data = {
      label: item,
      value: item,
      content: '',
      imageID: containerList[item] !== 1 ? containerList[item] : '1',
      pod: '',
    };
    if (item !== '' && containerList[item] !== 1) {
      // 容器
      if (typeof(containerList[item]) === 'string') {
        // 旧版本 containername -> imageID
        data.content =  <TDTooltip
            content={`镜像名: ${containerList[item]}`}
            placement="top-left"
            showArrow
            destroyOnClose>
          {item}
        </TDTooltip>;
      } else {
        // 新版本数据
        if (containerList[item][2] === '') {
        // docker
          data.content =  <TDTooltip
            content={`镜像名: ${containerList[item][0]}`}
            placement="top-left"
            showArrow
            destroyOnClose>
          {item}
        </TDTooltip>;
        } else {
          // k8s containerd
          // swap containerd info
          data.label = containerList[item][0];
          data.content =  <TDTooltip
              content={`所属Pod: ${containerList[item][1]}`}
              placement="top-left"
              showArrow
              destroyOnClose>
            {containerList[item][0]}
          </TDTooltip>;
          data.pod = containerList[item][1];
        }
      }
    } else {
      // host
      data.content =  <TDTooltip
            placement="top-left"
            showArrow
            destroyOnClose>
          全局
        </TDTooltip>;
    }
    return data;
  }

  // 将容器相关数据转换成option数据
  function initContainerNamelist(containerList) {
    const res = [];
    for (const item in containerList) {
      res.push(changeContainerInfoToObject(item));
    }
    return res;
  }

  function initContainerOptions(containerList) {
    const containerOption = {};
    for (const item in containerList) {
      containerOption[item] = changeContainerInfoToObject(item);
    }
    return containerOption;
  }


  function sortWithValue(enable) {
    setIsSort(enable);
  }

  function hideMinFrame(hidden) {
    if (hidden) {
      flameGraph.updateMinFrameSize(5);
    } else {
      flameGraph.updateMinFrameSize(1);
    }
  }

  function onOpInputFocus() {
    toolTip.hide();
    toolTip.disabled = true;
  }

  function addOps(op) {
    if (op[0] === 'focus' && op[2] !== 'func') {
      MessagePlugin.error('focus 只允许聚焦单个函数');
      return;
    }
    // NOTE: focus 操作始终最后执行
    if (ops.length !== 0 && ops[ops.length - 1][0] === 'focus') {
      if (op[0] === 'focus') {
        MessagePlugin.warning('只允许存在一个聚焦操作。请删除聚焦操作，重新选择聚焦函数');
        return;
      }
      setOps(old => [...old.slice(0, -1), op, ops[ops.length - 1]]);
    } else {
      setOps(old => [...old, op]);
    }
  }

  function onOpInputFinish(expr) {
    addOps(expr);
  }

  const [tooltipOptions, setToolltipOptions] = useState({ isShow: false });

  toolTip.onHide = () => {
    setToolltipOptions({
      isShow: false,
    });
  };

  toolTip.onShow = (n, left, top) => {
    setToolltipOptions({
      isShow: true,
      left,
      top,
      node: n,
      fg: flameGraph,
    });
  };

  function setSearcherComplementList() {
    const list = [];
    const attrKeys = ['func', 'process', 'module'];
    if (isGroupByTid) {
      attrKeys.push('tid');
    }
    attrKeys.forEach((key) => {
      const attrs = flameGraph.topAttrs(key, 100);
      for (const value of attrs) {
        list.push({ name: `${key}:${value}` });
      }
    });
    searcher.list = list;
  }

  useEffect(() => {
    const handleScroll = () => toolTip.hide();
    window.addEventListener('scroll', handleScroll);
    return window.removeEventListener('scroll', handleScroll);
  });

  // 获取调用图数据
  useEffect(() => {
    const params = { dataURL: props.flamegraphURL };
    const url = new URL(props.flamegraphURL);
    if (url.pathname.endsWith('.json.gz')) {
      params.config = {
        responseType: 'arraybuffer',
      };
    }
    props.DropAction.getFlamegraphData(params);
    setLoadingStatus(true);
  }, []);

  useEffect(() => {
    let data = props.DropReducer.GetFlamegraphData;
    const url = new URL(props.flamegraphURL);
    if (url.pathname.endsWith('.json.gz') && data.data) {
      const dataBuf = pako.inflate(data.data, { level: 9 /* python gzip 压缩的默认级别 */ });
      data = JSON.parse(new TextDecoder().decode(dataBuf));
    }
    if (Object.keys(data).length === 0) {
      return;
    }
    if (has.call(data, 'code') && data.code === 'error') {
      console.error('get flamegraph data error:', data.msg);
      return;
    }

    if (isInited) return;
    setIsInit(true);

    const mergedData = mergeData(data, containerList, containerName);
    const box = d3.select('#flamegraph');
    const rect = box.node().getBoundingClientRect();
    const { width } = rect;
    flameGraph
      .width(width)
      .minFrameSize(5)
      .tooltip(toolTip)
      .setColorScheme(vintageRainbow('process'));
    box.datum(mergedData[containerName])
      .call(flameGraph);

    setOriData(data);
    setMergedData(mergedData);

    setSearcherComplementList();

    setLoadingStatus(false);
  }, [props.DropReducer.GetFlamegraphData]);

  useEffect(() => {
    flameGraph.exec([...ops, ['sort', isSort]]);
    setSearcherComplementList();
  }, [ops]);

  useEffect(() => {
    flameGraph.exec([...ops, ['sort', isSort]]);
  }, [isSort]);

  function execOp(node, val) {
    const op = val.split(' ');
    op.push('func', node.data.func);

    addOps(op);

    setToolltipOptions({
      isShow: false,
    });
  }

  // function onColorSchemeChange(schemeName) {
  //   // NOTE: 使用字符串而非函数是为了解决: Warning: Functions are not valid as a React child.
  //   // This may happen if you return a Component instead of <Component /> from render.
  //   // Or maybe you meant to call this function rather than return it.
  //   setColorScheme(schemeName);

  //   let scheme = name2ColorScheme(schemeName);
  //   if (!scheme) { // 如果没有此scheme，则设置默认schme
  //     console.error('invalid color scheme', schemeName);
  //     scheme = vintageRainbow;
  //   }
  //   flameGraph.updateColorScheme(scheme(colorAttr));
  // }

  function onColorAttrChange(attr) {
    setColorAttr(attr);

    let scheme = name2ColorScheme(colorScheme);
    if (!scheme) {
      console.error('invalid color scheme', colorScheme);
      scheme = vintageRainbow;
    }
    flameGraph.updateColorScheme(scheme(attr));
  }

  // const [isHideIdle, setIsHideIdle] = useState(false);

  // function hideIdleProcess(hidden) {
  //   setIsHideIdle(hidden);
  //   if (hidden) {
  //     if (ops.find(op => op === hideIdleProcessOp)) {
  //       return;
  //     }
  //     addOps(hideIdleProcessOp);
  //   } else {
  //     setOps(ops.filter(op => op !== hideIdleProcessOp));
  //   }
  // }

  function handleGroupByTid(enabled) {
    if (enabled) {
      flameGraph.update(oriData);
    } else {
      flameGraph.update(mergedData[containerName]);
      colorAttr === 'tid' && onColorAttrChange('process');
    }
    setOps(old => [...old]);
    setIsGroupByTid(enabled);
  }

  function colorSchemeOptions() {
    const opts = [
      { label: '进程着色', value: 'process' },
      { label: '模块着色', value: 'module' },
    ];
    if (isGroupByTid) {
      opts.push({ label: '线程着色', value: 'tid' });
    }
    return opts;
  }

  function closeTag(id) {
    setOps(ops.filter((_, i) => i !== id));
  }

  const onContainerNameChange = (v) => {
    // const box = d3.select('#flamegraph');
    // box.datum(mergedData[v])
    //   .call(flameGraph);
    flameGraph.update(mergedData[v]);
    setContainerName(v);
  };

  return (
    <>
      <Tooltip
        options={tooltipOptions}
        onClick={execOp}
        isGroupByTid={isGroupByTid}
      />
      <div style={{ width: '100%' }}>
          { (containerList.length !== 0)
            ? <Row
          gutter={16}
          align='middle'>
            <Col span={5} >
              <TDTooltip content='基于容器名进行过滤,可根据容器名、镜像名、Pod名搜索'>
                <Select
                  value={containerName}
                  onChange={v => onContainerNameChange(v)}
                  options={containerNameList}
                  filterable
                  filter={(search, option) => option.label.indexOf(search) !== -1
                    || option.imageID.indexOf(search) !== -1 || option.pod.indexOf(search) !== -1 }
                ></Select>
              </TDTooltip>
            </Col>
            <Col span={7} flex={1}>
              {(containerName === '') ? '未选择容器,可在左侧选择'
                : `容器名: ${containerOptions[containerName].label} 
              所属Pod: ${containerOptions[containerName].pod ? containerOptions[containerName].pod : '无'}`}
            </Col>
            </Row> : ''
          }
        <Row gutter={16} style={{
          marginBottom: '10px',
        }}>
          {
            ops && ops.length !== 0
            && ops.map((cmd, id) => (
              <Col key={id}>
                <Tag
                  shape="round"
                  closable
                  onClose={() => {
                    // if (cmd === hideIdleProcessOp) {
                    //   hideIdleProcess(false);
                    // } else {
                    closeTag(id);
                    // }
                  }}
                >
                  {formatOp(cmd)}
                </Tag>
              </Col>
            ))
          }
        </Row>
        <Row
          gutter={16}
          align='middle'
          style={{
            marginBottom: '10px',
          }}>
          <Col flex={20}>
            <OpInput
              autoComplement={opAutoComplement}
              onFinish={op => onOpInputFinish(op)}
              onFocus={() => onOpInputFocus()}
              onBlur={() => toolTip.disabled = false}
            />
          </Col>
          {/*
            isGroupByTid && <Col flex={1}>
              <TDTooltip content=''>
                <Switch
                  value={isHideIdle}
                  label={[
                    <>隐藏Idle进程<Icon name="check" /></>,
                    <>隐藏Idle进程<Icon name="close" /></>,
                  ]}
                  size="large"
                  onChange={val => hideIdleProcess(val)}
                />
              </TDTooltip>
            </Col>
          */}
          <Col flex={1}>
            <TDTooltip content='隐藏宽度很小的帧'>
              <Switch
                defaultValue={true}
                label={[
                  <>隐藏小帧<Icon name="check" /></>,
                  <>隐藏小帧<Icon name="close" /></>,
                ]}
                size="large"
                onChange={val => hideMinFrame(val)}
              />
            </TDTooltip>
          </Col>
          <Col flex={1}>
            <TDTooltip content='对每个帧调用的帧按照total-time从大到小排序'>
              <Switch
                label={[
                  <>排序<Icon name="check" /></>,
                  <>排序<Icon name="close" /></>,
                ]}
                size="large"
                onChange={val => sortWithValue(val)}
              />
            </TDTooltip>
          </Col>
          <Col flex={1}>
            <TDTooltip content='按照线程划分火焰图的堆栈'>
              <Switch
                value={isGroupByTid}
                label={[
                  <>按线程分组<Icon name="check" /></>,
                  <>按线程分组<Icon name="close" /></>,
                ]}
                size="large"
                onChange={val => handleGroupByTid(val)}
              />
            </TDTooltip>
          </Col>
          {/*
          <Col flex={2}>
            <TDTooltip content='选择一个颜色方案来渲染火焰图'>
              <Select
                value={colorScheme}
                onChange={v => onColorSchemeChange(v)}
                options={[
                  {
                    label: '颜色方案1',
                    value: vintageRainbow.name,
                    content:
                      <div className="color-box">
                     {
                     vintageRainbow.colors.map((c, i) => (<div key={i} className='c' style={{ background: c }}></div>))
                     }
                      </div>,
                  },
                  {
                    label: '颜色方案2',
                    value: satisfyingSenses.name,
                    content:
                      <div className="color-box">
                    {
                    satisfyingSenses.colors.map((c, i) => (<div key={i} className='c' style={{ background: c }}></div>))
                    }
                      </div>,
                  },
                ]}
              ></Select>
            </TDTooltip>
          </Col>
           */
          }
          <Col flex={1}>
            <TDTooltip content='根据不同属性渲染火焰图，以醒目的区分帧的类别'>
              <Select
                value={colorAttr}
                onChange={v => onColorAttrChange(v)}
                options={colorSchemeOptions()}
              ></Select>
            </TDTooltip>
          </Col>
        </Row>
        <Row>
          <div id="flamegraph"
            style={{ width: '100%' }}
          />
           <Loading
            style={{ width: '10%', margin: '10% 45%' }}
            loading={loadingStatus}
            preventScrollThrough={true}
            text="火焰图渲染中">
            </Loading>
        </Row>
      </div>
    </>
  );
}

Flamegraph.propTypes = {
  DropReducer: PropTypes.any,
  DropAction: PropTypes.any,

  flamegraphURL: PropTypes.string,
  containerList: PropTypes.object,
};

const mapStateToProps = (state) => {
  const { DropReducer } = state;
  return {
    DropReducer,
  };
};// Reducer

const mapDispatchToProps = (dispatch) => {
  const DropAction = bindActionCreators(DropActionCreator, dispatch);

  return {
    DropAction,
  };
};// Action

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(memo(Flamegraph));
