import React, { useEffect, useState } from 'react';
import { Table, Card, Button, SearchBox, Icon, Tag } from '@tencent/tea-component';
import PropTypes from 'prop-types';
const { pageable, autotip  } = Table.addons;
// 受控组件
const IndexAgentList = (props) => {
  const [keyword, setKeyword] = useState('');
  const [agents, setAgents] = useState([]);
  const [oldAgents, setOldAgents] = useState('');
  const [loading, setLoading] = useState(false);
  const [inputKeyword, setInputKeyword] = useState('');

  useEffect(() => {
    setInputKeyword(keyword);
    if (!oldAgents || props.agents.length === 0) {
      return ;
    }
    setLoading(true);
    setTimeout(() => {
      let tempAgent = props.agents;
      if (keyword) {
        tempAgent = tempAgent.filter(x => x.hostname.includes(keyword)
            || x.ipaddr.includes(keyword));
      }
      setAgents(tempAgent);
      setOldAgents(props.agents.toString());
      setLoading(false);
    }, 800);
  }, [keyword]);

  useEffect(() => {
    if (oldAgents !== props.agents.toString()) {
      let tempAgent = props.agents;
      if (keyword) {
        tempAgent = tempAgent.filter(x => x.hostname.includes(keyword)
            || x.ipaddr.includes(keyword));
      }
      setAgents(tempAgent);
      setOldAgents(props.agents.toString());
    }
  }, [props.agents]);


  const columns = [{
    key: 'instance',
    header: 'ID/实例名',
    width: 200,
    render: cvm => (
        <div style={{ height: '40px' }}>
          <img style={{ width: '30px', height: '30px', padding: '0 5px 10px 0' }} src={cvm.online
            ? 'online.png' : 'offline.png'}></img>
          <div style={{ display: 'inline-block' }} >
          <p style={{ margin: '0px' }}>{cvm.hostname}{(cvm.group_id) ? '(共享)' : ''}</p>
          <p style={{ margin: '0px' }}>{cvm.ipaddr}</p>
          </div>
        </div>
    ),
  },
  {
    key: 'version',
    header: 'agent版本',
    width: 200,
    render: agent => (agent.version ? `agent版本: v${agent.version}`
      : <Tag  theme="warning">agent版本过低请及时升级</Tag>),
  },
  {
    key: 'status',
    header: '状态',
    width: 200,
    render: (cvm) => {
      if (cvm.online) {
        return  <p>在线<Icon type="success" /></p>;
      }
      if (!cvm.online) {
        return <p>离线<Icon type="error" /></p>;
      }
      return cvm.status;
    },
  },
  {
    key: 'action',
    header: '操作',
    render: cvm => <>
          <Button type = 'link' style={{ marginLeft: '100px' }} onClick={ () => props.viewTask(cvm) }>查看任务</Button>
          {/* <Button disabled = {!cvm.online} tooltip={ !cvm.online ? '机器已离线' : '' }
            type = 'link' style={{ marginLeft: '50px' }}
            onClick={ () => props.statAgent(cvm) }>监控</Button> */}
          <Button disabled = {cvm.group_id || cvm.online } tooltip={(cvm.group_id || cvm.online) ? '无法移除共享机器和在线机器' : ''}
           type = 'link' style={{ marginLeft: '50px' }} onClick={ () => props.removeAgent(cvm) }>
            移除
          </Button>
        </>,
  },
  ];

  return (
      <Card style = { props.style }>
        <Card.Body
          title="机器列表"
          style={{ padding: '20px 10px 0 20px' }}
          subtitle={
            <>
            <Button style={{ marginLeft: '20px' }} type = 'link' onClick={ () => window.location.reload() }>接入机器后请刷新页面
              </Button>
              <Button style={{ marginLeft: '20px' }} type = 'link' onClick={ () => props.onAccess() }>快速接入指引
              </Button>
              <Button type = 'link' onClick={ () => props.onAgent() }>机器列表详情</Button>
            </>
          }
          operation = {
              <>
                <SearchBox
                   value={ inputKeyword }
                   onChange = { inputKeyword => setInputKeyword(inputKeyword) }
                   onSearch={keyword => setKeyword(keyword)}
                   onClear={() => setKeyword('')}
                   placeholder="输入机器名或IP查询"
                  />
              </>
          }
        >
        {
          <Table
          columns = { columns }
          records = { agents }
          hideHeader
          compact
          verticalTop
          addons={[
            pageable({
              pageSize: 2,
              pageSizeVisible: false,
            }),
            autotip({
              isLoading: loading,
              isFound: Boolean(keyword),
              onClear: () => setKeyword(''),
              foundKeyword: keyword,
              emptyText: '暂未接入机器,请按照指引接入',
            }),
          ]}
        ></Table> }
        </Card.Body>
      </Card>
  );
};

IndexAgentList.propTypes = {
  agents: PropTypes.array, // agent信息
  onSearchAgent: PropTypes.func,  // 搜索agent
  onAccess: PropTypes.func, // 快速接入指引
  onAgent: PropTypes.func,  // 机器列表详情
  viewTask: PropTypes.func,  // 查看任务
  statAgent: PropTypes.func,  // 监控
  style: PropTypes.object, // 样式
  getAgents: PropTypes.func, // 获取agents
  removeAgent: PropTypes.func, // 移除agent
};

export default IndexAgentList;
