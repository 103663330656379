import React, { useEffect, useRef } from 'react';
import * as echarts from 'echarts';
import PropTypes from 'prop-types';
import { Divider, Space } from 'tdesign-react';
import { formatTime } from '../../utils/DateTime';
import { formatByte } from './util';
import SimpleBar from 'simplebar-react';
import 'simplebar-react/dist/simplebar.min.css';

function segmentUnionLength(seg) {
  const n = seg.length;

  const points = new Array(2 * n);
  for (let i = 0; i < n; i++) {
    points[i * 2] = [seg[i][0], false];
    points[i * 2 + 1] = [seg[i][1], true];
  }

  points.sort((a, b) => a[0] - b[0]);

  let result = 0;
  let Counter = 0;

  for (let i = 0; i < n * 2; i++) {
    if (Counter) result += points[i][0] - points[i - 1][0];

    if (points[i][1]) {
      Counter = Counter - 1;
    } else {
      Counter = Counter + 1;
    }
  }
  return result;
}

export function calcIoStat(diskData) {
  diskData.sort((a, b) => a[0] - b[0]);

  const iostat = [];
  diskData.forEach((item) => {
    const { timestamp } = item;
    for (const disk in item.disks) {
      const diskValue = item.disks[disk];
      const rwMetrics = {
        R: {
          iops: 0,
          bytes: 0,
          await: 0,
          totalDelta: 0,
        },
        W: {
          iops: 0,
          bytes: 0,
          await: 0,
          totalDelta: 0,
        },
        RW: {
          aqusz: 0,
          util: 0,
          tsSegs: [],
        },
      };
      diskValue.events.forEach((event) => {
        const { rwflag } = event;

        rwMetrics[rwflag].iops += 1;
        rwMetrics[rwflag].bytes += event.bytes;
        rwMetrics[rwflag].totalDelta += event.qdelta + event.delta;

        const end = event.toffset / 1000; // 微妙转毫秒
        let start = end - (event.qdelta + event.delta);
        if (start < 0) start = 0; // 由于误差，可能导致结果小于0
        rwMetrics.RW.tsSegs.push([start, end]);
      }); // events

      const totalDelta = rwMetrics.R.totalDelta + rwMetrics.W.totalDelta;
      rwMetrics.RW.aqusz = totalDelta / 1000;
      rwMetrics.RW.util = segmentUnionLength(rwMetrics.RW.tsSegs) / 1000 * 100;
      for (const rwflag in rwMetrics) {
        const { iops } = rwMetrics[rwflag];
        const { totalDelta } = rwMetrics[rwflag];

        if (iops) rwMetrics[rwflag].await = totalDelta / iops;
      }

      if (!iostat[disk]) iostat[disk] = [];

      // padding
      if (iostat[disk].length > 1) {
        const preTimestamp = iostat[disk][iostat[disk].length - 1][0];
        for (let ts = preTimestamp + 1; ts < timestamp; ts += 1) {
          iostat[disk].push([ts, [0, 0], [0, 0], [0, 0], 0, 0]);
        }
      }

      iostat[disk].push([
        timestamp,
        [rwMetrics.R.iops, rwMetrics.W.iops],
        [rwMetrics.R.bytes, rwMetrics.W.bytes],
        [rwMetrics.R.await, rwMetrics.W.await],
        rwMetrics.RW.aqusz,
        rwMetrics.RW.util,
      ]);
    } // disks
  }); // times

  const newIostat = {};
  for (const disk in iostat) {
    if (!newIostat[disk]) newIostat[disk] = {};
    const diskData = iostat[disk];

    ['iops', 'bytes', 'await'].forEach((metricName, idx) => {
      newIostat[disk][metricName] = {
        R: diskData.map(item => [formatTime(item[0], 0), item[idx + 1][0]]),
        W: diskData.map(item => [formatTime(item[0], 0), item[idx + 1][1]]),
      };
    });

    newIostat[disk].aqusz = {
      RW: diskData.map(item => [formatTime(item[0], 0), item[4]]),
    };
    newIostat[disk].util = {
      RW: diskData.map(item => [formatTime(item[0], 0), item[5]]),
    };
  }

  return newIostat;
}

function newOption(data, dataOpt) {
  if (!data[dataOpt.key]) return {};

  if (data[dataOpt.key].RW) {
    return newReadWriteOption(data[dataOpt.key], dataOpt);
  }
  return newReadAndWriteOption(data[dataOpt.key], dataOpt);
}

function newReadWriteOption(data, dataOpt) {
  const valueList = data.RW;
  const max = Math.max(...valueList.map(v => v[1]));
  const showSymbol = valueList.length === 1;

  return {
    title: {
      text: dataOpt.label,
      left: 'left',
    },
    visualMap: {
      show: false,
      type: 'continuous',
      seriesIndex: 0,
      min: 0,
      max,
    },
    tooltip: {
      show: true,
      trigger: 'axis',
      valueFormatter: value => dataOpt.valFormatter(value),
    },
    xAxis: {
      type: 'time',
    },
    yAxis: {
      type: 'value',
      axisLabel: {
        formatter: value => dataOpt.valFormatter(value),
      },
      axisLine: {
        show: true,
      },
    },
    series: [
      {
        type: 'line',
        showSymbol,
        z: 1,
        zlevel: 1,
        data: valueList,
      },
    ],
  };
}

function newReadAndWriteOption(data, dataOpt) {
  const rvalueList = data.R;
  const wvalueList = data.W;

  const yMax = Math.max(...rvalueList.map(v => v[1]), ...wvalueList.map(v => v[1]));

  // r, w长度相同
  const showSymbol = rvalueList.length === 1;

  return {
    title: [
      {
        text: dataOpt.label,
        left: 'left',
      },
    ],
    visualMap: [{
      show: false,
      type: 'continuous',
      seriesIndex: 0,
      min: 0,
      max: yMax,
      inRange: {
        color: ['rgba(84,112,198,0.3)', 'rgba(84,112,198,1)'],
      },
    },
    {
      show: false,
      type: 'continuous',
      seriesIndex: 1,
      min: 0,
      max: yMax,
      inRange: {
        color: ['rgba(145,204,117,0.3)', 'rgba(145,204,117,1)'],
      },
    }],
    legend: {
      data: ['Read', 'Write'],
      icon: 'circle',
    },
    tooltip: {
      show: true,
      trigger: 'axis',
      valueFormatter: value => dataOpt.valFormatter(value),
    },
    xAxis: [
      {
        type: 'time',
      },
    ],
    yAxis: [
      {
        type: 'value',
        axisLabel: {
          formatter: value => dataOpt.valFormatter(value),
        },
        axisLine: {
          show: true,
        },
      },
    ],
    series: [
      {
        name: 'Read',
        type: 'line',
        z: 1,
        zlevel: 1,
        showSymbol,
        data: rvalueList,
      },
      {
        name: 'Write',
        type: 'line',
        z: 2,
        zlevel: 2,
        showSymbol,
        data: wvalueList,
      },
    ],
  };
}

const DiskTracingIOStatChart = (props) => {
  const iopsChartRef = useRef(null);
  const bwChartRef = useRef(null);
  const awaitChartRef = useRef(null);
  const aquszChartRef = useRef(null);
  const utilChartRef = useRef(null);

  const panelOption = [
    {
      label: 'iops',
      key: 'iops',
      ref: iopsChartRef,
      valFormatter: value => `${value} io/s`,
    },
    {
      label: '吞吐量',
      key: 'bytes',
      ref: bwChartRef,
      valFormatter: value => formatByte(value).join(''),
    },
    {
      label: '平均响应时间',
      key: 'await',
      ref: awaitChartRef,
      valFormatter: value => `${value.toFixed(3)} ms`,
    },
    {
      label: '平均队列长度',
      key: 'aqusz',
      ref: aquszChartRef,
      valFormatter: value => `${value.toFixed(3)}`,
    },
    {
      label: '使用率',
      key: 'util',
      ref: utilChartRef,
      valFormatter: value => `${value.toFixed(2)}%`,
    },
  ];

  useEffect(() => {
    panelOption.forEach((opt) => {
      drawChart(props.data, opt);
    });
  }, [props.data]);

  function drawChart(data, opt) {
    if (!data) return;

    const domElem = opt.ref.current;
    echarts.dispose(opt.ref.current);

    let dom = domElem;
    for (let i = 0; i < 15; i += 1) { // FIXME: 由于获取到的clientWidht为0，故寻找一个clientWidth不为0的父节点
      if (dom.clientWidth !== 0) break;
      dom = dom.parentElement;
    }

    const myChart = echarts.init(domElem, null, {
      width: dom.clientWidth,
      height: 400,
    });

    const option = newOption(data, opt);
    myChart.setOption(option);
  }

  return <>
    <SimpleBar style={{ maxHeight: 600 }}>
      <Space direction='vertical'>
        {
          panelOption.map((opt, idx) => (
            <div key={idx} style={{ paddingTop: 10 }}>
              <div ref={opt.ref} id={opt.id} style={{ height: 400, width: '100%' }}></div>
              <Divider />
            </div>
          ))
        }
      </Space>
    </SimpleBar>
  </>;
};

DiskTracingIOStatChart.propTypes = {
  data: PropTypes.object,
};

export default DiskTracingIOStatChart;
