import React, { memo, useEffect, useState }   from 'react';
import { goodStatus, doesntHaveStatus, badStatus } from '@/utils/ErrorConfirm';
import { withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import DropActionCreator from '../../api/DropActions';
import { connect } from 'react-redux';
import { getOAName } from '@/utils/Storage';
import IndexTaskList from '../dropIndex/taskList';
import StartGeneralTask from './startGeneralTask';
import CheckModal from '@/components/checkModal';
import { HOST_URL } from '@/config';
import axios from 'axios';
import {
  Card,
  message,
} from '@tencent/tea-component';
import qs from 'qs';
import { jumpTargetConfig } from '../../utils/URL';

const GeneralSampling = withRouter((props) => {
  // hook
  const [agents, setAgents] = useState([]);
  const [username] = useState(getOAName());
  const [tasks, setTasks] = useState([]);
  const [deleteTaskObject, setDeteleTaskObject] = useState({});

  // hook status
  const [showDeleteTask, setShowDeleteTask] = useState(false);
  // effect
  useEffect(() => {
    const param = {
      user: username,
    };
    props.DropAction.getDropAgentList(param);
    const taskParam = {
      user: username,
      target_ip: '',
    };
    props.DropAction.getDropAgentInfoIP(taskParam);
  }, []);

  useEffect(() => {
    const pollingAgents = setInterval(() => {
      const param = {
        user: username,
      };
      props.DropAction.getDropAgentList(param);
    }, 2000);
    return () => clearInterval(pollingAgents);
  }, []);

  useEffect(() => {
    const pollingTasks = setInterval(() => {
      const taskParam = {
        user: username,
        target_ip: '',
      };
      props.DropAction.getDropAgentInfoIP(taskParam);
    }, 2000);
    return () => clearInterval(pollingTasks);
  }, []);

  useEffect(() => {
    const AgentList = props.DropReducer.GetDropAgentList;
    // 有结果
    if (Object.keys(AgentList).length) {
      if (doesntHaveStatus(AgentList)) {
        message.error({
          content: 'Agent信息获取失败,请重试',
        });
      } else if (badStatus(AgentList)) {
        message.error({
          content: 'Agent信息获取失败,请重试',
        });
      }
      if (goodStatus(AgentList)) {
        setAgents(AgentList.data.agents);
      }
    }
  }, [props.DropReducer]);

  useEffect(() => {
    const GetInfoRes = props.DropReducer.GetAgentInfoIPRes;
    // todo:优化判断为空方法
    if (Object.keys(GetInfoRes).length) {
      if (doesntHaveStatus(GetInfoRes)) {
        message.error({
          content: '任务列表获取失败,请刷新重试',
        });
      } else if (badStatus(GetInfoRes)) {
        message.error({
          content: '任务列表获取失败,请刷新重试',
        });
      }
      if (goodStatus(GetInfoRes)) {
        // debugger;
        const dataSource = GetInfoRes.data.tasks.filter(item => item.type === 0);
        dataSource.sort((a, b) => b.id - a.id);
        setTasks(dataSource);
      }
    }
  }, [props.DropReducer]);

  useEffect(() => {
    const { SendStartRes } = props.DropReducer;
    // 有值才进入
    if (Object.keys(SendStartRes).length) {
      if (doesntHaveStatus(SendStartRes)) {
        if (SendStartRes.message) {
          message.error({
            content: `error:${SendStartRes.message}`,
          });
        } else {
          message.error({
            content: '任务创建失败',
          });
        }
      } else if (badStatus(SendStartRes)) {
        if (SendStartRes.message) {
          message.error({
            content: `error:${SendStartRes.message}`,
          });
        } else {
          message.error({
            content: '任务创建失败',
          });
        }
      }
      if (goodStatus(SendStartRes)) {
        // 弹出成功了的提示
        message.success({
          content: '任务创建成功',
        });
      }
    }
  }, [props.DropReducer]);

  // func
  const onStart = (param) => {
    props.DropAction.sendDropStart(param);
  };

  const deleteTask = (deleteTaskObject) => {
    axios({
      method: 'delete',
      url: `${HOST_URL}/api/v1/tasks/${deleteTaskObject.id}`,
    })
      .then((response) => {
        if (goodStatus(response)) {
          setShowDeleteTask(false);
          message.success({
            content: '删除成功',
          });
        } else {
          message.error({
            content: '删除失败',
          });
        }
      })
      .catch((error) => {
        console.log(error);
        message.success({
          content: '系统错误',
        });
      });
  };

  // render
  return <>
    <Card>
          <Card.Body
            title="发起任务"
          >
            <StartGeneralTask
                style={{ margin: '0 300px' }}
                agents={ agents }
                onSubmit = { onStart }
            />
          </Card.Body>
    </Card>
    <Card>
      <IndexTaskList
        style={{ marginTop: '20px' }}
        tasks={ tasks }
        viewTask = { (task) => {
          window.open(`/task/result?${qs.stringify({ taskID: task.tid, isSharedURL: false })}`, jumpTargetConfig());
        } }
        deleteTask = { (task) => {
          setShowDeleteTask(true);
          setDeteleTaskObject(task);
        }}
      />
    </Card>
    <CheckModal
      caption='确定删除任务?'
      visable = {showDeleteTask}
      bodyText = '删除后无法恢复,是否删除?'
      okText='确认'
      cancelText='取消'
      onClick={ () => {
        deleteTask(deleteTaskObject);
      }}
      onClose={ () => {
        setShowDeleteTask(false);
      }}
    />
  </>;
});


// 只存一次接口数据 下次请求会替换掉当前的数据 所以需要把数据保存到state里
const mapStateToProps = (state) => {
  const { DropReducer } = state;
  return {
    DropReducer,
  };
};// Reducer

const mapDispatchToProps = (dispatch) => {
  const DropAction = bindActionCreators(DropActionCreator, dispatch);

  return {
    DropAction,
  };
};// Action


export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(memo(GeneralSampling));
