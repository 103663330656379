import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Form,
  Row,
  Col,
  Input,
  Select,
  Button,
  message,
} from '@tencent/tea-component';

const DiskTracing = (props) => {
  const [name, setName] = useState('');
  const [targetIP, setTargetIP] = useState(Array(3));
  const [durantion, setDuration] = useState();

  const getArgs = () => ({
    target_ip: targetIP,
    taskDesc: JSON.stringify({
      if_perf_record_callgraph: true,
      name,
      perf_record_target_ip: targetIP,
      perf_record_options: 'system',
      perf_record_freq: 10,
      perf_record_duration: Number(durantion),
      perf_record_pid: -1,
      perf_record_type: 2,
      perf_record_profiler_type: 0,
    }),
  });

  return <div style={props.style}>
    <Form layout='fixed'>
      <Row>
        <Col span={8}>
          <Form.Item label="任务名">
          <Input
            value={ name }
            onChange={(value) => {
              setName(value);
            }}
          />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item label="目标机器">
          <Select
              appearance="default"
              options={
                props.agents.map(agent => ({
                  value: agent.ipaddr,
                  text: `${agent.hostname}：${agent.ipaddr}`,
                  tooltip: (agent.online) ?  `${agent.hostname}：${agent.ipaddr}`
                    : '该机器已离线，请参照指引重新拉起',
                  disabled: !agent.online,
                }))
                }
              value={ targetIP }
              onChange={ (value) => {
                setTargetIP(value);
              }
            }
              placeholder="选择发起任务机器"
            />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item label="追踪时长">
          <Input
            value={ durantion }
            onChange={(value) => {
              setDuration(value);
            }}
          />
          </Form.Item>
        </Col>
      </Row>
      <Form.Action>
            <Button
              style={{ marginLeft: '90%' }}
              type="primary"
              onClick={() => {
                // 校验
                if (name === '' || targetIP === ''
                || durantion === '') {
                  message.error({
                    content: '参数不为空',
                  });
                  return ;
                }
                props.onSubmit(getArgs());
              }}>
              发起</Button>
      </Form.Action>
    </Form>
  </div>;
};

DiskTracing.propTypes = {
  agents: PropTypes.array,
  onSubmit: PropTypes.func,
  style: PropTypes.object,
  // typeCheck: PropTypes.array,
};

export default DiskTracing;

