// 日志库
/* eslint-disable no-console */
import { DEBUG } from '@/config';
import { DateTime } from './DateTime';
const path = require('path');

class Log {
  static getCallerFileNameAndLine(num) {
    function getException() {
      try {
        throw Error('');
      } catch (err) {
        return err;
      }
    }

    const err = getException();
    const { stack } = err;
    const stackArr = stack.split('\n');
    let callerLogIndex = 0;
    for (let i = 0; i < stackArr.length; i++) {
      if (stackArr[i].indexOf('getCallerFileNameAndLine') > 0 && i + num < stackArr.length) {
        callerLogIndex = i + num;
        break;
      }
    }

    if (callerLogIndex !== 0) {
      const callerStackLine = stackArr[callerLogIndex];
      return `[${callerStackLine.substring(callerStackLine.indexOf('at ') + 3, callerStackLine.lastIndexOf(' '))}] [${callerStackLine.substring(callerStackLine.lastIndexOf(path.sep) + 1, callerStackLine.lastIndexOf(':'))}]`;
    }
    return '[-][-]';
  }

  static debug(...logInfo) {
    if (DEBUG) {
      const curTime = DateTime.currentTime();
      console.log(`%c [DEBUG] ${curTime} ${Log.getCallerFileNameAndLine(2)}`, 'color:green', ...logInfo);
    }
    return this;
  }

  static info(...logInfo) {
    const curTime = DateTime.currentTime();
    console.log(`%c [INFO] ${curTime} ${Log.getCallerFileNameAndLine(2)}`, 'color:blue', ...logInfo);
    return this;
  }

  static warning(...logInfo) {
    const curTime = DateTime.currentTime();
    console.log(`%c [WARN] ${curTime} ${Log.getCallerFileNameAndLine(2)}`, 'color:orange', ...logInfo);
    return this;
  }

  static error(...logInfo) {
    const curTime = DateTime.currentTime();
    console.log(`%c [ERROR] ${curTime} ${Log.getCallerFileNameAndLine(2)}`, 'color:red', ...logInfo);
    return this;
  }

  static table(...groupObj) {
    console.table(...groupObj);
    return this;
  }

  static groupCollapsed(...groupObj) {
    console.groupCollapsed(...groupObj);
    return this;
  }

  static group(...groupObj) {
    console.group(...groupObj);
    return this;
  }

  static groupEnd(...groupObj) {
    console.groupEnd(...groupObj);
    return this;
  }

  static time(timerName) {
    console.time(timerName);
    return this;
  }

  static timeEnd(timerName) {
    console.timeEnd(timerName);
    return this;
  }
}

export { Log };
