import { applyMiddleware, combineReducers, compose, createStore } from 'redux';
import createSagaMiddleware from 'redux-saga';
import { all, fork } from 'redux-saga/effects';
// import {composeWithDevTools} from "redux-devtools-extension";
// import {logger} from "redux-logger";
// import {DEBUG} from "config";
// import {RESET_COMPONENT_REDUCER} from "../main/MainActions";

const makeRootSaga = sagas => function* rootSaga() {
  yield all(sagas.map(saga => fork(saga)));
};

// let rootReducer = {};
// eslint-disable-next-line
export default (initialState = {}, reducers = {}, sagas = []) => {
  //
  const sagaMiddleware = createSagaMiddleware();
  const middlewares = [];
  middlewares.push(sagaMiddleware);

  //  if (DEBUG) {
  //      middlewares.push(logger);
  //  }

  const rootSaga = makeRootSaga(sagas);
  const appReducer = combineReducers(reducers);
  //  rootReducer = (state, action) => {
  //      if (action.type === RESET_COMPONENT_REDUCER){
  //          const componentName = action.payload;
  //          state[componentName] = undefined;
  //      }
  //      return appReducer(state, action);
  //  };

  const rootMiddleware = compose(applyMiddleware(...middlewares));

  const enhancer = rootMiddleware;
  //  if (DEBUG) {
  //      enhancer = composeWithDevTools(rootMiddleware);
  //  }

  const store = createStore(appReducer, initialState, enhancer);

  sagaMiddleware.run(rootSaga);
  return { store };
};
