import React from 'react';
import { Form, Card, Button } from '@tencent/tea-component';
import PropTypes from 'prop-types';
// 受控组件
const IndexUserInfo = props => (
      <Card style={ props.style }>
        <Card.Body
          title="用户信息"
        >
          <Form readonly style={{ width: '200px', margin: '0 auto' }}>
            <Form.Item  label="用户名">
              <Form.Text>{ props.username }</Form.Text>
            </Form.Item>
            <Form.Item label="管理机器">
              <Form.Text>{ `${props.agentsNumber} 台` }</Form.Text>
            </Form.Item>
            <Form.Item label="发起任务
            ">
              <Form.Text>{ `${props.tasksNumber} 次` }</Form.Text>
            </Form.Item>
          </Form>
          <Button
            type='primary'
            style = {{ margin: '40px 100px' }}
            onClick={ () => props.setShowTaskTable()}
          >发起任务</Button>
        </Card.Body>
      </Card>
);

IndexUserInfo.propTypes = {
  setShowTaskTable: PropTypes.func,
  username: PropTypes.string,
  agentsNumber: PropTypes.number,
  tasksNumber: PropTypes.number,
  style: PropTypes.object,
};

export default IndexUserInfo;
