import React, { memo, useState, useEffect }   from 'react';
import { withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import DropActionCreator from '../../api/DropActions';
import { connect } from 'react-redux';
import { getUserUid } from '@/utils/Storage';
import {
  Layout,
  Card,
  Menu,
  Tabs,
  TabPanel,
  message,
  Button,
  Icon,
  Switch,
} from '@tencent/tea-component';
import PropTypes from 'prop-types';
import { goodStatus, doesntHaveStatus, badStatus } from '@/utils/ErrorConfirm';
import GroupInfo from './groupInfo';
import TaskList from '../../components/taskList';
import { dataToTime } from '../../utils/DateTime';
import CheckModal from '@/components/checkModal';
import StatAgent from '../../components/statAgent';
import CreateGroupModal from './createGroup';
import AddGroupMemberModal from './addGroupMember';
import GroupBlank from './blank';
import StartTaskComponent from '../../components/startTaskComponent';
const { Body, Sider, Content } = Layout;


const Groups = withRouter((props) => {
  // hook
  const [selectedGroup, setSelectedGroup] = useState(-1);
  const [groups, setGroups] = useState([]);
  const [groupInfo, setGroupInfo] = useState({});
  const [groupMember, setGroupMember] = useState([]);
  const [groupAgent, setGroupAgent] = useState([]);
  const [agents, setAgents] = useState([]);

  // hooktarget
  const [statAgentTraget] = useState({});
  // const [statAgentTraget, setStatAgentTarget] = useState({});
  const [removeGroupMemberTarget, setRemoveGroupAgentTarget] = useState({});

  // hookstatus
  const [showDisbandGroup, setShowDisbandGroup] = useState(false);
  const [showRemoveGroupMember, setShowRemoveGroupMember] = useState(false);
  const [showStatAgent, setShowStatAgent] = useState(false);
  const [showCreateGroup, setShowCreateGroup] = useState(false);
  const [showAddGroupMember, setShowAddGroupMember] = useState(false);
  const [showStartTaskModel, setShowStarkTaskModel] = useState(false);

  // hook data
  const [groupAgentsMap, setGroupAgentsMap] = useState({});

  // hook trigger
  const [groupInfoTrigger, setGroupInfoTrigger] = useState(false);
  const [groupsTrigger, setGroupsTrigger] = useState(false);

  // effect
  useEffect(() => {
    props.DropAction.getGroups();
  }, [groupsTrigger]);

  useEffect(() => {
    if (selectedGroup !== -1 && selectedGroup < groups.length) {
      props.DropAction.getGroup({
        group_id: groups[selectedGroup]?.gid,
      });
      props.DropAction.getGroupAgent({
        group_id: groups[selectedGroup]?.gid,
      });
      props.DropAction.getUserAgents();
    }
  }, [selectedGroup]);

  useEffect(() => {
    if (selectedGroup !== -1 && selectedGroup < groups.length) {
      props.DropAction.getGroup({
        group_id: groups[selectedGroup]?.gid,
      });
      props.DropAction.getGroupAgent({
        group_id: groups[selectedGroup]?.gid,
      });
      props.DropAction.getUserAgents();
    }
  }, [groupInfoTrigger]);

  // 处理回调
  useEffect(() => {
    const { GetGroups } = props.DropReducer;
    // 有值才进入
    if (Object.keys(GetGroups).length) {
      if (doesntHaveStatus(GetGroups)) {
        message.error({
          content: `error:${GetGroups?.message}`,
        });
      } else if (badStatus(GetGroups)) {
        message.error({
          content: `error:${GetGroups?.message}`,
        });
      }
      if (goodStatus(GetGroups)) {
        setGroups(GetGroups.data.groups);
        if (GetGroups.data.groups.length) {
          setSelectedGroup(0);
        } else {
          setSelectedGroup(-1);
        }
      }
    }

    const { GetGroup } = props.DropReducer;
    // 有值才进入
    if (Object.keys(GetGroup).length) {
      if (doesntHaveStatus(GetGroup)) {
        message.error({
          content: `error:${GetGroup?.message}`,
        });
      } else if (badStatus(GetGroup)) {
        message.error({
          content: `error:${GetGroup?.message}`,
        });
      }
      if (goodStatus(GetGroup)) {
        setGroupInfo(GetGroup.data.group_info);
        setGroupMember(GetGroup.data.group_member);
      }
    }

    const { GetGroupAgent } = props.DropReducer;
    if (Object.keys(GetGroupAgent).length) {
      if (doesntHaveStatus(GetGroupAgent)) {
        message.error({
          content: `error:${GetGroupAgent?.message}`,
        });
      } else if (badStatus(GetGroupAgent)) {
        message.error({
          content: `error:${GetGroupAgent?.message}`,
        });
      }
      if (goodStatus(GetGroupAgent)) {
        setGroupAgent(GetGroupAgent.data.agents);
        const agentsMap = {};
        GetGroupAgent.data.agents.map((value, index) => {
          agentsMap[value.id] = index;
          return 0;
        });
        setGroupAgentsMap(agentsMap);
      }
    }

    const { GetUserAgents } = props.DropReducer;
    if (Object.keys(GetUserAgents).length) {
      if (doesntHaveStatus(GetUserAgents)) {
        message.error({
          content: 'Agent信息获取失败,请重试',
        });
      } else if (badStatus(GetUserAgents)) {
        message.error({
          content: 'Agent信息获取失败,请重试',
        });
      }
      if (goodStatus(GetUserAgents)) {
        setAgents(GetUserAgents.data.agents);
      }
    }

    const { DisbandGroup } = props.DropReducer;
    if (Object.keys(DisbandGroup).length) {
      if (doesntHaveStatus(DisbandGroup)) {
        message.error({
          content: `error:${DisbandGroup?.message}`,
        });
      } else if (badStatus(DisbandGroup)) {
        message.error({
          content: `error:${DisbandGroup?.message}`,
        });
      }
      if (goodStatus(DisbandGroup)) {
        message.success({
          content: '解散成功',
        });
        setGroupsTrigger(!groupsTrigger);
      }
    }

    const { RemoveGroupMember } = props.DropReducer;
    if (Object.keys(RemoveGroupMember).length) {
      if (doesntHaveStatus(RemoveGroupMember)) {
        message.error({
          content: `error:${RemoveGroupMember?.message}`,
        });
      } else if (badStatus(RemoveGroupMember)) {
        message.error({
          content: `error:${RemoveGroupMember?.message}`,
        });
      }
      if (goodStatus(RemoveGroupMember)) {
        message.success({
          content: '移除成功',
        });
        setGroupInfoTrigger(!groupInfoTrigger);
      }
    }

    const { CreateGroup } = props.DropReducer;
    if (Object.keys(CreateGroup).length) {
      if (doesntHaveStatus(CreateGroup)) {
        message.error({
          content: `error:${CreateGroup?.message}`,
        });
      } else if (badStatus(CreateGroup)) {
        message.error({
          content: `error:${CreateGroup?.message}`,
        });
      }
      if (goodStatus(CreateGroup)) {
        message.success({
          content: '创建成功',
        });
        setGroupsTrigger(!groupsTrigger);
      }
    }

    const { AddGroupMember } = props.DropReducer;
    if (Object.keys(AddGroupMember).length) {
      if (doesntHaveStatus(AddGroupMember)) {
        message.error({
          content: `error:${AddGroupMember?.message}`,
        });
      } else if (badStatus(AddGroupMember)) {
        message.error({
          content: `error:${AddGroupMember?.message}`,
        });
      }
      if (goodStatus(AddGroupMember)) {
        message.success({
          content: '添加成功',
        });
        setGroupInfoTrigger(!groupInfoTrigger);
      }
    }

    const { AddGroupAgent } = props.DropReducer;
    if (Object.keys(AddGroupAgent).length) {
      if (doesntHaveStatus(AddGroupAgent)) {
        message.error({
          content: `error:${AddGroupAgent?.message}`,
        });
      } else if (badStatus(AddGroupAgent)) {
        message.error({
          content: `error:${AddGroupAgent?.message}`,
        });
      }
      if (goodStatus(AddGroupAgent)) {
        message.success({
          content: '添加成功',
        });
        setGroupInfoTrigger(!groupInfoTrigger);
      }
    }

    const { RemoveGroupAgent } = props.DropReducer;
    if (Object.keys(RemoveGroupAgent).length) {
      if (doesntHaveStatus(RemoveGroupAgent)) {
        message.error({
          content: `error:${RemoveGroupAgent?.message}`,
        });
      } else if (badStatus(RemoveGroupAgent)) {
        message.error({
          content: `error:${RemoveGroupAgent?.message}`,
        });
      }
      if (goodStatus(RemoveGroupAgent)) {
        message.success({
          content: '移除成功',
        });
        setGroupInfoTrigger(!groupInfoTrigger);
      }
    }
  }, [props.DropReducer]);


  const getMenuItemProps = id => ({
    selected: selectedGroup === id,
    onClick: () => setSelectedGroup(id),
  });

  const tabs = [
    { id: 'groupInfo', label: '用户组信息' },
    { id: 'groupAgent', label: '用户组机器' },
  ];

  const groupMemberColumns = [
    {
      key: 'name',
      header: '用户名',
    },
    {
      key: 'uid',
      header: '成员角色',
      render: member => ((member.uid === groupInfo.owner_id) ? '管理员' : '成员'),
    },
    {
      key: 'created_at',
      header: '加入时间',
      render: member => dataToTime(member.created_at),
    },
    {
      key: 'action',
      header: '操作',
      render: member => <Button
        type='link'
        onClick={ () => {
          setRemoveGroupAgentTarget(member);
          setShowRemoveGroupMember(true);
        }}
        disabled={ getUserUid() !== groupInfo.owner_id  || member.uid === groupInfo.owner_id}
        >
          移除
        </Button>,
    },
  ];

  const agentsColumns = [
    {
      key: 'ipaddr',
      header: '主机IP',
    },
    {
      key: 'hostname',
      header: '主机名',
    },
    {
      key: 'status',
      header: '状态',
      render: (agent) => {
        if (agent.online === true) {
          return  <p>
                  在线<Icon type="success" />
                  </p>;
        } if (agent.online === false) {
          return  <p>
                  离线<Icon type="error" />
                  </p>;
        }
      },
    },
    {
      key: 'actionShareAgent',
      header: '共享状态',
      render: agent => <Switch
        defaultValue={ groupAgentsMap[agent.id] !== undefined }
        onChange={
          (value) => {
            if (value) {
              addGroupAgent(agent);
            } else {
              removeGroupAgent(agent);
            }
          }
        }>
        共享</Switch>,
    },
    {
      key: 'action',
      header: '操作',
      render: (agent) => {
        if (agent.online === true) {
          return <span>
                  <Button
                      disabled={!agent.online}
                      tooltip={(!agent.online) ? '机器已离线' : ''}
                      type="link"
                      onClick={() => {
                        setAgents([agent]);
                        setShowStarkTaskModel(true);
                      }}>发起任务
                  </Button >
                  {/* <Button
                    type="link"
                    onClick={() => {
                      statAgent(agent);
                    }}>
                  监控</Button > */}
                </span>;
        }
        return <p>机器已离线，无法进行操作</p>;
      },
    },
  ];

  const groupAgentsColumns = [
    {
      key: 'ipaddr',
      header: '主机IP',
    },
    {
      key: 'hostname',
      header: '主机名',
    },
    {
      key: 'name',
      header: '拥有者',
    },
    {
      key: 'status',
      header: '状态',
      render: (agent) => {
        if (agent.online === true) {
          return  <p>
                  在线<Icon type="success" />
                  </p>;
        } if (agent.online === false) {
          return  <p>
                  离线<Icon type="error" />
                  </p>;
        }
      },
    },
    {
      key: 'action',
      header: '操作',
      render: (agent) => {
        if (agent.online === true) {
          return <span>
                  <Button
                      disabled={!agent.online}
                      tooltip={(!agent.online) ? '机器已离线' : ''}
                      type="link"
                      onClick={() => {
                        setAgents([agent]);
                        setShowStarkTaskModel(true);
                      }}>发起任务
                  </Button >
                  {/* <Button
                    type="link"
                    onClick={() => {
                      statAgent(agent);
                    }}>
                  监控</Button > */}
                </span>;
        }
        return <p>机器已离线，无法进行操作</p>;
      },
    },
  ];

  const disbandGroup = () => {
    props.DropAction.disbandGroup(groupInfo);
    setShowDisbandGroup(false);
  };

  const addGroupMember = (userName) => {
    props.DropAction.addGroupMember({
      group_id: groupInfo.gid,
      group_name: groupInfo.group_name,
      user_name: userName,
    });
  };

  const removeGroupMember = () => {
    props.DropAction.removeGroupMember({
      group_id: removeGroupMemberTarget.gid,
      user_id: removeGroupMemberTarget.uid,
    });
    setShowRemoveGroupMember(false);
  };

  const createGroup = (groupName) => {
    props.DropAction.createGroup({
      group_name: groupName,
    });
    setShowCreateGroup(false);
  };

  // const statAgent = (agent) => {
  //   setStatAgentTarget(agent);
  //   setShowStatAgent(true);
  // };

  const addGroupAgent = (agent) => {
    props.DropAction.addGroupAgent({
      group_id: groupInfo.gid,
      agent_name: agent.hostname,
      agent_id: agent.id,
    });
  };

  const removeGroupAgent = (agent) => {
    props.DropAction.removeGroupAgent({
      group_id: groupInfo.gid,
      agent_name: agent.hostname,
      agent_id: agent.id,
    });
  };

  return <>
       <Layout className="demo-layout-l">
      <Body>
        { groups.length
          ? <Sider>
          <Menu>
            <Menu.Group title="我的用户组">
              {groups.map((value, index) => <Menu.Item
                title={value.group_name}
                key={index}
                {...getMenuItemProps(index)}
                />)}
            </Menu.Group>
          </Menu>
        </Sider>
          : '' }
        <Content style={{ minHeight: '600px' }}>
          <Content.Header
            title='用户组信息'
            operation={
            <Button type='link'
              onClick={ () => setShowCreateGroup(true) }>
            创建用户组</Button>}
          />
          <Content.Body>
            { groups.length
              ? <Tabs key={selectedGroup} ceiling animated={false} tabs={tabs}>
              <TabPanel id="groupInfo">
                <Card>
                  <GroupInfo
                    groupInfo={groupInfo}
                    groupMember={groupMember}
                    isGroupOwner={getUserUid() === groupInfo.owner_id}
                    disbandGroup={() => setShowDisbandGroup(true)}
                    addGroupMember={() => setShowAddGroupMember(true)}
                  />
                </Card>
                <Card>
                  <TaskList
                    key='groupMemberList'
                    cardTitle='成员列表'
                    records={groupMember}
                    columns={groupMemberColumns}
                    searchPlaceholder='输入用户名搜索'
                    searchFilter={(records, keyword) => records.filter(record => record.name.includes(keyword))
                    }
                  />
                </Card>
              </TabPanel>
              <TabPanel id="groupAgent">
                <Card>
                  <TaskList
                    key='AgentList'
                    cardTitle='个人机器'
                    records={agents}
                    columns={agentsColumns}
                    searchPlaceholder='输入机器名或ip搜索'
                    searchFilter={(records, keyword) => records.filter(record => record.ipaddr.includes(keyword)
                     || record.hostname.includes(keyword))
                    }
                  />
                </Card>
                <Card>
                  <TaskList
                    key='groupAgentList'
                    cardTitle='共享机器'
                    records={groupAgent}
                    columns={groupAgentsColumns}
                    searchPlaceholder='输入机器名或ip搜索'
                    searchFilter={(records, keyword) => records.filter(record => record.ipaddr.includes(keyword)
                      || record.hostname.includes(keyword))
                    }
                  />
                </Card>
              </TabPanel>
            </Tabs>
              : <GroupBlank createGroup={() => setShowCreateGroup(true) }/> }
          </Content.Body>
        </Content>
      </Body>
    </Layout>
    <CheckModal
      caption='移除成员'
      visable={showRemoveGroupMember}
      bodyText='是否移除成员?'
      okText='确认'
      cancelText='取消'
      onClick={() => removeGroupMember()}
      onClose={() => setShowRemoveGroupMember(false)}
    />
    <CheckModal
      caption='解散组'
      visable={showDisbandGroup}
      bodyText='是否确认解散组?'
      okText='确认'
      cancelText='取消'
      onClick={() => disbandGroup()}
      onClose={() => setShowDisbandGroup(false)}
    />
    {(showStatAgent) ? <StatAgent
      visable={showStatAgent}
      onClose={ () => setShowStatAgent(false) }
      agent = {statAgentTraget}
    /> : ''
    }
    <CreateGroupModal
      caption='创建用户组'
      visable={showCreateGroup}
      okText='创建'
      cancelText='取消'
      onClick={ groupName => createGroup(groupName) }
      onClose={ () => setShowCreateGroup(false) }
    />
    <AddGroupMemberModal
      caption='添加成员'
      visable={showAddGroupMember}
      okText='添加'
      cancelText='关闭'
      onClick={ groupName => addGroupMember(groupName) }
      onClose={ () => setShowAddGroupMember(false) }
    />
    <StartTaskComponent
      agents={agents}
      visable={showStartTaskModel}
      onClose={() => setShowStarkTaskModel(false)}
      successAction={ () => {
        props.history.push({ pathname: 'tasks' });
      }}
    />
    </>;
});


Groups.propTypes = {
  DropReducer: PropTypes.any,
  DropAction: PropTypes.any,
  history: PropTypes.any,
};

const mapStateToProps = (state) => {
  const { DropReducer } = state;
  return {
    DropReducer,
  };
};// Reducer

const mapDispatchToProps = (dispatch) => {
  const DropAction = bindActionCreators(DropActionCreator, dispatch);

  return {
    DropAction,
  };
};// Action


export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(memo(Groups));
