import React, { useEffect, useState } from 'react';
import {
  Card,
} from '@tencent/tea-component';
import { bindActionCreators } from 'redux';
import DropActionCreator from '@/api/DropActions';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import '@tencent/tea-component/dist/tea.css';
import '../Drop.css';
import PropTypes from 'prop-types';
import { Log } from '@/utils/Log';
import axios from 'axios';
import qs from 'qs';
import { GongFengHOST } from '@/config/';

const urlParams = new URLSearchParams(window.location.search);
const token = urlParams.get("code");

function GitLogin(_props) {
  const [status, setstatus] = useState(0);

  useEffect(() => {
    const requestParams = {
      verifyToken: token,
      uid: qs.parse(location.search.slice(1)).state,
      origin: window.location.origin,
    };
    axios({
      method: 'post',
      url: `${GongFengHOST}/api/v1/gongfeng/token`,
      data: requestParams, // 使用 data 属性发送 POST 数据
    }).catch((error) => {
      if (error.response) {
        // 请求已发送，服务器回复了状态码（但状态码不在范围 2xx）
        Log.error(`Error Status: ${error.response.status}`);
        setstatus(1);
      } else if (error.request) {
        // 请求已发送，但没有收到响应
        Log.error('No response received');
      } else {
        // 发送请求时出现错误
        Log.error('Error', error.message);
      };
    });
  }, []); // 传入空数组，只在组件挂载时执行一次

  Log.debug(`token:${token}`);
  // 存下token

  // 渲染uid的模块
  const content = <div className="drop-user-info-show-module-style">
    <div style={{ margin: '10px auto', padding: '20px' }}>
      <Card style={{ width: '80%' }}>
        <Card.Body
          title = { status === 0
          ? "工蜂联动成功, 请关闭此标签页返回重试"
          : "工蜂联动失败, 请重试"
          }
        >
        </Card.Body>
      </Card>
    </div>
  </div>;

  return content;
}

GitLogin.propTypes = {
  DropAction: PropTypes.any.isRequired,
  DropReducer: PropTypes.any.isRequired,
};

const mapStateToProps = (state) => {
  const { DropReducer } = state;
  return {
    DropReducer,
  };
}; // Reducer

const mapDispatchToProps = (dispatch) => {
  const DropAction = bindActionCreators(DropActionCreator, dispatch);

  return {
    DropAction,
  };
}; // Action

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps,
)(GitLogin));
