import { FEATURE_MINIMAL_VERSION, MACHINE_FEATURES } from '../../config';

export const CommonCpuTask = {
  fullName: '通用分析-CPU采样分析',
  label: 'CPU采样分析',
  task_type: 0,
  require_feature_ids: [],
  perf_wide: true,
  container_type: true,
  profiling_freqence: true,
  annotate_enable_check: true,
  record_subprocess: true,
  callgraph: true,
  general_profiler_type: true,
  duration: true,
};

export const CommonMemoryTask = {
  fullName: '通用分析-内存泄漏检测',
  label: '内存泄漏检测',
  task_type: 4,
  require_feature_ids: [MACHINE_FEATURES.BCC_ENABLE, MACHINE_FEATURES.MEMLEAK_ENABLE],
  trace_object: true,
  tracing_wide: true,
  duration: true,
};

export const GolangCpuTask = {
  fullName: 'Golang分析-CPU采样分析',
  label: 'CPU采样分析',
  task_type: 0,
  profiler_type: 2,
  require_feature_ids: [],
  require_version: FEATURE_MINIMAL_VERSION.pprof_profiling,
  duration: true,
  pprof_listen: true,
};

export const GolangMemoryTask = {
  fullName: 'Golang分析-内存采样分析',
  label: '内存采样分析',
  task_type: 5,
  require_feature_ids: [],
  pprof_mem_leak_wide: true,
  pprof_listen: true,
};

export const JavaCpuTask = {
  fullName: 'Java分析-CPU采样分析',
  label: 'CPU采样分析',
  task_type: 1,
  profiler_type: 1,
  event: 'itimer',
  require_feature_ids: [
    MACHINE_FEATURES.PERF_ENABLE, MACHINE_FEATURES.PERF_RECORD_ENABLE, MACHINE_FEATURES.PERF_SCRIPT_ENABLE],
  perf_wide: true,
  java_profiler_type: true,
  duration: true,
};

export const JavaMemoryTask = {
  fullName: 'Java分析-内存采样分析',
  label: '内存采样分析',
  task_type: 1,
  profiler_type: 1,
  event: 'alloc',
  require_feature_ids: [],
  perf_wide: true,
  java_profiler_type: true,
  duration: true,
};

export const JavaHeapDumpTask = {
  fullName: 'Java分析-堆转储分析',
  label: '堆转储分析',
  task_type: 6,
  require_feature_ids: [],
  perf_wide: true,
  java_heap_task: true,
};

export const JavaMemoryCompositeTask = {
  fullName: 'Java分析-内存采样+堆转储分析',
  label: '内存采样+堆转储分析',
  task_type: -2,
  require_feature_ids: [],
  perf_wide: true,
  java_heap_task: true,
  duration: true,
};

export const TimingAnalysisTask = {
  fullName: '时序分析',
  label: '时序分析',
  task_type: 3,
  require_feature_ids: [],
  profiling_freqence: true,
  perf_wide: true,
  duration: true,
};

export const DiskTracingTask = {
  fullName: '磁盘追踪',
  label: '磁盘追踪',
  task_type: 2,
  require_feature_ids: [MACHINE_FEATURES.BCC_ENABLE, MACHINE_FEATURES.BIO_ENABLE],
  duration: true,
};

export const DynamicDetectionTask = {
  fullName: '动态检测',
  label: '动态检测',
  task_type: -1,
  require_feature_ids: [MACHINE_FEATURES.BCC_ENABLE],
  tracing_wide: true,
  trace_object: true,
  duration: true,
};
