// 请求返回状态判断库
export const has = Object.prototype.hasOwnProperty;


export const doesntHaveStatus = obj => !obj || !has.call(obj, 'code') || obj.code === undefined;
// :( bad boy
// 用 list 预防大小写和其他自定义状态的扩展
export const badStatus = obj => has.call(obj, 'code')
  && !['NotNewest', 'Success', 'success', 'SUCCESS', 'OK', 0].includes(obj.code);
// :) good boy
export const goodStatus = obj => has.call(obj, 'code')
    && ['Success', 'success', 'SUCCESS', 'OK', 0].includes(obj.code);
