import React, { useEffect } from 'react';
import * as echarts from 'echarts';
import PropTypes from 'prop-types';
import { formatTime } from '../../utils/DateTime';

const DiskTracingResultChart = (props) => {
  const colorTable = [
    'rgb(127, 39, 4)', 'rgb(139, 43, 4)', 'rgb(151, 48, 3)', 'rgb(163, 53, 3)',
    'rgb(176, 58, 3)', 'rgb(190, 63, 2)', 'rgb(204, 69, 3)', 'rgb(215, 76, 5)',
    'rgb(225, 85, 9)',
    'rgb(232, 94, 14)',
    'rgb(238, 104, 22)',
    'rgb(243, 115, 31)',
    'rgb(247, 125, 42)',
    'rgb(250, 135, 55)',
    'rgb(252, 146, 68)',
    'rgb(253, 156, 81)',
    'rgb(253, 166, 95)',
    'rgb(253, 175, 110)',
    'rgb(253, 185, 126)',
    'rgb(253, 195, 141)',
    'rgb(253, 204, 156)',
    'rgb(253, 212, 171)',
    'rgb(254, 219, 184)',
    'rgb(254, 225, 196)',
    'rgb(254, 231, 207)',
    'rgb(254, 236, 217)',
    'rgb(255, 241, 226)',
  ];

  const xData = props.xData.slice();
  const yData = props.yData.slice();

  // 填充x，y轴坐标值，使x，y格数相同，使图比例固定
  for (let i = 0; i < xData.length - yData.length; i++) {
    yData.push('......');
  }
  const xOriginLen = xData.length;
  const yOriginLen = yData.length;
  const endTimeStamp = props.endTimeStamp + 1;
  for (let i = 0; i < yOriginLen - xOriginLen; i++) {
    xData.push(formatTime(i + endTimeStamp, 1));
  }

  // 计算初始缩放比例
  const zoomEndValue = 60;
  let showDataZoom = true;
  let dataZoomEnd = 0;
  if (props.yData.length > zoomEndValue) { // NOTE: 需判断原始y轴高度
    dataZoomEnd = 100;
  } else if (xData.length > zoomEndValue) {
    dataZoomEnd = Math.floor(zoomEndValue / xData.length * 100);
  } else {
    dataZoomEnd = 100;
    showDataZoom = false;
  }

  // 计算初始visualMap取值
  const xlen = xData.length;
  const ylen = yData.length;
  const minEventCnt = getMinEventCnt(props.graphData, [xlen, 0, zoomEndValue], [ylen, 0, zoomEndValue]);
  const maxEventCnt = getMaxEventCnt(props.graphData, [xlen, 0, zoomEndValue], [ylen, 0, zoomEndValue]);

  const option = {
    tooltip: {
      position: 'top',
    },
    grid: {
      height: '70%',
      top: '10%',
    },
    xAxis: {
      type: 'category',
      data: xData,
      splitArea: {
        show: false,
      },
    },
    yAxis: [
      {
        id: 1,
        name: '延时范围',
        type: 'category',
        show: true,
        boundaryGap: true,
        data: yData,
        splitArea: {
          show: false,
        },
      },
    ],
    visualMap: {
      min: minEventCnt,
      max: maxEventCnt,
      show: true,
      orient: 'horizontal',
      left: 'center',
      top: 'top',
      calculable: true,
      inRange: {
        color: colorTable.reverse(),
      },
    },
    dataZoom: [
      {
        id: 'xzoom',
        type: 'slider',
        show: showDataZoom,
        xAxisIndex: [0],
        filterMode: 'none',
        start: 0,
        end: dataZoomEnd,
        height: 18,
        brushSelect: false,
      },
      {
        id: 'yzoom',
        type: 'slider',
        show: false,
        yAxisIndex: [0],
        filterMode: 'none',
        start: 0,
        end: dataZoomEnd,
        width: 18,
        right: 10,
      },
    ],
    series: [
      {
        type: 'heatmap',
        data: props.graphData,
        label: {
          show: false,
        },
        emphasis: {
          itemStyle: {
            shadowBlur: 10,
            shadowColor: 'rgba(0, 0, 0, 0.5)',
          },
        },
        tooltip: {
          formatter: value => `<b>${formatTime(props.startTimeStamp + value.data[0], 0)}</b><br>
            延时范围:<b>${yData[value.data[1]]}</b><br>
            事件数量:<b>${value.data[2]}</b><br>
            点击查看具体磁盘事件`,
        },
        itemStyle: {
          borderWidth: 0.02,
          borderColor: '#000',
        },
      },
    ],
  };

  useEffect(() => {
    if (document.getElementById('diskGraph') !== null) {
      echarts.dispose(document.getElementById('diskGraph'));
    }
    const chartDom = document.getElementById('diskGraph');

    let { clientWidth } = chartDom;
    // FIXME: 若某个磁盘初始窗口不是热图，择tab切换到热图后clientWidht为0，故寻找一个clientWidth不为0的父节点
    if (clientWidth === 0) {
      let dom = chartDom;
      for (let i = 0; i < 10; i += 1) {
        if (dom.clientWidth !== 0) break;
        dom = dom.parentElement;
      }
      clientWidth = dom.clientWidth;
    }

    const myChart = echarts.init(chartDom, null, {
      width: clientWidth,
      height: 450,
    });

    option && myChart.setOption(option);
    myChart.off('click');
    myChart.on('click', (e) => {
      props.setEvent(e.data);
    });
    myChart.on('datazoom', (e) => {
      const id = e.dataZoomId;

      // 计算缩放比例
      const dzOpt = myChart.getOption().dataZoom;
      const dzCurIdx = id === 'xzoom' ? 0 : 1;
      const dzAdjustIdx = dzCurIdx === 0 ? 1 : 0;
      const dzRange = dzOpt[dzCurIdx].end - dzOpt[dzCurIdx].start;
      let { start, end } = dzOpt[dzAdjustIdx];
      end = start + dzRange;
      if (end > 100) {
        end = 100;
        start = 100 - dzRange;
      }

      const newDzOpt = [{}, {}];
      newDzOpt[dzAdjustIdx] = {
        start,
        end,
      };

      // x, y 缩放条比例相同
      const xlen = xData.length;
      const ylen = yData.length;
      const minEventCnt = getMinEventCnt(props.graphData, [xlen, e.start, e.end], [ylen, start, end]);
      const maxEventCnt = getMaxEventCnt(props.graphData, [xlen, e.start, e.end], [ylen, start, end]);

      // 设置选项
      myChart.setOption({
        visualMap: {
          min: minEventCnt,
          max: maxEventCnt,
        },
        dataZoom: newDzOpt,
      });
    });
  }, [props.graphData]);

  return <>
    <div style={{ height: '450px', width: '100%' }} id='diskGraph'></div>
  </>;
};

DiskTracingResultChart.propTypes = {
  setEvent: PropTypes.func,
  startTimeStamp: PropTypes.number,
  endTimeStamp: PropTypes.number,
  xData: PropTypes.array,
  yData: PropTypes.array,
  graphData: PropTypes.array,
  maxEventNumber: PropTypes.number,
};

function getMinEventCnt(graphData, [xlen, xstart, xend], [ylen, ystart, yend]) {
  const xStartIdx = Math.round(xlen * xstart / 100);
  const xEndIdx = Math.round(xlen * xend / 100);
  const yStartIdx = Math.round(ylen * ystart / 100);
  const yEndIdx = Math.round(ylen * yend / 100);

  const cnts = graphData.
    filter(value => value[0] >= xStartIdx && value[0] <= xEndIdx).
    filter(value => value[1] >= yStartIdx && value[1] <= yEndIdx).
    map(value => value[2]);
  return cnts.length !== 0 ? Math.min(...cnts) : 0;
}

function getMaxEventCnt(graphData, [xlen, xstart, xend], [ylen, ystart, yend]) {
  const xStartIdx = Math.round(xlen * xstart / 100);
  const xEndIdx = Math.round(xlen * xend / 100);
  const yStartIdx = Math.round(ylen * ystart / 100);
  const yEndIdx = Math.round(ylen * yend / 100);

  const cnts = graphData.
    filter(value => value[0] >= xStartIdx && value[0] <= xEndIdx).
    filter(value => value[1] >= yStartIdx && value[1] <= yEndIdx).
    map(value => value[2]);
  return cnts.length !== 0 ? Math.max(...cnts) : 0;
}

export default DiskTracingResultChart;
