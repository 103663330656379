export function formatByte(bytes) {
  const base = 1024;
  const decimals = 2; // 保留2位小数

  const units = ['B', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

  let digit;
  if (bytes === 0) {
    digit = 0;
  } else {
    digit = Math.floor(Math.log(bytes) / Math.log(base));
  }

  const value = parseFloat((bytes / base ** digit).toFixed(decimals));
  const unit = units[digit];
  return [value, unit];
}
