/* eslint-disable */
import * as each from './foreach';

function search(root, attr, term, searchMatch = null) {
  const results = [];

  console.assert(term !== undefined || term !== null, 'invalid term of search', attr, typeof term, term);
  if (term === undefined || term === null) {
    return results;
  }

  root.each((node) => {
    if (node.hidden) return;

    const value = node.data[attr];

    if (typeof searchMatch === 'function') {
      if (searchMatch(value, term)) {
        results.push(node);
      }
      return;
    }

    switch (typeof value) {
      case 'number':
        if (value === term) {
          results.push(node);
        }
        break;

      case 'string':
        if (value.includes(term)) {
          results.push(node);
        }
        break;

      default:
        console.assert(false, 'invalid attr of search', node.data, attr);
    }
  });

  return results;
}

export function stacks(root, op, ...searchArgs) {
  const nodes = search(root, ...searchArgs);

  if (op.matched) {
    nodes.forEach((node) => {
      each.stack(node, (n) => {
        op.matched(n);
      });
    });
  }

  if (op.notMatched) {
    nodes.forEach((node) => {
      each.stack(node, (n) => {
        n.protected = true;
      });
    });
    nodes.forEach((node) => {
      each.nonStack(node, (n) => {
        if (!n.protected) {
          op.notMatched(n);
        }
      });
    });
    nodes.forEach((node) => {
      each.stack(node, (n) => {
        n.protected = false;
      });
    });
  }
}

export function callers(root, op, ...searchArgs) {
  const nodes = search(root, ...searchArgs);

  if (op.matched) {
    nodes.forEach((node) => {
      each.ancestors(node, (n) => {
        op.matched(n);
      });
    });
  }

  if (op.notMatched) {
    nodes.forEach((node) => {
      node.protected = true;
      each.ancestors(node, (n) => {
        n.protected = true;
      });
    });

    nodes.forEach((node) => {
      each.nonAncestors(node, (n) => {
        if (n.protected) return;
        op.notMatched(n);
      });
    });

    nodes.forEach((node) => {
      node.protected = false;
      each.ancestors(node, (n) => {
        n.protected = false;
      });
    });
  }
}

export function callees(root, op, ...searchArgs) {
  const nodes = search(root, ...searchArgs);
  if (op.matched) {
    nodes.forEach((node) => {
      each.descendants(node, (n) => {
        op.matched(n);
      });
    });
  }

  if (op.notMatched) {
    nodes.forEach((node) => {
      node.protected = true;
      each.descendants(node, (n) => {
        n.protected = true;
      });
    });

    nodes.forEach((node) => {
      each.nonDescendants(node, (n) => {
        if (!n.protected) {
          n.hidden = true;
        }
      });
    });

    nodes.forEach((node) => {
      node.protected = false;
      each.descendants(node, (n) => {
        n.protected = false;
      });
    });
  }
}

export function frames(root, op, ...searchArgs) {
  const nodes = search(root, ...searchArgs);
  if (op.matched) {
    nodes.forEach((node) => {
      op.matched(node);
    });
  }

  if (op.notMatched) {
    nodes.forEach((node) => {
      node.protected = true;
    });
    each.descendants(root, (n) => {
      if (n.protected) return;
      op.notMatched(n);
    });
    nodes.forEach((node) => {
      node.protected = false;
    });
  }
}

function findTop(root, n) {
  const { topNodes } = root;
  if (n === 0) {
    return [];
  }
  let cnt = 0;
  const results = [];
  for (let i = 1; i < topNodes.length && cnt < n; i++) {
    const node = topNodes[i];
    if (node.hidden) {
      continue;
    }
    node.rank = cnt + 1;
    results.push(node);
    cnt += 1;
  }
  return results;
}

export function matchTop(root, op, ...findTopArgs) {
  const nodes = findTop(root, ...findTopArgs);

  if (op.matched) {
    nodes.forEach((node) => {
      op.matched(node);
    });
  }

  if (op.notMatched) {
    nodes.forEach((node) => {
      node.protected = true;
    });
    each.descendants(root, (n) => {
      if (n.protected) return;
      op.notMatched(n);
    });
    nodes.forEach((node) => {
      node.protected = false;
    });
  }
}

