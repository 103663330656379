import { Collapse, Pagination, Space } from 'tdesign-react';
import { Text } from '@tencent/tea-component';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { Log } from '../../../utils/Log';
import { InfoCircleIcon } from 'tdesign-icons-react';

const bigObjectPercentage = 0.05;

const JavaHeapResultTab = (props) => {
  Log.debug('JavaHeapResultTab', props);
  const { Panel } = Collapse;
  const [current, setCurrent] = React.useState(1);
  const [pageSize, setPageSize] = React.useState(10);
  const [showAll] = React.useState(true);
  const [hprofResult, setHprofResult] = React.useState([]);

  useEffect(() => {
    const filteredAndSortedResult = props.hprof_result
      .filter(item => showAll || item.overall_percentage >= bigObjectPercentage)
      .sort((a, b) => {
        const x = b.analyze_result?.size - a.analyze_result?.size;
        if (x !== 0) {
          return x;
        }
        return b.additional_info?.duplication_count - a.additional_info?.duplication_count;
      });
    setHprofResult(filteredAndSortedResult);
  }, [showAll, props.hprof_result]);

  const onChange = React.useCallback((pageInfo) => {
    const index = pageInfo.current;
    setCurrent(index);
  }, []);

  const onPageSizeChange = React.useCallback((index) => {
    setPageSize(index);
  }, []);

  return <Space direction="vertical" size={8} style={{ paddingTop: '8px', width: '100%' }}>
      {props.hprof_result.length ? <span style={{ display: 'flex', justifyContent: 'space-between' }}>
          <div style={{ paddingTop: '2px' }}>
            <InfoCircleIcon />
            <Text style={{ paddingLeft: '3px', paddingRight: '12px' }}>点击条目可查看对应引用链</Text>
          </div>
        </span> : null
      }
    {hprofResult.length ? null : <Text theme="success">{'暂无统计信息'}</Text>}
    <Collapse defaultValue={['default']}>
      {
        hprofResult.slice((current - 1) * pageSize, Math.min(current * pageSize, hprofResult.length))
          .map((result, index) => {
            console.log(result, index);
            const referenceChain = result.problem_info.toString().split('\n');
            const stackChain = result.stack_info.toString().split('\n');
            return <Panel key={index} destroyOnCollapse header={
              <div>
                {result?.message}
              </div>
            }>
              <Space direction="vertical" size={0}>
                 相关引用链:
                {
                  referenceChain.map((chain, index) => <Text key={index}>{chain}</Text>)
                }
                <br/>
                {result.stack_info.toString() ? '所在线程调用栈：' : null}
                {result.stack_info.toString()
                  ? stackChain.map((chain, index) => <Text key={index}>{chain}</Text>)
                  : null}
              </Space>
            </Panel>;
          })
      }
    </Collapse>
    {hprofResult.length > pageSize && <Pagination
      showJumper
      total={hprofResult.length}
      current={current}
      onChange={onChange}
      pageSize={pageSize}
      onPageSizeChange={onPageSizeChange}
    />}
  </Space>;
};

JavaHeapResultTab.propTypes = {
  hprof_result: PropTypes.array,
};

export default JavaHeapResultTab;
