export function stack(node, handle) {
  handle(node);
  ancestors(node, handle);
  descendants(node, handle);
}

export function nonStack(node, handle) {
  siblings(node, handle);
  ancestors(node, (n) => {
    siblings(n, handle);
  });
}

export function ancestors(node, handle) {
  if (!node.parent) {
    return;
  }
  let { parent } = node;
  while (parent.parent) {
    handle(parent);
    parent = parent.parent;
  }
}

export function nonAncestors(node, handle) {
  handle(node);
  ancestors(node, (parent) => {
    siblings(parent, handle);
  });
  siblings(node, handle);
  descendants(node, handle);
}

export function siblings(node, handle) {
  if (!node.parent) {
    return;
  }
  const { children } = node.parent;
  children.forEach((child) => {
    if (child !== node) {
      handle(child);
      descendants(child, handle);
    } else {
    }
  });
}

export function descendants(node, handle) {
  if (!node.children) {
    return;
  }
  node.children.forEach(n => forEachNode(n, handle));
}

export function nonDescendants(node, handle) {
  handle(node);
  siblings(node, handle);
  ancestors(node, handle);
  ancestors(node, (parent) => {
    siblings(parent, handle);
  });
}

function forEachNode(node, f) {
  f(node);
  let { children } = node;
  if (children) {
    const stack = [children];
    let count; let child; let grandChildren;
    while (stack.length) {
      children = stack.pop();
      count = children.length;
      /* eslint-disable no-plusplus */
      while (count--) {
        child = children[count];
        f(child);
        grandChildren = child.children;
        if (grandChildren) {
          stack.push(grandChildren);
        }
      }
    }
  }
}
