/*
description:Drop主页面 通过页面切换加载不同的功能界面
*/
import React, { memo, useEffect, Suspense, useState } from 'react';
import {
  Layout,
  Card,
  message,
  StatusTip,
} from '@tencent/tea-component';
import AppHeader from 'components/header';
// import LeftMenu from 'components/leftMenu';
import AppFooter from 'components/footer';
import '@tencent/tea-component/dist/tea.css';
// import { BrowserRouter } from 'react-router-dom';
import { renderRoutes } from 'react-router-config';
import { getOAName, getUserUid } from '@/utils/Storage';
import { bindActionCreators } from 'redux';
import { goodStatus, doesntHaveStatus, badStatus } from '@/utils/ErrorConfirm';
import DropActionCreator from '@/api/DropActions';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { jumpTargetConfig } from '../../utils/URL';
const { Header, Body, Content, Footer } = Layout;

Drop.propTypes = {
  route: PropTypes.any,
  DropAction: PropTypes.any.isRequired,
  location: PropTypes.any.isRequired,
  history: PropTypes.any.isRequired,
  DropReducer: PropTypes.any.isRequired,
};

function Drop(props) {
  const { route } = props;

  // hook
  // hook-state
  const [loadingAgents, setLoadingAgents] = useState(true);

  // 是否展示header
  const mp = {
    '/tasks': '任务列表',
    '/user': '快速接入',
    '/agents': '机器列表',
    '/tasksByAgent': '历史任务',
    '/generalSampling': '通用分析',
    '/javaSampling': 'Java采样分析',
    '/timingSampling': '时序分析',
    '/tracing': '动态追踪',
    '/diskTracingResult': '磁盘追踪结果',
    '/task/result': '任务结果',
  };

  // 是否展示card
  const cardMp = {
    '/tasks': '任务列表',
    '/user': '配置信息',
    '/agents': '机器列表',
    '/tasksByAgent': '任务列表',
    '/tracing': '动态追踪',
    '/diskTracingResult': '磁盘追踪结果',
    '/groups': '用户组',
    '/task/result': '任务结果',
    '/fileAnalysis': '文件分析',
  };

  // 触发登陆副作用
  useEffect(() => {
    getUserUid();
    props.DropAction.getDropAgentList({
      user: getOAName(),
    });
  }, []);

  // effect
  useEffect(() => {
    const { StartDiskTracing } = props.DropReducer;
    // 有值才进入
    if (Object.keys(StartDiskTracing).length) {
      if (doesntHaveStatus(StartDiskTracing)) {
        if (StartDiskTracing.message) {
          message.error({
            content: `erro:${StartDiskTracing.message}`,
          });
        } else {
          message.error({
            content: '任务创建失败',
          });
        }
      } else if (badStatus(StartDiskTracing)) {
        if (StartDiskTracing.message) {
          message.error({
            content: `erro:${StartDiskTracing.message}`,
          });
        } else {
          message.error({
            content: '任务创建失败',
          });
        }
      }
      if (goodStatus(StartDiskTracing)) {
        // 弹出成功了的提示
        message.success({
          content: '任务创建成功',
        });
      }
    }
  }, [props.DropReducer]);

  useEffect(() => {
    // 获取agent的回调
    const AgentList = props.DropReducer.GetDropAgentList;
    // 有结果
    if (Object.keys(AgentList).length) {
      if (doesntHaveStatus(AgentList)) {
        message.error({
          content: 'Agent信息获取失败,请重试',
        });
      } else if (badStatus(AgentList)) {
        message.error({
          content: 'Agent信息获取失败,请重试',
        });
      }
      if (goodStatus(AgentList)) {
        if (AgentList.data.agents.length === 0  && (props.location.pathname === '/index'
        || props.location.pathname === '/')) {
          props.history.push('/miniIndex');
        }
        setLoadingAgents(false);
      }
    }
  }, [props.DropReducer]);

  const mainContent = ((cardMp[props.location.pathname]))
    ? <Card>
        <Card.Body >
            <Suspense fallback={'loading'}>{renderRoutes(route.routes)}</Suspense>
        </Card.Body>
      </Card>
    : <Suspense fallback={'loading'}>{renderRoutes(route.routes)}</Suspense>;

  return <Layout>
      <Header>
        <AppHeader props={props} />
      </Header>
      <Body style={{ minHeight: '780px' }}>
        {/* <Sider>
          <LeftMenu ></LeftMenu>
        </Sider> */}
        <Content>
          {(mp[props.location.pathname]) ? <Content.Header
              title={mp[props.location.pathname]}
              showBackButton = { jumpTargetConfig() === '_self'}
              onBackButtonClick={() => props.history.goBack()}
            /> : ''}
          <Content.Body >
            {
              (loadingAgents)
                ? <StatusTip
                status="loading"
                style={{ margin: '200px 45%' }}
              />
                : mainContent
            }
          </Content.Body>
        </Content>
      </Body>
      <Footer>
        <AppFooter></AppFooter>
      </Footer>
    </Layout>;
}


const mapStateToProps = (state) => {
  const { DropReducer } = state;
  return {
    DropReducer,
  };
};// Reducer

const mapDispatchToProps = (dispatch) => {
  const DropAction = bindActionCreators(DropActionCreator, dispatch);

  return {
    DropAction,
  };
};// Action


export default memo(connect(
  mapStateToProps,
  mapDispatchToProps,
)(Drop));
