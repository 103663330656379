import React, { useEffect } from 'react';
import { Log } from '../../../utils/Log';
import PropTypes from 'prop-types';
import { Collapse, Pagination } from 'tdesign-react';
import axios from 'axios';
import { HOST_URL } from '../../../config';
import './domtree.css';
import { getFileSize } from '../../../utils/File';
import { InfoCircleIcon } from 'tdesign-icons-react';
import { Text } from '@tencent/tea-component';

const DomTreeTab = (props) => {
  Log.debug('DomTreeTab', props);
  return <>
        <span style={{ display: 'flex', justifyContent: 'space-between' }}>
          <div style={{ paddingTop: '10px', paddingBottom: '8px' }}>
            <InfoCircleIcon/>
            <Text style={{ paddingLeft: '3px', paddingRight: '12px' }}>{'已隐藏占用空间较小（<0.01%）的对象'}</Text>
          </div>
        </span>
        <DomTreeNode
          tid={props.tid}
          objectId={'0'}
        />
    </>;
};

const DomTreeNode = (props) => {
  const [current, setCurrent] = React.useState(1);
  const [nodes, setNodes] = React.useState([]);
  const [pageSize, setPageSize] = React.useState(10);
  const [pendingCollapseValue, setPendingCollapseValue] = React.useState([]);
  const [collapseValue, setCollapseValue] = React.useState([]);
  const [loading, setLoading] = React.useState(true);
  const [total, setTotal] = React.useState(0);
  const [heapSize, setHeapSize] = React.useState(0);

  useEffect(() => {
    if (!nodes) setLoading(true);
    axios({
      method: 'get',
      url: `${HOST_URL}/api/v1/task/detail/javadomtree`,
      params: {
        tid: props.tid,
        target: props.objectId,
        page_size: pageSize,
        page: current,
      },
    })
      .then((response) => {
        setNodes(response.data.children);
        setTotal(response.data.total);
        setHeapSize(response.data.heapSize);
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [pageSize, current]);

  useEffect(() => {
    if (pendingCollapseValue.length < collapseValue.length) {
      setCollapseValue(pendingCollapseValue);
    } else {
      setTimeout(() => setCollapseValue(pendingCollapseValue), 100);
    }
  }, [pendingCollapseValue]);

  const { Panel } = Collapse;

  const onChange = React.useCallback((pageInfo) => {
    const index = pageInfo.current;
    setCurrent(index);
    setPendingCollapseValue([]);
    setCollapseValue([]);
  }, []);

  const onPageSizeChange = React.useCallback((index) => {
    setPageSize(index);
  }, []);

  const node = <>
    <Collapse className="dom-tree-node" value={collapseValue} onChange={setPendingCollapseValue} style={{ padding: '0' }}>
      {nodes.map((node, index) => <Panel
          key={index}
          style={{ padding: '0' }}
          header={`${(node.Type === 'class' ? 'class ' : '')}${node.Title} @ 0x${node.Id.toString(16)}`}
          headerRightContent={`${(node.RetainedSize / heapSize * 100).toFixed(2)}%(${getFileSize(node.RetainedSize)})`}>
          {pendingCollapseValue.includes(index + 1) && <DomTreeNode tid={props.tid} objectId={node.Id}></DomTreeNode>}
      </Panel>)}
    </Collapse>

    {total > 10 && <Pagination
    showJumper
    total={total}
    current={current}
    onChange={onChange}
    pageSize={pageSize}
    onPageSizeChange={onPageSizeChange}
    />}
  </>;
  return <>{loading ? '加载中' : node}</>;
};

DomTreeTab.propTypes = {
  tid: PropTypes.string,
};

DomTreeNode.propTypes = {
  tid: PropTypes.string,
  objectId: PropTypes.string,
};

export default DomTreeTab;
