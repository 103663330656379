import React, { useState, useEffect } from 'react';
import {
  message,
} from '@tencent/tea-component';
import { goodStatus } from '@/utils/ErrorConfirm';
import { HOST_URL } from '@/config';
import axios from 'axios';
import { withRouter } from 'react-router-dom';
import StatAgentModal from './statAgentModal';
import PropTypes from 'prop-types';

const StatAgent = withRouter((props) => {
  // hook
  const [statAgentResult, setStatAgentResult] = useState({
    selfPstats: [],
    childrenPstats: [],
  });

  // hook
  useEffect(() => {
    const polling = setInterval(() => {
      statAgent(props.agent);
    }, 2000);
    return () => clearInterval(polling);
  }, []);

  const statAgent = (agent) => {
    axios({
      method: 'get',
      url: `${HOST_URL}/api/v1/agent/stat`,
      params: {
        target_ip: agent.ipaddr,
      } })
      .then((response) => {
        if (goodStatus(response)) {
          setStatAgentResult(response?.data?.result);
        } else {
          message.error({
            content: '信息获取失败,请重试',
          });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  // render
  return <StatAgentModal
      visable = { props.visable }
      onClose = { () => props.onClose() }
      result = { statAgentResult }
    /> ;
});


StatAgent.propTypes = {
  visable: PropTypes.bool,
  onClose: PropTypes.func,
  agent: PropTypes.object,
};

export default StatAgent;
