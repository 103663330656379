import React, { memo, useState, useEffect } from 'react';
import {
  message,
  Button,
} from '@tencent/tea-component';
import IndexTaskList from '@/pages/dropIndex/taskList';
import { getOAName } from '@/utils/Storage';
import { goodStatus } from '@/utils/ErrorConfirm';
import { HOST_URL } from '@/config';
import axios from 'axios';
import { withRouter } from 'react-router-dom';
import CheckModal from '@/components/checkModal';
import { bindActionCreators } from 'redux';
import DropActionCreator from '@/api/DropActions';
import { connect } from 'react-redux';
import StartTaskComponent from '@/components/startTaskComponent';
import qs from 'qs';
import PropTypes from 'prop-types';
import { Log } from '@/utils/Log';
import { jumpTargetConfig } from '../../utils/URL';

const AllTaskList = (props) => {
  Log.debug('AllTaskList', props);
  const { agent } = qs.parse(props.location.search.slice(1));
  const filterAgent = agent ?? '';
  // hook
  const [username] = useState(getOAName());
  const [tasks, setTasks] = useState([]);
  const [agents, setAgents] = useState([]);
  const [deleteTaskObject, setDeteleTaskObject] = useState({});

  // hook-status
  const [showDeleteTask, setShowDeleteTask] = useState(false);
  const [showStartTask, setShowStartTask] = useState(false);

  // effect
  // init
  useEffect(() => {
    queryTasks();
    queryAgents();
  }, []);

  // polling
  useEffect(() => {
    const pollingAgents = setInterval(() => {
      queryAgents();
    }, 2000);
    return () => clearInterval(pollingAgents);
  }, []);

  useEffect(() => {
    const pollingTasks = setInterval(() => {
      queryTasks();
    }, 2000);
    return () => clearInterval(pollingTasks);
  }, []);

  // func
  const queryTasks = () => {
    axios({
      method: 'get',
      url: `${HOST_URL}/api/v1/tasks`,
      params: {
        user: username,
        target_ip: filterAgent,
      } })
      .then((response) => {
        if (goodStatus(response)) {
          setTasks(response.data.tasks.sort((a, b) => Date.parse(b.create_time.Time) - Date.parse(a.create_time.Time)));
        } else {
          message.error({
            content: '用户信息获取失败,请重试',
          });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const queryAgents = () => {
    axios({
      method: 'get',
      url: `${HOST_URL}/api/v1/agents`,
      params: {
        user: username,
      } })
      .then((response) => {
        if (goodStatus(response)) {
          setAgents(response?.data?.agents);
        } else {
          message.error({
            content: '用户信息获取失败,请重试',
          });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const deleteTask = (deleteTaskObject) => {
    axios({
      method: 'delete',
      url: `${HOST_URL}/api/v1/tasks/${deleteTaskObject.tid}`,
    })
      .then((response) => {
        if (goodStatus(response)) {
          setShowDeleteTask(false);
          message.success({
            content: '删除成功',
          });
        } else {
          message.error({
            content: '删除失败',
          });
        }
      })
      .catch((error) => {
        console.log(error);
        message.success({
          content: '系统错误',
        });
      });
  };

  // render
  return <>
      <Button
        type='primary'
        onClick={() => setShowStartTask(true)}>
      新建任务</Button>
      <IndexTaskList
        tasks={ tasks }
        viewTask = { (task) => {
          window.open(`/task/result?${qs.stringify({ taskID: task.tid, isSharedURL: false })}`, jumpTargetConfig());
        } }
        deleteTask = { (task) => {
          setShowDeleteTask(true);
          setDeteleTaskObject(task);
        }}
        tableRow = {10}
        bordered = {true}
      />
    <CheckModal
      caption='确定删除任务?'
      visable = {showDeleteTask}
      bodyText = '删除后无法恢复,是否删除?'
      okText='确认'
      cancelText='取消'
      onClick={ () => {
        deleteTask(deleteTaskObject);
      }}
      onClose={ () => {
        setShowDeleteTask(false);
      }}
    />
    <StartTaskComponent
      visable={showStartTask}
      agents={agents}
      onClose={ () => setShowStartTask(false) }
    ></StartTaskComponent>
  </> ;
};

AllTaskList.propTypes = {
  history: PropTypes.object,
  location: PropTypes.object,
};

// 只存一次接口数据 下次请求会替换掉当前的数据 所以需要把数据保存到state里
const mapStateToProps = (state) => {
  const { DropReducer } = state;
  return {
    DropReducer,
  };
};// Reducer

const mapDispatchToProps = (dispatch) => {
  const DropAction = bindActionCreators(DropActionCreator, dispatch);

  return {
    DropAction,
  };
};// Action


export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(memo(withRouter(AllTaskList)));
