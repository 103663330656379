const DEBUG = process.env.NODE_ENV === 'development';
const TEST_ENV = process.env.REACT_APP_ENV !== 'build';

// 由 public/config.js 动态注入
const HOST_URL = window?.config?.HOST_URL;
const GongFengHOST = window?.config?.GongFengHOST;
const AGENT_DOCKER_RUN_HOST = window?.config?.AGENT_DOCKER_RUN_HOST;
const AGENT_DOCKER_RUN_PORT = window?.config?.AGENT_DOCKER_RUN_PORT;
const AGENT_SSL_ENABLED = window?.config?.AGENT_SSL_ENABLED;
const K8S_IMAGE_NAME = window?.config?.K8S_IMAGE_NAME;

const PRODUCT_NAME = 'drop';
const PRODUCT_PREFIX = `${PRODUCT_NAME}_`;

const FIBONA_APPID = '5cd0f40f2a69fc08';
const FIBONA_INSTALL_URL = 'https://chrome.google.com/webstore/detail/fibona/jojigmehaiphbljmilpibfgdkhadbdnn?hl=zh-CN&authuser=0';
const FIBONA_HELP_URL = 'https://iwiki.woa.com/p/4009438183';
const FIBONA_TRIGGER = {
  topN: 11, // 热点函数
  asm: 12, // 热点汇编
  collection: 13, // 知识库关联
  golangmem: 24,
};


//  status    analysis_status    对应状态
//   0                              新建
//   1                              执行中
//   3                              执行失败
//   2              0               执行成功，待分析
//   2              1               执行成功，分析中
//   2              2               执行成功，分析成功
//   2              3               执行成功，分析失败
//   4专为复合任务生成
//   4              0               执行完成了一个检查点
//   4              1               有部分检查点分析成功
//   4              2               全部分析完成
const TASK_STATUS_INFO = {
  0: '新建',
  10: '执行中',
  20: '执行成功，待分析',
  21: '执行成功，分析中',
  22: '执行成功，分析成功',
  23: '执行成功，分析失败',
  30: '执行失败',
  40: '执行与分析中',
  41: '部分检查点成功,执行与分析中',
  42: '全部完成',
};

const TASK_TYPE_MAP = {
  0: '通用分析',
  1: 'Java采样分析',
  2: '磁盘追踪',
  3: '时序分析',
  4: '内存泄漏检测',
  5: 'Golang内存分析',
  6: 'Java堆分析',
  '-1': '动态检测',
};

const ERROR_CODE_MSG = {
  1012003: '上传采样数据失败, 请重试(errcode=1012003)',
  1021006: '任务参数有误, 请调整参数后重试(errcode=1021006)',
  1021004: '剩余内存/磁盘资源不足,中止执行,请检查资源剩余情况(errcode=1021004)',
  1021007: '采样器执行失败,请重试(errcode=1021007)',
  1021008: '采样器执行中止,请重试(errcode=1021008)',
  1026008: '热点汇编数据获取失败,请重试(errcode=1026008)',
  1027007: '采样数据解析失败,请重试(errcode=1027007)',
  1027008: '采样数据解析中止,请重试(errcode=1027008)',
  1022002: '无权限创建输出文件,采样器启动失败(errcode=1022002)',
  1022007: '采样器执行失败,请重试(errcode=1022007)',
  1022008: '采样器执行中止,请重试(errcode=1022008)',
  1022012: '无法读取输出文件,采样结果上传失败(errcode=1022012)',
  1022013: '目标pid不存在,采样器执行中止(errcode=1022013)',
  1022014: '进程正在被其他采样器占用,本次采样中止(errcode=1022014)',
  1025007: '磁盘追踪执行失败,请重试(errcode=1025007)',
  1025008: '磁盘追踪执行中止,请重试(errcode=1025008)',
  1023007: '虚拟内存泄漏检测执行失败,请重试(errcode=1023007)',
  1023008: '虚拟内存泄漏检测执行中止,请重试(errcode=1023008)',
  1024007: '获取PProf数据失败,请重试(errcode=1024007)',
  1024008: '获取PProf数据中止,请重试(errcode=1024008)',
  1011015: 'agent异常退出,请重试(errcode=1011015)',
  1011016: 'agent拉起超时或任务下发超时,请重试(errcode=1011016)',
  1011018: 'agent异常退出后快速重启, 请重试(errcode=1011018)',
};

const CODE_TO_MESSAGE = (codestr) => {
  if (codestr in ERROR_CODE_MSG) {
    return ERROR_CODE_MSG[codestr];
  }
  return codestr;
};

const TASK_NAME_MAP = {
  ProfilingGeneralTask: 0,
  ProfilingJavaTask: 1,
  ProfilingTimingAnalysisTask: 3,
  TracingDiskTraceTask: 2,
  TracingMemLeakTask: 4,
  PprofMemLeakTask: 5,
  TracingJavaHeapTask: 6,
  DynamicDetection: -1,
};


// 功能最低版本
// eg: 1.1.0 => 10100 1.2.45 => 10245
const FEATURE_MINIMAL_VERSION = {
  profilingByContainer: 10100,
  annotate: 10201,
  record_process: 10207,
  dwarf_lbr_callgraph_option: 10208,
  pprof_profiling: 10300,
  resource_precheck: 10301,
};

// agent环境自检功能表
const MACHINE_FEATURES = {
  // wide option
  OPTION_PORT: 1000000,
  OPTION_COMM: 1000100,
  OPTION_PID_SUBPROCESS: 1000200,
  OPTION_CONTAINER_MODE: 1000300,
  OPTION_COMMAND: 1000400,
  // hotmethod
  PERF_ENABLE: 2000000,
  PERF_RECORD_ENABLE: 2000100,
  PERF_SCRIPT_ENABLE: 2000200,
  PERF_ANNOTATE_ENABLE: 2000300,
  ASYNC_PROFILER_ENABLE: 2000400,
  PPROF_ENABLE: 2000500,
  // bcc tracing
  BCC_ENABLE: 3000100,
  MEMLEAK_ENABLE: 3000200,
  PAGE_FAULT_ENABLE: 3000300,
  BIO_ENABLE: 3000400,
};

module.exports = {
  DEBUG,
  PRODUCT_NAME,
  PRODUCT_PREFIX,
  HOST_URL,
  GongFengHOST,
  TEST_ENV,
  AGENT_DOCKER_RUN_PORT,
  AGENT_DOCKER_RUN_HOST,
  AGENT_SSL_ENABLED,
  K8S_IMAGE_NAME,
  TASK_STATUS_INFO,
  TASK_TYPE_MAP,
  FEATURE_MINIMAL_VERSION,
  MACHINE_FEATURES,
  TASK_NAME_MAP,
  FIBONA_APPID,
  FIBONA_INSTALL_URL,
  FIBONA_HELP_URL,
  FIBONA_TRIGGER,
  CODE_TO_MESSAGE,
};
