import React from 'react';
import {
  Text,
  H4,
} from '@tencent/tea-component';

const EmptyTask = () => <>
    <div style={{ height: '350px' }}>
      <div style={{ display: 'inline-block', marginLeft: '150px' }}>
        <img style={{ marginTop: '100px' }} alt='无数据' src='noTaskData.png'></img>
        <Text theme='primary' parent='p' style={{ margin: '30px auto' }}>无历史任务记录</Text>
      </div>
      <div style={{ display: 'inline-block', marginLeft: '50px' }}>
          <H4 style={{ marginBottom: '40px' }} parent='div' theme="text">通用分析任务介绍</H4>
          <Text style={{ marginBottom: '60px', height: '40px' }} parent='div' theme="text">
            通过perf采样器收集数据，性能分析后生成优化建议</Text>
          <Text style={{ marginBottom: '40px' }} parent='p' theme="text">主要板块：
            <b>开销数据、热点方法、活跃线程、火焰图</b></Text>
      </div>
      <div style={{ display: 'inline-block', marginLeft: '100px' }}>
          <H4 style={{ marginBottom: '40px' }} parent='div' theme="text">Java采样分析任务介绍</H4>
          <Text theme="text" parent='div' style={{ marginBottom: '60px', width: '200px', height: '40px' }}>通过async-profiler采样器收集目标JVM内部活动与Java程序的数据，
          快速定位OOM等常见问题，提供GC暂停时间长、线程阻塞等瓶颈的优化建议
          </Text>
          <Text theme="text" parent='div' style={{ marginBottom: '40px' }}>主要板块：<b>CPU 、内存、GC</b></Text>
      </div>
      <div style={{ display: 'inline-block', marginLeft: '100px' }}>
          <H4 style={{ marginBottom: '40px' }} parent='div' theme="text">动态追踪任务介绍</H4>
          <Text theme="text" parent='div' style={{ marginBottom: '60px', width: '200px', height: '40px' }}>通过我们编写的biotrace工具追踪I/O事件，提供I/O延迟分布情况、I/O错误追踪等信息
          </Text>
          <Text theme="text" parent='div' style={{ marginBottom: '40px' }}>主要板块：<b>磁盘I/O、网络I/O</b></Text>
      </div>
    </div>
  </>;

export default EmptyTask;

