/*
time:2021-9-2
author:ashinz
description:Drop User Info 界面
*/

import React from 'react';
import {
  Card,
  Copy,
  Form,
} from '@tencent/tea-component';
import { bindActionCreators } from 'redux';
import DropActionCreator from '@/api/DropActions';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import '@tencent/tea-component/dist/tea.css';
import { getOAName, getUserUid } from '@/utils/Storage';
import '../Drop.css';
import PropTypes from 'prop-types';
import AccessAgent from '../../components/accessAgent';

class DropUserInfo extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      user: getOAName(), // 用户名 通过tps获取
      uid: getUserUid(), // 用户uid
    };
  }

  render() {
    // 渲染uid的模块
    const content = <div className="drop-user-info-show-module-style">
            <div style={{ margin: '10px auto', padding: '20px' }}>
                <Card style={{ width: '80%' }}>
                    <Card.Body
                        title="用户信息"
                    >
                        <Form readonly>
                            <Form.Item label="用户名">
                                <Form.Text>{this.state.user}</Form.Text>
                            </Form.Item>
                            <Form.Item label="uid">
                                {!this.state.uid
                                  ?                                   <Form.Text>加载中</Form.Text>
                                  : <Form.Text>{this.state.uid} <Copy text={this.state.uid}></Copy></Form.Text>}
                            </Form.Item>
                        </Form>
                    </Card.Body>
                </Card>
                <Card style={{ width: '80%' }}>
                   <AccessAgent></AccessAgent>
                </Card>
            </div>
        </div>;
    return content;
  }
}

DropUserInfo.propTypes = {
  DropAction: PropTypes.any.isRequired,
  DropReducer: PropTypes.any.isRequired,
};

const mapStateToProps = (state) => {
  const { DropReducer } = state;
  return {
    DropReducer,
  };
};// Reducer

const mapDispatchToProps = (dispatch) => {
  const DropAction = bindActionCreators(DropActionCreator, dispatch);

  return {
    DropAction,
  };
};// Action


export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps,
)(DropUserInfo));
