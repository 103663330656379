import PropTypes from 'prop-types';
// 封装visable属性
export const VisableItem = props => (props.visable ? props.children : '');

VisableItem.propTypes = {
  visable: PropTypes.bool,
};

VisableItem.defaultProps = {
  visable: false,
};
