/* eslint-disable */
import { select } from 'd3-selection';
// import { format } from 'd3-format';
import { descending } from 'd3-array';
import { partition, hierarchy } from 'd3-hierarchy';
import { easeCubic } from 'd3-ease';
import 'd3-transition';
// import { generateColorVector } from './colorUtils';
// import { calculateColor } from './colorScheme';
import * as d3 from 'd3';
import * as command from './command';
import * as process from './process';

export default function () {
  let w = 960; // graph width
  let h = null; // graph height
  let c = 18; // cell height
  let selection = null; // selection
  let tooltip = null; // tooltip
  let transitionDuration = 750;
  let transitionEase = easeCubic; // tooltip offset
  let sort = false;
  let inverted = false; // invert the graph direction
  let minFrameSize = 0;
  // let searchDetails = null;
  // let selfValue = false;
  // let scrollOnZoom = false;
  let minHeight = null;
  // let computeDelta = false;
  let colorScheme = null;
  let rootNode = null;

  const getChildren = function (d) {
    return d.c || d.children;
  };

  function show(d) {
    d.data.fade = false;
    d.data.hide = false;
    if (d.children) {
      d.children.forEach(show);
    }
  }

  function hideSiblings(node) {
    let child = node;
    let { parent } = child;
    let children; let i; let sibling;
    while (parent) {
      children = parent.children;
      i = children.length;
      /* eslint-disable no-plusplus */
      while (i--) {
        sibling = children[i];
        if (sibling !== child) {
          sibling.data.hide = true;
        }
      }
      child = parent;
      parent = child.parent;
    }
  }

  function fadeAncestors(d) {
    if (d.parent) {
      d.parent.data.fade = true;
      fadeAncestors(d.parent);
    }
  }

  function zoom(root, d) {
    function processTree(n) {
      hideSiblings(n);
      show(n);
      fadeAncestors(n);
    }

    if (root.focusNode && d.depth === 0) {
      const { calleesRoot, callersRoot } = root.focusNode;
      processTree(calleesRoot);
      processTree(callersRoot);
    } else {
      hideSiblings(d);
      show(d);
      fadeAncestors(d);
    }

    if (tooltip) tooltip.hide();
    update();
  }

  function sortByValue(a, b) {
    return descending(a.value, b.value);
  }

  const p = partition();

  function updateTree() {
    selection.each((root) => {
      process.updateTree(root, sortByValue);
    });
  }

  function render() {
    selection.each(function (root) {
      const svg = select(this).select('svg');
      process.render({
        root,
        partition: p,

        svg,
        colorScheme,
        zoom,
        tooltip,

        graphWidth: w,
        // h: h,
        cellHeight: c,
        transitionEase,
        transitionDuration,
        reappraiseNode,
        minFrameSize,
      });
    });
  }

  function update() {
    updateTree();
    render();
  }

  function merge(data, samples) {
    samples.forEach((sample) => {
      const node = data.find(element => (element.name === sample.name));

      if (node) {
        node.value += sample.value;
        if (sample.children) {
          if (!node.children) {
            node.children = [];
          }
          merge(node.children, sample.children);
        }
      } else {
        data.push(sample);
      }
    });
  }

  function forEachNodeOri(node, f) {
    f(node);
    let children = node.oriChildren;
    if (children) {
      const stack = [children];
      let count; let child; let grandChildren;
      while (stack.length) {
        children = stack.pop();
        count = children.length;
        /* eslint-disable no-plusplus */
        while (count--) {
          child = children[count];
          f(child);
          grandChildren = child.oriChildren;
          if (grandChildren) {
            stack.push(grandChildren);
          }
        }
      }
    }
  }

  function forEachNode(node, f) {
    f(node);
    let { children } = node;
    if (children) {
      const stack = [children];
      let count; let child; let grandChildren;
      while (stack.length) {
        children = stack.pop();
        count = children.length;
        /* eslint-disable no-plusplus */
        while (count--) {
          child = children[count];
          f(child);
          grandChildren = child.children;
          if (grandChildren) {
            stack.push(grandChildren);
          }
        }
      }
    }
  }

  function adoptNode(node) {
    let id = 0;
    forEachNode(node, (n) => {
      /* eslint-disable no-plusplus */
      n.id = id++;
    });
    return id;
  }

  function reappraiseNode(root) {
    let node; let children; let grandChildren; let childrenValue; let i; let j; let child; let childValue;
    const stack = [];
    const included = [];
    const excluded = [];
    const compoundValue = true;
    let item = root.data;
    if (item.hide) {
      root.value = 0;
      children = root.children;
      if (children) {
        excluded.push(children);
      }
    } else {
      root.value = item.fade ? 0 : item.value;
      stack.push(root);
    }
    // First DFS pass:
    // 1. Update node.value with node's self value
    // 2. Populate excluded list with children under hidden nodes
    // 3. Populate included list with children under visible nodes
    while ((node = stack.pop())) {
      children = node.children;
      if (children && (i = children.length)) {
        childrenValue = 0;
        while (i--) {
          child = children[i];
          item = child.data;
          if (item.hide) {
            child.value = 0;
            grandChildren = child.children;
            if (grandChildren) {
              excluded.push(grandChildren);
            }
            continue;
          }
          if (item.fade) {
            child.value = 0;
          } else {
            childValue = item.value;
            child.value = childValue;
            childrenValue += childValue;
          }
          stack.push(child);
        }
        // Here second part of `&&` is actually checking for `node.data.fade`. However,
        // checking for node.value is faster and presents more oportunities for JS optimizer.
        if (compoundValue && node.value) {
          node.value -= childrenValue;
        }
        included.push(children);
      }
    }
    // Postorder traversal to compute compound value of each visible node.
    i = included.length;
    /* eslint-disable no-plusplus */
    while (i--) {
      children = included[i];
      childrenValue = 0;
      j = children.length;
      /* eslint-disable no-plusplus */
      while (j--) {
        childrenValue += children[j].value;
      }
      children[0].parent.value += childrenValue;
    }
    // Continue DFS to set value of all hidden nodes to 0.
    while (excluded.length) {
      children = excluded.pop();
      j = children.length;
      while (j--) { /* eslint-disable no-plusplus */
        child = children[j];
        child.value = 0;
        grandChildren = child.children;
        if (grandChildren) {
          excluded.push(grandChildren);
        }
      }
    }
  }

  function processData() {
    selection.datum((data) => {
      if (data.constructor.name !== 'Node') {
        // creating a root hierarchical structure
        const root = hierarchy(data, getChildren);
        // augumenting nodes with ids
        root.maxid = adoptNode(root);
        // calculate actual value
        reappraiseNode(root);

        calcuSelfValue(root);
        // store value for later use
        root.originalValue = root.value;

        // computing deltas for differentials
        // if (computeDelta) {
        //   root.eachAfter((node) => {
        //     let sum = getDelta(node);
        //     const { children } = node;
        //     let i = children && children.length;
        //     while (--i >= 0) sum += children[i].delta;
        //     node.delta = sum;
        //   });
        // }

        // setting the bound data for the selection
        return root;
      }
    });

    selection.each((root) => {
      rootNode = root;
    });

    forEachNode(rootNode, (n) => {
      if (n.oriValue !== undefined) {
        return;
      }
      n.oriValue = n.value;
      n.oriDepth = n.depth;
      if (n.children) {
        n.oriChildren = [...n.children];
      }
      n.oriParent = n.parent;
    });

    rootNode.topNodes = rootNode.descendants().sort((a, b) => d3.descending(a.value, b.value));
    rootNode.topSelfvNodes = rootNode.descendants().sort((a, b) => d3.descending(a.selfv, b.selfv));
    rootNode.totalValue = rootNode.value
    rootNode.isRoot = true
  }

  function calcuSelfValue(root) {
    root.each((n) => {
      if (!n.children) {
        n.selfv = n.value;
        n.data.selfv = n.value; // 为了在copy节点的时候，可以复用selfv
        return;
      }

      let childrenVal = 0;
      n.children.forEach((c) => {
        childrenVal += c.value;
      });
      n.selfv = parseFloat((n.value - childrenVal).toFixed(10));
      n.data.selfv = parseFloat((n.value - childrenVal).toFixed(10));
      console.assert(n.data.selfv >= 0, 'invalid data value > children value', n);
    });

    if (root.isRoot) {
      let childrenVal = 0;
      root.children.forEach((c) => {
        childrenVal += c.selfv;
      });
      root.selfv = childrenVal;
      root.data.selfv = childrenVal;
    }
  }

  function chart(s) {
    if (!arguments.length) {
      return chart;
    }

    // saving the selection on `.call`
    selection = s;

    // processing raw data to be used in the chart
    processData();

    // create chart svg
    selection.each(function () {
      if (select(this).select('svg')
        .size() === 0) {
        const svg = select(this)
          .append('svg:svg')
          .attr('width', w)
          .attr('class', 'partition d3-flame-graph');

        if (h) {
          if (h < minHeight) h = minHeight;
          svg.attr('height', h);
        }

        svg.append('svg:text')
          .attr('class', 'title')
          .attr('text-anchor', 'middle')
          .attr('y', '25')
          .attr('x', w / 2)
          .attr('fill', '#808080');
        // .text(title);
      }
    });

    // first draw
    update();
  }

  chart.height = function (_) {
    if (!arguments.length) {
      return h;
    }
    h = _;
    return chart;
  };

  chart.minHeight = function (_) {
    if (!arguments.length) {
      return minHeight;
    }
    minHeight = _;
    return chart;
  };

  chart.width = function (_) {
    if (!arguments.length) {
      return w;
    }
    w = _;
    return chart;
  };

  chart.cellHeight = function (_) {
    if (!arguments.length) {
      return c;
    }
    c = _;
    return chart;
  };

  chart.tooltip = function (_) {
    tooltip = _;
    return chart;
  };

  chart.transitionDuration = function (_) {
    if (!arguments.length) {
      return transitionDuration;
    }
    transitionDuration = _;
    return chart;
  };

  chart.transitionEase = function (_) {
    if (!arguments.length) {
      return transitionEase;
    }
    transitionEase = _;
    return chart;
  };

  chart.sort = function (_) {
    if (!arguments.length) {
      return sort;
    }
    sort = _;
    return chart;
  };

  chart.inverted = function (_) {
    if (!arguments.length) {
      return inverted;
    }
    inverted = _;
    return chart;
  };

  chart.updateColorScheme = function (_) {
    colorScheme = _;
    render();
    return chart;
  };

  chart.setColorScheme = function (_) {
    if (!arguments.length) {
      return colorScheme;
    }
    colorScheme = _;
    return chart;
  };

  function resetAll() {
    selection.each((root) => {
      root.focusNode = null; // TODO: 优化
      root.isSort = false;
      forEachNodeOri(root, (n) => {
        n.value = n.oriValue;
        n.parent = n.oriParent;
        n.depth = n.oriDepth;
        n.hidden = false;
        n.hl = false;
        n.focus = false;
        n.rank = 0;
        if (n.oriChildren) {
          n.children = [...n.oriChildren];
        }
      });
      zoom(root, root); // zoom to root
    });
  }

  chart.exec = function (ops) {
    if (!selection) {
      return;
    }

    selection.each((root) => {
      resetAll();

      if (!ops || ops.length === 0) {
        update();
        return;
      }

      ops.forEach((op) => {
        command.exec(root, op);

        if (op[0] === 'focus') {
          updateTree(); // 更新focus节点
        }
      });
      update();
    });
  };

  chart.totalTimeOf = function (node) {
    let root = rootNode;

    let partValue;
    if (root.focusNode) {
      partValue = root.focusNode.calleesRoot.value;
    } else {
      partValue = root.value;
    }
    let totalValue = root.totalValue;

    let nodeValue;
    if (node.isCaller) {
      nodeValue = node.realValue;
    } else {
      nodeValue = node.value;
    }

    const profile = (nodeValue / totalValue * 100).toFixed(2);
    const part = (nodeValue / partValue * 100).toFixed(2);

    if (root.focusNode) {
      return { focused: part, profile };
    }
    return { selected: part, profile };
  };

  chart.selfTimeOf = function (node) {
    let root = rootNode;

    let partValue;
    if (root.focusNode) {
      partValue = root.focusNode.calleesRoot.value;
    } else {
      partValue = root.value;
    }

    let totalValue = root.totalValue;
    let nodeSelfv = node.selfv;

    const part = (nodeSelfv / partValue * 100).toFixed(2);
    const profile = (nodeSelfv / totalValue * 100).toFixed(2);

    if (root.focusNode) {
      return { focused: part, profile };
    }
    return { selected: part, profile };
  };

  chart.realValue = function (node) {
    if (node.isCaller) {
      return node.realValue;
    }
    return node.value;
  };

  chart.topAttrs = function (key, n) {
    const attrs = new Set();
    if (n <= 0) {
      return attrs;
    }
    if (!rootNode) {
      return attrs;
    }

    const root = rootNode;
    let topNodes;
    if (root.focusNodes) {
      topNodes = root.focusNodes.topSelfvNodes;
    } else {
      topNodes = root.topSelfvNodes;
    }
    for (let i = 0; i < topNodes.length && attrs.size < n; ++i) {
      const node = topNodes[i];
      if (!node.hidden && node.data.tid !== -1 && (typeof node.data.key !== undefined)) {
        attrs.add(node.data[key]);
      }
    }
    return attrs;
  };

  chart.merge = function (data) {
    if (!selection) {
      return chart;
    }

    // TODO: Fix merge with zoom
    // Merging a zoomed chart doesn't work properly, so
    //  clearing zoom before merge.
    // To apply zoom on merge, we would need to set hide
    //  and fade on new data according to current data.
    // New ids are generated for the whole data structure,
    //  so previous ids might not be the same. For merge to
    //  work with zoom, previous ids should be maintained.
    // this.resetZoom();

    // Clear search details
    // Merge requires a new search, updating data and
    //  the details handler with search results.
    // Since we don't store the search term, can't
    //  perform search again.
    // searchDetails = null;
    // detailsHandler(null);

    selection.datum((root) => {
      merge([root.data], [data]);
      return root.data;
    });
    processData();
    update();
    return chart;
  };

  chart.update = function (data) {
    if (!selection) {
      return chart;
    }
    if (data) {
      selection.datum(data);
      processData();
    }
    update();
    return chart;
  };

  chart.destroy = function () {
    if (!selection) {
      return chart;
    }
    if (tooltip) {
      tooltip.hide();
    }
    selection.selectAll('svg').remove();
    return chart;
  };

  chart.minFrameSize = function (_) {
    if (!arguments.length) {
      return minFrameSize;
    }
    minFrameSize = _;
    return chart;
  };

  chart.updateMinFrameSize = function (_) {
    minFrameSize = _;
    render();
    return chart;
  };

  return chart;
}
