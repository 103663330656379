import React from 'react';
import {
  Modal,
  Button,
} from '@tencent/tea-component';
import PropTypes from 'prop-types';

const CheckModal = props => <Modal
    size="s"
    caption={ props.caption }
    visible={ props.visable }
    onClose={() => {
      props.onClose();
    }}
  >
    <Modal.Body>{ props.bodyText }</Modal.Body>
    <Modal.Footer>
        <Button type="primary" onClick={() => {
          props.onClick();
        }}>
            { props.okText }
        </Button>
        <Button type="weak" onClick={() => {
          props.onClose();
        }}>
            { props.cancelText }
        </Button>
    </Modal.Footer>
  </Modal>;

CheckModal.propTypes = {
  caption: PropTypes.string,
  visable: PropTypes.bool,
  bodyText: PropTypes.string,
  okText: PropTypes.string,
  cancelText: PropTypes.string,
  onClose: PropTypes.func,
  onClick: PropTypes.func,
};

export default CheckModal;
