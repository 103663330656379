import React, { useState, useRef, useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';

const DraggableScalableContainer = ({ children, className = '', onResetRef }) => {
  const [transform, setTransform] = useState({ x: 0, y: 0, scale: 1 });
  const [isDragging, setIsDragging] = useState(false);
  const [dragStart, setDragStart] = useState({ x: 0, y: 0 });
  const containerRef = useRef(null);
  const contentRef = useRef(null);

  const applyTransform = useCallback(() => {
    if (contentRef.current) {
      contentRef.current.style.transform = `matrix(${transform.scale}, 0, 0, ${transform.scale}, ${transform.x}, ${transform.y})`;
    }
  }, [transform]);

  useEffect(() => {
    applyTransform();
  }, [applyTransform]);

  const getMousePosition = useCallback((e) => {
    const containerRect = containerRef.current.getBoundingClientRect();
    return {
      x: e.clientX - containerRect.left + containerRef.current.scrollLeft,
      y: e.clientY - containerRect.top + containerRef.current.scrollTop,
    };
  }, []);

  const handleMouseDown = useCallback((e) => {
    if (e.button !== 0) return; // Only left mouse button
    setIsDragging(true);
    setDragStart(getMousePosition(e));
  }, [getMousePosition]);

  const handleMouseMove = useCallback((e) => {
    if (isDragging) {
      const mousePos = getMousePosition(e);
      setTransform(prev => ({
        ...prev,
        x: prev.x + (mousePos.x - dragStart.x),
        y: prev.y + (mousePos.y - dragStart.y),
      }));
      setDragStart(mousePos);
    }
  }, [isDragging, dragStart, getMousePosition]);

  const handleMouseUp = useCallback(() => {
    setIsDragging(false);
  }, []);

  const handleWheel = useCallback((e) => {
    e.preventDefault();
    e.stopPropagation();
    const scaleFactor = Math.pow(1.001, -e.deltaY);
    const mousePos = getMousePosition(e);

    setTransform((prev) => {
      const newScale = prev.scale * scaleFactor;
      return {
        scale: newScale,
        x: mousePos.x - (mousePos.x - prev.x) * scaleFactor,
        y: mousePos.y - (mousePos.y - prev.y) * scaleFactor,
      };
    });
  }, [getMousePosition]);

  const resetTransform = useCallback(() => {
    setTransform({ x: 0, y: 0, scale: 1 });
  }, []);

  useEffect(() => {
    if (onResetRef) {
      onResetRef.current = resetTransform;
    }
  }, [onResetRef, resetTransform]);

  useEffect(() => {
    const currentContainer = containerRef.current;
    if (currentContainer) {
      const preventDefaultScroll = (e) => {
        if (e.target === currentContainer || currentContainer.contains(e.target)) {
          e.preventDefault();
        }
      };

      currentContainer.addEventListener('wheel', preventDefaultScroll, { passive: false });

      return () => {
        currentContainer.removeEventListener('wheel', preventDefaultScroll);
      };
    }
  }, []);

  return (
    <div
      ref={containerRef}
      className={`overflow-auto ${className}`}
      onMouseMove={handleMouseMove}
      onMouseUp={handleMouseUp}
      onMouseLeave={handleMouseUp}
      onWheel={handleWheel}
    >
      <div
        ref={contentRef}
        style={{
          cursor: isDragging ? 'grabbing' : 'grab',
          transformOrigin: '0 0',
          width: 'fit-content',
          height: 'fit-content',
          minWidth: '100%',
          minHeight: '100%',
        }}
        onMouseDown={handleMouseDown}
      >
        {children}
      </div>
    </div>
  );
};

DraggableScalableContainer.propTypes = {
  children: PropTypes.element,
  className: PropTypes.string,
  onResetRef: PropTypes.any,
};

export default DraggableScalableContainer;
