import React, { useState }  from 'react';
import PropTypes from 'prop-types';
import { Form,
  Input,
  RadioGroup,
  Radio,
  Button,
  Select,
  Justify,
  message,
} from '@tencent/tea-component';

const StartGeneralTask = (props) => {
  const [ifPerfRecordCallgraph] = useState(true);
  const [name, setName] = useState('');
  const [perfRecordTargetIp, setPerfRecordTargetIp] = useState('');
  const [perfRecordOptions, setPerfRecordOptions] = useState('system');
  const [perfRecordFreq, setPerfRecordFreq] = useState(99);
  const [perfRecordDuration, setPerfRecordDuration] = useState(10);
  const [perfRecordPid, setPerfRecordPid] = useState(-1);
  const [perfRecordType] = useState(0);
  const [perfRecordProfilerType] = useState(0);
  const [containerName] = useState('');
  const [containerType, setContainerType] = useState(0);

  const getArgs = () => ({
    target_ip: perfRecordTargetIp,
    taskDesc: JSON.stringify({
      if_perf_record_callgraph: ifPerfRecordCallgraph,
      name,
      perf_record_target_ip: perfRecordTargetIp,
      perf_record_options: perfRecordOptions,
      perf_record_freq: Number(perfRecordFreq),
      perf_record_duration: Number(perfRecordDuration),
      perf_record_pid: Number(perfRecordPid),
      perf_record_type: perfRecordType,
      perf_record_profiler_type: perfRecordProfilerType,
      container_name: containerName,
      container_type: containerType,
    }),
  });

  return <div style={props.style}>
    <Form layout='fixed'>
      <Justify
        style={{ margin: '0 auto' }}
        left={
          <>
            <Form.Item label="任务名">
              <Input
                value={name}
                onChange={(value, context) => {
                  setName(value);
                  console.log(value, context);
                }}
              />
            </Form.Item>
          </>
        }
        right={
          <>
            <Form.Item label="目标机器">
            <Select
              appearance="default"
              options={
                props.agents.map(agent => ({
                  value: agent.ipaddr,
                  text: `${agent.hostname}：${agent.ipaddr}`,
                  tooltip: (agent.online) ?  `${agent.hostname}：${agent.ipaddr}`
                    : '该机器已离线，请参照指引重新拉起',
                  disabled: !agent.online,
                }))
                }
              value={ perfRecordTargetIp }
              onChange={ value => setPerfRecordTargetIp(value) }
              placeholder="选择发起任务机器"
            />
            </Form.Item>
          </>
        }
      />
        <Justify
          left={
            <Form.Item label="采样范围">
              <RadioGroup
                value={ perfRecordOptions }
                onChange={value => setPerfRecordOptions(value)}
              >
                <Radio name={'system'} >System Wide</Radio>
                <Radio name={'pid'} >PID</Radio>
              </RadioGroup>
            </Form.Item>
          }
          right={
            (perfRecordOptions === 'pid')
              ?  <Form.Item label="PID">
                  <Input
                    value={ perfRecordPid }
                    onChange={(value, context) => {
                      setPerfRecordPid(value);
                      console.log(value, context);
                    }}
                  />
                </Form.Item>
              : ''
            }
        />
        <Justify
          left={
            <Form.Item label="采样时长" message='每次采样的时长，单位秒，默认10'>
              <Input
                value={ perfRecordDuration }
                onChange={(value, context) => {
                  setPerfRecordDuration(value);
                  console.log(value, context);
                }}
              />
            </Form.Item>
          }
          right={
            <Form.Item label="采样频率" message='采样的频率，单位Hz，整数，默认为99'>
            <Input
              value={ perfRecordFreq }
              onChange={(value, context) => {
                setPerfRecordFreq(value);
                console.log(value, context);
              }}
            />
          </Form.Item>
          }
        />
        <Justify
          left={
            <Form.Item label="容器视角"  message='目前仅支持Docker及K8s接入方式'>
              <RadioGroup
                value={ containerType }
                onChange={value => setContainerType(value)}
              >
                <Radio name={1} >开启</Radio>
                <Radio name={0} >关闭</Radio>
              </RadioGroup>
            </Form.Item>
          }
        />
            {/* <Form.Item label="采样次数">
              <Input />
            </Form.Item>
            <Form.Item label="采样间隔">
              <Input />
            </Form.Item> */}
          </Form>
          <Form.Action>
            <Button
              style={{ marginLeft: '600px' }}
              type="primary"
              onClick={() => {
                if (name === '' || perfRecordTargetIp === ''
                || perfRecordFreq === '' || perfRecordDuration === ''
                || perfRecordPid === '') {
                  message.error({
                    content: '参数不为空',
                  });
                  return ;
                }
                props.onSubmit(getArgs());
              }}>
              发起</Button>
          </Form.Action>
  </div>;
};

StartGeneralTask.propTypes = {
  agents: PropTypes.array,
  onSubmit: PropTypes.func,
  style: PropTypes.object,
};

export default StartGeneralTask;

