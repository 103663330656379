import React, { memo, useState, useEffect } from 'react';
import { bindActionCreators } from 'redux';
import DropActionCreator from '../../api/DropActions';
import { connect } from 'react-redux';
import DiskTracing from './diskTracing';
import { getOAName } from '@/utils/Storage';
import { goodStatus, doesntHaveStatus, badStatus } from '@/utils/ErrorConfirm';
import IndexTaskList from '../dropIndex/taskList';
import CheckModal from '@/components/checkModal';
import PropTypes from 'prop-types';
import {
  message,
  Segment,
  Card,
} from '@tencent/tea-component';
import qs from 'qs';
import { jumpTargetConfig } from '../../utils/URL';

const TracingIndex = (props) => {
  // hook
  const [tracingContent, setTracingContent] = useState(0);
  const [agents, setAgents] = useState([]);
  const [tasks, setTasks] = useState([]);
  const [username] = useState(getOAName());
  const [deleteTaskObject, setDeteleTaskObject] = useState({});

  const [showDeleteTask, setShowDeleteTask] = useState(false);


  useEffect(() => {
    const param = {
      user: username,
    };
    props.DropAction.getDropAgentList(param);
    const taskParam = {
      user: username,
      target_ip: '',
    };
    props.DropAction.getDropAgentInfoIP(taskParam);
  }, []);

  useEffect(() => {
    const pollingAgents = setInterval(() => {
      const param = {
        user: username,
      };
      props.DropAction.getDropAgentList(param);
    }, 2000);
    return () => clearInterval(pollingAgents);
  }, []);

  useEffect(() => {
    const pollingTasks = setInterval(() => {
      const taskParam = {
        user: username,
        target_ip: '',
      };
      props.DropAction.getDropAgentInfoIP(taskParam);
    }, 2000);
    return () => clearInterval(pollingTasks);
  }, []);

  useEffect(() => {
    const AgentList = props.DropReducer.GetDropAgentList;
    // 有结果
    if (Object.keys(AgentList).length) {
      if (doesntHaveStatus(AgentList)) {
        message.error({
          content: 'Agent信息获取失败,请重试',
        });
      } else if (badStatus(AgentList)) {
        message.error({
          content: 'Agent信息获取失败,请重试',
        });
      }
      if (goodStatus(AgentList)) {
        setAgents(AgentList.data.agents);
      }
    }
  }, [props.DropReducer]);

  useEffect(() => {
    const GetInfoRes = props.DropReducer.GetAgentInfoIPRes;
    // todo:优化判断为空方法
    if (Object.keys(GetInfoRes).length) {
      if (doesntHaveStatus(GetInfoRes)) {
        message.error({
          content: '任务列表获取失败,请刷新重试',
        });
      } else if (badStatus(GetInfoRes)) {
        message.error({
          content: '任务列表获取失败,请刷新重试',
        });
      }
      if (goodStatus(GetInfoRes)) {
        // debugger;
        const dataSource = GetInfoRes.data.tasks.filter(item => item.type === 2);
        dataSource.sort((a, b) => b.id - a.id);
        setTasks(dataSource);
      }
    }
  }, [props.DropReducer]);

  useEffect(() => {
    const { DeleteDropTask } = props.DropReducer;
    if (Object.keys(DeleteDropTask).length) {
      setShowDeleteTask(false);
      // 错误回调
      if (doesntHaveStatus(DeleteDropTask)) {
        message.error({
          content: '删除失败',
        });
      } else if (badStatus(DeleteDropTask)) {
        message.error({
          content: '删除失败',
        });
      }
      // 删除成功立马更新
      if (goodStatus(DeleteDropTask)) {
        const param = {
          user: username,
          target_ip: '',
        };
        props.DropAction.getDropAgentInfoIP(param);
        message.success({
          content: '删除成功',
        });
      }
    }
  }, [props.DropReducer]);

  // func
  // 发起任务
  const onSubmit = (args) => {
    props.DropAction.startDiskTracing(args);
  };

  const deleteTask = (task) => {
    const param = {
      tid: task.id,
    };
    props.DropAction.deleteDropTask(param);
  };

  // const
  const segmentToModule = [
    (<DiskTracing
      agents={agents}
      onSubmit={onSubmit}
      style={{ margin: '0 auto', width: '1000px' }}
      key={false}></DiskTracing>),
  ];


  // render
  return <>
  <Segment
        value={tracingContent.toString()}
        onChange={value => setTracingContent(parseInt(value, 10))}
        options={[
          { text: '磁盘I/O', value: '0' },
          { text: '网络I/O', value: '1', disabled: true, tooltip: '开发中' },
        ]}
      />
    <Card.Body
      title="发起任务"
    >
      {segmentToModule[tracingContent]}
    </Card.Body>
     <IndexTaskList
      style={{ marginTop: '20px' }}
      tasks={ tasks }
      viewTask = { (task) => {
        window.open(`/task/result?${qs.stringify({ taskID: task.tid, isSharedURL: false })}`, jumpTargetConfig());
      }}
      deleteTask = { (task) => {
        setShowDeleteTask(true);
        setDeteleTaskObject(task);
      }}
    />
      <CheckModal
      caption='确定删除任务?'
      visable = {showDeleteTask}
      bodyText = '删除后无法恢复,是否删除?'
      okText='确认'
      cancelText='取消'
      onClick={ () => {
        deleteTask(deleteTaskObject);
      }}
      onClose={ () => {
        setShowDeleteTask(false);
      }}
    />
  </>;
};


TracingIndex.propTypes = {
  DropReducer: PropTypes.any,
  DropAction: PropTypes.any,
  history: PropTypes.any,
};

const mapStateToProps = (state) => {
  const { DropReducer } = state;
  return {
    DropReducer,
  };
};// Reducer

const mapDispatchToProps = (dispatch) => {
  const DropAction = bindActionCreators(DropActionCreator, dispatch);

  return {
    DropAction,
  };
};// Action


export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(memo(TracingIndex));
