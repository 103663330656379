import React, { memo, useEffect, useState } from 'react';
import {
  Card,
} from '@tencent/tea-component';
import DropActionCreator from '@/api/DropActions';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { HOST_URL, TASK_TYPE_MAP } from '../../config';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';
import dayjs from 'dayjs';
import {
  Button,
  DatePicker,
  Dialog,
  Form,
  Input,
  Link,
  MessagePlugin,
  Popconfirm, Radio,
  Switch,
  Table,
  Tag,
  Tooltip,
} from 'tdesign-react';
import axios from 'axios';
import { goodStatus } from '../../utils/ErrorConfirm';
import relativeTime from 'dayjs/plugin/relativeTime';
import 'dayjs/locale/zh-cn';
import { CheckCircleFilledIcon, CloseCircleFilledIcon } from 'tdesign-icons-react';
import cron from 'cron-validate';

const { FormItem } = Form;
const VisFormItem = props => (props.visable ? props.children : '');

const scheduledTypes = {
  cron: {
    name: '循环',
    format: s => s,
  },
  'one-time': {
    name: '一次性',
    format: s => dayjs(Number(s)).format('YYYY-MM-DD HH:mm:ss'),
  },
};

const EditForm = (props) => {
  const [form] = Form.useForm();
  const data = props.row;
  if (data.type === 'one-time') {
    data.schedule = Number(data.schedule);
  }

  const disableTime = () => ({
    // 返回小时数组
    hour: [...Array(dayjs().hour()).keys()],
    minute: [...Array(dayjs().minute() + 1).keys()],
  });

  const submit = (e) => {
    if (e.validateResult !== true) {
      MessagePlugin.error('请正确填写表单');
      return;
    }

    props.onSubmit(Object.assign(form.getFieldsValue(true), { id: data.id }));
  };

  return <Form form={form}
                 onSubmit={submit}
                 initialData={data}
    >
        <FormItem label="任务名" name="name" rules={[
          { required: true, type: 'error', message: '请输入任务名' },
        ]}>
            <Input placeholder="请输入任务名"/>
        </FormItem>
        <FormItem label="定时类型" name="type">
            <Radio.Group>
                <Radio value="one-time">指定时间</Radio>
                <Radio value="cron">循环</Radio>
            </Radio.Group>
        </FormItem>
        <VisFormItem visable={Form.useWatch('type', form) === 'one-time'} style={{ marginLeft: 5 }}>
            <FormItem label="定时时间"
                      name="schedule"
                      rules={[
                        { required: true, type: 'error', message: '请选择执行时间' },
                        { validator: v => dayjs(v).isAfter(dayjs()), message: '请选择未来的时间' },
                      ]}>
                <DatePicker enableTimePicker allowInput clearable
                            valueType="time-stamp"
                            disableDate={{ before: dayjs().subtract(1, 'day')
                              .format() }}
                            timePickerProps={{ disableTime }}
                />
            </FormItem>
        </VisFormItem>
        <VisFormItem visable={Form.useWatch('type', form) === 'cron'} style={{ marginLeft: 5 }}>
            <FormItem label="Cron 表达式" name="schedule" help={'在线生成：https://crontab.guru/'} rules={[
              { required: true, type: 'error', message: '请输入 Cron 表达式' },
              { validator: v => cron(String(v))?.isValid(), message: '请输入正确的 Cron 表达式' },
            ]}>
                <Input placeholder="请输入合法的 Cron 表达式"></Input>
            </FormItem>
        </VisFormItem>
        <FormItem label="启用" name="enable">
            <Switch/>
        </FormItem>
        <FormItem style={{ marginLeft: 100 }}>
            <Button theme="primary" type="submit" style={{ marginRight: 50 }}>
                提交
            </Button>
            <Button type="reset">重置</Button>
        </FormItem>
    </Form>;
};

EditForm.propTypes = {
  row: PropTypes.any,
  onSubmit: PropTypes.any,
  onClose: PropTypes.any,
};

const TaskHistory = (props) => {
  dayjs.locale('zh-cn');
  dayjs.extend(relativeTime);

  const columns = [
    {
      title: '序号',
      colKey: 'number',
      align: 'center',
    },
    {
      title: '执行时间',
      colKey: 'created_at',
      align: 'center',
      cell: ({ row }) => <Tooltip content={dayjs(row.created_at).format('YYYY-MM-DD HH:mm:ss')}>
        {dayjs(row.created_at).fromNow()}
      </Tooltip>,
    },
    {
      title: '执行结果',
      colKey: 'result',
      align: 'center',
      cell: ({ row }) => <Tooltip content={row.result}>
        <Tag shape="round"
             theme={row.result === '' ? 'success' : 'danger'}
             variant="light-outline"
             icon={row.result === '' ? <CheckCircleFilledIcon/> : <CloseCircleFilledIcon/>} >
          {row.result === '' ? '成功' : '失败'}
        </Tag>
      </Tooltip>,
    },
    {
      title: '操作',
      colKey: 'task',
      align: 'center',
      cell: ({ row }) => <Link theme="primary" hover="color" onClick={() => {
        window.open(`/task/result?taskID=${row.task}&isSharedURL=false`);
      }}>
        查看
      </Link>,
    },
  ];

  return <Table
    rowKey="id"
    size="small"
    columns={columns}
    data={props.records}
    bordered
    pagination={{
      pageSizeOptions: [],
      theme: 'simple',
      defaultCurrent: 1,
      defaultPageSize: 10,
      total: props.records.length,
      showJumper: true,
      selectProps: {
        popupProps: {
          attach: () => document.getElementById('pagination-table'),
        },
      },
    }}
  ></Table>;
};

TaskHistory.propTypes = {
  records: PropTypes.array,
};

const ScheduledTask = () => {
  const [tasks, setTasks] = useState([]);
  const [currentTask, setCurrentTask] = useState({});
  const [editVisible, setEditVisible] = useState(false);
  const [historyVisible, setHistoryVisible] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [history, setHistory] = useState([]);

  const onEdit = (row) => {
    setCurrentTask(row);
    setEditVisible(true);
  };
  const OnHistory = (row) => {
    setCurrentTask(row);
    setHistoryVisible(true);
  };

  function getTaskHistory(id) {
    axios({
      url: `${HOST_URL}/api/v1/schedule/history`,
      params: {
        id,
        count: 30,
      },
    }).then((response) => {
      if (goodStatus(response)) {
        // add number field to records and sort by created_at desc
        setHistory(response.data.records.map((v, i) => {
          v.number = i + 1;
          return v;
        }).sort((a, b) => Date.parse(b.created_at) - Date.parse(a.created_at)));
      } else {
        MessagePlugin.error(`请求错误: ${response.msg}`);
      }
    })
      .catch((error) => {
        MessagePlugin.error(`请求错误: ${error}`);
      });
  }

  function getScheduledTasks() {
    axios({
      url: `${HOST_URL}/api/v1/schedule/task`,
    }).then((response) => {
      if (goodStatus(response)) {
        // 按是否启用，创建时间倒序
        setTasks(response.data.tasks.sort((a, b) => {
          if (a.enable !== b.enable) {
            return b.enable - a.enable;
          }
          return Date.parse(b.created_at) - Date.parse(a.created_at);
        }));
      } else {
        MessagePlugin.error(`请求错误: ${response.msg}`);
      }
    })
      .catch((error) => {
        MessagePlugin.error(`请求错误: ${error}`);
      });
  }

  const editScheduledTask = (data) => {
    axios({
      method: 'put',
      url: `${HOST_URL}/api/v1/schedule/${data.id}`,
      data,
    }).then((response) => {
      if (goodStatus(response)) {
        setEditVisible(false);
        setRefresh(!refresh);
        MessagePlugin.success('编辑成功');
      } else {
        MessagePlugin.error(`编辑失败: ${response.msg}`);
      }
    })
      .catch((error) => {
        MessagePlugin.error(`编辑失败: ${error}`);
      });
  };

  const deleteTask = (row) => {
    axios({
      method: 'delete',
      url: `${HOST_URL}/api/v1/schedule/${row.id}`,
    })
      .then((response) => {
        if (goodStatus(response)) {
          setRefresh(!refresh);
          MessagePlugin.success('删除成功');
        } else {
          MessagePlugin.error(`删除失败: ${response.msg}`);
        }
      })
      .catch((error) => {
        MessagePlugin.error(`删除失败: ${error}`);
      });
  };

  useEffect(() => {
    getScheduledTasks();
  }, [refresh]);

  useEffect(() => {
    if (historyVisible) {
      getTaskHistory(currentTask.id);
    }
  }, [currentTask]);

  const columns = [
    {
      title: '任务名',
      colKey: 'name',
      align: 'center',
    },
    {
      title: <Tooltip content="如需修改任务内容，请删除计划任务后再次发起任务">
                {'任务类型'}
            </Tooltip>,
      colKey: 'task_type',
      align: 'center',
      cell: ({ row }) => <Tag shape="round">
                {TASK_TYPE_MAP[row.task_type]}
            </Tag>,
    },
    {
      title: '定时类型',
      colKey: 'type',
      align: 'center',
      cell: ({ row }) => <Tag shape="round">
                {scheduledTypes[row.type].name}
            </Tag>,
    },
    {
      title: '定时时间',
      colKey: 'schedule',
      align: 'center',
      cell: ({ row }) => scheduledTypes[row.type].format(row.schedule),
    },
    {
      title: '启用',
      colKey: 'enable',
      align: 'center',
      cell: ({ row }) => <Tag
                shape="round"
                theme={row.enable ? 'success' : 'danger'}
                variant="light-outline"
                icon={row.enable ? <CheckCircleFilledIcon/> : <CloseCircleFilledIcon/>}
            >
                {row.enable ? '启用' : '禁用'}
            </Tag>,
    },
    {
      title: '操作',
      colKey: 'delete',
      align: 'center',
      cell: ({ row }) => <>
                    <Link theme="primary" hover="color" onClick={() => onEdit(row)}>
                        编辑
                    </Link>
                    <Popconfirm
                        content="确认删除吗"
                        destroyOnClose
                        placement="top"
                        showArrow
                        theme="default"
                        onConfirm={() => deleteTask(row)}
                    >
                        <Link theme="primary" hover="color" style={{ marginLeft: 10 }}>
                            删除
                        </Link>
                    </Popconfirm>
                    <Link theme="primary"
                          hover="color"
                          onClick={() => OnHistory(row)}
                          style={{ marginLeft: 10 }}>
                      历史记录
                    </Link>
                </>,
    },
  ];

  return <Card>
        <Card.Body title="定时任务">
            <Table
                rowKey="id"
                size="small"
                columns={columns}
                data={tasks}
                bordered
                pagination={{
                  defaultCurrent: 1,
                  defaultPageSize: 10,
                  total: tasks.length,
                  showJumper: true,
                  selectProps: {
                    popupProps: {
                      attach: () => document.getElementById('pagination-table'),
                    },
                  },
                }}
            ></Table>
        </Card.Body>
        <Dialog
            header="编辑计划任务"
            confirmBtn={null}
            cancelBtn={null}
            onClose={() => setEditVisible(false)}
            visible={editVisible}
        >
            <EditForm key={currentTask.id} row={currentTask}
                      onSubmit={data => editScheduledTask(data)}
                      onClose={() => setEditVisible(false)}
            />
        </Dialog>
    <Dialog
      header={`"${currentTask.name}" 历史记录`}
      confirmBtn={null}
      cancelBtn={null}
      onClose={() => setHistoryVisible(false)}
      visible={historyVisible}
    >
      <TaskHistory key={currentTask.id} records={history}
                   onClose={() => setHistoryVisible(false)}
      />
    </Dialog>
    </Card>;
};

ScheduledTask.propTypes = {
  DropReducer: PropTypes.any,
  DropAction: PropTypes.any,
  history: PropTypes.any,
};

const mapStateToProps = (state) => {
  const { DropReducer } = state;
  return {
    DropReducer,
  };
};// Reducer

const mapDispatchToProps = (dispatch) => {
  const DropAction = bindActionCreators(DropActionCreator, dispatch);

  return {
    DropAction,
  };
};// Action


export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(memo(withRouter(ScheduledTask)));
