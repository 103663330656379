export function getFlameGraphURL(baseURL, funcName) {
  const key = 'flamegraph_search';
  const url = new URL(baseURL);
  url.searchParams.set(key, `${escapeRegex(funcName)}\\s`);
  return url;
}

export const downloadFile = (filename, url) => {
  const xhr = new XMLHttpRequest();
  xhr.open('GET', url, true);
  xhr.responseType = 'blob';
  xhr.onload = function () {
    if (xhr.status === 200) {
      const blob = new Blob([xhr.response]);
      const link = document.createElement('a');
      link.href = URL.createObjectURL(blob);
      link.download = filename;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  };
  xhr.send();
};

const getLocalStorage = (key) => {
  const value = window.localStorage.getItem(key);
  if (value === null) {
    return '';
  }
  return value;
};


export const jumpTargetConfig = () => {
  // 正常登陆
  if (getLocalStorage('drop_user_uid') === '') {
    return '_blank';
  }
  return '_self';
};

// https://stackoverflow.com/questions/3561493/is-there-a-regexp-escape-function-in-javascript
function escapeRegex(s) {
  return s.replace(/[/\-\\^$*+?.()|[\]{}]/g, '\\$&');
}
