// eslint-disable-next-line
export default {
  SEND_DROP_START: 'SEND_DROP_START',
  DID_SEND_DROP_START: 'DID_SEND_DROP_START',

  DID_GET_DROP_INFO: 'DID_GET_DROP_INFO',
  GET_DROP_INFO: 'GET_DROP_INFO',

  GET_DROP_AGENT_INFO_IP: 'GET_DROP_AGENT_INFO_IP',
  DID_GET_DROP_AGENT_INFO_IP: 'DID_GET_DROP_AGENT_INFO_IP',

  // 添加drop_result
  DID_GET_DROP_TASK_RESULT: 'DID_GET_DROP_TASK_RESULT',
  GET_DROP_TASK_RESULT: 'GET_DROP_TASK_RESULT',

  DID_GET_DROP_RESULT_IMAGE: 'DID_GET_DROP_RESULT_IMAGE',
  GET_DROP_RESULT_IMAGE: 'GET_DROP_RESULT_IMAGE',

  DID_GET_DROP_COST: 'DID_GET_DROP_COST',
  GET_DROP_COST: 'GET_DROP_COST',

  DELETE_DROP_TASK: 'DELETE_DROP_TASK',
  DID_DELETE_DROP_TASK: 'DID_DELETE_DROP_TASK',

  GET_DROP_AGENT_LIST: 'GET_DROP_AGENT_LIST',
  DID_GET_DROP_AGENT_LIST: 'DID_GET_DROP_AGENT_LIST',

  GET_AGENT_MONITOR_DATA: 'GET_AGENT_MONITOR_DATA',
  DID_GET_AGENT_MONITOR_DATA: 'DID_GET_AGENT_MONITOR_DATA',

  GET_USER_UID: 'GET_USER_UID',
  DID_GET_USER_UID: 'DID_GET_USER_UID',

  START_DISK_TRACING: 'START_DISK_TRACING',
  DID_START_DISK_TRACING: 'DID_START_DISK_TRACING',

  CREATE_GROUP: 'CREATE_GROUP',
  DID_CREATE_GROUP: 'DID_CREATE_GROUP',

  DISBAND_GROUP: 'DISBAND_GROUP',
  DID_DISBAND_GROUP: 'DID_DISBAND_GROUP',

  ADD_GROUP_MEMBER: 'ADD_GROUP_MEMBER',
  DID_ADD_GROUP_MEMBER: 'DID_ADD_GROUP_MEMBER',

  REMOVE_GROUP_MEMBER: 'REMOVE_GROUP_MEMBER',
  DID_REMOVE_GROUP_MEMBER: 'DID_REMOVE_GROUP_MEMBER',

  GET_GROUP: 'GET_GROUP',
  DID_GET_GROUP: 'DID_GET_GROUP',

  GET_GROUP_AGENT: 'GET_GROUP_AGENT',
  DID_GET_GROUP_AGENT: 'DID_GET_GROUP_AGENT',

  ADD_GROUP_AGENT: 'ADD_GROUP_AGENT',
  DID_ADD_GROUP_AGENT: 'DID_ADD_GROUP_AGENT',

  REMOVE_GROUP_AGENT: 'REMOVE_GROUP_AGENT',
  DID_REMOVE_GROUP_AGENT: 'DID_REMOVE_GROUP_AGENT',

  GET_GROUPS: 'GET_GROUPS',
  DID_GET_GROUPS: 'DID_GET_GROUPS',

  GET_USER_AGENTS: 'GET_USER_AGENTS',
  DID_GET_USER_AGENTS: 'DID_GET_USER_AGENTS',

  GET_DROP_RESULT_CALLGRAPH_IMAGE: 'GET_DROP_RESULT_CALLGRAPH_IMAGE',
  DID_GET_DROP_RESULT_CALLGRAPH_IMAGE: 'DID_GET_DROP_RESULT_CALLGRAPH_IMAGE',

  GET_CALLGRAPH_DATA: 'GET_CALLGRAPH_DATA',
  DID_GET_CALLGRAPH_DATA: 'DID_GET_CALLGRAPH_DATA',
  GET_TASK_RESULT: 'GET_TASK_RESULT',
  DID_GET_TASK_RESULT: 'DID_GET_TASK_RESULT',

  GET_SHARED_TASK_URL: 'GET_SHARED_TASK_URL',
  DID_GET_SHARED_TASK_URL: 'DID_GET_SHARED_TASK_URL',

  GET_FLAMEGRAPH_DATA: 'GET_FLAMEGRAPH_DATA',
  DID_GET_FLAMEGRAPH_DATA: 'DID_GET_FLAMEGRAPH_DATA',

  REMOVE_AGENT: 'REMOVE_AGENT',
  DID_REMOVE_AGENT: 'DID_REMOVE_AGENT',
};
