import React, { useEffect, useRef, useState } from 'react';
import { withRouter, useHistory } from 'react-router-dom';
import './style.css';
import { Upload, Form, Input, Space, Button, Select, MessagePlugin } from 'tdesign-react';
import { POST } from '@/api';
import moment from 'moment/moment';
import { Text } from '@tencent/tea-component';
import axios from 'axios';
import { HOST_URL } from '../../config';
import { goodStatus } from '../../utils/ErrorConfirm';

const { FormItem } = Form;
const getCurrentTime = () => moment(new Date()).format('MM-DD HH:mm');

const FileAnalysis = () => {
  const history = useHistory();
  const [files, setFiles] = useState([]);
  const [uploadUrl, setUploadUrl] = useState('');
  const [form] = Form.useForm();
  const uploadRef = useRef(null);
  let controller = new AbortController();

  useEffect(() => {
    try {
      axios({
        url: `${HOST_URL}/api/v1/file/upload`,
        params: {
          filename: `upload-${getCurrentTime()}`,
        },
      }).then((response) => {
        if (goodStatus(response) && response.data.uploadUrl) {
          setUploadUrl(response.data.uploadUrl);
        } else {
          MessagePlugin.error(`请求错误: ${response.msg}`);
        }
      });
    } catch (e) {
      MessagePlugin.error(`请求错误: ${e.message}`);
    }
  }, []);

  const onSubmit = async (e) => {
    if (files.length <= 0 || !(e.validateResult === true)) {
      return;
    }
    const formData = form.getFieldsValue(true);
    const content = new FormData();
    const url = new URL(uploadUrl);
    const path = decodeURIComponent(url.pathname.substring(1));
    content.append('file_cos_path',  path);
    content.append('file_source', 'cos');
    content.append('origin', 'drop');
    content.append('task_target_type', 'system');
    content.append('task_target_value', '');
    content.append('task_freq', '99');
    content.append('project_name', 'drop');
    content.append('task_duration', '10');
    content.append('task_name', formData.task_name);
    content.append('target_machine', formData.target_machine);
    let taskType = '0';
    if (formData.task_type === '6') {
      taskType = '6';
    }
    content.append('task_type', taskType);
    const res = await POST('/openapi/v1/task', content, { headers: { 'Content-Type': 'multipart/form-data' } });
    if (res.code) {
      return history.push('/tasks');
    }
    return MessagePlugin.error(res.message || '上传失败');
  };
  const rules = {
    task_type: [{ required: true, message: '文件类型必填', type: 'error' }],
    task_name: [{ required: true, message: '任务名必填', type: 'error' }],
    target_machine: [{ required: true, message: '采样任务执行机器名必填', type: 'error' }],
  };
  const taskOptions =  [
    { label: 'perf script/折叠调用栈数据/雨滴通用分析采集结果', value: '0' },
    { label: 'Java堆转储（Heap Dump）数据', value: '6' },
  ];
  return <div className='flie-analysis'>
    <Upload
      ref={uploadRef}
      files={files}
      tips={<>
        <Text> 帮助文档: </Text>
        <a rel="noreferrer"
           href="https://iwiki.woa.com/p/4008935837"
           target="_blank">iWiki</a>
        <Text> 支持格式: </Text>
        <a rel="noreferrer"
           href="https://man7.org/linux/man-pages/man1/perf-script.1.html"
           target="_blank">perf script</a>/
        <a rel="noreferrer"
           href="https://github.com/brendangregg/FlameGraph?tab=readme-ov-file#2-fold-stacks"
           target="_blank">折叠调用栈数据</a>/
        <>雨滴通用分析采集结果</>
      </>}
      autoUpload={true}
      requestMethod={async (file) => {
        try {
          await axios({
            method: 'PUT',
            url: uploadUrl,
            data: file.raw,
            headers: {
              'Content-Type': 'application/octet-stream',
            },
            timeout: 0,
            onUploadProgress: (progressEvent) => {
              if (progressEvent.lengthComputable) {
                const complete = (((progressEvent.loaded / progressEvent.total) * 100) | 0);
                uploadRef.current.uploadFilePercent({ file, percent: complete });
              }
            },
            signal: controller.signal,
          });
          return { status: 'success', response: { url: uploadUrl } };
        } catch (e) {
          return { status: 'fail' };
        }
      }}
      disabled={uploadUrl === ''}
      onChange={setFiles}
      method={'PUT'}
      theme="file"
      sizeLimit={{ size: 5, unit: 'GB' }}
      onCancelUpload={() => {
        controller.abort();
        controller = new AbortController();
      }}
      draggable
    />
    <div>
      <Form className='from' labelAlign="right" form={form} rules={rules} onSubmit={onSubmit} colon labelWidth={180}>
        <FormItem label="文件类型" name="task_type" initialData={taskOptions[0].label}>
          <Select options={taskOptions}/>
        </FormItem>
        <FormItem label="任务名" name="task_name" initialData={getCurrentTime()}>
          <Input placeholder='请输入任务名' />
        </FormItem>
        <FormItem label="采样任务执行机器名" name="target_machine" initialData={'文件上传'}>
          <Input placeholder='请输入采样任务执行机器名' />
        </FormItem>
        <FormItem style={{ marginLeft: 180 }}>
          <Space>
            <Button type="submit" theme="primary">
              提交
            </Button>
          </Space>
        </FormItem>
      </Form>
    </div>
  </div>;
};

export default withRouter(FileAnalysis);
