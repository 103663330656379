/*
description:封装请求
*/
import axios from 'axios';
import qs from 'qs';
import { HOST_URL } from '@/config';
import { Log } from '@/utils/Log';
import { _proxy } from '@/mock';
import { getOAName, getUserUid } from '@/utils/Storage';
const mockProxyInfo = _proxy.proxy;
const Axios = axios;

Axios.defaults.timeout = 30000; // 请求超时设置为30秒
Axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';
Axios.defaults.baseURL = HOST_URL;
// Axios.defaults.headers.common["X-Requested-With"] = "XMLHttpRequest";
Axios.defaults.withCredentials = true;
Axios.defaults.xsrfCookieName = 'csrftoken';
Axios.defaults.xsrfHeaderName = 'X-CSRFToken';

/*
 * Const requestList = [];
 * const cancelToken = Axios.CancelToken;
 */
// 请求列表
const requestList = {}; // 记录所有cgi的发起时间，以便判断响应是否是最新的

// 取消列表
const { CancelToken } = axios;
const sources = {};
// 请求拦截器，有需要的话就按照下面的网址配置一下, https://segmentfault.com/a/1190000016474460
/*eslint-disable*/
Axios.interceptors.request.use((config) => {
  config.crossdomain = true;
  // add header
  config.headers['Drop_user_uid'] = getUserUid();
  config.headers['Drop_user_name'] = getOAName();
  if ((config.method === 'post' && !config.headers["Content-Type"])|| config.method === 'patch') {
    config.data = qs.stringify(config.data);
  }
  const requestTimestamp = new Date().getTime();
  // const requestPath = getRequestPath(config.baseURL, config.url);
  config.timestamp = requestTimestamp;
  if (['get', 'post', 'patch', 'delete'].includes(config.method)) {
    const request = JSON.stringify(config.url) + JSON.stringify(config.params);
    config.cancelToken = new CancelToken((cancel) => {
      sources[request] = cancel;
    })
    //1.判断请求是否已存在请求列表，避免重复请求，将当前请求添加进请求列表数组；
    if (requestList[request] && requestList[request] !== 0) {
      sources[request](request);
    } else {
      requestList[request] = requestTimestamp;
    }
    // requestRecord[request] = requestTimestamp;
  }
  return config;
});
/*eslint-disable*/


// 响应拦截器
// More status code: https://developer.mozilla.org/en-US/docs/Web/HTTP/Status
/*eslint-disable*/
Axios.interceptors.response.use(
  (response) => {
    const request = JSON.stringify(response.config.url) + JSON.stringify(response.config.params);
    requestList[request] = 0
    if (response.headers && response.headers['content-type'] === 'application/octet-stream') {
      return response;
    }
    return response.data || undefined;
  },
  (error) => {
    // 错误处理
    Log.error('Axios interceptors error:', error);
    if (!error.response) {
      Log.error('!error.response');
    }
    if (axios.isCancel(error)) {
      requestList[error.Cancel] = 0
      return Promise.reject({
        code: 'canceled',
        message: 'canceled request',
      });
    }
    if (!error.response || [404].includes(error.response.status)) {
      Log.error('Oops! Something wrong happened.');
      return Promise.reject(error);
    } if ([401].includes(error.response.status)) {
      // 未登陆,前端做重定向至登陆界面
      if (error.response.data.code === 4010001) {
        console.log(error.response);
        // 跳转至登录前访问的页面
        const location = new URL(error.response.data.data.location);
        location.searchParams.set('redirect_uri', window.location.href);

        window.location.href = location.toString();
      }
    } else {
      Log.error('Oops! Something wrong happened, into else and return error');
      return Promise.reject(error);
    }
  },
);
/*eslint-disable*/


function mockProxy(url) {
  if (process.env.MOCK === 'false') {
    return url;
  }
  let newUrl = undefined;
  const { baseURL } = Axios.defaults;
  for (const key in mockProxyInfo) {
    if (url.indexOf(key) !== -1 && url.indexOf(baseURL) !== -1) {
      newUrl = url.replace(baseURL, mockProxyInfo[key]);
      Log.debug(`${'MOCK！！！！mock地址是'}${newUrl}`);
    }
  }
  return newUrl || url;
}

const POST = (url, params, config = {}) => {
  const newUrl = mockProxy(url);
  return Axios.post(newUrl, params, config);
};
const GET = (url, params, config = {}) => {
  config.params = params;
  const newUrl = mockProxy(url);
  return Axios.get(newUrl, config);
};
const DELETE = (url, params, config = {}) => {
  config.params = params;
  const newUrl = mockProxy(url);
  return Axios.delete(newUrl, config);
}; 
const PATCH = (url, params, config = {}) => {
  const newUrl = mockProxy(url);
  return Axios.patch(newUrl, params, config);
}; 
const PUT = (url, params, config = {}) => {
  const newUrl = mockProxy(url);
  return Axios.put(newUrl, params, config);
};


export { POST, GET, PATCH, PUT, DELETE, Axios };
