/*
time:2022-3-8
author:ashinz
description:Drop mini index
*/

import React, { memo, useState, useEffect } from 'react';
import {
  Card,
  Text,
  H6,
  Button,
  message,
  notification,
} from '@tencent/tea-component';
// import { bindActionCreators } from 'redux';
// import DropActionCreator from '@/api/DropActions';
import { withRouter } from 'react-router-dom';
// import { connect } from 'react-redux';
import '@tencent/tea-component/dist/tea.css';
import { goodStatus } from '@/utils/ErrorConfirm';
import { getOAName } from '@/utils/Storage';
import '../Drop.css';
// import PropTypes from 'prop-types';
import  AccessAgent  from '@/components/accessAgent';
import StartTaskComponent from '../../components/startTaskComponent';
import axios from 'axios';
import { HOST_URL } from '@/config';
import { bindActionCreators } from 'redux';
import DropActionCreator from '../../api/DropActions';
import { connect } from 'react-redux';


const miniIndex = withRouter((props) => {
  console.log(props);
  //  hook
  const [username] = useState(getOAName());
  const [agentList, setAgentList] = useState([]);
  const [flag, setFlag] = useState(false);

  // hook status
  const [showStartTask, setShowStartTask] = useState(false);

  //  useEffect
  useEffect(() => {
    axios({
      method: 'get',
      url: `${HOST_URL}/api/v1/agents`,
      params: {
        user: username,
      } })
      .then((response) => {
        if (goodStatus(response)) {
          setAgentList(response?.data?.agents);
          if (response.data.agents.length !== 0 && !flag) {
            notification.success({
              duration: 2000,
              description:
                '您已成功接入机器，快去尝试一下发起任务吧，也可以点击导航栏机器列表查看已有机器',
              footer: <Button type="text"
              onClick={() => {
                props.history.push('/agents');
              }}
              >发起</Button>,
            });
            setFlag(true);
          };
        } else {
          message.error({
            content: '用户信息获取失败,请重试',
          });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  return <>
    <Card>
        <Card.Body
          title="Drop简介"
          style={{ height: '530px' }}
        >
          <div  style={{ width: '50%', display: 'inline-block' }}>
          <Text theme="text">Drop通过数据采样、动态追踪等功能，帮助用户快速定位性能瓶颈并提出优化建议，为性能优化工程师提供交流与协作的平台， 助力降低业务成本提升产品竞争力、加速性能优化项目落地</Text>
          <div style={{ marginTop: '45px' }}>
            <Text theme="text">支持语言：<b>C/C++/Java</b></Text>
          </div>
          <div style={{ marginTop: '45px' }}>
            <H6>应用场景：</H6>
            <Text theme="text">采样系统的热点函数方法，通过活跃线程信息、函数占用CPU时间及函数间的调用关系，锁定优化代码路径；收集JVM内部活动，分析CPU、内存、GC情况，提供优化方法...
            </Text>
          </div >
          <div style={{ marginTop: '40px' }}>
            <img width='100%' alt='process' src='./process.png'></img>
          </div>
          </div>
          <img style={{ padding: '10px', margin: '10px 0px 200px 0px ', display: 'inline-block' }} width='50%' alt='Architecture' src='./Architecture.png'></img>
        </Card.Body>
    </Card>
    <Card>
      <AccessAgent style={{ height: '310px' }}></AccessAgent>
    </Card>
    <Card>
        <Card.Body
          title="通用分析"
          subtitle={
          <Button type="primary"
            onClick={() => setShowStartTask(true)}
            style={{ marginLeft: '40px' }}>
            发起通用分析任务
          </Button>}
        >
          <div  style={{  display: 'inline-block' }}>
          <Text theme="text">通过perf采样器收集数据，性能分析后生成优化建议</Text>
          <Text style={{ marginTop: '30px' }} parent='p' theme="text">主要板块：
            <b>开销数据、热点方法、活跃线程、火焰图</b></Text>
          </div>
          <div style={{  marginLeft: '130px', display: 'inline-block' }}>
          <Text theme="text">特色功能：火焰图</Text>
          <Text style={{ marginTop: '30px' }} parent='p' theme="text">倒序展示，增加过滤条件，定位代码问题速度++</Text>
          </div>
          <div style={{ marginLeft: '100px', display: 'inline-block' }}>
            <img width='500px' alt='flameGraph' src='./flameGraph.png'></img>
          </div>
        </Card.Body>
    </Card>
    <Card>
        <Card.Body
          title="Java采样分析"
          subtitle={<Button type="primary"
          onClick={() => setShowStartTask(true)}
          style={{ marginLeft: '40px' }}>
            发起Java采样分析任务
          </Button>}
        >
          <Text theme="text">通过async-profiler采样器收集目标JVM内部活动与Java程序的数据，
          快速定位OOM等常见问题，提供GC暂停时间长、线程阻塞等瓶颈的优化建议
          </Text>
          <div style={{ marginTop: '30px' }}>
            <Text theme="text" >主要板块：<b>CPU 、内存、GC</b></Text>
          </div>
        </Card.Body>
    </Card>
    <StartTaskComponent
      visable={showStartTask}
      agents={agentList}
      onClose={ () => setShowStartTask(false) }
    ></StartTaskComponent>
    </>;
});

// 只存一次接口数据 下次请求会替换掉当前的数据 所以需要把数据保存到state里
const mapStateToProps = (state) => {
  const { DropReducer } = state;
  return {
    DropReducer,
  };
};// Reducer

const mapDispatchToProps = (dispatch) => {
  const DropAction = bindActionCreators(DropActionCreator, dispatch);

  return {
    DropAction,
  };
};// Action


export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(memo(miniIndex));
