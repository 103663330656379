import './style.css';
import React, { useEffect, useState } from 'react';
import { withRouter, useHistory, useLocation } from 'react-router-dom';
import axios from 'axios';
import { HOST_URL } from '../../config';
import { Button, Dialog, Tabs, Card } from 'tdesign-react';
import TaskSelector from '../../components/taskSelector';
import PropTypes from 'prop-types';
import * as pako from 'pako';
import FlameGraph from '../../components/flamegraph/flamegraph';
import FibonaButton from '../../components/fibona';
import { taskTimeFormat } from '../../utils/DateTime';
import { SwapIcon } from 'tdesign-icons-react';
import { ColorDifferenceBar } from '../../components/flamegraph/d3-flamegraph/colorScheme';

const TaskCompare = () => {
  const history = useHistory();
  const location = useLocation();

  const [dialogVisible1, setDialogVisible1] = useState(false);
  const [dialogVisible2, setDialogVisible2] = useState(false);

  const [tasks, setTasks] = useState([]);

  const [selectedTask1, setSelectedTask1] = useState(null);
  const [selectedTask2, setSelectedTask2] = useState(null);
  const [compareMode, setCompareMode] = useState(0);

  const [finalResultType, setFinalResultType] = useState('');

  useEffect(() => {
    axios({
      method: 'get',
      url: `${HOST_URL}/api/v1/tasks`,
    }).then((res) => {
      const taskRes = res.data.tasks.filter(key => key.analysis_status === 2)
        .sort((a, b) => Date.parse(b.create_time.Time) - Date.parse(a.create_time.Time));
      setTasks(taskRes);
      const searchParams = new URLSearchParams(location.search);
      setSelectedTask1(taskRes.find(key => key.tid === searchParams.get('task1')));
      setSelectedTask2(taskRes.find(key => key.tid === searchParams.get('task2')));
    });
  }, []);

  let tabList = [
    { label: '任务1', value: 0 },
    { label: '任务2', value: 1 },
    { label: '差分火焰图-基于任务1', value: 2 },
    { label: '差分火焰图-基于任务2', value: 3 },
    { label: '差分火焰图-合并视图', value: 4 },
  ];

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    if (selectedTask1?.tid) {
      searchParams.set('task1', selectedTask1?.tid);
    }
    if (selectedTask2?.tid) {
      searchParams.set('task2', selectedTask2?.tid);
    }
    history.push({
      search: searchParams.toString(),
    });

    if (taskResultType(selectedTask1) === taskResultType(selectedTask1)) {
      setFinalResultType(taskResultType(selectedTask1));
      if (taskResultType(selectedTask1) === '通用分析结果') {
        tabList = [
          { label: '任务1', value: 0 },
          { label: '任务2', value: 1 },
          { label: '差分火焰图-基于任务1', value: 2 },
          { label: '差分火焰图-基于任务2', value: 3 },
          { label: '差分火焰图-合并视图', value: 4 },
        ];
      } else {
        tabList = [
          { label: '任务1', value: 0 },
          { label: '任务2', value: 1 },
        ];
      }
    } else {
      setFinalResultType('任务结果不一致');
    }
  }, [selectedTask1, selectedTask2]);

  const handleClose1 = () => {
    setDialogVisible1(false);
  };
  const handleClose2 = () => {
    setDialogVisible2(false);
  };

  return (
    <div style={{ minHeight: '1100px' }}>
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <Card style={{ width: '50%', marginRight: '10px' }}>
          <div style={{ padding: '8px', fontSize: '13px' }}>
            <p>任务名: <span>{selectedTask1?.name}</span></p>
            <p>任务ID: <span>{selectedTask1?.tid}</span></p>
            <p>目标机器: <span>{selectedTask1?.target_ip}</span></p>
            <p>创建时间: <span>{taskTimeFormat(selectedTask1?.create_time)}</span></p>
            <p>开始时间: <span>{taskTimeFormat(selectedTask1?.begin_time)}</span></p>
            <p>结束时间: <span>{taskTimeFormat(selectedTask1?.end_time)}</span></p>
            <p>结果类型: <span>{taskResultType(selectedTask1)}</span></p>
          </div>
          <Button
            style={{ margin: '5px' }}
            theme="primary"
            onClick={() => {
              setDialogVisible1(true);
            }}
          >
            任务1
          </Button>
        </Card>
        <Button className='swap-button' shape="circle" variant="outline" onClick={() => {
          setSelectedTask1(selectedTask2);
          setSelectedTask2(selectedTask1);
        }}>
          <SwapIcon />
        </Button>
        <Card style={{ width: '50%', marginLeft: '10px' }}>
          <div>
            <div style={{ padding: '8px', fontSize: '13px' }}>
              <p>任务名: <span>{selectedTask2?.name}</span></p>
              <p>任务ID: <span>{selectedTask2?.tid}</span></p>
              <p>目标机器: <span>{selectedTask2?.target_ip}</span></p>
              <p>创建时间: <span>{taskTimeFormat(selectedTask2?.create_time)}</span></p>
              <p>开始时间: <span>{taskTimeFormat(selectedTask2?.begin_time)}</span></p>
              <p>结束时间: <span>{taskTimeFormat(selectedTask2?.end_time)}</span></p>
              <p>结果类型: <span>{taskResultType(selectedTask2)}</span></p>
            </div>
          </div>
          <Button
            style={{ margin: '5px' }}
            theme="primary"
            onClick={() => {
              setDialogVisible2(true);
            }}
          >
            任务2
          </Button>
        </Card>
        <Dialog
          mode="modeless"
          draggable={true}
          visible={dialogVisible1}
          onClose={handleClose1}
          width="1500px"
          footer={false}
        >
          <TaskSelector
            tasks={tasks}
            onSelectTask={(task) => {
              setSelectedTask1(task);
              handleClose1();
            }}
          />
        </Dialog>
      </div>
      <Dialog
        mode="modeless"
        draggable={true}
        visible={dialogVisible2}
        onClose={handleClose2}
        width="1500px"
        footer={false}
      >
        <TaskSelector
          tasks={tasks}
          onSelectTask={(task) => {
            setSelectedTask2(task);
            handleClose2();
          }}
        />
      </Dialog>
      <Tabs
        style={{ marginTop: '20px' }}
        list={tabList}
        onChange={(value) => {
          setCompareMode(value);
        }}
        value={compareMode}
      >
      </Tabs>
      {finalResultType === '通用分析结果' ? <FlameGraphCompare
        task1={selectedTask1}
        task2={selectedTask2}
        compareMode={compareMode}
      /> : null}
      {finalResultType === '任务结果不一致' ? <text>任务结果不一致</text> : null}
    </div>
  );
};

const fetchTask = async (tid) => {
  const response = await axios.get(`${HOST_URL}/api/v1/task`, {
    params: { tid, is_shared_task: 0 },
  });
  return response.data;
};

const fetchFlameGraph = async (tid, flamegraphFile) => {
  const response = await axios.get(`${HOST_URL}/api/v1/cosfiles`, {
    params: { cos_files: [flamegraphFile], tid, is_shared_task: 0 },
  });
  if (response.data.cos_files[0]) {
    const data = await axios.get(response.data.cos_files[0], { responseType: 'arraybuffer' });
    try {
      const dataBuf = pako.inflate(data.data, { level: 9 /* python gzip 压缩的默认级别 */ });
      return JSON.parse(new TextDecoder().decode(dataBuf));
    } catch (e) {
      console.log(e);
    }
  }
};

const flameGraphCache = new Map();

const useFlameGraph = (task, setFlame) => {
  useEffect(() => {
    const getFlameGraph = async () => {
      if (task?.tid) {
        if (flameGraphCache.has(task.tid)) {
          setFlame(flameGraphCache.get(task.tid));
          console.log('从缓存中获取火焰图');
          return;
        }

        try {
          const taskData = await fetchTask(task.tid);
          const flamegraphFile = taskData.taskResult?.['']?.extended_flamegraph_file;

          if (flamegraphFile) {
            const data = await fetchFlameGraph(task.tid, flamegraphFile);
            const flameGraph = mergeData(data);
            flameGraphCache.set(task.tid, flameGraph);
            setFlame(flameGraph);
            console.log('火焰图获取成功');
          } else {
            setFlame(null);
          }
        } catch (error) {
          console.error('Error fetching flame graph:', error);
          setFlame(null);
        }
      } else {
        setFlame(null);
      }
    };

    getFlameGraph();
  }, [task]);
};

const FlameGraphCompare = (props) => {
  const [flame1, setFlame1] = useState(null);
  const [flame2, setFlame2] = useState(null);

  const [showFlame, setShowFlame] = useState(null);

  useFlameGraph(props.task1, setFlame1);
  useFlameGraph(props.task2, setFlame2);

  useEffect(() => {
    if (flame1 && flame2) {
      switch (props.compareMode) {
        case 0:
          setShowFlame(flame1);
          break;
        case 1:
          setShowFlame(flame2);
          break;
        case 2:
          setShowFlame(diffFlamegraph(flame1, flame2, 'f1'));
          break;
        case 3:
          setShowFlame(diffFlamegraph(flame1, flame2, 'f2'));
          break;
        case 4:
          setShowFlame(diffFlamegraph(flame1, flame2, 'both'));
          break;
      }
    }
  }, [flame1, flame2, props.compareMode]);

  useEffect(() => {
    console.log('火焰图切换', showFlame);
  }, [showFlame]);

  if (!flame1 || !flame2) {
    return <div>
      {!props.task1 && !flame1 ? <div>请选择任务1</div>
        : (props.task1 && !flame1 ? <div>任务1不支持火焰图</div> : null)}
      {!props.task2 && !flame2 ? <div>请选择任务2</div>
        : (props.task2 && !flame2 ? <div>任务2不支持火焰图</div> : null)}
    </div>;
  }
  if (!showFlame) {
    return <text>加载中</text>;
  }
  return <Card
    bordered
    size="medium"
    theme="normal"
  >
    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
      <FibonaButton title="火焰图对比分析" trigger={25} format="csv" data={() => fibonaContent(flame1, flame2)} />
      <ColorDifferenceBar />
    </div>
    <FlameGraph
      flamegraphContent={showFlame}
    />
  </Card>;
};

function diffFlamegraph(fg1, fg2, strategy = 'both') {
  function diffNode(node1, node2, total1, total2) {
    if (!node1 && !node2) return null;

    let result;
    if (!node1) {
      if (strategy === 'f1') return null;
      if (strategy === 'f2') result = { ...node2, value: node2.value, diff: -1, children: [] };
      if (strategy === 'both') result = { ...node2, value: (node2.value / total2), diff: -1, children: [] };
    } else if (!node2) {
      if (strategy === 'f1') result = { ...node1, value: node1.value, diff: 1, children: [] };
      if (strategy === 'f2') return null;
      if (strategy === 'both') result = { ...node1, value: (node1.value / total1), diff: 1, children: [] };
    } else {
      const diff = (node1.value / total1) - (node2.value / total2);
      if (strategy === 'f1') {
        result = {
          ...node1,
          value: node1.value,
          diff: diff / (node1.value / total1),
          children: [],
        };
      }
      if (strategy === 'f2') {
        result = {
          ...node2,
          value: node2.value,
          diff: diff / (node2.value / total2),
          children: [],
        };
      }
      if (strategy === 'both') {
        result = {
          ...node1,
          value: ((node1.value / total1) + (node2.value / total2)),
          diff: diff / ((node1.value / total1) + (node2.value / total2)),
          children: [],
        };
      }
    }

    const children1 = node1 && node1.children ? node1.children : [];
    const children2 = node2 && node2.children ? node2.children : [];

    if (strategy === 'f1') {
      children1.forEach((child1) => {
        const { func, process } = child1;
        const child2 = children2.find(c => c.func === func && c.process === process);
        const diffChild = diffNode(child1, child2, total1, total2);
        if (diffChild) {
          result.children.push(diffChild);
        }
      });
    }

    if (strategy === 'f2') {
      children2.forEach((child2) => {
        const { func, process } = child2;
        const child1 = children1.find(c => c.func === func && c.process === process);
        const diffChild = diffNode(child1, child2, total1, total2);
        if (diffChild) {
          result.children.push(diffChild);
        }
      });
    }

    if (strategy === 'both') {
      const generateKey = d => [d.process, d.func].join('\x1E');

      const allChildrenKeys = new Set([
        ...children1.map(c => generateKey(c)),
        ...children2.map(c => generateKey(c)),
      ]);

      allChildrenKeys.forEach((key) => {
        const child1 = children1.find(c => generateKey(c) === key);
        const child2 = children2.find(c => generateKey(c) === key);
        const diffChild = diffNode(child1, child2, total1, total2);
        if (diffChild) {
          result.children.push(diffChild);
        }
      });
    }

    return result;
  }

  const r = diffNode(fg1, fg2, fg1.value, fg2.value);
  return r;
}

function fibonaContent(flame1, flame2) {
  const diff = calculateDiff(flame1, flame2);
  const content = diff.slice(0, 50).map(item => `${item.path} ${item.diff.toFixed(4)}%`)
    .join('\n');
  return content;
}

function calculateDiff(flame1, flame2) {
  const map1 = convertToMap(flame1);
  const map2 = convertToMap(flame2);
  const allPaths = new Set([...map1.keys(), ...map2.keys()]);
  const diffList = [];

  for (const path of allPaths) {
    const value1 = map1.get(path) || 0;
    const value2 = map2.get(path) || 0;
    const diff = ((value1 / flame1.value) - (value2 / flame2.value)) * 100;
    if (Math.abs(diff) > 0.000001) {
      diffList.push({ path, diff });
    }
  }

  return diffList.sort((a, b) => Math.abs(b.diff) - Math.abs(a.diff));
}

function convertToMap(flame) {
  const map = new Map();
  const totalValue = flame.value;
  processNode(flame, '', map, totalValue);
  return map;
}

function processNode(node, path, map, totalValue) {
  const newPath = path ? `${path};${node.func}` : node.func;
  let curValue = node.value;

  if (node.children && node.children.length > 0) {
    node.children.forEach((child) => {
      processNode(child, newPath, map, totalValue);
      curValue -= child.value;
    });
  }

  map.set(newPath, (map.get(newPath) || 0) + curValue);
}

function taskResultType(task) {
  console.log('task', task);
  if (!task) {
    return '';
  }
  if (task?.type === 0) {
    return '通用分析结果';
  }
  if (task?.type === 5) {
    return 'Golang内存分析';
  }
  return '暂不支持的类型';
}

function mergeData(oriData) {
  if (!oriData) return null;

  const data = { ...oriData };
  if (!data.children) return data;

  const stack = [data];
  let n = null;
  while (n = stack.pop()) {
    if (!n.children) {
      continue;
    }

    const mergeMap = {};
    const { children } = n;

    for (let i = 0; i < children.length; ++i) {
      const child = children[i];
      const key = [child.process, child.func].join('\x1E');
      if (!mergeMap[key]) {
        const base = {};
        base.func = child.func;
        base.process = child.process;
        base.module = child.module;
        base.tid = 1; // 将tid设置为定值
        base.value = 0;
        mergeMap[key] = base;
      }

      const base = mergeMap[key];
      base.value += child.value;
      const baseLines = base.module.split('\n');
      const childModules = child.module.split('\n');

      childModules.forEach((childModule) => {
        if (!baseLines.includes(childModule.trim())) {
          base.module = `${base.module.trim()}\n${childModule.trim()}`;
        }
      });

      if (!child.children) continue;

      if (base.children) {
        base.children.push(...child.children);
      } else {
        base.children = [...child.children];
      }

      stack.push(base);
    }

    const newChildren = Object.values(mergeMap);
    if (newChildren.length > 0) {
      n.children = newChildren;
    }
  }

  return data;
}

FlameGraphCompare.propTypes = {
  task1: PropTypes.any,
  task2: PropTypes.any,
  compareMode: PropTypes.any,
};

export default withRouter(TaskCompare);
