/*
update time:2023年06月16日09:44:24
author:lokinli
description:汇编码显示
*/
import './style.css';
import React, { memo, useEffect, useState }   from 'react';
import { withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import DropActionCreator from '../../api/DropActions';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { HOST_URL, FIBONA_TRIGGER } from '@/config';
import axios from 'axios';
import qs from 'qs';
import {
  Select,
  Form,
  NotificationPlugin,
  Table,
  Tag,
  Dialog,
  Link,
} from 'tdesign-react';
import FibonaButton from '../../components/fibona';
const { FormItem } = Form;

const DisplayAsmCodeByTable = (props) => {
  const [suggestions, setSuggestions] = useState([{
    suggestion_id: '',
    suggestion_content: '',
    suggestion_href: '',
  }]);
  const [showSuggestionModel, setShowSuggestionModel] = useState(false);

  const suggestionColumn = [
    {
      colKey: 'suggestion_id',
      title: 'ID',
      width: '100',
    },
    {
      colKey: 'suggestion_content',
      title: '建议',
      width: '250',
    },
    {
      colKey: 'suggestion_href',
      title: '相关链接',
      cell: (record) => {
        if (!record.row?.suggestion_href) {
          return '暂无';
        }
        return <Link theme='primary' target='_blank' href={record.row.suggestion_href}>链接</Link>;
      },
    },
  ];

  const column = [
    {
      colKey: 'percent',
      title: '百分比',
      width: '100',
      cell: (record) => {
        const { percent } = record.row;
        let tag = percent;
        if (percent >= 50) {
          tag = <Tag theme='danger'>{percent}</Tag>;
        } else if (percent >= 30) {
          tag = <Tag theme="warning">{percent}</Tag>;
        }
        return tag;
      },
    },
    {
      colKey: 'addr',
      title: '地址',
      width: '120',
    },
    {
      colKey: 'hex',
      title: 'hex',
      width: '140',
    },
    // {
    //   colKey: 'jumpCode',
    //   title: '跳转地址',
    //   width: '120',
    // },
    {
      colKey: 'opcode',
      title: '指令码',
      width: '120',
    },
    {
      colKey: 'operand',
      title: '操作数',
      width: '300',
    },
    // {
    //   colKey: 'comment',
    //   title: '注释',
    //   width: '160',
    // },
    {
      colKey: 'code',
      title: '源代码(数字为对应行号)',
      cell: record => <div style={{ whiteSpace: 'pre-line' }}>{record.row.code}</div>,
    },
    {
      colKey: 'suggestions',
      title: '建议',
      width: '100',
      cell: (record) => {
        const suggestion =  record.row?.suggestion;
        if (suggestion?.length) {
          return <Link theme="primary" onClick={() => {
            setSuggestions(suggestion);
            setShowSuggestionModel(true);
          }}>
            查看
          </Link>;
        }
        return '';
      },
    },
  ];
  return <div>
  <Table
    columns={column}
    data={props.rows}
  />
   <Dialog
    closeBtn
    closeOnEscKeydown
    closeOnOverlayClick
    footer
    header
    mode="modal"
    onClose={() => setShowSuggestionModel(false)}
    placement="top"
    preventScrollThrough
    showOverlay
    theme="default"
    visible={showSuggestionModel}
  >
    <Table
      columns={suggestionColumn}
      data={suggestions}
    />
  </Dialog>
  </div>;
};

DisplayAsmCodeByTable.propTypes = {
  rows: PropTypes.array,
};

const FunctionAssemblyPerfReport = withRouter((props) => {
  // 初始化高亮横条
  // const [activeRow, setActiveRow] = useState(0);
  const [rows, setRows] = useState([]);
  // const [jumpRow, setJumpRow] = useState(null);
  // 初始化汇编码区域
  // const containerRef = useRef(null);
  // 获取参数并解析
  const args = qs.parse(props.location.search.slice(1));
  const [taskID] = useState(args.taskID);
  const [taskExpiredTime] = useState(Number(args.taskExpiredTime));
  const [sharedTaskToken] = useState(args.sharedTaskToken);
  const [isSharedURL] = useState(args.isSharedURL);
  const [selectedFunction, setSelectedFunction] = useState(args.functionName);
  const [allFucntionDicList, setAllFucntionDicList] = useState({});

  useEffect(() => {
    console.log(props);
    let requestParams = {};
    const annotateResults = '/drop_annotate_results.json';
    const cosFiles = taskID + annotateResults;
    if (isSharedURL === 'true') {
      requestParams = {
        cos_files: [cosFiles],
        tid: taskID,
        expired_time: taskExpiredTime,
        token: sharedTaskToken,
        is_shared_task: '1',
      };
    } else {
      requestParams = {
        cos_files: [cosFiles],
        tid: taskID,
        is_shared_task: '0',
      };
    }
    axios({
      method: 'get',
      url: `${HOST_URL}/api/v1/cosfiles`,
      params: requestParams,
    }).then((response) => {
      const files = response.data.cos_files;
      axios({
        method: 'get',
        url: files[0],
      }).then((response) => {
        console.log(response);
        setAllFucntionDicList(response);
        let asmCodeList = [];
        console.log(selectedFunction);
        const selectFunctionBool = selectedFunction in response;
        if (selectFunctionBool) {
          asmCodeList = response[selectedFunction].asmCodeArray;
        } else {
          const functionNameList = Object.keys(response);
          const firstFunction = functionNameList[0];
          asmCodeList = response[firstFunction].asmCodeArray;
          setSelectedFunction(firstFunction);
          const notification = NotificationPlugin.warning({
            title: '提示',
            content: '由于未携带符号表或其他原因，未找到选中函数对应汇编结果，已展示其他函数结果',
            closeBtn: true,
            duration: 3000,
            onCloseBtnClick: () => {
              NotificationPlugin.close(notification);
            },
          });
        }
        setRows(asmCodeList);
        // for (let i = 0; i < asmCodeList.length; i++) {
        //   if (asmCodeList[i].jumpTo) {
        //     setJumpRow(i);
        //     break;
        //   }
        // }
      });
    });
  }, []);

  // // 窗口上下滑动控制
  // useEffect(() => {
  //   if (containerRef.current && containerRef.current.children[activeRow]) {
  //     const containerTop = containerRef.current.offsetTop;
  //     const containerHeight = containerRef.current.offsetHeight;
  //     const rowTop = containerRef.current.children[activeRow].offsetTop;
  //     const rowHeight = containerRef.current.children[activeRow].offsetHeight;
  //     if (rowTop - 5 * rowHeight < containerTop) {
  //       containerRef.current.scrollTo(0, rowTop);
  //     } else if (rowTop + rowHeight > containerTop + containerHeight) {
  //       containerRef.current.scrollTo(
  //         0,
  //         rowTop + 5 * rowHeight - containerHeight,
  //       );
  //     }
  //   }
  // }, [activeRow]);

  // // 处理键盘的上下移动
  // const handleKeyDown = (event) => {
  //   switch (event.key) {
  //     case 'ArrowUp':
  //       setActiveRow(prev => (prev > 0 ? prev - 1 : prev));
  //       event.preventDefault();
  //       break;
  //     case 'ArrowDown':
  //       setActiveRow(prev => (prev < rows.length - 1 ? prev + 1 : prev));
  //       event.preventDefault();
  //       break;
  //     default:
  //       break;
  //   }
  // };

  // 更换选择的函数时，更新对应的汇编码
  const handleFuntionOptionChange = (event) => {
    console.log(event);
    setSelectedFunction(event);
    const asmCodeList = allFucntionDicList[event].asmCodeArray;
    setRows(asmCodeList);
    // for (let i = 0; i < asmCodeList.length; i++) {
    //   if (asmCodeList[i].jumpTo) {
    //     setJumpRow(i);
    //     break;
    //   }
    // }
  };

  // 返回页面进行渲染
  return (
    <div>
      <Form
        labelAlign="right"
        layout="vertical"
        preventSubmitDefault
        resetType="empty"
        showErrorMessage
      >
      <FormItem
        initialData= {selectedFunction}
        label="当前函数"
        help="选择切换函数展示热点汇编，调用占比为函数占整个样本占比，汇编调用百分比为汇编指令占该函数内百分比"
      >
      <Select value={selectedFunction} onChange={e => handleFuntionOptionChange(e)}
        options = {Object.keys(allFucntionDicList).map(key => (
          { value: key, label: key + (allFucntionDicList[key].percent ? `  调用占比${allFucntionDicList[key]?.percent}` : '') }
        ))} >
      </Select>
      </FormItem>
      </Form>
      <FibonaButton format="csv" trigger={FIBONA_TRIGGER.asm} title="热点汇编调优方案" data={() => {
        let data = '地址,指令码,操作数,源代码,百分比\r\n';
        const replaceRegexp = /[\n\r]/g;
        const addrSet = new Set();
        rows.forEach((row) => {
          if (row.percent > 10) {
            const N = 10;
            // 将上下N条代码也加入
            const index = rows.indexOf(row);
            const start = index - N > 0 ? index - N : 0;
            const end = index + N + 1 < rows.length ? index + N + 1 : rows.length;

            for (let i = start; i < end; i++) {
              const asmCode = rows[i];
              if (addrSet.has(asmCode.addr)) { // 去重
                continue;
              }

              const line = [asmCode.addr, asmCode.opcode, asmCode.operand.replace(replaceRegexp, ' '), asmCode.code.replace(replaceRegexp, ' '), `${asmCode.percent}%`];

              data += `${line.map((item) => {
                if (item.includes(',')) {
                  return `"${item}"`;
                }
                return item;
              }).join(',')}\r\n`;
              addrSet.add(asmCode.addr);
            }
          }
        });
        return data;
      }} />
    {/* <div
      className="container"
      onKeyDown={handleKeyDown}
      ref={containerRef}
      tabIndex={0}
    >
      <div className="row header">
        <div className="percent">占比(Percent)</div>
        <div className="addr">地址(Address)</div>
        <div className="jumpCode"></div>
        <div className="opcode">操作码(Opcode)</div>
        <div className="operand">操作数(Operand)</div>
        <div className="hex">十六进制(Hex)</div>
        <div className="comment">汇编指令注释(Comment)</div>
      </div>
      {rows.map((row, index) => (
        <div
          className={`row ${activeRow === index ? 'active' : ''} ${
            jumpRow === index ? 'jump' : ''
          }`}
          key={index}
        >
          <div className="percent">{row.percent}</div>
          <div className="addr">{row.addr}</div>
          <div className="jumpCode"></div>
          <div className="opcode">{row.opcode}</div>
          <div className="operand">{row.operand}</div>
          <div className="hex">{row.hex}</div>
          <div className="comment">{row.comment}</div>
        </div>
      ))}
    </div> */}
    <div style={{ marginTop: 10 }}>
      <DisplayAsmCodeByTable  rows={rows}/>
    </div>
    </div>
  );
});

// 只存一次接口数据 下次请求会替换掉当前的数据 所以需要把数据保存到state里
const mapStateToProps = (state) => {
  const { DropReducer } = state;
  return {
    DropReducer,
  };
};// Reducer

const mapDispatchToProps = (dispatch) => {
  const DropAction = bindActionCreators(DropActionCreator, dispatch);

  return {
    DropAction,
  };
};// Action


FunctionAssemblyPerfReport.propTypes = {
  allFucntionDicList: PropTypes.object,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(memo(FunctionAssemblyPerfReport));
