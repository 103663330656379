/*
description:页面底部组件
*/
import React, { memo } from 'react';
import '@tencent/tea-component/dist/tea.css';

const footer = () => <div className="demo-layout-footer"
        style={{ textAlign: 'center', height: '70px', marginBottom: '0px' }}>
        Copyright © Tencent Cloud. All Rights Reserved. 腾讯云 版权所有<br></br>
        负责团队 @ CSIG质量部 - 专项性能工程中心
  </div>;
export default memo(footer);
