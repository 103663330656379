import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Modal,
  Button,
  Form,
  Input,
} from '@tencent/tea-component';

const AddGroupMemberModal = (props) => {
  const [userName, setUserName] = useState('');

  return <>
      <Modal
        size="s"
        caption={ props.caption }
        visible={ props.visable }
        onClose={() => {
          props.onClose();
        }}
      >
    <Modal.Body>
        <Form>
            <Form.Item label="成员名">
              <Input placeholder="输入添加成员名"
                value={userName}
                onChange={(value) => {
                  setUserName(value);
                }}
                />
            </Form.Item>
        </Form>
    </Modal.Body>
    <Modal.Footer>
        <Button type="primary" onClick={() => {
          props.onClick(userName);
          setUserName('');
        }}>
            { props.okText }
        </Button>
        <Button type="weak" onClick={() => {
          props.onClose();
        }}>
            { props.cancelText }
        </Button>
    </Modal.Footer>
  </Modal>
    </>;
};

AddGroupMemberModal.propTypes = {
  caption: PropTypes.string,
  visable: PropTypes.bool,
  okText: PropTypes.string,
  cancelText: PropTypes.string,
  onClose: PropTypes.func,
  onClick: PropTypes.func,
};

export default AddGroupMemberModal;
