import React from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Blank,
  Layout,
} from '@tencent/tea-component';
const { Body, Content } = Layout;

const GroupBlank = props => <>
          <Layout>
      <Body>
        <Content>
          <Content.Body>
            <Blank
              title="尚未加入用户组"
              image={{
                background:
                  'https://imgcache.qq.com/qcloud/tcloud_dtc/static/tc_console/114b4d79-fdd8-4c8c-8771-418a8fa81bbb.svg',
                logo:
                  'https://imgcache.qq.com/qcloud/tcloud_dtc/static/tc_console_product/3ad6629b-8cf5-42ee-8a7f-4e3802768638.svg',
              }}
              operation={<Button type="primary" onClick={ () => props.createGroup() }>创建用户组</Button>}
            >
            </Blank>
          </Content.Body>
        </Content>
      </Body>
    </Layout>
    </>;

GroupBlank.propTypes = {
  createGroup: PropTypes.func,
};

export default GroupBlank;
