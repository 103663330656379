import { Button, Tooltip } from 'tdesign-react';
import { SearchIcon } from 'tdesign-icons-react';
import { FIBONA_HELP_URL } from '../../config';
import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { Log } from '../../utils/Log';

const FibonaButton = (props) => {
  const myRef = useRef(null);
  const variant = props.plain ? 'text' : 'base';

  const onClick = async () => {
    Log.debug("Button");
    // 回调，让上层知道Fibona被点击了
    if (props.FibonaClick !== undefined) {
      await props.FibonaClick();
    }
    try {
      let currentHTMLElement = myRef.current;
      if (props.domref != null) {
        currentHTMLElement = props.domref;
      }
      window.fibonaAI.send({
        PopupConfig: {
          rtx: 'rtx',
          UIConfig: {
            target: currentHTMLElement,
            title: props.title,
            maxWidth: '1000px',
          },
        },
        PromptConfig: {
          trigger_id: props.trigger,
          clues: {
            content: [
              {
                content: await props.data()
              },
            ],
          },
        },
        PluginConfig: {
          consumer: 'variable',
        },
      }, props.callback
     );
    } catch (err) {
      Log.error('FibonaAI 拉起失败：', err);
    }
  };

  const button = <div style={props.plain ? {} : { background: '#0076ba', width: 'fit-content', borderRadius: '3px' }}>
        <Button ref={myRef} variant={variant} icon={<SearchIcon/>}
                style={{ borderRadius: '3px 0 0 3px', background: 'transparent', border: '0' }}
                onClick={onClick}
        >
            <b>{props.title}</b>
        </Button>
        {props.plain ? ''
          : <Button variant={variant} style={props.plain ? {} : { border: '0', background: '#004d80', cursor: 'default' }}
                    disabled>
                <div style={{ fontSize: '12px', lineHeight: '12px' }}>
                    <p><b>FibonaAI</b></p>
                </div>
            </Button>}
    </div>;

  return <Tooltip theme="light"
                    content={<>使用帮助：<a href={FIBONA_HELP_URL} target="_blank" rel="noreferrer">iWiki</a></>}>
        {button}
    </Tooltip>;
};

FibonaButton.propTypes = {
  plain: PropTypes.bool,
  data: PropTypes.func,
  format: PropTypes.string,
  title: PropTypes.string,
  trigger: PropTypes.number,
  FibonaClick: PropTypes.func,
  domref: PropTypes.any,
  callback: PropTypes.func,
};

export default FibonaButton;
