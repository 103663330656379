import React, { memo, useState, useEffect } from 'react';
import {
  Card,
  List,
  ExternalLink,
  Radio,
  Form,
  Copy,
  message,
  Input,
  Text,
  Switch,
} from '@tencent/tea-component';
import '@tencent/tea-component/dist/tea.css';
import { Textarea } from 'tdesign-react';
import { goodStatus } from '@/utils/ErrorConfirm';
import { getOAName } from '@/utils/Storage';
import axios from 'axios';
import { HOST_URL } from '@/config';
import { AGENT_DOCKER_RUN_PORT, AGENT_DOCKER_RUN_HOST, K8S_IMAGE_NAME, AGENT_SSL_ENABLED } from '../../config';
import { yamlPatch } from 'yaml-diff-patch';
import yaml from 'js-yaml';
// import PropTypes from 'prop-types';

const AccessAgent = () => {
  const defaultSidecarYaml = '';

  // hook
  const [reslove, setReslove] = useState([]);
  const [link, setLink] = useState([]);
  const [chooseIndex, setChooseIndex] = useState([0, 0, 0]); // 网络 os 架构
  const [username] = useState(getOAName());
  const [uid, setUid] = useState('');
  const [command, setCommand] = useState('');
  const [accessType, setAccessType] = useState('docker');
  const [dockerMountType, setDockerMountType] = useState(1);
  const [networkEnv, setNetworkEnv] = useState(0); // 0:内网 1:外网
  const [architecture, setArchitecture] = useState(0); // 0: x86 1: arm64
  // const [os, setOS] = useState(0); // 0: centos7 1:centos8
  const os = 0; // CentOS7 的编译包可以在其他版本上运行，暂时注释操作系统选择
  const [cosIntranetAddr, setCosIntranetAddr] = useState('');

  const [TKExEKSPodName, setTKExEKSPodName] = useState('');
  const [TKExEKSPodNameSpace, setTKExEKSPodNameSpace] = useState('');

  const [SidecarYaml, setSidecarYaml] = useState('');
  const [SidecarContainerd, setSidecarContainerd] = useState(false);
  const [SidecarPid, setSidecarPid] = useState(true);
  const [SidecarLimitCPU] = useState('0m');
  const [SidecarLimitMemory] = useState('0Mi');
  const [SidecarResource, setSidecarResource] = useState(defaultSidecarYaml);

  // const CENTOS7 = 0;
  // const CENTOS8 = 1;
  const IDC = 1;
  const CSIGHUB = 0; // 腾讯内网
  // const TENCENTCLOUD_HUB = 1;
  // const X86 = 0;
  const ARM64 = 1;
  // Effect
  // 获取相关信息
  useEffect(() => {
    axios({
      method: 'get',
      url: `${HOST_URL}/api/v1/users`,
      params: {
        user: username,
      } })
      .then((response) => {
        if (goodStatus(response)) {
          setUid(response?.data?.uid);
          setReslove(['curl', `curl --resolve ${response?.data?.resolveURL}`]);
          setLink(response?.data?.link);
          setCosIntranetAddr(response?.data?.resolveURL.split(':')[2]);
          setCommand('curl --header '
          + `'Content-Type:application/x-sh' '${response.data.link[0]}' -o install_agent.sh `
          + `&& DROP_UID=${response.data.uid}  IDC=0 bash install_agent.sh`);
        } else {
          message.error({
            content: '用户信息获取失败,请重试',
          });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  const convertToSidecarYaml = (v) => {
    // try unmarshal yaml
    const res = yaml.load(v.resource);
    if (!res?.spec?.template?.spec?.containers) {
      message.error({ content: 'YAML格式错误' });
      return '';
    }
    const ctrName = 'drop-agent';

    const ops = [
      // { op: 'add', path: '/spec/template/spec/shareProcessNamespace', value: true },
    ];

    const ctr = {
      name: ctrName,
      image: 'csighub.tencentyun.com/drop/drop-agent-pub:latest',
      command: ['sudo', './drop_agent'],
      args: ['-uid', `${uid}`, '-serv_ip', `${AGENT_DOCKER_RUN_HOST}`, '-serv_port', `${AGENT_DOCKER_RUN_PORT}`, `${AGENT_SSL_ENABLED ? '-ssl' : ''}`],
      resources: {
        limits: {
          cpu: v.limitCPU,
          memory: v.limitMemory,
        },
        requests: {
          cpu: '0m',
          memory: '0Mi',
        },
      },
      // securityContext: {
      //   privileged: true,
      // },
    };

    const ctrWithPid = {
      name: ctrName,
      image: 'csighub.tencentyun.com/drop/drop-agent-pub:latest',
      command: ['sudo', './drop_agent'],
      args: ['-uid', `${uid}`, '-serv_ip', `${AGENT_DOCKER_RUN_HOST}`, '-serv_port', `${AGENT_DOCKER_RUN_PORT}`, `${AGENT_SSL_ENABLED ? '-ssl' : ''}`],
      resources: {
        limits: {
          cpu: v.limitCPU,
          memory: v.limitMemory,
        },
        requests: {
          cpu: '0m',
          memory: '0Mi',
        },
      },
      securityContext: {
        capabilities: {
          add: [
            'SYS_PTRACE',
          ],
        },
      },
    };

    if (v.containerd) {
      const volumeName = 'drop-containerd';

      ctr.volumeMounts = [{
        mountPath: '/run/containerd',
        name: volumeName,
      }];

      const volume = {
        name: volumeName,
        hostPath: {
          path: '/run/containerd',
          type: 'Directory',
        },
      };

      const volumeIndex = res.spec.template.spec.volumes.findIndex(v => v.name === volumeName);
      if (volumeIndex !== -1) {
        ops.push({ op: 'replace', path: `/spec/template/spec/volumes/${volumeIndex}`, value: volume });
      } else  {
        ops.push({ op: 'add', path: '/spec/template/spec/volumes/-', value: volume });
      }
    }

    const ctrIndex = res.spec.template.spec.containers.findIndex(ctr => ctr.name === ctrName);
    if (ctrIndex !== -1) {
      ops.push({ op: 'replace', path: `/spec/template/spec/containers/${ctrIndex}`, value: v.pid ? ctrWithPid : ctr });
    } else {
      ops.push({ op: 'add', path: '/spec/template/spec/containers/-', value: v.pid ? ctrWithPid : ctr });
    }

    if (v.pid) {
      ops.push({ op: 'add', path: '/spec/template/spec/shareProcessNamespace', value: true });
    }

    // if (res.spec.template.spec.containers[0]?.lifecycle?.postStart === undefined) {
    //   ops.push({ op: 'add', path: '/spec/template/spec/containers/0/lifecycle', value: {
    //     postStart: {
    //       exec: {
    //         command: ['bash', '-c', 'sleep 5'],
    //       },
    //     },
    //   } });
    // }

    setSidecarYaml(yamlPatch(v.resource, ops));
  };

  // other function
  const selectEnvironment = (type, value) => {
    const tempChoose = chooseIndex;
    tempChoose[type] = value;
    setChooseIndex(tempChoose);
    const installIndex = tempChoose[2] * 2 + tempChoose[1];
    const cosConfig = (tempChoose[0] === IDC) ? `COS_INTRANET_ADDR=${cosIntranetAddr}` : '';
    const Command = `${reslove[tempChoose[0]]} --header 'Content-Type:application/x-sh'`
      + ` '${link[installIndex]}' -o install_agent.sh && DROP_UID=${uid}`
      + ` IDC=${tempChoose[0]} ${cosConfig} bash install_agent.sh`;
    setCommand(Command);
  };

  const imageInfo = [
    'csighub.tencentyun.com/drop/drop-agent-pub',
    'yunyu.tencentcloudcr.com/drop/drop-agent-pub',
  ];

  const k8sdockerMountInfo = ['',
    `- mountPath: /run/docker.sock
          name: docker`,
    `- name: docker
        hostPath:
          path: /run/docker.sock
          type: Socket`];

  const dockerAccessContent = `sudo docker pull ${imageInfo[networkEnv]}:${architecture == ARM64 ? 'arm-' : ''}${K8S_IMAGE_NAME} \
      && sudo docker run -d --rm \
      --mount type=bind,source=/run/containerd,target=/run/containerd \
      --mount type=bind,source=/run/docker.sock,target=/run/docker.sock \
      --privileged \
      --pid=host --network=host \
      ${imageInfo[networkEnv]}:${architecture == ARM64 ? 'arm-' : ''}${K8S_IMAGE_NAME}  \
      /bin/bash -c 'sudo ../scripts/install_bcc_deps.sh &&  \
      ./drop_agent -uid ${uid} -serv_ip ${AGENT_DOCKER_RUN_HOST} -serv_port ${AGENT_DOCKER_RUN_PORT} ${AGENT_SSL_ENABLED ? '-ssl' : ''} -bcc_enabled 1'`;


  const k8SCommand = `["sudo","./drop_agent","-uid","${uid}",`
  + `"-serv_ip","${AGENT_DOCKER_RUN_HOST}","-serv_port","${AGENT_DOCKER_RUN_PORT}" ${AGENT_SSL_ENABLED ? ',"-ssl"' : ''}]`;
  const k8sAccessContent = `cat << EOF > agent.yaml && kubectl apply -f agent.yaml 
apiVersion: apps/v1
kind: DaemonSet
metadata:
  name: drop-agent
  labels:
    k8s-app: drop
spec:
  selector:
    matchLabels:
      name: drop-agent
  template:
    metadata:
      labels:
        name: drop-agent
    spec:
      tolerations:
      - key: node-role.kubernetes.io/control-plane
        operator: Exists
        effect: NoSchedule
      - key: node-role.kubernetes.io/master
        operator: Exists
        effect: NoSchedule
      containers:
      - name: drop-agent
        image: ${imageInfo[networkEnv]}:${architecture == ARM64 ? 'arm-' : ''}${K8S_IMAGE_NAME}
        resources:
          limits:
            memory: 1024Mi
          requests:
            cpu: 200m
            memory: 512Mi
        command: ${k8SCommand}
        securityContext:
          privileged: true
        volumeMounts:
        - mountPath: /run/containerd
          name: containerd
        ${k8sdockerMountInfo[dockerMountType]}
      terminationGracePeriodSeconds: 30
      hostNetwork: true
      hostPID: true
      securityContext:
        seccompProfile:
          type: Unconfined
      os:
        name: "linux"
      volumes:
      - name: containerd
        hostPath:
          path: /run/containerd
          type: Directory
      ${k8sdockerMountInfo[dockerMountType * 2]}
EOF`;

  const  TKExkubectlCommand = `kubectl annotate pod  \
\`kubectl get pods -n ${TKExEKSPodNameSpace} | grep ${TKExEKSPodName} | awk '{printf $1}'\` -n ${TKExEKSPodNameSpace} \
eks.tke.cloud.tencent.com/debug-pod='{"apiVersion":"v1","kind":"Pod","metadata":{"name":"pod-debugger"},\
"spec":{"containers":[{"image":"${imageInfo[CSIGHUB]}:${architecture ? 'arm-' : ''}latest","name":"debugger",\
"command":["sudo","./drop_agent","-uid","${uid}","-serv_ip","${AGENT_DOCKER_RUN_HOST}","-serv_port","${AGENT_DOCKER_RUN_PORT}" ${AGENT_SSL_ENABLED ? ',"-ssl"' : ''}],\
"resources":{"limits":{"memory":"200Mi","cpu":"100m"}},\
"securityContext":{"privileged":true},"volumeMounts":[{"mountPath":"/run/containerd","name":"containerd"}]}],\
"dnsPolicy":"ClusterFirst","hostIPC":true,"hostNetwork":true,"hostPID":true,\
"volumes":[{"name":"containerd","hostPath":{"path":"/run/containerd","type":"Directory"}}]}}'`;

  const TKExEKSCommand = `${reslove[IDC]} --header 'Content-Type:application/x-sh'`
  + ` '${link[architecture * 2 + os]}' -o install_agent.sh && DROP_UID=${uid}`
  + ' EKS=1 IDC=0 bash install_agent.sh';

  // const shareProcessNamespaceDoc = 'https://kubernetes.io/zh-cn/docs/tasks/configure-pod-container/share-process-namespace/';
  const accessContent = {
    k8s: <List type="number">
      <List.Item>接入需要privileged权限并挂载相关文件，请确认是否符合安全要求</List.Item>
      <List.Item>选择待接入机器配置信息
              <Form layout="fixed" fixedLabelWidth="7em">
                  <Form.Item tips="选择容器的运行时组件" label="容器运行时">
                      <Radio.Group
                          value={dockerMountType}
                          defaultValue={1}
                          onChange={value => setDockerMountType(value)}>
                          <Radio style={{ width: 150 }} name={1}>Docker</Radio>
                          <Radio style={{ width: 100 }} name={0}>Containerd</Radio>
                      </Radio.Group>
                  </Form.Item>
              </Form>
              <Form layout="fixed" fixedLabelWidth="7em">
                  <Form.Item tips="选择对应的网络环境" label="网络环境">
                      <Radio.Group
                          value={networkEnv}
                          defaultValue={0}
                          onChange={value => setNetworkEnv(value)}>
                          <Radio style={{ width: 150 }} name={0}>DevCloud</Radio>
                          <Radio style={{ width: 100 }} name={1}>公有云环境</Radio>
                      </Radio.Group>
                  </Form.Item>
              </Form>
              <Form layout="fixed" fixedLabelWidth="7em">
                  <Form.Item tips="使用hostnamectl查看架构信息" label="CPU架构">
                      <Radio.Group
                          value={architecture}
                          defaultValue={0}
                          onChange={value => setArchitecture(value)}>
                          <Radio style={{ width: 150 }} name={0}>x86_64  </Radio>
                          <Radio name={1}>aarch64  </Radio>
                      </Radio.Group>
                  </Form.Item>
              </Form>
      </List.Item>
          <List.Item>点击按钮复制如下命令
              <Copy text={k8sAccessContent}></Copy>
              <List>
                  <List.Item>
                      <Textarea
                          style={{ background: 'white', color: 'black' }}
                          autosize={{ minRows: 6, maxRows: 6 }}
                          value={k8sAccessContent}
                          readonly={true}
                      />
                  </List.Item>
              </List>
          </List.Item>
          <List.Item>Pod处于Running状态即说明接入成功，可在机器列表中查看，目前K8s部署仅支持发起热点通用任务</List.Item>
        </List>,
    sidecar: <List type="number">
        {/* <List.Item>接入需要开启 Pod 中
          <ExternalLink href={shareProcessNamespaceDoc}>shareProcessNamespace</ExternalLink>选项，
            请自行评估业务风险</List.Item> */}
        <List.Item>请选择接入自定义配置信息
            <Form layout="inline" style={{ display: 'flex' }}>
                <Form.Item tips={<Text>需要挂载 <code>/run/containerd</code> 目录，请自行选择挂载。该选项仅用于优化使用体验，不会影响实际采样结果。</Text>}
                           label="启用容器视角" name="containerd">
                    <Switch value={SidecarContainerd}
                            onChange={(v) => {
                              setSidecarContainerd(v);
                              convertToSidecarYaml({
                                containerd: v,
                                pid: SidecarPid,
                                limitCPU: SidecarLimitCPU,
                                limitMemory: SidecarLimitMemory,
                                resource: SidecarResource,
                              });
                            }}></Switch>
                </Form.Item>
              <Form.Item tips={<Text>开启后支持指定Pid为采样目标。需要开启进程命名空间共享与进程跟踪（SYS_PTRACE）权限。如需使用java采样功能，则必选。</Text>}
                         label="启用Pid支持" name="containerd">
                <Switch value={SidecarPid}
                        onChange={(v) => {
                          setSidecarPid(v);
                          convertToSidecarYaml({
                            containerd: SidecarContainerd,
                            pid: v,
                            limitCPU: SidecarLimitCPU,
                            limitMemory: SidecarLimitMemory,
                            resource: SidecarResource,
                          });
                        }}></Switch>
              </Form.Item>
                {/* <Form.Item tips={'CPU 资源限制越高，执行阶段越快，请根据机器配置和实际负载调整'}
                           label={'CPU资源限制'}>
                    <Input placeholder={'请输入 CPU 资源限制'}
                           value={SidecarLimitCPU}
                           onChange={(v) => {
                             setSidecarLimitCPU(v);
                             convertToSidecarYaml({
                               containerd: SidecarContainerd,
                               limitCPU: v,
                               limitMemory: SidecarLimitMemory,
                               resource: SidecarResource,
                             });
                           }}></Input>
                </Form.Item> */}
                {/* <Form.Item tips={'内存限制越高，执行阶段越快，请根据机器配置和实际负载调整'}
                           label={'内存资源限制'} >
                    <Input placeholder={'请输入内存资源限制'}
                           value={SidecarLimitMemory}
                           onChange={(v) => {
                             setSidecarLimitMemory(v);
                             convertToSidecarYaml({
                               containerd: SidecarContainerd,
                               limitCPU: SidecarLimitCPU,
                               limitMemory: v,
                               resource: SidecarResource,
                             });
                           }}></Input>
                </Form.Item> */}
                <Form.Item tips={'当前仅支持对 Deployment 改造，更多类型正在支持中'}
                           label={'欲改造资源 YAML'} name={'resource'}>
                    <Textarea placeholder={'请输入资源 YAML'}
                              value={SidecarResource}
                              onChange={(v) => {
                                setSidecarResource(v);
                                convertToSidecarYaml({
                                  containerd: SidecarContainerd,
                                  pid: SidecarPid,
                                  limitCPU: SidecarLimitCPU,
                                  limitMemory: SidecarLimitMemory,
                                  resource: v,
                                });
                              }}
                              style={{ 'flex-grow': 1 }}
                              rows="7"></Textarea>
                </Form.Item>
            </Form>
        </List.Item>

        <List.Item>点击按钮复制改造后 YAML&nbsp;
            <Copy text={SidecarYaml}></Copy>
            <List>
                <List.Item>
                    <Textarea
                        style={{ background: 'white', color: 'black' }}
                        value={SidecarYaml}
                        placeholder={'请先填写资源 YAML'}
                        readonly={true}
                        rows="7"
                    />
                </List.Item>
            </List>
        </List.Item>
        </List>,
    docker: <List type="number">
        <List.Item>接入需要privileged权限并挂载相关文件，请确认是否符合安全要求</List.Item>
        <List.Item>选择待接入机器配置信息
              <Form layout="fixed" fixedLabelWidth="7em">
                  <Form.Item tips="选择对应的网络环境" label="网络环境">
                      <Radio.Group
                          value={networkEnv}
                          defaultValue={0}
                          onChange={value => setNetworkEnv(value)}>
                          <Radio style={{ width: 150 }} name={0}>DevCloud</Radio>
                          <Radio style={{ width: 100 }} name={1}>公有云环境</Radio>
                      </Radio.Group>
                  </Form.Item>
              </Form>
              <Form layout="fixed" fixedLabelWidth="7em">
                  <Form.Item tips="使用hostnamectl查看架构信息" label="CPU架构">
                      <Radio.Group
                          value={architecture}
                          defaultValue={0}
                          onChange={value => setArchitecture(value)}>
                          <Radio style={{ width: 150 }} name={0}>x86_64  </Radio>
                          <Radio name={1}>aarch64  </Radio>
                      </Radio.Group>
                  </Form.Item>
              </Form>
        </List.Item>
          <List.Item>容器部署点击按钮复制如下命令
              <Copy text={dockerAccessContent}></Copy>
              <List>
                  <List.Item>
                      <Textarea
                          style={{ background: 'white', color: 'black' }}
                          autosize={{ minRows: 5, maxRows: 5 }}
                          value={dockerAccessContent}
                          readonly={true}
                      />
                  </List.Item>
              </List>
          </List.Item>
          <List.Item>容器持续运行即说明接入成功，可在机器列表中查看，目前容器部署仅支持发起热点通用任务</List.Item>
        </List>,
    general: <List type="number">
          <List.Item>选择待接入机器配置信息
              <Form layout="fixed" fixedLabelWidth="7em">
                  <Form.Item tips="选择对应的网络环境" label="网络环境">
                      <Radio.Group
                          defaultValue={0}
                          onChange={value => selectEnvironment(0, value)}>
                          <Radio style={{ width: 150 }} name={0}>DevCloud及公有云环境</Radio>
                          <Radio style={{ width: 100 }} name={1}>IDC环境</Radio>
                      </Radio.Group>
                  </Form.Item>
              </Form>
               {/* Agent静态链接后 CentOS7 版本可以在其他版本上运行，将操作系统选择暂时注释，默认使用 CentOS7 的默认值 */}
               {/* <Form layout="fixed" fixedLabelWidth="7em"> */}
               {/*   <Form.Item tips="可以使用cat /etc/centos-release查看" label="操作系统">*/}
               {/*       <Radio.Group*/}
               {/*           defaultValue={0}*/}
               {/*           onChange={value => selectEnvironment(1, value)}>*/}
               {/*           <Radio style={{ width: 150 }} name={0}>CentOS 7  </Radio>*/}
               {/*           <Radio name={1}>CentOS 8  </Radio>*/}
               {/*       </Radio.Group>*/}
               {/*   </Form.Item>*/}
               {/* </Form> */}
              <Form layout="fixed" fixedLabelWidth="7em">
                  <Form.Item tips="使用hostnamectl查看架构信息" label="CPU架构">
                      <Radio.Group
                          defaultValue={0}
                          onChange={value => selectEnvironment(2, value)}>
                          <Radio style={{ width: 150 }} name={0}>x86_64  </Radio>
                          <Radio name={1}>aarch64  </Radio>
                      </Radio.Group>
                  </Form.Item>
              </Form>
          </List.Item>
          <List.Item>点击按钮复制如下命令
              <Copy text={command}></Copy>
              <List>
                  <List.Item>
                      <Textarea
                          style={{ background: 'white', color: 'black' }}
                          autosize={{ minRows: 6, maxRows: 6 }}
                          value={command}
                          readonly={true}
                      />
                  </List.Item>
              </List>
          </List.Item>
          <List.Item>在待接入机器上执行该命令，提示Setup drop agent done则说明已成功接入，可在机器列表中查看。接入失败请参考<a href="https://iwiki.woa.com/pages/viewpage.action?pageId=1583794981">完整指引</a></List.Item>
      </List>,
    TKExkubectl: <List type="number">
      <List.Item>选择待接入机器配置信息
          <Form layout="fixed" fixedLabelWidth="7em">
              <Form.Item tips="使用hostnamectl查看架构信息" label="CPU架构">
                  <Radio.Group
                      defaultValue={0}
                      onChange={value => setArchitecture(value)}>
                      <Radio style={{ width: 150 }} name={0}>x86_64</Radio>
                      <Radio name={1}>aarch64</Radio>
                  </Radio.Group>
              </Form.Item>
          </Form>
      </List.Item>
      <List.Item>输入Pod相关信息
        <Form layout="fixed" fixedLabelWidth="7em">
            <Form.Item label="Namespace">
              <Input
              value={TKExEKSPodNameSpace}
              onChange={(value) => {
                setTKExEKSPodNameSpace(value);
              }}
            />
            </Form.Item>
            <Form.Item label="Pod">
              <Input
                value={TKExEKSPodName}
                onChange={(value) => {
                  setTKExEKSPodName(value);
                }}
              />
            </Form.Item>
        </Form>
      </List.Item>
      <List.Item>点击按钮复制如下命令
          <Copy text={TKExkubectlCommand}></Copy>
          <List>
              <List.Item>
                  <Textarea
                      style={{ background: 'white', color: 'black' }}
                      autosize={{ minRows: 6, maxRows: 6 }}
                      value={TKExkubectlCommand}
                      readonly={true}
                  />
              </List.Item>
          </List>
      </List.Item>
      <List.Item>在有凭证机器上执行该命令，对应Pod数量变成2/1即为成功，可在机器列表中查看。接入失败请参考<a href="https://iwiki.woa.com/pages/viewpage.action?pageId=1583794981">完整指引</a></List.Item>
  </List>,
    TKExEKS: <List type="number">
    <List.Item>选择待接入机器配置信息
        {/* Agent静态链接后 CentOS7 版本可以在其他版本上运行，将操作系统选择暂时注释，默认使用 CentOS7 的默认值 */}
        {/* <Form layout="fixed" fixedLabelWidth="7em"> */}
        {/*    <Form.Item tips="可以使用cat /etc/centos-release查看" label="操作系统">*/}
        {/*        <Radio.Group*/}
        {/*            defaultValue={0}*/}
        {/*            onChange={value => setOS(value)}>*/}
        {/*            <Radio style={{ width: 150 }} name={0}>CentOS 7  </Radio>*/}
        {/*            <Radio name={1}>CentOS 8 </Radio>*/}
        {/*        </Radio.Group>*/}
        {/*    </Form.Item>*/}
        {/* </Form> */}
        <Form layout="fixed" fixedLabelWidth="7em">
            <Form.Item tips="使用hostnamectl查看架构信息" label="CPU架构">
                <Radio.Group
                    defaultValue={0}
                    onChange={value => setArchitecture(value)}>
                    <Radio style={{ width: 150 }} name={0}>x86_64  </Radio>
                    <Radio name={1}>aarch64  </Radio>
                </Radio.Group>
            </Form.Item>
        </Form>
    </List.Item>
    <List.Item>点击按钮复制如下命令
        <Copy text={TKExEKSCommand}></Copy>
        <List>
            <List.Item>
                <Textarea
                    style={{ background: 'white', color: 'black' }}
                    autosize={{ minRows: 6, maxRows: 6 }}
                    value={TKExEKSCommand}
                    readonly={true}
                />
            </List.Item>
        </List>
    </List.Item>
    <List.Item>在待接入节点上执行该命令，提示Setup drop agent done则说明已成功接入，可在机器列表中查看。接入失败请参考<a href="https://iwiki.woa.com/pages/viewpage.action?pageId=1583794981">完整指引</a></List.Item>
  </List>,
  };

  return <Card.Body
        title="快速开始"
        operation={<ExternalLink href='https://iwiki.woa.com/pages/viewpage.action?pageId=1595698865'>使用指引</ExternalLink>}
    >
        <Form>
          <Form.Item tips="选择安装方式" label="安装方式">
              <Radio.Group
                  defaultValue={'docker'}
                  onChange={value => setAccessType(value)}>
                  <Radio style={{ width: 150 }} name={'docker'}>Docker</Radio>
                  <Radio style={{ width: 150 }} name={'k8s'}>K8s-DaemonSet</Radio>
                  <Radio style={{ width: 150 }} name={'sidecar'}>K8s-Sidecar</Radio>
                  <Radio style={{ width: 150 }} name={'general'}>二进制安装</Radio>
                  <Radio style={{ width: 150 }} name={'TKExEKS'}>TKEx-EKS节点</Radio>
                  {/* <Radio style={{ width: 150 }} name={'TKExkubectl'}>TKEx-Kubectl注入</Radio> */}
              </Radio.Group>
          </Form.Item>
        </Form>
        <div style={{ marginTop: 10 }}>
        {accessContent[accessType]}
        </div>
      </Card.Body>;
};

export default memo(AccessAgent);
