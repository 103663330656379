/* eslint-disable */
function generateHash(name) {
  // Return a vector (0.0->1.0) that is a hash of the input string.
  // The hash is computed to favor early characters over later ones, so
  // that strings with similar starts have similar vectors. Only the first
  // 6 characters are considered.
  const MAX_CHAR = 6;

  let hash = 0;
  let maxHash = 0;
  let weight = 1;
  const mod = 10;

  if (name) {
    for (let i = 0; i < name.length; i++) {
      if (i > MAX_CHAR) {
        break;
      }
      hash += weight * (name.charCodeAt(i) % mod);
      maxHash += weight * (mod - 1);
      weight *= 0.70;
    }
    if (maxHash > 0) {
      hash = hash / maxHash;
    }
  }
  return hash;
}

export function generateColorVector(name) {
  let vector = 0;
  if (name) {
    const nameArr = name.split('`');
    if (nameArr.length > 1) {
      name = nameArr[nameArr.length - 1]; // drop module name if present
    }
    name = name.split('(')[0]; // drop extra info
    vector = generateHash(name);
  }
  return vector;
}

export function generateIntHash(value) {
  if (typeof value === 'number') {
    return value;
  }
  let hash = 0; let i; let chr;
  if (value.length === 0) return hash;
  for (i = 0; i < value.length; i++) {
    chr = value.charCodeAt(i);
    hash = ((hash << 5) - hash) + chr;
    hash |= 0; // Convert to 32bit integer
  }
  return Math.abs(hash);
};
