import React, { useState, useEffect } from 'react';
import { Table, Card, Button, SearchBox, StatusTip, Tooltip } from '@tencent/tea-component';
import PropTypes from 'prop-types';
import EmptyTask from './emptyTask';
import ButtonShareTask from '@/components/buttonShareTask';
import { TASK_STATUS_INFO, TASK_TYPE_MAP, CODE_TO_MESSAGE } from '../../config';
import { taskTimeFormat } from '../../utils/DateTime';
const { pageable, autotip, filterable } = Table.addons;

const ALL_VALUE = '__ALL__';
// 受控组件
const IndexTaskList = (props) => {
  // hook
  const [inputKeyword, setInputKeyword] = useState('');
  const [keyword, setKeyword] = useState('');
  const [loading, setLoading] = useState(false);
  const [tasks, setTasks] = useState([]);
  const [taskType, setTaskType] = useState(ALL_VALUE);

  useEffect(() => {
    setInputKeyword(keyword);
    if (props.tasks.length === 0) {
      return ;
    }
    setLoading(true);
    setTimeout(() => {
      let tempTask = props.tasks;
      if (keyword) {
        tempTask = tempTask.filter(x => x.name.includes(keyword)
            || x.target_ip.includes(keyword));
      }
      if (taskType !== ALL_VALUE) {
        tempTask = tempTask.filter(x => x.type === taskType);
      }
      setTasks(tempTask);
      setLoading(false);
    }, 800);
  }, [keyword, taskType]);

  useEffect(() => {
    let tempTask = props.tasks;
    if (keyword) {
      tempTask = tempTask.filter(x => x.name.includes(keyword)
          || x.target_ip.includes(keyword));
    }
    if (taskType !== ALL_VALUE) {
      tempTask = tempTask.filter(x => x.type === taskType);
    }
    setTasks(tempTask);
  }, [props.tasks]);


  const columns = [
    {
      header: '任务名',
      key: 'name',
      align: 'center',

    },

    {
      header: '目标机器IP',
      key: 'target_ip',
      align: 'center',
      render: (record) => {
        const target = record.target_ip;
        if (target === '-') { // 复合任务可能存在多个目标主机的情况
          return <Tooltip title="该任务存在多个目标主机，请查看任务详情">{target}</Tooltip>;
        }
        return target;
      },
    },
    {
      header: '任务类型',
      key: 'type',
      align: 'center',
      render: record => TASK_TYPE_MAP[record.type],
      width: 100,
    },
    {
      header: '新建时间',
      key: 'create_time',
      align: 'center',
      render: record => taskTimeFormat(record.create_time),
      width: 150,
    },
    {
      header: '执行时间',
      key: 'begin_time',
      align: 'center',
      render: record => taskTimeFormat(record.begin_time),
      width: 150,
    },

    {
      header: '结束时间',
      key: 'end_time',
      align: 'center',
      render: record => taskTimeFormat(record.end_time),
      width: 150,
    },
    {
      header: '状态',
      key: 'status',
      align: 'center',
      render: (record) => {
        const key = record.status * 10 + record.analysis_status;
        return TASK_STATUS_INFO[key];
      },

    },
    {
      header: '结果',
      key: 'result',
      align: 'center',
      render: (record) => {
        // todo：优化状态信息
        if (record.status === 4) {
          if (record.analysis_status === 0) {
            return <StatusTip status="loading" loadingText="分析中">
            </StatusTip>;
          }
          return <Button type="link" onClick={
              () => {
                props.viewTask(record);
              }
            }>查看</Button>;
        }
        if (record.analysis_status === 3) {
          return <Tooltip title={CODE_TO_MESSAGE(record.status_info)}>
            <StatusTip status="error" errorText="分析失败"></StatusTip></Tooltip>;
        }
        if (record.status === 3) {
        // todo: 添加更多错误信息
          if (record.status_info === 'Unrecognized task and profiler type') {
            return <StatusTip status="error" errorText="agent不支持该类任务,请升级agent到最新版本">
                      </StatusTip>;
          }
          return <Tooltip title={CODE_TO_MESSAGE(record.status_info)}><StatusTip status="error" errorText="执行失败">
          </StatusTip></Tooltip>;
        }
        if (record.status !== 2) {
          return <StatusTip status="loading" loadingText="执行中">
                      </StatusTip>;
        }
        if (record.analysis_status !== 2) {
          return <StatusTip status="loading" loadingText="分析中">
                      </StatusTip>;
        }
        return <Button type="link" onClick={
          () => {
            props.viewTask(record);
          }
        }>查看</Button>;
      },
    },
    {
      header: '操作',
      key: 'shareTask',
      align: 'center',
      render: record => <ButtonShareTask task={record}></ButtonShareTask>,
    },
    {
      header: '操作',
      key: 'action',
      align: 'center',
      render: record => <Button type="link" onClick={ () => props.deleteTask(record)}>删除</Button>,
    },
  ];

  return (
    <div style = {props.style}>
        <Card.Body
          title="历史任务"
          subtitle={(props.viewTasks)
            ? <>
              <Button style={{ marginLeft: '20px' }} type = 'link' onClick={ () => props.viewTasks() }>历史任务详情
              </Button>
            </>
            : ''
          }
          operation = {
              <>
                <SearchBox
                  value={ inputKeyword }
                  onChange = { inputKeyword => setInputKeyword(inputKeyword) }
                  onSearch={keyword => setKeyword(keyword)}
                  onClear={() => setKeyword('')}
                  placeholder="输入任务名或IP查询"
                  />
              </>
          }
        >
        {
          (props.tasks.length !== 0) ? <div>
            <Table
          columns = { columns }
          recordKey = 'id'
          records = { tasks }
          bordered = {props.bordered}
          addons={[
            pageable({
              pageSize: props.tableRow,
              pageSizeVisible: false,
              pageCountChangingResetType: 'first',
            }),
            autotip({
              isLoading: loading,
              isFound: Boolean(keyword),
              onClear: () => setKeyword(''),
              foundKeyword: keyword,
            }),
            filterable({
              type: 'single',
              column: 'type',
              value: taskType,
              onChange: value => setTaskType(value),
              // 增加 "全部" 选项
              all: {
                value: ALL_VALUE,
                text: '全部',
              },
              // 选项列表
              options: [
                { value: 0, text: '通用采样分析' },
                { value: 1, text: 'Java采样分析' },
                { value: 2, text: '磁盘追踪' },
                { value: 3, text: '时序分析' },
                { value: 4, text: '内存泄漏检测' },
                { value: 6, text: 'Java堆分析' },
                { value: -1, text: '动态检测' },
              ],
            }),
          ]}
        ></Table></div>
            : <EmptyTask/>}
        </Card.Body>
    </div>
  );
};

IndexTaskList.propTypes = {
  tasks: PropTypes.array, // agent信息
  onSearchTask: PropTypes.func,  // 搜索任务
  viewTask: PropTypes.func,  // 查看任务
  viewTasks: PropTypes.func, // 历史任务详情
  deleteTask: PropTypes.func, // 删除任务
  style: PropTypes.object,
  tableRow: PropTypes.number,
  bordered: PropTypes.any,
};


IndexTaskList.defaultProps = {
  tableRow: 4,
  bordered: false,
};

export default IndexTaskList;
